import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { isProduction } from './services/core/env';
import store from './store/store';

if (!isProduction()) {
    console.debug('process.env', process.env);
}

const root = createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <App />
    </Provider>
    // </React.StrictMode>
);

reportWebVitals(console.debug);
