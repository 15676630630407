import React from 'react';

function EditIcon() {
    return (
        <svg
            className=" fill-[#103B58] group-hover:fill-[#F35B54]"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            height={22}
            width={22}
            viewBox="0 0 26 27">
            <path
                // fill="#103B58"
                d="M4.404 23.107l6.557-2.318a.77.77 0 00.28-.176L24.823 7.03a3.751 3.751 0 000-5.3 3.751 3.751 0 00-5.299 0L5.942 15.315a.76.76 0 00-.176.28l-2.318 6.557a.75.75 0 00.956.956zm14.59-18.726l3.18 3.18L10.71 19.023l-3.179-3.18L18.994 4.382zm4.77-1.59a2.25 2.25 0 010 3.18l-.53.53-3.18-3.18.53-.53a2.25 2.25 0 013.18 0zM6.782 17.214l2.558 2.558-3.957 1.4 1.399-3.958z"></path>
            <path
                // fill="#103B58"
                d="M10.181 16.374a.75.75 0 001.06 0l7.993-7.994a.75.75 0 10-1.06-1.06l-7.993 7.994a.75.75 0 000 1.06zM.342 25.463c0 .414.336.75.75.75h15.087a.75.75 0 000-1.499H1.092a.75.75 0 00-.75.75z"></path>
        </svg>
    );
}

export default EditIcon;
