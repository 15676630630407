import React from 'react';
import SearchMultipleSelect from '../../UI/SearchMultipleSelect';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { setKeyWordFilter, setSearchStudentName } from '../../../store/searchSponsorSlice';
import * as labelConst from '../../../utils/GetLabels';

function KeyWordSearchComp({ filterStudentsByKey, callStudentsApi }) {
    const [keyInput, setKeyInput] = useState('');
    const keySearch = useSelector((state) => state.searchSponsorSlice.keyWordFilter);
    const dispatch = useDispatch();

    function resetFilters() {
        dispatch(setKeyWordFilter([]));
        callStudentsApi();
    }

    const handleKeyInputKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === 'Return') {
            dispatch(setSearchStudentName(''));
            event.preventDefault();
            let input = keyInput.trim().toLocaleLowerCase();
            if (input && !keySearch.includes(input)) {
                dispatch(setKeyWordFilter([...keySearch, input]));
                filterStudentsByKey([...keySearch, input]);
            }
            setKeyInput('');
        }
    };

    const handleRemoveKeyInput = (indexToRemove) => {
        const filteredArray = keySearch.filter((_, index) => index !== indexToRemove);
        dispatch(setKeyWordFilter(filteredArray));
        filterStudentsByKey(filteredArray);
    };
    return (
        <>
            <SearchMultipleSelect
                placeholder={labelConst.STUDENT_KEY_SEARCH_INSTRUCTION}
                value={keyInput}
                onChange={({ target }) => setKeyInput(target.value)}
                label={'Key Word Search'}
                onKeyDown={handleKeyInputKeyDown}
                keySearch={keySearch}
                CloseHandle={handleRemoveKeyInput}
                resetHandleFilters={resetFilters}
            />
        </>
    );
}

export default KeyWordSearchComp;
