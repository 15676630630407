/* eslint-disable import/no-anonymous-default-export */
import InfoIcon from '@mui/icons-material/Info';

import Typography from './Typography';

export default (props) => {
    //console.debug('==> Label', props);

    if (!props.value) return null;

    if (props.flavor === 'helper') {
        return <Typography className="mt-1 text-xs text-gray-400">{props.value}</Typography>;
    } else if (props.flavor === 'error') {
        return <Typography className="mt-1 text-xs text-red-500">{props.value}</Typography>;
    } else if (props.flavor === 'helperBg') {
        return (
            <Typography className="mt-1 text-[12px] font-semibold text-white">
                <span>
                    <InfoIcon className="text-[15px] font-bold text-white" />{' '}
                </span>
                {props.value}
            </Typography>
        );
    } else {
        return <Typography className={`mt-1 ${ props.bottomMargin ? 'mb-[-5px]':''}  text-xs text-gray-500`}>{props.value}</Typography>;
    }
};
