import React, { Suspense, lazy } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { AuthProvider } from '../contexts/Auth';
import { DrawerProvider } from '../contexts/Drawer';
import Drawer from './UI/Imperative/Drawer';
import theme from '../styles/theme';
import dialog from '../services/app/dialog';
import Routes from './Routes';

import '../styles/index.css';

const DialogManager = lazy(() => import('./UI/Imperative/DialogManager'));

function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <AuthProvider>
                        <DrawerProvider>
                            <Routes />
                            <Suspense fallback={<p>Loading...</p>}>
                                <DialogManager ref={(mgr) => dialog.setManager(mgr)} />
                            </Suspense>
                            <Drawer />
                        </DrawerProvider>
                    </AuthProvider>
                </BrowserRouter>
            </ThemeProvider>
        </LocalizationProvider>
    );
}

export default App;
