import { isString } from '../../core/types';
import { INFO, WARN, SUCCESS, ERROR, CONFIRM, CANCEL, SUBMIT } from '../../../components/UI/Imperative/DialogManager';

let manager;

const objectify = (props) => (isString(props) ? { content: props } : props);

const setManager = (mgr) => (manager = mgr);

const custom = (props) => manager.showDialog(props);

const info = (props) => {
    const _props = Object.assign(
        { type: INFO, title: 'Info', actions: [{ type: SUBMIT, label: 'OK', variant: 'text' }] },
        objectify(props)
    );
    return new Promise((resolve) => {
        _props.onClose = () => resolve();
        manager.showDialog(_props);
    });
};

const warn = (props) => info(Object.assign({ type: WARN, title: 'Warning' }, objectify(props)));

const success = (props) => info(Object.assign({ type: SUCCESS, title: 'Success' }, objectify(props)));

const error = (props) => info(Object.assign({ type: ERROR, title: 'Error' }, objectify(props)));

const confirm = (props) => {
    let isConfirmed = false;
    const _props = Object.assign(
        {
            title: 'Confirm',
            actions: [
                { type: CANCEL, label: 'Cancel' },
                { type: SUBMIT, label: 'OK', onClick: () => (isConfirmed = true) }
            ]
        },
        objectify(props)
    );

    return new Promise((resolve) => {
        _props.onClose = () => resolve(isConfirmed);
        manager.showDialog({ ..._props, type: CONFIRM });
    });
};

const close = (dialog) => manager.close(dialog);

const subscribe = (dialog, subscriber) => manager.subscribe(dialog, subscriber);

const unsubscribe = (dialog) => manager.unsubscribe(dialog);

const valid = (dialog, valid) => manager.valid(dialog, valid);

// eslint-disable-next-line import/no-anonymous-default-export
export default { setManager, custom, info, warn, success, error, confirm, close, subscribe, unsubscribe, valid };
