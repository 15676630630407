import { META_KEYS, Roles } from '../../../utils/GetConstants';
import { generateYearList } from './LookingFor';

export const SPONSOR_CANDIDATE_LIST = {
    Employee: { id: 'employment', value: 'Employee', level: 4 },
    Event: { id: 'event', value: 'Event', level: 4 },
    'Grad Assistant': { id: 'gradAssistantship', value: 'Grad Assistant', level: 3 },
    Intern: { id: 'internship', value: 'Intern', level: 2 },
    Mentee: { id: 'mentorship', value: 'Mentee', level: 2 },
    Recruits: { id: 'recruits', value: 'Recruits', level: 2 },
    Scholars: { id: 'scholarship', value: 'Scholars', level: 0 },
    'clubs and team': { id: 'clubsandteam', value: 'clubs and team', level: 0 },
    'Students to Sponsor': { id: 'sponsorship', value: 'Students to Sponsor', level: 1 },
    Volunteers: { id: 'volunteer', value: 'Volunteers', level: 1 },
};

export const STUDENT_OPPORTUNITY_IDS = {
    Employment: { id: 'employment', value: 'Employment', level: 4 },
    Event: { id: 'event', value: 'Event', level: 4 },
    Internship: { id: 'internship', value: 'Internship', level: 3 },
    'Grad Assistantship': { id: 'gradAssistantship', value: 'Grad Assistantship', level: 3 },
    Mentorship: { id: 'mentorship', value: 'Mentorship', level: 3 },
    Recruitment: { id: 'recruits', value: 'Recruits', level: 2 },
    Scholarships: { id: 'scholarship', value: 'Scholarship', level: 0 },
    'clubs and team': { id: 'clubsandteam', value: 'clubs and team', level: 0 },
    Sponsorships: { id: 'sponsorship', value: 'Sponsorship', level: 1 },
    'Volunteer Opps': { id: 'volunteer', value: 'Volunteer Opps', level: 1 },
    'Summer Job': { id: 'SummerJob', value: 'Summer Job', level: 4 },
};

const GRAD_SPORTS_LIST = [
    'Baseball',
    'Beach Volleyball',
    'Bowling',
    'Cross Country',
    'Fencing',
    'Field Hockey',
    'Football',
    'Golf',
    'Gymnastics',
    'Ice Hockey',
    'Lacrosse',
    'Riffle',
    'Rowing',
    'Skiing',
    'Soccer',
    'Softball',
    'Swim / Dive',
    'Tennis',
    'Track & Field',
    'Volleyball',
    'Water Polo',
    'Wrestling',
];

const includedSubjectsMetaValues = ['Intern', 'Internship', 'Mentee', 'Mentorship'];

export function getOpportunityList({
    opportunityType,
    subjects,
    categories,
    listType = Roles.Sponsor,
    fieldValue,
    collegesList = [],
}) {
    const lookingForList = listType === Roles.Sponsor ? LOOKING_FOR_LIST : STUDENT_LOOKING_FOR_LIST;
    let list = [...lookingForList[opportunityType]];
    const newList = [];

    if (opportunityType === 'Employee' && fieldValue === 'Full-time') {
        if (subjects) {
            list[1].values[fieldValue][2] = {
                level: 5,
                values: subjects.map(({ id, name }) => ({ label: name, value: name, id })),
                key: META_KEYS.COLLEGE_FIELDS_CAT,
                parent: META_KEYS.COLLEGE_LIST,
            };
        }
        return [...list];
    }

    subjects &&
        newList.push({
            level: 1,
            values: subjects.map(({ id, name }) => ({ label: name, value: name, id })),
            key: META_KEYS.COLLEGE_FIELDS_CAT,
            parent: includedSubjectsMetaValues.includes(fieldValue)
                ? META_KEYS.LOCATION_TYPE
                : opportunityType === 'Grad Assistant' || opportunityType === 'Grad Assistantship'
                ? META_KEYS.GRAD_ASSISTNT_TYPE
                : META_KEYS.EMP_TYPE,
        });

    categories &&
        categories.length > 0 &&
        newList.push({
            level: 2,
            values: categories.map(({ id, name }) => ({ label: name, value: name, id })),
            key: META_KEYS.COLLEGE_FIELDS_SUBJECT,
            parent: META_KEYS.COLLEGE_FIELDS_CAT,
        });

    if (
        fieldValue === 'Mentee' ||
        fieldValue === 'Mentorship' ||
        fieldValue === 'Intern' ||
        fieldValue === 'Internship'
    ) {
        list.push(...newList);
        return [...list];
    }

    if (
        (opportunityType === 'Employee' && fieldValue === 'Intern') ||
        (opportunityType === 'Employment' && fieldValue === 'Internship')
    ) {
        const internList = list[list.length - 1].values[fieldValue];
        const slicedList = internList.slice(0, 2);
        slicedList.push(...newList);
        list[list.length - 1].values[fieldValue] = slicedList;
        return [...list];
    }

    if (opportunityType === 'Event' && (fieldValue === 'Virtual Event' || fieldValue === 'Campus Event')) {
        if (collegesList?.length > 0) {
            list[1].values[fieldValue][1] = {
                level: 4,
                label:
                    fieldValue === 'Virtual Event' ? 'Select college or "Not Applicable" from list' : 'Select college',
                values: collegesList.map(({ id, name }) =>
                    name === 'Loading...' ? { label: name, value: null, id: null } : { label: name, value: name, id }
                ),
                key: META_KEYS.COLLEGE_LIST,
                parent: META_KEYS.CAMPUS_EVENT_TYPE,
            };
        }
        if (subjects) {
            list[1].values[fieldValue][2] = {
                level: 5,
                values: subjects.map(({ id, name }) => ({ label: name, value: name, id })),
                key: META_KEYS.COLLEGE_FIELDS_CAT,
                parent: META_KEYS.COLLEGE_LIST,
            };
        }
        return [...list];
    }
    if (opportunityType === 'Event' && fieldValue === 'Official Visit') {
        if (subjects) {
            let values = {
                ...list[1]?.values,
                'Official Visit': [
                    {
                        level: 3,
                        label: 'Select a Field of Study from dropdown list',
                        values: subjects.map(({ id, name }) => ({ label: name, value: name, id })),
                        key: META_KEYS.COLLEGE_FIELDS_CAT,
                        parent: META_KEYS.EVENT_TYPE,
                    },
                ],
            };
            list[1].values = values;
        }
        return [...list];
    }

    switch (opportunityType) {
        case 'Employee':
        case 'Employment':
            const employmentType = listType === Roles.Sponsor ? 'Research Assistant' : 'Research Assistantship';
            list[1].values['Part-time'][1].values[employmentType] = newList;
            return [...list];
        case 'Grad Assistant':
        case 'Grad Assistantship':
            list[list.length - 1].values['Specific Fields of Study'] = newList;
            return [...list];

        default:
            return lookingForList[opportunityType];
    }
}

export const disableOpportunityOptions = ['Part-time'];

export const LOOKING_FOR_LIST = {
    Employee: [
        {
            level: 1,
            label: 'Select Employment Type',
            values: ['Full-time', 'Part-time'],
            key: META_KEYS.JOB_TYPE,
            parent: META_KEYS.OPP_TYPE,
        },
        {
            level: 2,
            values: {
                'Full-time': [
                    { level: 1, values: ['Paid', 'Unpaid'], key: META_KEYS.PAYMENT_TYPE, parent: META_KEYS.JOB_TYPE },
                    {
                        level: 2,
                        values: ['On Site', 'Virtual'],
                        key: META_KEYS.LOCATION_TYPE,
                        parent: META_KEYS.PAYMENT_TYPE,
                    },
                ],
                'Part-time': [
                    {
                        level: 3,
                        values: [
                            'Babysitter',
                            'Influencers',
                            'Lawn Mower',
                            'Office Assistant',
                            'Other',
                            'Pet Care',
                            'Private Lessons',
                            'Restaurant Help',
                            'Research Assistant',
                            'Snow Removal',
                            'Tutor',
                        ],
                        key: META_KEYS.EMP_TYPE,
                        parent: META_KEYS.JOB_TYPE,
                    },
                    {
                        level: 4,
                        values: {
                            Influencers: [],
                            Babysitter: [],
                            'Lawn Mower': [],
                            'Pet Care': [],
                            'Snow Removal': [],
                            'Restaurant Help': [],
                            'Office Assistant': [],
                            Other: [],
                            'Private Lessons': [
                                {
                                    level: 5,
                                    values: ['Athletics', 'Performing Arts', 'Spirit Squads'],
                                    key: META_KEYS.PVT_LESSON_TYPE,
                                    parent: META_KEYS.EMP_TYPE,
                                },
                                {
                                    level: 6,
                                    values: {
                                        Athletics: [
                                            {
                                                level: 1,
                                                values: GRAD_SPORTS_LIST,
                                                key: META_KEYS.SPORT,
                                                parent: META_KEYS.PVT_LESSON_TYPE,
                                            },
                                            {
                                                level: 2,
                                                values: ['Men', 'Women'],
                                                key: META_KEYS.GENDER_TYPE,
                                                parent: META_KEYS.SPORT,
                                            },
                                        ],
                                        'Performing Arts': [
                                            {
                                                level: 1,
                                                values: ['Band', 'Choir', 'Theatre'],
                                                key: META_KEYS.PERFORMING_ARTS,
                                                parent: META_KEYS.PVT_LESSON_TYPE,
                                            },
                                        ],
                                        'Spirit Squads': [
                                            {
                                                level: 1,
                                                values: ['Cheer', 'Dance'],
                                                key: META_KEYS.SPIRIT_SQUADS,
                                                parent: META_KEYS.PVT_LESSON_TYPE,
                                            },
                                        ],
                                    },
                                },
                            ],
                            'Research Assistants': [],
                            Tutor: [
                                {
                                    level: 1,
                                    values: ['English', 'Math', 'Science', 'Social Studies'],
                                    key: META_KEYS.TUTOR_TYPE,
                                    parent: META_KEYS.EMP_TYPE,
                                },
                                {
                                    level: 2,
                                    values: ['Grades K-4', 'Grades 5-8', 'High School', 'College'],
                                    isMultiple: true,
                                    key: META_KEYS.TUTOR_SUB_GRAD,
                                    parent: META_KEYS.TUTOR_TYPE,
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
    Event: [
        {
            level: 1,
            label: 'Select Event Type',
            values: ['Campus Event', 'Virtual Event', 'Official Visit'],
            key: META_KEYS.EVENT_TYPE,
            parent: META_KEYS.OPP_TYPE,
        },
        {
            level: 2,
            values: {
                'Campus Event': [
                    {
                        level: 3,
                        values: ['Info Session', 'Interview Request', 'Testimonials'],
                        key: META_KEYS.CAMPUS_EVENT_TYPE,
                        parent: META_KEYS.EVENT_TYPE,
                    },
                ],

                'Virtual Event': [
                    {
                        level: 1,
                        values: [
                            'Assessment Training',
                            'Info Session',
                            'Interview Coaching',
                            'Interview Request',
                            'Testimonials',
                        ],
                        key: META_KEYS.VIRTUAL_EVENT_TYPE,
                        parent: META_KEYS.EVENT_TYPE,
                    },
                ],
            },
        },
    ],

    'Grad Assistant': [
        { level: 1, values: ['Paid', 'Unpaid'], key: META_KEYS.PAYMENT_TYPE, parent: META_KEYS.OPP_TYPE },
        { level: 2, values: ['On Site', 'Virtual'], key: META_KEYS.LOCATION_TYPE, parent: META_KEYS.PAYMENT_TYPE },
        {
            level: 3,
            values: [
                'Athletic Administration',
                'Athletics - Specific Sports',
                'Admissions',
                'Business Office',
                'Marketing',
                'Performing Arts',
                'Spirit Squads',
                'Specific Fields of Study',
            ],
            parent: META_KEYS.LOCATION_TYPE,
            key: META_KEYS.GRAD_ASSISTNT_TYPE,
        },
        {
            level: 4,
            values: {
                'Athletics - Specific Sports': [
                    { level: 1, values: GRAD_SPORTS_LIST, key: META_KEYS.SPORT, parent: META_KEYS.GRAD_ASSISTNT_TYPE },
                    { level: 2, values: ['Men', 'Women'], key: META_KEYS.GENDER_TYPE, parent: META_KEYS.SPORT },
                ],
                'Performing Arts': [
                    {
                        level: 1,
                        values: ['Band', 'Choir', 'Theatre'],
                        key: META_KEYS.PERFORMING_ARTS,
                        parent: META_KEYS.GRAD_ASSISTNT_TYPE,
                    },
                ],
                'Spirit Squads': [
                    {
                        level: 1,
                        values: ['Cheer', 'Dance'],
                        key: META_KEYS.SPIRIT_SQUADS,
                        parent: META_KEYS.GRAD_ASSISTNT_TYPE,
                    },
                ],
                'Specific Fields of Study': [],
            },
        },
    ],
    Intern: [
        { level: 1, values: ['Paid', 'Unpaid'], key: META_KEYS.PAYMENT_TYPE, parent: META_KEYS.OPP_TYPE },
        { level: 2, values: ['On Site', 'Virtual'], key: META_KEYS.LOCATION_TYPE, parent: META_KEYS.PAYMENT_TYPE },
    ],
    Mentee: [
        { level: 1, values: ['Paid', 'Unpaid'], key: META_KEYS.PAYMENT_TYPE, parent: META_KEYS.OPP_TYPE },
        { level: 2, values: ['On Site', 'Virtual'], key: META_KEYS.LOCATION_TYPE, parent: META_KEYS.PAYMENT_TYPE },
    ],
    Recruits: [
        {
            level: 1,
            label: 'Select Grad Year',
            values: generateYearList(),
            key: META_KEYS.GRAD_YEAR,
            parent: META_KEYS.OPP_TYPE,
        },
        {
            level: 2,
            values: ['Athletics', 'Performing Arts', 'College Students', 'Spirit Squads'],
            key: META_KEYS.RECRUITS_TYPE,
            parent: META_KEYS.GRAD_YEAR,
        },
        {
            level: 3,
            values: {
                Athletics: [
                    { level: 1, values: GRAD_SPORTS_LIST, key: META_KEYS.SPORT, parent: META_KEYS.RECRUITS_TYPE },
                    { level: 2, values: ['Men', 'Women'], key: META_KEYS.GENDER_TYPE, parent: META_KEYS.SPORT },
                ],
                'Performing Arts': [
                    {
                        level: 1,
                        values: ['Band', 'Choir', 'Theatre'],
                        key: META_KEYS.PERFORMING_ARTS,
                        parent: META_KEYS.RECRUITS_TYPE,
                    },
                ],
                'Spirit Squads': [
                    {
                        level: 1,
                        values: ['Cheer', 'Dance'],
                        key: META_KEYS.SPIRIT_SQUADS,
                        parent: META_KEYS.RECRUITS_TYPE,
                    },
                ],
            },
        },
    ],
    Scholars: [],
    'Students to Sponsor': [
        {
            level: 1,
            values: ['Internship', 'Mentorship'],
            isMultiple: true,
            key: META_KEYS.SPONSORSHIP_TYPE,
            parent: META_KEYS.OPP_TYPE,
        },
    ],
    Volunteers: [
        { level: 1, values: ['On Site', 'Virtual'], key: META_KEYS.LOCATION_TYPE, parent: META_KEYS.OPP_TYPE },
    ],
    'clubs and team': [],
};

export const STUDENT_LOOKING_FOR_LIST = {
    Employment: [
        {
            level: 1,
            label: 'Select Employment Type',
            values: ['Full-time', 'Part-time'],
            key: META_KEYS.JOB_TYPE,
            parent: META_KEYS.OPP_TYPE,
        },
        {
            level: 2,
            values: {
                'Full-time': [
                    { level: 1, values: ['Paid', 'Unpaid'], key: META_KEYS.PAYMENT_TYPE, parent: META_KEYS.JOB_TYPE },
                    {
                        level: 2,
                        values: ['On Site', 'Virtual'],
                        key: META_KEYS.LOCATION_TYPE,
                        parent: META_KEYS.PAYMENT_TYPE,
                    },
                ],
                'Part-time': [
                    {
                        level: 3,
                        values: [
                            'Babysitter',
                            'Influencers',
                            'Lawn Mower',
                            'Office Assistant',
                            'Other',
                            'Pet Care',
                            'Private Lessons',
                            'Restaurant Help',
                            'Research Assistantship',
                            'Snow Removal',
                            'Tutor',
                        ],
                        key: META_KEYS.EMP_TYPE,
                        parent: META_KEYS.JOB_TYPE,
                    },
                    {
                        level: 4,
                        values: {
                            Influencers: [],
                            Babysitter: [],
                            'Lawn Mower': [],
                            'Pet Care': [],
                            'Snow Removal': [],
                            'Restaurant Help': [],
                            'Office Assistant': [],
                            Other: [],
                            'Private Lessons': [
                                {
                                    level: 5,
                                    values: ['Athletics', 'Performing Arts', 'Spirit Squads'],
                                    key: META_KEYS.PVT_LESSON_TYPE,
                                    parent: META_KEYS.EMP_TYPE,
                                },
                                {
                                    level: 6,
                                    values: {
                                        Athletics: [
                                            {
                                                level: 1,
                                                values: GRAD_SPORTS_LIST,
                                                key: META_KEYS.SPORT,
                                                parent: META_KEYS.PVT_LESSON_TYPE,
                                            },
                                            {
                                                level: 2,
                                                values: ['Men', 'Women'],
                                                key: META_KEYS.GENDER_TYPE,
                                                parent: META_KEYS.SPORT,
                                            },
                                        ],
                                        'Performing Arts': [
                                            {
                                                level: 1,
                                                values: ['Band', 'Choir', 'Theatre'],
                                                key: META_KEYS.PERFORMING_ARTS,
                                                parent: META_KEYS.PVT_LESSON_TYPE,
                                            },
                                        ],
                                        'Spirit Squads': [
                                            {
                                                level: 1,
                                                values: ['Cheer', 'Dance'],
                                                key: META_KEYS.SPIRIT_SQUADS,
                                                parent: META_KEYS.PVT_LESSON_TYPE,
                                            },
                                        ],
                                    },
                                },
                            ],
                            'Research Assistantship': [],
                            Tutor: [
                                {
                                    level: 1,
                                    values: ['English', 'Math', 'Science', 'Social Studies'],
                                    key: META_KEYS.TUTOR_TYPE,
                                    parent: META_KEYS.EMP_TYPE,
                                },
                                {
                                    level: 2,
                                    values: ['Grades K-4', 'Grades 5-8', 'High School', 'College'],
                                    isMultiple: true,
                                    key: META_KEYS.TUTOR_SUB_GRAD,
                                    parent: META_KEYS.TUTOR_TYPE,
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
    'Summer Job': [
        {
            level: 1,
            label: 'Select type of Summer Job',
            values: [
                'Babysitter',
                'Influencers',
                'Lawn Mower',
                'Office Assistant',
                'Other',
                'Pet Care',
                'Private Lessons',
                'Restaurant Help',
                'Research Assistantship',
                'Tutor',
            ],
            key: META_KEYS.SUMMER_OPP_TYPE,
            parent: META_KEYS.OPP_TYPE,
        },
        {
            level: 2,
            values: {
                Influencers: [],
                Babysitter: [],
                'Lawn Mower': [],
                'Pet Care': [],
                'Snow Removal': [],
                'Restaurant Help': [],
                'Office Assistant': [],
                Other: [
                    {
                        level: 3,
                        values: [],
                        key: META_KEYS.SUMMER_OTHER_TYPE,
                        parent: META_KEYS.SUMMER_OPP_TYPE,
                        fieldType: 'open',
                        label: 'Describe Summer Job you are offering',
                        placeholder: 'Example: Child Camp Transportation',
                    },
                ],
                'Private Lessons': [
                    {
                        level: 5,
                        values: ['Athletics', 'Performing Arts', 'Spirit Squads'],
                        key: META_KEYS.PVT_LESSON_TYPE,
                        parent: META_KEYS.SUMMER_OPP_TYPE,
                    },
                    {
                        level: 6,
                        values: {
                            Athletics: [
                                {
                                    level: 1,
                                    values: GRAD_SPORTS_LIST,
                                    key: META_KEYS.SPORT,
                                    parent: META_KEYS.PVT_LESSON_TYPE,
                                },
                                {
                                    level: 2,
                                    values: ['Men', 'Women'],
                                    key: META_KEYS.GENDER_TYPE,
                                    parent: META_KEYS.SPORT,
                                },
                            ],
                            'Performing Arts': [
                                {
                                    level: 1,
                                    values: ['Band', 'Choir', 'Theatre'],
                                    key: META_KEYS.PERFORMING_ARTS,
                                    parent: META_KEYS.PVT_LESSON_TYPE,
                                },
                            ],
                            'Spirit Squads': [
                                {
                                    level: 1,
                                    values: ['Cheer', 'Dance'],
                                    key: META_KEYS.SPIRIT_SQUADS,
                                    parent: META_KEYS.PVT_LESSON_TYPE,
                                },
                            ],
                        },
                    },
                ],
                'Research Assistantship': [],
                Tutor: [
                    {
                        level: 1,
                        values: ['English', 'Math', 'Science', 'Social Studies'],
                        key: META_KEYS.TUTOR_TYPE,
                        parent: META_KEYS.SUMMER_OPP_TYPE,
                    },
                    {
                        level: 2,
                        values: ['Grades K-4', 'Grades 5-8', 'High School', 'College'],
                        isMultiple: true,
                        key: META_KEYS.TUTOR_SUB_GRAD,
                        parent: META_KEYS.TUTOR_TYPE,
                    },
                ],
            },
        },
    ],
    Event: [
        {
            level: 1,
            label: 'Select Event Type',
            values: ['Campus Event', 'Virtual Event', 'Official Visit'],
            key: META_KEYS.EVENT_TYPE,
            parent: META_KEYS.OPP_TYPE,
        },
        {
            level: 2,
            values: {
                'Campus Event': [
                    {
                        level: 3,
                        values: ['Info Session', 'Interview Request', 'Testimonials'],
                        key: META_KEYS.CAMPUS_EVENT_TYPE,
                        parent: META_KEYS.EVENT_TYPE,
                    },
                ],

                'Virtual Event': [
                    {
                        level: 1,
                        values: [
                            'Assessment Training',
                            'Info Session',
                            'Interview Coaching',
                            'Interview Request',
                            'Testimonials',
                        ],
                        key: META_KEYS.VIRTUAL_EVENT_TYPE,
                        parent: META_KEYS.EVENT_TYPE,
                    },
                ],
            },
        },
    ],
    Internship: [
        { level: 1, values: ['Paid', 'Unpaid'], key: META_KEYS.PAYMENT_TYPE, parent: META_KEYS.OPP_TYPE },
        { level: 2, values: ['On Site', 'Virtual'], key: META_KEYS.LOCATION_TYPE, parent: META_KEYS.PAYMENT_TYPE },
    ],
    'Grad Assistantship': [
        { level: 1, values: ['Paid', 'Unpaid'], key: META_KEYS.PAYMENT_TYPE, parent: META_KEYS.OPP_TYPE },
        { level: 2, values: ['On Site', 'Virtual'], key: META_KEYS.LOCATION_TYPE, parent: META_KEYS.PAYMENT_TYPE },
        {
            level: 3,
            values: [
                'Athletic Administration',
                'Athletics - Specific Sports',
                'Admissions',
                'Business Office',
                'Marketing',
                'Performing Arts',
                'Spirit Squads',
                'Specific Fields of Study',
            ],
            parent: META_KEYS.LOCATION_TYPE,
            key: META_KEYS.GRAD_ASSISTNT_TYPE,
        },
        {
            level: 4,
            values: {
                'Athletics - Specific Sports': [
                    { level: 1, values: GRAD_SPORTS_LIST, key: META_KEYS.SPORT, parent: META_KEYS.GRAD_ASSISTNT_TYPE },
                    { level: 2, values: ['Men', 'Women'], key: META_KEYS.GENDER_TYPE, parent: META_KEYS.SPORT },
                ],
                'Performing Arts': [
                    {
                        level: 1,
                        values: ['Band', 'Choir', 'Theatre'],
                        key: META_KEYS.PERFORMING_ARTS,
                        parent: META_KEYS.GRAD_ASSISTNT_TYPE,
                    },
                ],
                'Spirit Squads': [
                    {
                        level: 1,
                        values: ['Cheer', 'Dance'],
                        key: META_KEYS.SPIRIT_SQUADS,
                        parent: META_KEYS.GRAD_ASSISTNT_TYPE,
                    },
                ],
                'Specific Fields of Study': [],
            },
        },
    ],
    Mentorship: [
        { level: 1, values: ['Paid', 'Unpaid'], key: META_KEYS.PAYMENT_TYPE, parent: META_KEYS.OPP_TYPE },
        { level: 2, values: ['On Site', 'Virtual'], key: META_KEYS.LOCATION_TYPE, parent: META_KEYS.PAYMENT_TYPE },
    ],
    Recruitment: [
        {
            level: 1,
            label: 'Select Grad Year',
            values: generateYearList(),
            key: META_KEYS.GRAD_YEAR,
            parent: META_KEYS.OPP_TYPE,
        },
        {
            level: 2,
            values: ['Athletics', 'Performing Arts', 'College Students', 'Spirit Squads'],
            key: META_KEYS.RECRUITS_TYPE,
            parent: META_KEYS.GRAD_YEAR,
        },
        {
            level: 3,
            values: {
                Athletics: [
                    { level: 1, values: GRAD_SPORTS_LIST, key: META_KEYS.SPORT, parent: META_KEYS.RECRUITS_TYPE },
                    { level: 2, values: ['Men', 'Women'], key: META_KEYS.GENDER_TYPE, parent: META_KEYS.SPORT },
                ],
                'Performing Arts': [
                    {
                        level: 1,
                        values: ['Band', 'Choir', 'Theatre'],
                        key: META_KEYS.PERFORMING_ARTS,
                        parent: META_KEYS.RECRUITS_TYPE,
                    },
                ],
                'Spirit Squads': [
                    {
                        level: 1,
                        values: ['Cheer', 'Dance'],
                        key: META_KEYS.SPIRIT_SQUADS,
                        parent: META_KEYS.RECRUITS_TYPE,
                    },
                ],
            },
        },
    ],
    Scholarships: [],
    Sponsorships: [
        {
            level: 1,
            values: ['Internship', 'Mentorship'],
            isMultiple: true,
            key: META_KEYS.SPONSORSHIP_TYPE,
            parent: META_KEYS.OPP_TYPE,
        },
    ],
    'Volunteer Opps': [
        { level: 1, values: ['On Site', 'Virtual'], key: META_KEYS.LOCATION_TYPE, parent: META_KEYS.OPP_TYPE },
    ],
    'clubs and team': [],
};
