import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Container, Grid, IconButton, InputAdornment, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { polyfill } from 'seamless-scroll-polyfill';
import { useAuth } from '../../../contexts/Auth';
import { useSnackbar } from '../../../hooks';
import {
    getMyFavouritesStudent,
    getStudentList,
    getSubscriptionActivation,
    updateSubcriptionModelRequest,
} from '../../../services/app/api/index';
import {
    setGoToPage,
    setKeyWordFilter,
    setSearchStudentName,
    setStoredStudentsData,
} from '../../../store/searchSponsorSlice';
import { setMyFavouriteStudents } from '../../../store/searchStudentSlice';
import { applyFilter } from '../../../utils/GenericFunctions';
import * as labelConst from '../../../utils/GetLabels';
import { EmptySearchResult } from '../../Common/EmptySeachResult';
import BlockingLoader from '../../UI/BlockingLoader';
import Button from '../../UI/Button';
import MuiModal from '../../UI/MuiModal';
import TextField from '../../UI/TextField';
import MyFavourite from './MyFavourite';
import { SponsorSearchBox } from './SponsorSearchBox';
import { SponsorSearchCards } from './SponsorSearchCards';
import { SponsorSearchPagination } from './SponsorSearchPagination';

polyfill();

function StudentSearch() {
    const { getRole, getUserName, getProfileId } = useAuth();
    const [studentsData, setStudentsData] = useState([]);
    const [allStudentData, setAllStudentData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const storedStudentsData = useSelector((state) => state.searchSponsorSlice.storedStudentsData);

    const searchStudentName = useSelector((state) => state.searchSponsorSlice.searchStudentName);
    const allFilters = useSelector((state) => state.searchSponsorSlice.allFilters);
    const favouriteStudentData = useSelector((state) => state.searchStudentSlice.myFavouriteStudents);
    const keySearch = useSelector((state) => state.searchSponsorSlice.keyWordFilter);
    const [totalPages, setTotalPages] = useState(Math.ceil(storedStudentsData?.data.length / 10));
    const goToPage = useSelector((state) => state.searchSponsorSlice.page);
    const isFilterApplied = useSelector((state) => state.searchSponsorSlice.isFilterApplied);

    const paginate = useSelector((state) => state?.searchSponsorSlice?.paginate);
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const [openBox, setOpenBox] = useState(false);
    const [data, setData] = useState({});

    const { activated, expired_at, plan } = data || {};

    useEffect(() => {
        if (favouriteStudentData.length === 0) {
            getMyFavStudents();
        }
        if (storedStudentsData.data.length > 0) {
            const startIndex = (goToPage - 1) * 10;
            const endIndex = startIndex + 10;
            if (keySearch.length > 0) {
                filterStudentsByKey(keySearch, goToPage, startIndex, endIndex);
            } else if (!!searchStudentName) {
                filterStudentsByName(searchStudentName, goToPage, startIndex, endIndex);
            } else {
                setStudentsData(storedStudentsData.data.slice(startIndex, endIndex));
                setAllStudentData(storedStudentsData.data);
            }
            setIsLoading(false);
        } else {
            callStudentsDataApi();
        }
    }, []);

    function combineArraysByMatchingMyFav(pin, id) {
        const students = studentsData.map((item) =>
            item?.profile.id === id ? { ...item, profile: { ...item.profile, pin: pin } } : item
        );
        const allStudents = allStudentData.map((item) =>
            item?.profile.id === id ? { ...item, profile: { ...item.profile, pin: pin } } : item
        );
        const storedStudents = storedStudentsData.data.map((item) =>
            item?.profile.id === id ? { ...item, profile: { ...item.profile, pin: pin } } : item
        );
        dispatch(setStoredStudentsData({ data: storedStudents }));
        setStudentsData(students);
        setAllStudentData(allStudents);
    }

    const filterStudentsByKey = (searchArray, page = 1, startIndex = 0, endIndex = 10) => {
        dispatch(setGoToPage(page));
        if (searchArray.length === 0) {
            setTotalPages(Math.ceil(storedStudentsData.data.length / 10));
            setStudentsData(storedStudentsData.data.slice(0, 10));
            setAllStudentData(storedStudentsData.data);
        } else {
            const filteredArray = applyFilter(storedStudentsData.data, searchArray);
            setTotalPages(Math.ceil(filteredArray.length / 10));
            setStudentsData(filteredArray.slice(startIndex, endIndex));
            setAllStudentData(filteredArray);
        }
    };

    const filterStudentsByName = (studentName, page = 1, startIndex = 0, endIndex = 10) => {
        dispatch(setGoToPage(page));
        dispatch(setSearchStudentName(studentName));
        dispatch(setKeyWordFilter([]));
        if (studentName.length === 0) {
            setTotalPages(Math.ceil(storedStudentsData.data.length / 10));
            setStudentsData(storedStudentsData.data.slice(0, 10));
            setAllStudentData(storedStudentsData.data);
        } else {
            const filteredArray = storedStudentsData.data.filter((item) => {
                let fullName = `${item.user.first_name} ${item?.user.last_name}`;
                return fullName.toLocaleLowerCase().includes(studentName.toLocaleLowerCase());
            });
            setTotalPages(Math.ceil(filteredArray.length / 10));
            setStudentsData(filteredArray.slice(startIndex, endIndex));
            setAllStudentData(filteredArray);
        }
    };

    async function callStudentsDataApi(page = 1, allFilters = {}) {
        setIsLoading(true);
        try {
            const data = {
                profileId: getProfileId(),
                page: page,
                pageSize: -1,
                ...allFilters,
            };
            const response = await getStudentList(data);
            const studentsData = response.data;
            setStudentsData(studentsData.data.slice(0, 10));
            setAllStudentData(studentsData.data);
            setTotalPages(Math.ceil(studentsData.data.length / 10));
            dispatch(setStoredStudentsData(studentsData));
            dispatch(setGoToPage(1));
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }

    const getMyFavStudents = () => {
        const data = {
            id: getProfileId(),
        };
        getMyFavouritesStudent({ data })
            .then((response) => {
                const emptyList = [null, null, null, null, null, null, null, null, null, null];
                const allStudents = [...response.data?.data, ...emptyList].slice(0, 10);
                dispatch(setMyFavouriteStudents(allStudents));
            })
            .catch((error) => console.error(error));
    };

    function onPageChange(event, newValue) {
        setTimeout(() => {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }, 50);
        dispatch(setGoToPage(newValue));
        const startIndex = (newValue - 1) * 10;
        const endIndex = startIndex + 10;
        setStudentsData(allStudentData.slice(startIndex, endIndex));
    }

    function renderBack() {
        return getRole() ? (
            <div className="flex justify-between items-center">
                <Button onClick={() => navigate(-1)}>
                    <span className="material-icons material-icons-outlined mr-2">west</span> Back
                </Button>
                <IconButton className="bg-primary text-white" LinkComponent={'a'} href="#pagination" title="Go Down">
                    <ArrowDropDownIcon />
                </IconButton>
            </div>
        ) : (
            <></>
        );
    }

    const areSponsorsListEmpty = storedStudentsData?.data?.length === 0;
    const shouldShowEmptySearchResult = areSponsorsListEmpty && !isLoading;

    const handleSubscribe = () => {
        if (activated === null || activated === true) {
            updateSubcriptionModelRequest({
                userId: getProfileId(),
                payload: {
                    plan: 'Your29 Plan',
                },
            })
                .then(() => {
                    snackbar.success(labelConst.SUBSCRIPTION_CONFIRMATION_MESSAGE, { autoHideDuration: 10000 });
                    setOpenBox(false);
                    subscriptionActivation();
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {});
        } else {
            snackbar.success(`${labelConst.ALREADY_SUBSCRIPTION_REQUESTED}`, { autoHideDuration: 10000 });
        }
    };

    const openMesaageBox = () => {
        setOpenBox(true);
    };

    useEffect(() => {
        getProfileId() && subscriptionActivation();
    }, [getProfileId()]);

    const subscriptionActivation = () => {
        getSubscriptionActivation({ params: getProfileId() })
            .then((res) => setData(res?.data?.data))
            .catch((error) => console.log(error));
    };

    const openConfirmationBox = () => {
        if (activated === null || activated === true) {
            setOpenBox(true);
        } else {
            snackbar.success(`${labelConst.ALREADY_SUBSCRIPTION_REQUESTED}`, { autoHideDuration: 10000 });
        }
    };

    return (
        <div className="bg-[#F8F8F8] pt-10 pb-20">
            <Container>
                <MuiModal
                    handleClose={() => setOpenBox(false)}
                    handleConfirm={handleSubscribe}
                    open={openBox}
                    title={'Confirm Subscription'}
                    subscribe={activated === false ? 'Requested':'Subscribe'}
                    message={activated === false ? labelConst.MESSAGE_ACTION_FOR_ALREADYSUBSCRIBED_USER:labelConst.MESSAGE_ACTION_FOR_UNSUBSCRIBED_USER}
                />
                <div className="mb-4">{renderBack()}</div>
                <MyFavourite
                    combineArraysByMatchingMyFav={combineArraysByMatchingMyFav}
                    getMyStudents={getMyFavStudents}
                    callStudentsApi={callStudentsDataApi}
                />

                <Grid className="container hidden sm:flex justify-end">
                    <SponsorSearchPagination
                        page={goToPage}
                        totalPages={totalPages}
                        onPageChange={onPageChange}
                        siblingCount={0}
                    />
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                        <SponsorSearchBox
                            callStudentsApi={callStudentsDataApi}
                            filterStudentsByKey={filterStudentsByKey}
                        />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Grid className="container sm:hidden  flex justify-end">
                            <SponsorSearchPagination
                                page={goToPage}
                                totalPages={totalPages}
                                onPageChange={onPageChange}
                                siblingCount={0}
                            />
                        </Grid>
                        <Box className={'sm:ml-1'}>
                            <div className="sticky top-[64px] z-50 bg-[#F8F8F8] pb-2">
                                <div
                                    style={{ boxShadow: '0px 4px 3px rgba(0,0,0,0.05)' }}
                                    className="bg-white py-2 px-2 rounded mt-4 sm:mt-0 ">
                                    <h2 className="font-geometric text-base leading-5 lg:text-lg font-bold text-primary">
                                        Search Student:
                                    </h2>
                                    <div>
                                        <TextField
                                            size="small"
                                            placeholder={'Looking for a specific student? Type in the name here.'}
                                            fullWidth={true}
                                            value={searchStudentName}
                                            onChange={(e) => filterStudentsByName(e.target.value)}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon color="primary" />{' '}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {(isFilterApplied || !!searchStudentName || !!keySearch.length) && (
                                <Paper elevation={2} className="bg-primary mt-2 py-2 px-2 rounded">
                                    <div>
                                        <h2 className="font-geometric text-base leading-5 text-center lg:text-lg font-bold text-white">
                                            You have {allStudentData?.length} Matches!
                                        </h2>
                                    </div>
                                </Paper>
                            )}
                            {shouldShowEmptySearchResult || studentsData.length === 0 ? <EmptySearchResult /> : null}
                            <BlockingLoader loading={isLoading} />
                            {studentsData?.map((student) => {
                                return (
                                    <SponsorSearchCards
                                        combineArraysByMatchingMyFav={combineArraysByMatchingMyFav}
                                        getMyStudents={getMyFavStudents}
                                        isLoading={isLoading}
                                        setOpenTo={openMesaageBox}
                                        {...student}
                                    />
                                );
                            })}
                            <div id="pagination">
                                <SponsorSearchPagination
                                    page={goToPage}
                                    totalPages={totalPages}
                                    onPageChange={onPageChange}
                                />
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default StudentSearch;
