import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedUser:{},
    showUsersList:false,
    messageUser:{}
}

const messageSlice = createSlice({
    name:'messaging slice',
    initialState:initialState,
    reducers:{
        setSelectedUser:(state, action)=>{
            state.selectedUser = action.payload
        },
        handleShowUserList:(state,action)=>{
            state.showUsersList= action.payload
        },
        setMessageUser:(state,action)=>{
            state.messageUser= action.payload
        },
        
    }
})

export const {setSelectedUser, handleShowUserList,setMessageUser} = messageSlice.actions
export default messageSlice.reducer