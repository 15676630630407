import { createSlice } from '@reduxjs/toolkit';
import { INITIAL_SELECTED_INPUTS } from '../utils/GetConstants';

const initialState = {
    page: 1,
    storedOpportunityData: { data: [], paginate: {} },
    keyWordFilter: [],
    selectOpportunityType: null,
    appliedOpportunityFilters: INITIAL_SELECTED_INPUTS ,
    opportunityStatus:false,
    myFavouriteStudents:[]
};

export const searchStudentSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        setGoToPage: (state, action) => {
            state.page = action.payload;
        },
        setStoredOpportunityData: (state, action) => {
            state.storedOpportunityData = action.payload;
        },
        setKeyWordFilter: (state, action) => {
            state.keyWordFilter = action.payload;
        },
        setSelectOpportunityType: (state, action) => {
            state.selectOpportunityType = action.payload;
        },
        setAppliedOpportunityFilters: (state, action) => {
            state.appliedOpportunityFilters = action.payload;
        },
        setOpportunityStatus: (state, action) => {
            state.opportunityStatus = action.payload;
        },
        setMyFavouriteStudents: (state, action) => {
            state.myFavouriteStudents = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setGoToPage,
    setStoredOpportunityData,
    setKeyWordFilter,
    setSelectOpportunityType,
    setAppliedOpportunityFilters,
    setOpportunityStatus,
    setMyFavouriteStudents
} = searchStudentSlice.actions;

export default searchStudentSlice.reducer;
