import { Button } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/Auth';
import { getStudentApplications as getStudentApplicationsApi } from '../../../services/app/api';
import { EmptyApplicationResult } from '../../Common/EmptyApplicationResult';
import BlockingLoader from '../../UI/BlockingLoader';
import Container from '../../UI/Container';
import { ApplicationCard } from './ApplicationCard';

function getApplicationLabel(status, text, count) {
    if (status === text.toLowerCase()) {
        return `${text} (${count})`;
    }
    return text;
}

function Applications() {
    const [applicationList, setApplicationList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { getProfileId } = useAuth();
    const [value, setValue] = useState('applied');
    const totalApplications = applicationList.length;
    const navigate = useNavigate();

    useEffect(() => {
        fetchStudentApplications({ status: 'pending' });
    }, []);

    async function fetchStudentApplications({ status = 'pending' }) {
        try {
            setIsLoading(true);
            const { data: applications } = await getStudentApplicationsApi({ userId: getProfileId(), status });
            setApplicationList(applications.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setApplicationList([]);
        }
    }

    const handleChange = (event, newValue) => {
        const status = newValue === 'applied' ? 'pending' : newValue;
        fetchStudentApplications({ status });
        setValue(newValue);
    };

    function renderBack() {
        return (
            <>
                <Button onClick={() => navigate(-1)}>
                    <span className="material-icons material-icons-outlined mr-2">west</span> Back
                </Button>
            </>
        );
    }

    return (
        <div className="bg-[#fafafa] py-10">
            <BlockingLoader loading={isLoading} />

            <Container className="px-3 md:px-8 lg:max-w-4xl xl:max-w-4xl 2xl:max-w-4xl mx-auto pt-2">
                {renderBack()}
                <h1 className="font-geometric text-2xl lg:text-4xl font-bold text-[#3A3A3F] mb-6 lg:mb-10">
                    My Applications
                </h1>

                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example">
                    <Tab
                        sx={{ p: 0, fontSize: 14 }}
                        value="applied"
                        label={getApplicationLabel(value, 'Applied', totalApplications)}
                    />
                    <Tab
                        sx={{ p: 0, fontSize: 14 }}
                        value="accepted"
                        label={getApplicationLabel(value, 'Accepted', totalApplications)}
                    />
                    <Tab
                        sx={{ p: 0, fontSize: 14 }}
                        value="rejected"
                        label={getApplicationLabel(value, 'Rejected', totalApplications)}
                    />
                    <Tab
                        sx={{ p: 0, fontSize: 14 }}
                        value="archived"
                        label={getApplicationLabel(value, 'Archived', totalApplications)}
                    />
                    <Tab
                        sx={{ p: 0, fontSize: 14 }}
                        value="completed"
                        label={getApplicationLabel(value, 'Completed', totalApplications)}
                    />
                </Tabs>
                <div className="space-y-3 mt-5">
                    {applicationList.length <= 0 && !isLoading ? (
                        <EmptyApplicationResult text={`No ${value} Applications Found`} />
                    ) : null}
                    {applicationList.map((data) => {
                        return (
                            <ApplicationCard
                                key={data.id}
                                {...data}
                                fetchStudentApplications={fetchStudentApplications}
                                handleLoading={(bool) => setIsLoading(bool)}
                            />
                        );
                    })}
                </div>
            </Container>
        </div>
    );
}

export default Applications;
