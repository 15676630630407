/* eslint-disable import/no-anonymous-default-export */

import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import { toString } from '../../services/core/types';
import Label from './Label';
import Typography from './Typography';
// import { inputLabelClasses } from "@mui/material/InputLabel";

export default (props) => {
    const {
        classes = {},
        label,
        helperMessageBg,
        placeholder,
        InputLabelProps = {},
        helperMessage = '',
        error = false,
        errorMessage = '',
        noHelperText = false,
        usernameHelper,
        sx = {
            [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: '#103B58',
                border: '2px solid #103B58',
            },
            [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: '#103B58',
            },
            [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
                {
                    borderColor: '#38A4D0',
                },
            // [`& .${outlinedInputClasses.input}`]: {
            //     color: '#565656',
            // },
            // [`&:hover .${outlinedInputClasses.input}`]: {
            //     color: 'red',
            // },
            // [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]:
            //     {
            //         color: '#103B58',
            //     },
            // [`& .${inputLabelClasses.outlined}`]: {
            //     color: '#103B58',
            // },
            // [`&:hover .${inputLabelClasses.outlined}`]: {
            //     color: '#103B58',
            // },
            // [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
            //     color: '#103B58',
            // },
        },
    } = props;

    return (
        <div className={['flex flex-col', toString(classes.root)].join(' ')}>
            {label && (
                <div className="">
                    <Label value={label} />
                </div>
            )}
            <TextField
                {...props}
                label={null}
                InputLabelProps={{ ...InputLabelProps, shrink: false }}
                sx={sx}
                className={classes.textfield}>
                {props.children}
            </TextField>

            {noHelperText ? (
                ''
            ) : error ? (
                <Label flavor="error" value={errorMessage || `${label || placeholder} seems to be incorrect.`} />
            ) : helperMessageBg ? (
                <div className="bg-secondary mt-1 px-2 py-1 rounded-tl-lg rounded-br-lg shadow-lg">
                    <Label flavor="helperBg" value={helperMessage} />
                </div>
            ) : usernameHelper ? (
                <Typography className="text-gray-500 text-12 ">{helperMessage}</Typography>
            ) : (
                <Label flavor="helper" value={helperMessage} />
            )}
        </div>
    );
};
