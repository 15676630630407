import AttachFileIcon from '@mui/icons-material/AttachFile';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton, Modal, Paper } from '@mui/material';
import { format, fromUnixTime } from 'date-fns/esm';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/Auth';
import { useSnackbar } from '../../../hooks';
import { editOpportunityAttachment } from '../../../services/app/api';
import { formatCurrencyRange, getSubMetaDataValue } from '../../../utils/GenericFunctions';
import { META_KEYS } from '../../../utils/GetConstants';
import { OpportunityInfoList } from '../../Common/OpportunityInfoList';
import Typography from '../../UI/Typography';
import Upload from '../../UI/Upload';

// Todo: Refactor
const SUB_METADATA_KEYS = {
    Employee: META_KEYS.EMP_TYPE,
    Recruits: META_KEYS.RECRUITS_TYPE,
    'Grad Assistant': META_KEYS.GRAD_ASSISTNT_TYPE,
    Mentee: META_KEYS.COLLEGE_FIELDS_CAT,
    'Private Lessons': META_KEYS.PVT_LESSON_TYPE,
    Intern: META_KEYS.COLLEGE_FIELDS_CAT,
    'Research Assistant': META_KEYS.COLLEGE_FIELDS_CAT,
    Tutor: META_KEYS.TUTOR_TYPE,
};

const excludedMetaOppType = ['Scholars', 'Students to Sponsor', 'Volunteers', 'Influencers'];

function getSubMetaData(metadata) {
    const { value: opportunityType } = metadata.find(({ key }) => key === META_KEYS.OPP_TYPE) || {};
    if (!opportunityType) {
        return null;
    }

    const { value } = getSubMetaDataValue(metadata, opportunityType, SUB_METADATA_KEYS, excludedMetaOppType);
    return value;
}

const YesNoQuestionsAccordian = ({ questions, application }) => {
    return (
        <div className="mt-2 sm:w-full ">
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography className="text-primary font-bold font-geometric">Yes/No Questions </Typography>
                </AccordionSummary>
                <hr />
                <AccordionDetails>
                    {questions.question_1 && (
                        <>
                            <div className="flex items-center justify-between  my-3 ">
                                <Typography className="font-geometric font-medium">{questions.question_1}</Typography>
                                <Typography
                                    className={`font-geometric text-left w-8 font-bold ${
                                        application.answer_1 === 'Yes' ? 'text-green-500' : 'text-red-500'
                                    }`}>
                                    {application.answer_1 === null || application.answer_1 === 'undefined'
                                        ? 'NA'
                                        : application.answer_1}
                                </Typography>
                            </div>
                            <hr />
                        </>
                    )}
                    {questions.question_2 && (
                        <>
                            <div className="flex items-center justify-between  my-3 ">
                                <Typography className="font-geometric font-medium">{questions.question_2}</Typography>
                                <Typography
                                    className={`font-geometric text-left w-8  font-bold ${
                                        application.answer_2 === 'Yes' ? 'text-green-500' : 'text-red-500'
                                    }`}>
                                    {application.answer_2 === null || application.answer_2 === 'undefined'
                                        ? 'NA'
                                        : application.answer_2}
                                </Typography>
                            </div>
                            <hr />
                        </>
                    )}
                    {questions.question_3 && (
                        <>
                            <div className="flex items-center justify-between mt-3 ">
                                <Typography className="font-geometric font-medium">{questions.question_3}</Typography>
                                <Typography
                                    className={`font-geometric text-left w-8 font-bold ${
                                        application.answer_3 === 'Yes' ? 'text-green-500' : 'text-red-500'
                                    }`}>
                                    {application.answer_3 === null || application.answer_3 === 'undefined'
                                        ? 'NA'
                                        : application.answer_3}
                                </Typography>
                            </div>
                        </>
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export function ApplicationCard({
    id,
    title,
    application,
    amount,
    deadline,
    metadata,
    user: { first_name, last_name },
    profile: { profile_photo, username },
    question_1,
    question_2,
    question_3,
    fetchStudentApplications,
    handleLoading,
}) {
    const { created_at: submittedDate, team } = application;
    const isScholarshipDeadlinePassed = false;

    const formattedSubmittedDate = format(fromUnixTime(Number(submittedDate)), 'MMM dd yyyy');
    const deadlineDate = format(fromUnixTime(Number(deadline)), 'MM / dd / yyyy');
    const userProfileLink = `/sponsor/${username}`;
    const snackbar = useSnackbar();
    const [attachment, setAttachment] = useState(null);
    const [openAttachmentBox, setOpenAttachmentBox] = useState(false);
    const { getUserName, getSubRole } = useAuth();

    const metaValue = getSubMetaData(metadata);

    const UploadAttachmentModal = () => {
        const handleConfirm = () => {
            setOpenAttachmentBox(false);
            handleLoading(true);
            const data = {
                userId: getUserName(),
                opportunityId: id,
                file: attachment,
            };
            editOpportunityAttachment({ data })
                .then((response) => {
                    snackbar.success('Attachment Uploaded Successfully', { autoHideDuration: 2500 });
                    fetchStudentApplications({ status: 'pending' });
                })
                .catch((error) => {
                    console.log(error);
                    snackbar.error('Failed To Upload Attachment', { autoHideDuration: 2500 });
                });
            setAttachment(null);
        };

        const handleClose = () => {
            setOpenAttachmentBox(false);
            setAttachment(null);
        };
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
        };
        return (
            <Modal
                className="bg-transparent"
                open={openAttachmentBox}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Paper elevation={4} className="w-[90%] md:w-1/2" sx={style}>
                    <div className="bg-primary px-4 text-white h-16 flex items-center">
                        <Typography id="modal-modal-title" variant="p" className="font-geometric text-17">
                            Update Attachment
                        </Typography>
                    </div>
                    <>
                        <div className=" m-2 sm:m-6 mb-3 border-b-1 pb-3 border-gray-500 ">
                            <Typography id="modal-modal-title" variant="p" className="font-geometric text-[16px]  ">
                                Update your attachment for opportunity "{title}".
                            </Typography>
                        </div>
                        <div className=" mx-2 sm:mx-8 flex items-center justify-between hover:bg-gray-100 p-2 ">
                            <p className="flex items-center">
                                <div className="mr-2 h-2 w-2 rounded-full bg-primary" />
                                Add additional attachment
                            </p>
                            <div className="flex items-end flex-col">
                                <div className="min-w-[80px] ml-1 " size="small">
                                    <Upload
                                        allowedExtensions="application/pdf, image/* "
                                        onChange={(e) => setAttachment(e.target.files[0])}
                                        btnComponent={Button}
                                        btnProps={{
                                            variant: 'contained',
                                            startIcon: <AttachFileIcon />,
                                            title: 'Click here to upload file.',
                                        }}
                                        btnChildren="Attach"
                                    />
                                </div>
                                {attachment && <p className="text-xs">{attachment.name}</p>}
                            </div>
                        </div>
                    </>

                    <div className=" m-2  sm:m-6 mb-3 border-b-1 pb-3 border-gray-500 "></div>
                    <div className="flex justify-end m-4 gap-4 ">
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button disabled={!attachment} onClick={handleConfirm} variant="contained" color="primary">
                            confirm
                        </Button>
                    </div>
                </Paper>
            </Modal>
        );
    };

    return (
        <article className="relative bg-white px-7 py-6 rounded" style={{ boxShadow: '0px 4px 3px rgba(0,0,0,0.05)' }}>
            <UploadAttachmentModal />
            <div>
                {metaValue ? (
                    <div className="bg-[#F3E7E4] inline-block px-3 py-1 rounded lg:-ml-2">
                        <h3 className="text-xs font-semibold font-geometric text-[#A56B5D]">{metaValue}</h3>
                    </div>
                ) : null}
                <h2 className="font-geometric font-semibold text-[#3A3A3F] text-base lg:text-lg">{title}</h2>
                <Link to={userProfileLink} className="inline-flex items-center gap-2 group border-none cursor-pointer">
                    <img
                        src={profile_photo}
                        alt={`${first_name} ${last_name}`}
                        className="w-6 h-6 rounded-full object-contain"
                    />
                    <p className="font-geometric text-[#0B051C] opacity-70 text-sm group-hover:text-primary font-medium">
                        {first_name} {last_name}
                    </p>
                </Link>
            </div>

            <OpportunityInfoList metadata={metadata} />
            {(question_1 || question_2 || question_3) && (
                <YesNoQuestionsAccordian questions={{ question_1, question_2, question_3 }} application={application} />
            )}

            <div className=" flex-col md:flex-row flex md:items-center gap-x-5 mt-4">
                <div className="flex gap-x-5">
                    {amount ? (
                        <div className="font-geometric flex gap-x-2 items-center text-sm lg:text-base text-[#3C3C3C]">
                            <h4>Amount:</h4>
                            <p className="text-[#282828] font-medium">
                                {isNaN(amount)
                                    ? formatCurrencyRange(amount)
                                    : `$${formatCurrencyRange(Number(amount).toLocaleString())}`}
                            </p>
                        </div>
                    ) : null}

                    <div className="font-geometric flex gap-x-2 items-center text-sm lg:text-base text-[#3C3C3C]">
                        <h4>Deadline:</h4>
                        <p
                            className={`${
                                isScholarshipDeadlinePassed ? 'text-[#DA0E0E]' : 'text-[#282828]'
                            } font-medium`}>
                            {deadlineDate}
                        </p>
                    </div>
                </div>
                {application.file && (
                    <div className="font-geometric flex gap-x-2 items-center text-sm lg:text-base text-[#3C3C3C]">
                        <a
                            href={application.file}
                            className="border-0 flex items-center "
                            target="_blank"
                            rel="noreferrer">
                            <AttachFileIcon fontSize="sm" className="rotate-[20deg]" />
                            Attachment
                        </a>
                        {application.status === 'Pending' && (
                            <IconButton
                                size="small"
                                aria-label="edit"
                                title="Edit Attachment"
                                onClick={() => setOpenAttachmentBox(true)}>
                                <EditIcon />
                            </IconButton>
                        )}
                    </div>
                )}
            </div>
            <div className="mt-4"></div>
            {getSubRole() === 'Leader' && (
                <p className="text-sm text-grayish">
                    {' '}
                    <span className="font-medium">Applied for Team or Club:</span> {team?.name}
                </p>
            )}
            <p className="text-sm text-grayish">
                {' '}
                <span className="font-medium">Submitted:</span> {formattedSubmittedDate}
            </p>
        </article>
    );
}
