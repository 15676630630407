import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useAuth } from '../../contexts/Auth';
import Dialog from './Dialog';
import DialogTitle from './DialogTitle';
import DialogContent from './DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { updateVideoTour as updateVideoTourApi, getUser as getUserApi } from '../../services/app/api';
import { SPONSOR_PROFILE_TOUR, STUDENT_PROFILE_TOUR } from '../../utils/GetConstants';

function PopupVideo() {
    const { getProfileId, getRole } = useAuth();
    const [openTo, setOpenTo] = useState();

    useEffect(() => {
        getUserApi({ params: { id: getProfileId() } }).then((resp) => {
            const { feed_tour, profile_tour } = resp.data?.data || {};
            setOpenTo(!profile_tour);
        });
    }, []);

    const handleClosedVideo = () => {
        updateVideoTourApi({ id: getProfileId(), payload: { profile: true, feed: false } });
        setOpenTo(false);
    };
    return (
        <Dialog open={openTo} close={openTo}>
            <DialogTitle className="flex justify-end ">
                <CloseIcon onClick={handleClosedVideo} />
            </DialogTitle>
            <DialogContent>
                <video
                    autoPlay={true}
                    onEnded={handleClosedVideo}
                    className={`shadow-2xl rounded-2xl object-center object-cover`}
                    controls
                    src={`${getRole() === 'Student' ? STUDENT_PROFILE_TOUR : SPONSOR_PROFILE_TOUR}#t=0.5`}
                />
            </DialogContent>
        </Dialog>
    );
}

export default PopupVideo;
