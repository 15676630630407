import { createSlice } from '@reduxjs/toolkit';
import {
    allCollegeProgramsFilter,
    avgActScoreFilter,
    avgNetPriceFilter,
    avgSatScoreFilter,
    collegeTypeFilter,
    degreeTypeFilter,
    distance,
    fourYearCollegeSizeFilter,
    fourYearEnrollments,
    locationTypeFilter,
    minorityDistinctionFilter,
    religiousAffiliationFilter,
    twoYearCollegeSizeFilter,
    twoYearEnrollments,
} from '../utils/collegeSearchFilters';

const searchCollegeName = '';
const searchByState = '';
const searchByZipcode = '';

const initialState = {
    storedCollegeData: [],
    paginate: {},
    searchByState,
    searchCollegeName,
    searchByZipcode,
    locationType: locationTypeFilter,
    degreeType: degreeTypeFilter,
    collegeType: collegeTypeFilter,
    twoYearCollegeSize: twoYearCollegeSizeFilter,
    fourYearCollegeSize: fourYearCollegeSizeFilter,
    collegeProgram: allCollegeProgramsFilter,
    religiousAffiliation: religiousAffiliationFilter,
    minorityDistinction: minorityDistinctionFilter,
    avgActScore: avgActScoreFilter,
    avgSatScore: avgSatScoreFilter,
    avgNetPrice: avgNetPriceFilter,
    favouriteColleges: [null, null, null, null, null, null, null, null, null, null],
    isFilterApplied: false,
    fourYearEnrollments: fourYearEnrollments,
    twoYearEnrollments: twoYearEnrollments,
    distance: distance,
};

export const searchCollegeSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        setStoredCollegeData: (state, action) => {
            state.storedCollegeData = action.payload?.data || [];
            state.paginate = action.payload?.paginate || {};
        },
        setCollegePaginate: (state, action) => {
            state.paginate = action.payload || {};
        },
        setSearchCollegeName: (state, action) => {
            state.searchCollegeName = action.payload;
        },
        setSearchByState: (state, action) => {
            state.searchByState = action.payload;
        },
        setSearchByZipcode: (state, action) => {
            state.searchByZipcode = action.payload;
        },
        setLocationType: (state, action) => {
            state.locationType = action.payload;
        },
        setDegreeType: (state, action) => {
            state.degreeType = action.payload;
        },
        setCollegeType: (state, action) => {
            state.collegeType = action.payload;
        },
        setTwoYearCollegeSize: (state, action) => {
            state.twoYearCollegeSize = action.payload;
        },
        setFourYearCollegeSize: (state, action) => {
            state.fourYearCollegeSize = action.payload;
        },
        setCollegeProgram: (state, action) => {
            state.collegeProgram = action.payload;
        },
        setReligiousAffiliation: (state, action) => {
            state.religiousAffiliation = action.payload;
        },
        setMinorityDistinction: (state, action) => {
            state.minorityDistinction = action.payload;
        },
        setAvgSatScore: (state, action) => {
            state.avgSatScore = action.payload;
        },
        setAvgActScore: (state, action) => {
            state.avgActScore = action.payload;
        },
        setAvgNetPrice: (state, action) => {
            state.avgNetPrice = action.payload;
        },
        setFavouriteColleges: (state, action) => {
            state.favouriteColleges = action.payload;
        },
        setFourYearEnrollments: (state, action) => {
            state.fourYearEnrollments = action.payload;
        },
        setTwoYearEnrollments: (state, action) => {
            state.twoYearEnrollments = action.payload;
        },
        setDistance: (state, action) => {
            state.distance = action.payload;
        },

        setClearAllFilters: (state, action) => {
            state.searchCollegeName = '';
            state.searchByState = '';
            state.searchByZipcode = '';
            state.locationType = locationTypeFilter;
            state.degreeType = degreeTypeFilter;
            state.collegeType = collegeTypeFilter;
            state.twoYearCollegeSize = twoYearCollegeSizeFilter;
            state.fourYearCollegeSize = fourYearCollegeSizeFilter;
            state.collegeProgram = allCollegeProgramsFilter;
            state.religiousAffiliation = religiousAffiliationFilter;
            state.minorityDistinction = minorityDistinctionFilter;
            state.avgActScore = avgActScoreFilter;
            state.avgSatScore = avgSatScoreFilter;
            state.avgNetPrice = avgNetPriceFilter;
            state.fourYearEnrollments = fourYearEnrollments;
            state.twoYearEnrollments = twoYearEnrollments;
            state.distance = distance;
        },
        setClearFilterDistance: (state, action) => {
            state.distance = distance;
        },
        setIsFilterApplied: (state, action) => {
            state.isFilterApplied = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setStoredCollegeData,
    setSearchCollegeName,
    setSearchByState,
    setSearchByZipcode,
    setLocationType,
    setClearAllFilters,
    setCollegePaginate,
    setDegreeType,
    setCollegeType,
    setTwoYearCollegeSize,
    setFourYearCollegeSize,
    setCollegeProgram,
    setReligiousAffiliation,
    setMinorityDistinction,
    setAvgActScore,
    setAvgSatScore,
    setAvgNetPrice,
    setFavouriteColleges,
    setIsFilterApplied,
    setFourYearEnrollments,
    setTwoYearEnrollments,
    setDistance,
    setClearFilterDistance,
} = searchCollegeSlice.actions;

export default searchCollegeSlice.reducer;
