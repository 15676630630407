import * as labelConst from './GetLabels';

export const TERMS_AND_CONDITIONS_URL = '/terms-and-conditions';

export const Roles = {
    Student: 'student',
    Advisor: 'advisor',
    Admin: 'admin',
    Sponsor: 'sponsor',
    HighSchool_Storefront: 'Storefront',
};

export const StudentLevel = {
    Doctoral: 'Doctoral Program',
    HighSchool: 'High School',
    College: 'College',
    Graduate: 'Graduate Program',
};

export const SPONSOR_MENU_ITEMS = [
    { label: labelConst.QUICK_INFORMATION, value: labelConst.QUICK_INFORMATION },
    { label: labelConst.WHY_YOUR29, value: labelConst.WHY_YOUR29 },
    { label: labelConst.YOUR29_OPPORTUNITIES, value: labelConst.YOUR29_OPPORTUNITIES },
    { label: labelConst.WHAT_THEY_ARE_SAYING, value: labelConst.WHAT_THEY_ARE_SAYING },
    { label: labelConst.FOR_MORE_INFORMATION, value: labelConst.FOR_MORE_INFORMATION },
];
export const LEADER_MENU_ITEM = [
    { label: labelConst.QUICK_INFORMATION, value: labelConst.QUICK_INFORMATION },
    { label: labelConst.LIST, value: labelConst.LIST },
    { label: labelConst.FOR_MORE_INFORMATION, value: labelConst.FOR_MORE_INFORMATION },
];

export const HS_STOREFRONT_MENU_ITEM = [
    { label: labelConst.QUICK_INFORMATION, value: labelConst.QUICK_INFORMATION },
    { label: labelConst.WHY_YOUR29, value: labelConst.WHY_YOUR29 },
    { label: labelConst.YOUR29_OPPORTUNITIES, value: labelConst.YOUR29_OPPORTUNITIES },
    { label: labelConst.FOR_MORE_INFORMATION, value: labelConst.FOR_MORE_INFORMATION },
];

export const SCHOLARSHIP_CANDIDATE_LIST = [
    { label: 'All Students', value: 'All Students' },
    { label: 'College Students Only', value: 'College Students Only' },
    { label: 'HS Students Only', value: 'HS Students Only' },
    { label: 'HS Graduating Senior Students', value: 'HS Graduating Senior Students' },
    { label: 'HS Graduating Senior Athletes', value: 'HS Graduating Senior Athletes' },
    { label: 'College Athletes Only', value: 'College Athletes Only' },
    { label: 'Other', value: 'Other' },
];

export const STOREFRONT_SCHOLARSHIP_CANDIDATE_LIST = [
    { label: 'Our HS Students Only', value: 'HS Students Only' },
    { label: 'HS Graduating Senior Students', value: 'HS Graduating Senior Students' },
    { label: 'HS Graduating Senior Athletes', value: 'HS Graduating Senior Athletes' },
];

export const SCHOLARSHIP_TEAM_CANDIDATE_LIST = [
    { label: 'All Students', value: 'All Students' },
    { label: 'College Students Only', value: 'College Students Only' },
    { label: 'HS Students Only', value: 'HS Students Only' },
];

export const STUDENT_SCHOLARSHIP_TYPES_LIST = [
    { label: 'Hire Part-Time & Full-Time Employees', value: 'Employee' },
    { label: 'Host an Event or Visit', value: 'Event' },
    { label: 'Hire a Grad Assistant', value: 'Grad Assistant' },
    { label: 'Hire Interns', value: 'Intern' },
    { label: 'Offer Mentorships', value: 'Mentee' },
    { label: 'Find Recruits', value: 'Recruits' },
    { label: 'Host a Scholarship', value: 'Scholars' },
    { label: 'Sponsor Individuals, Clubs or Teams', value: 'Students to Sponsor', depreciated: true },
    { label: 'Find Volunteers', value: 'Volunteers' },
];

export const TEAM_SCHOLARSHIP_TYPES_LIST = [
    { label: 'Host an Event or Visit', value: 'Event' },
    { label: 'Sponsor Clubs or Teams', value: 'clubs and team' },
];

export const STOREFRONT_SCHOLARSHIP_TYPES_LIST = [
    // { label: 'Host an Event', value: 'Event' },
    { label: 'Host a Scholarship', value: 'Scholars' },
    { label: 'Find Volunteers', value: 'Volunteers' },
];

export const LEADER_SCHOLARSHIP_TYPES_LIST = [
    { label: 'Event', value: 'Event' },
    { label: 'Sponsor Clubs or Teams', value: 'clubs and team' },
];

export const STUDENT_OPPORTUNITY_TYPES = [
    { label: 'Summer Jobs', value: 'Summer Job' },
    { label: 'Employment', value: 'Employment' },
    { label: 'Event', value: 'Event' },
    { label: 'Grad Assistantship', value: 'Grad Assistantship' },
    { label: 'Internship', value: 'Internship' },
    { label: 'Mentorship', value: 'Mentorship' },
    { label: 'Recruitment', value: 'Recruitment' },
    { label: 'Scholarships', value: 'Scholarships' },
    { label: 'Sponsorships', value: 'Sponsorships', depreciated: true },
    { label: 'Volunteer Opps', value: 'Volunteer Opps' },
];
export const STUDENT_OPPORTUNITIES = [
    { label: 'Employee', value: 'Employee' },
    { label: 'Event', value: 'Event' },
    { label: 'Grad Assistant', value: 'Grad Assistant' },
    { label: 'Intern', value: 'Intern' },
    { label: 'Mentee', value: 'Mentee' },
    { label: 'Recruits', value: 'Recruits' },
    { label: 'Scholars', value: 'Scholars' },
    { label: 'Students to Sponsor', value: 'Students to Sponsor' },
    { label: 'Volunteers', value: 'Volunteers' },
];

export const MAPPED_OPPORTUNITY_VALUES = {
    Employment: 'Employee',
    Event: 'Event',
    'Grad Assistantship': 'Grad Assistant',
    Internship: 'Intern',
    Mentorship: 'Mentee',
    Recruitment: 'Recruits',
    Scholarships: 'Scholars',
    Sponsorships: 'Students to Sponsor',
    'Volunteer Opps': 'Volunteers',
    'Influencer Opp': 'Influencers',
    Internship: 'Intern',
    'Research Assistantship': 'Research Assistant',
    Tutoring: 'Tutor',
};

export const SPONSOR_SEARCH_MAPPED_OPPORTUNITIES = (function (obj = {}) {
    Object.entries(MAPPED_OPPORTUNITY_VALUES).forEach(([key, value]) => {
        obj[value] = key;
    });
    return obj;
})();

export const GRADUATE_PROGRAM = 'Graduate Program';
export const DOCTORAL_PROGRAM = 'Doctoral Program';

//Bulb help youtube urls
export const MY_GOALS_URL = 'https://www.youtube.com/embed/c37Kf91zf2s';
export const MY_BACKGROUND_URL = 'https://www.youtube.com/embed/jmR_mpccZ-E';
export const WHY_YOUR29_URL = 'https://www.youtube.com/embed/q2VYBeciik4';
export const WHAT_MAKES_ME_DIFFERENT_URL = 'https://www.youtube.com/embed/xzfk2O8l6ss';
export const UPLOAD_SELFIE_VIDEO_URL = 'https://www.youtube.com/embed/FY-JApbaMAs';
export const AWARDS_AND_ACCOMPLISHMENTS_URL = 'https://www.youtube.com/embed/MW3A3vijQqY';
export const WHAT_THEY_SAYING_URL = ' https://www.youtube.com/embed/PuXnZ9rBICU';
export const ACTIVITIES_URL = 'https://www.youtube.com/embed/Jo7fimSl-mA';
export const UPLOAD_VIDEO_URL = 'https://www.youtube.com/embed/lhfJiiOPUhs';
export const ADDITIONAL_ATTACHMENTS_URL = 'https://www.youtube.com/embed/aHQKklxoyqM';
export const SPONSOR_ONBOARDING_WELCOME =
    'https://storage.googleapis.com/dev-y29-bucket/students/onboarding/Your29%20Onboarding%20Sponsor%20Welcome%20Video.mp4?t=1690896922';
export const STUDENT_ONBOARDING_WELCOME =
    'https://storage.googleapis.com/dev-y29-bucket/students/onboarding/Your29%20Student%20Onboarding%20Welcome%20Video.mp4?t=1690896807';
export const STUDENT_PROFILE_TOUR = `https://storage.googleapis.com/dev-y29-bucket/tour/profile/Student%20Profile%20Tour%20Video.mp4?t=1697622044`;
export const SPONSOR_PROFILE_TOUR = `https://storage.googleapis.com/dev-y29-bucket/tour/profile/Sponsor%20Profile%20Tour%20Video.mp4?t=1697621845`;
export const STUDENT_FEED_TOUR = `https://storage.googleapis.com/dev-y29-bucket/tour/feed/Student%20Feed%20Tour%20Video.mp4?t=1697621666`;
export const SPONSOR_FEED_TOUR = `https://storage.googleapis.com/dev-y29-bucket/tour/feed/Sponsor%20Feed%20Tour%20Video.mp4?t=1697621248`;

export const META_KEYS = {
    OPP_TYPE: 'opp_type',
    EMP_TYPE: 'emp_type',
    LINK_TYPE: 'link_type',
    JOB_TYPE: 'job_type',
    LOCATION_TYPE: 'location_type',
    LOCATION: 'location',
    PAYMENT_TYPE: 'payment_type',
    PVT_LESSON_TYPE: 'pvt_lesson_type',
    COLLEGE_FIELDS_CAT: 'college_fields_cat',
    COLLEGE_FIELDS_SUBJECT: 'college_fields_subject',
    SPORT: 'sport',
    TUTOR_TYPE: 'tutor_type',
    TUTOR_SUB_GRAD: 'tutor_sub_grad',
    GRAD_ASSISTNT_TYPE: 'grad_assistnt_type',
    PERFORMING_ARTS: 'performing_arts',
    SPIRIT_SQUADS: 'spirit_squads',
    SPONSORSHIP_TYPE: 'sponsorship_type',
    GRAD_YEAR: 'grad_year',
    RECRUITS_TYPE: 'recruits_type',
    GENDER_TYPE: 'gender_type',
    EVENT_TYPE: 'event_type',
    CAMPUS_EVENT_TYPE: 'event_name',
    VIRTUAL_EVENT_TYPE: 'event_name',
    COLLEGE_LIST: 'college',
    SUMMER_OPP_TYPE: 'summer_opp_type',
    SUMMER_OTHER_TYPE: 'summer_other_type',
};

export const INITIAL_SELECTED_INPUTS = {
    employment_level1: null,
    employment_level2: null,
    employment_level3: null,
    employment_level4: null,
    employment_level5: null,
    employment_level6: null,
    gradAssistantship_level1: null,
    gradAssistantship_level2: null,
    gradAssistantship_level3: null,
    gradAssistantship_level4: null,
    gradAssistantship_level5: null,
    internship_level1: null,
    internship_level2: null,
    mentorship_level1: null,
    mentorship_level2: null,
    recruits_level1: null,
    recruits_level2: null,
    recruits_level3: null,
    recruits_level4: null,
    sponsorship_level1: null,
    volunteer_level1: null,
    location: null,
    event_level1: null,
    event_level2: null,
    event_level3: null,
    event_level4: null,
    event_level5: null,
};

export const ADMIN_TAB_LIST = [
    {
        key: 1,
        to: '',
        page: 'Update Feed',
        title: 'Update Feed',
        icon_name: 'home',
    },
    {
        key: 2,
        to: 'users',
        page: 'Users',
        title: 'Collection of all student profiles.',
        icon_name: 'face',
    },
    {
        key: 3,
        to: 'analytics',
        page: 'Analytics',
        title: 'Consumer Facing Dashboard',
        icon_name: 'analytics',
    },
    {
        key: 4,
        to: 'feedback',
        page: 'Feedback',
        title: 'Feedback',
        icon_name: 'note_alt_icon',
    },
    {
        key: 5,
        to: 'permissions',
        page: 'Permission',
        title: 'Permissions',
        icon_name: 'badge_icon',
    },
    {
        key: 6,
        to: 'opportunities',
        page: `${labelConst.SPONSOR_OPP}`,
        title: 'Collection of all Scholarships Opportunities.',
        icon_name: 'menu_book',
    },
    {
        key: 7,
        to: 'colleges',
        page: 'Colleges',
        title: 'Collection of all colleges.',
        icon_name: 'apartment',
    },
    {
        key: 8,
        to: 'high-schools',
        page: 'High Schools',
        title: 'Collection of all high schools.',
        icon_name: 'school',
    },

    {
        key: 9,
        to: 'accomplishment-types',
        page: 'Accomplishment Types',
        title: 'Collection of all accomplishment types.',
        icon_name: 'workspace_premium',
    },
    {
        key: 10,
        to: 'activity-types',
        page: 'Activity Types',
        title: 'Collection of all activity types.',
        icon_name: 'celebration',
    },
    {
        key: 11,
        to: 'interests',
        page: 'Interests',
        title: 'Collection of all interests.',
        icon_name: 'sports_football',
    },
];

export const ADMIN_ROLES = [
    { id: 1, role: 'Admin' },
    {
        id: 2,
        role: 'Author',
    },
];

export const applyCriteriaList = [
    {
        value: 'full-profile-completion',
        label: 'Full profile complete',
    },
    {
        label: 'Basic profile complete',
        value: 'basic-profile-completion',
    },
    {
        value: 'verified-user',
        label: 'Verified students',
    },
];

export const referenceToJoinY29 = [
    'Your29 Team',
    'Email',
    'Teacher, Professor or Counselor',
    'Instagram',
    'Facebook',
    'Linked In',
    'Referral / Friend',
];

export const STUDENTS_FOLLOWUP_QUESTION = [
    {
        question: 'Have you been hired by sponsor?',
        question_type: 'yes/no',
        fieldName: 'hired',
    },
    {
        question: 'Please rate your experience with your sponsor.',
        question_type: 'rating',
        fieldName: 'rating_for_user',
    },
    {
        question: 'Please type what was good about your sponsor',
        question_type: 'review',
        fieldName: 'review',
    },
    {
        question: 'Please rate your Finding Summer Jobs experience on Your29',
        question_type: 'rating',
        fieldName: 'rating_for_y29',
    },
];

export const SPONSOR_FOLLOWUP_QUESTION = [
    {
        question: 'Did you hire to student?',
        question_type: 'yes/no',
        fieldName: 'hired',
    },
    {
        question: 'Please rate your experience with this student.',
        question_type: 'rating',
        fieldName: 'rating_for_user',
    },
    {
        question: 'Did this student meet, exceed or not meet your expectations?',
        question_type: 'review',
        fieldName: 'review',
    },
    {
        question: 'Please rate your Find Summer Help hiring experience with Your29',
        question_type: 'rating',
        fieldName: 'rating_for_y29',
    },
];
