import { useSnackbar as useNotiSnackbar } from 'notistack';

const placements = {
    vertical: 'top',
    horizontal: 'center',
};

const commonOptions = {
    anchorOrigin: placements,
    autoHideDuration: 2500,
};

export function useSnackbar() {
    const { enqueueSnackbar, closeSnackbar } = useNotiSnackbar();
    const action = snackbarId => (
        <>
          <button onClick={() => { closeSnackbar(snackbarId) }}>
            Dismiss
          </button>
        </>
      );

    function success(title, options = {}) {
        enqueueSnackbar(title, {
            variant: 'success',
            ...commonOptions,
            ...options,
        });
    }

    function error(title, options = {}) {
        enqueueSnackbar(title, {
            variant: 'error',
            ...commonOptions,
            ...options,
            action
        });
    }

   

    return { success, error, closeSnackbar , action };
}
