export function EmptyApplicationResult({ text }) {
    return (
        <div
            style={{ boxShadow: '0px 4px 3px rgba(0,0,0,0.05)' }}
            className="bg-white py-8 flex flex-col items-center font-geometric space-y-8 rounded-md">
            <div className="text-center space-y-1">
                <h4 className="text-base lg:text-xl font-bold text-[#4A4A4A] capitalize">{text}</h4>
            </div>
        </div>
    );
}
