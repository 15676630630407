import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PushPinIcon from '@mui/icons-material/PushPin';
import { Accordion, AccordionDetails, AccordionSummary, Box, Paper, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/Auth';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { addRemoveMyFavouritesStudent } from '../../../services/app/api';
import { isFutureDate } from '../../../utils/GenericFunctions';
import * as labelConst from '../../../utils/GetLabels';
import { MY_FAV_TIP } from '../../../utils/GetLabels';
import Headshot from '../../UI/Headshot';
import IconButton from '../../UI/IconButton';

const MyFavourite = (props) => {
    const { combineArraysByMatchingMyFav, getMyStudents } = props;
    const { getProfileId, getSubscriptionPlan, getSubscriptionExpireDate } = useAuth();
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const studentData = useSelector((state) => state.searchStudentSlice.myFavouriteStudents);
    const [isFuture, setIsFuture] = useState(false);

    useEffect(() => {
        setIsFuture(isFutureDate(getSubscriptionExpireDate()));
    }, [getSubscriptionExpireDate]);

    const allowMessage = getSubscriptionPlan() === labelConst.PLAN_VAL && isFuture;

    const FavouriteCard = ({ data }) => {
        const handleGoToProfile = () => {
            navigate(`/student/${data.profile.username}`, {
                state: { from: 'Candidates' },
            });
        };
        let profileId = data.profile.id;

        const handleFavourites = () => {
            const pin = 'false';
            const data = {
                id: getProfileId(),
                payload: { favorite_profile_id: profileId, pin: pin },
            };
            addRemoveMyFavouritesStudent({ data })
                .then((response) => {
                    snackbar.success('User removed from My Favorites.');
                    getMyStudents();
                    combineArraysByMatchingMyFav(false, profileId);
                })
                .catch((error) => {
                    snackbar.error('Failed to remove user from My Favorites');
                    console.log(error);
                });
        };
        return (
            <Paper className="text-center h-40 relative sm:w-40 sm:p-2 flex flex-col items-center justify-center rounded-md">
                <IconButton onClick={handleFavourites} size={'small'} className={'absolute top-0 right-0'}>
                    <CancelIcon color="primary" />
                </IconButton>

                <div
                    onClick={handleGoToProfile}
                    className="rounded-full mt-4 sm:m-0 p-0 cursor-pointer flex items-center justify-center">
                    <Headshot user="Student" url={data.profile.profile_photo} size={90} />
                </div>
                <Tooltip title={`${allowMessage ? `${data?.user?.first_name} ${data?.user?.last_name}` : ''} `}>
                    <Typography
                        onClick={handleGoToProfile}
                        className=" text-ellipsis whitespace-nowrap overflow-hidden w-24 sm:w-36 font-semibold cursor-pointer font-geometric text-primary text-12 break-all sm:text-[15px] mt-2">
                        {`${allowMessage ? `${data?.user?.first_name} ${data?.user?.last_name.charAt(0)}.` : ''} `}
                    </Typography>
                </Tooltip>
                <Typography className="font-geometric text-gray-500 text-10 sm:text-12">
                    {data?.location[0].city}
                </Typography>
            </Paper>
        );
    };
    const EmptyFavouriteCard = () => {
        return (
            <Paper className="text-center h-40 relative sm:w-40 sm:p-2 flex flex-col items-center justify-center rounded-md">
                <div className="rounded-full border-4 border-primary rotate-45 h-20 w-20 m-0 p-0 flex items-center justify-center">
                    <PushPinIcon fontSize="large" color="primary" />
                </div>
                <Typography className="font-semibold font-geometric text-primary text-[15px] mt-2">
                    Add To Favorites
                </Typography>
            </Paper>
        );
    };

    const CardList = () => {
        return (
            <Grid container spacing={1}>
                {studentData.map((item, index) => (
                    <Grid className="hidden sm:block m-auto" item>
                        {!!item ? <FavouriteCard data={item} /> : <EmptyFavouriteCard />}
                    </Grid>
                ))}
            </Grid>
        );
    };

    const MobileCardList = () => {
        return (
            <Accordion className="block sm:hidden">
                <AccordionSummary
                    className="bg-primary"
                    expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} fontSize="large" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography className="text-white font-geometric font-semibold text-34">My Favorites</Typography>
                </AccordionSummary>
                <AccordionDetails className="bg-[#F8F8F8]">
                    <Typography className="text-grayish font-geometric text-[14px]">{MY_FAV_TIP}</Typography>
                    <Grid container spacing={2}>
                        {studentData.map((item, index) => (
                            <Grid className="block sm:hidden" item xs={4} key={index} sm={4} md={2}>
                                {!!item ? <FavouriteCard data={item} /> : <EmptyFavouriteCard />}
                            </Grid>
                        ))}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    };

    return (
        <div className="my-6">
            <Grid container spacing={1} direction="row">
                <Grid className="hidden sm:block" item xs={12} sm={3}>
                    <Box className={`bg-primary flex flex-col justify-center h-full w-full p-1`}>
                        <Typography className="text-white break-all text-center mb-8 font-geometric font-semibold text-4xl">
                            My Favorites
                        </Typography>
                        <Typography className="font-geometric text-base font-bold text-white text-center">
                            {MY_FAV_TIP}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <CardList />
                    <MobileCardList />
                </Grid>
            </Grid>
        </div>
    );
};

export default MyFavourite;
