import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { Box, Card, Rating } from '@mui/material';
import Paper from '../../UI/Paper';
import Typography from '../../UI/Typography';

import { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/Auth';
import { followUpQuestionAndAnswer as getFollowUpQuestionAndAnswer } from '../../../services/app/api';
import { SPONSOR_FOLLOWUP_QUESTION, STUDENTS_FOLLOWUP_QUESTION } from '../../../utils/GetConstants';
import BlockingLoader from '../../UI/BlockingLoader';

const FollowUp = () => {
    const { getProfileId } = useAuth();
    const [connectionData, setConnectionData] = useState([]);
    const [loading, setLoading] = useState(false);

    const getFollowUpFunc = async () => {
        setLoading(true);
        try {
            const res = await getFollowUpQuestionAndAnswer({ user_id: getProfileId() });
            setConnectionData(res?.data?.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };
    useEffect(() => {
        getFollowUpFunc();
    }, []);

    const followResponse = (data) => {
        const { student = {}, sponsor = {} } = data['connection'] || {};
        return (
            <Paper className="flex flex-col gap-1 p-5 rounded-md">
                <Typography className="font-bold text-primary">{`FollowUp ${
                    student?.user?.first_name ? student?.user?.first_name : 'Student'
                } - ${sponsor?.user?.first_name ? sponsor?.user?.first_name : 'Sponsor'}`}</Typography>
                <div className="flex flex-row gap-4 p-5">
                    <Card className="bg-secondary w-full h-auto rounded-md p-2">
                        <Typography className="font-semibold text-primary text-base">Student Response</Typography>
                        {!!data['connection']?.student ? (
                            STUDENTS_FOLLOWUP_QUESTION.map(({ question, question_type, fieldName }, index) => {
                                return (
                                    <div className="mt-2" key={index}>
                                        <div className="my-1">
                                            <Typography className="text-primary  font-semibold">
                                                {index + 1}. {'  '}
                                                {question}
                                            </Typography>
                                            {question_type === 'yes/no' && (
                                                <Typography className="text-primary  flex items-center">
                                                    Ans.
                                                    {student['hire'] === false ? (
                                                        <SentimentVeryDissatisfiedIcon sx={{ fontSize: 40 }} />
                                                    ) : (
                                                        <SentimentVerySatisfiedIcon sx={{ fontSize: 40 }} />
                                                    )}
                                                </Typography>
                                            )}
                                            {fieldName === 'rating_for_user' && (
                                                <Typography className="text-primary flex items-center">
                                                    Ans.{' '}
                                                    <Rating
                                                        name="simple-controlled"
                                                        defaultValue={0}
                                                        sx={{
                                                            '& .MuiRating-iconFilled': {
                                                                color: '#103B58',
                                                            },
                                                            fontSize: 30,
                                                        }}
                                                        size="large"
                                                        value={student['rating_for_user']}
                                                    />{' '}
                                                </Typography>
                                            )}
                                            {question_type === 'review' && (
                                                <Typography className="text-primary flex items-center">
                                                    {`Ans. ${student['review'] || 'NA'}`}
                                                </Typography>
                                            )}
                                            {fieldName === 'rating_for_y29' && (
                                                <Typography className="text-primary flex items-center">
                                                    Ans.{' '}
                                                    <Rating
                                                        name="simple-controlled"
                                                        defaultValue={0}
                                                        sx={{
                                                            '& .MuiRating-iconFilled': {
                                                                color: '#103B58',
                                                            },
                                                            fontSize: 30,
                                                        }}
                                                        size="large"
                                                        value={student['rating_for_y29']}
                                                    />{' '}
                                                </Typography>
                                            )}
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <p className="text-center text-white pt-10 text-base">Response not submitted</p>
                        )}
                    </Card>
                    <Card className="bg-primary w-full h-auto rounded-md p-2">
                        <Typography className="text-base text-white font-semibold">Sponsor Response</Typography>
                        {data['connection']?.sponsor ? (
                            SPONSOR_FOLLOWUP_QUESTION.map(({ question, question_type, fieldName }, index) => {
                                return (
                                    <div className="mt-2" key={index}>
                                        <div className="my-1">
                                            <Typography className="text-white font-semibold">
                                                {index + 1}. {'  '} {question}
                                            </Typography>
                                            {question_type === 'yes/no' && (
                                                <Typography className="text-white flex items-center">
                                                    Ans.{' '}
                                                    {sponsor['hire'] === false ? (
                                                        <SentimentVeryDissatisfiedIcon sx={{ fontSize: 40 }} />
                                                    ) : (
                                                        <SentimentVerySatisfiedIcon sx={{ fontSize: 40 }} />
                                                    )}
                                                </Typography>
                                            )}

                                            {question_type === 'review' && sponsor['review'] && (
                                                <Typography className="text-white flex items-center">
                                                    {`Ans. ${sponsor['review'] || 'NA'}`}
                                                </Typography>
                                            )}

                                            {fieldName === 'rating_for_user' && (
                                                <Typography className="text-white flex items-center">
                                                    Ans.{' '}
                                                    <Rating
                                                        name="simple-controlled"
                                                        defaultValue={0}
                                                        sx={{
                                                            '& .MuiRating-iconFilled': {
                                                                color: '#F35B54',
                                                            },
                                                            fontSize: 30,
                                                        }}
                                                        size="large"
                                                        value={sponsor['rating_for_user']}
                                                    />
                                                </Typography>
                                            )}
                                            {fieldName === 'rating_for_y29' && (
                                                <Typography className="text-white flex items-center">
                                                    Ans.{' '}
                                                    <Rating
                                                        name="simple-controlled"
                                                        defaultValue={0}
                                                        sx={{
                                                            '& .MuiRating-iconFilled': {
                                                                color: '#F35B54',
                                                            },
                                                            fontSize: 30,
                                                        }}
                                                        size="large"
                                                        value={sponsor['rating_for_y29']}
                                                    />
                                                </Typography>
                                            )}
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <p className="text-center text-white pt-10 text-base">Response not submitted</p>
                        )}
                    </Card>
                </div>
            </Paper>
        );
    };
    return (
        <div>
            <Box className="w-full flex flex-col gap-5">
                <BlockingLoader loading={loading} />
                {connectionData?.length > 0 &&
                    connectionData?.map((data) => {
                        return <div>{followResponse(data)}</div>;
                    })}
            </Box>
        </div>
    );
};

export default FollowUp;
