import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    page :1,
    scrollBarPosition:0
}

const appliedCandidatesSlice = createSlice({
    name: 'candidates data',
    initialState,
    reducers: {
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setScrollBarPosition: (state, action) => {
            state.scrollBarPosition = action.payload;
        },
    },
});

export const { setPage, setScrollBarPosition } = appliedCandidatesSlice.actions;
export default appliedCandidatesSlice.reducer;
