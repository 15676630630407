import SearchImage from '../../images/no-result';

export function EmptySearchResult({ variant = 'blue' }) {
    return (
        <div
            style={{ boxShadow: '0px 4px 3px rgba(0,0,0,0.05)' }}
            className="bg-white py-8 flex flex-col items-center font-geometric space-y-8 rounded-md">
            <SearchImage color={variant} />
            <div className="text-center space-y-1">
                <h4 className="text-base lg:text-xl font-bold text-[#4A4A4A]">No Results Found</h4>
                <p className="text-sm lg:text-base max-w-[24ch] text-[#5E5E5E]">
                    Try adjusting your search to find what you are looking for
                </p>
            </div>
        </div>
    );
}
