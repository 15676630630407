import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '../UI/Typography';
import EditIcon from '../../images/edit-icon';
import IconButton from '../UI/IconButton';

function SectionHeader({ selected = false, headingTitle, editMode = false, className = '', iconTitle, linkTo, state }) {
    return (
        <div className={`flex justify-between items-center ${className}`}>
            <Typography
                className={`font-geometric font-extrabold text-base sm:text-[23px] ${
                    selected ? 'text-secondary' : 'text-primary'
                }`}>
                {headingTitle}
            </Typography>
            {editMode && (
                <IconButton
                    className="bg-[#EDEDED] p-2 md:p-3 group"
                    title={iconTitle}
                    component={Link}
                    to={linkTo}
                    state={state}>
                    <EditIcon />
                </IconButton>
            )}
        </div>
    );
}

export default SectionHeader;
