import React, { createContext, useContext, useState } from 'react';
import { generateGuid } from '../services/core/utils';
import Typography from '../components/UI/Typography';

const ANCHOR_TOP = 'top';
const ANCHOR_RIGHT = 'right';
const ANCHOR_BOTTOM = 'bottom';
const ANCHOR_LEFT = 'left';
const ELEVATION = 16;
const TEMPORARY = 'temporary';
const PERSISTENT = 'persistent';
const PERMANENT = 'permanent';

const DrawerContext = createContext();

const normalizeDimensions = (props = {}) =>
    props.anchor === ANCHOR_TOP || props.anchor === ANCHOR_BOTTOM
        ? { ...{ width: '100%', height: 'auto' }, ...props }
        : { ...{ width: 'auto', height: '100%' }, ...props };

const normalize = (props = {}) => ({
    ...{
        id: null,
        anchor: ANCHOR_RIGHT,
        elevation: ELEVATION,
        hideBackdrop: false,
        variant: TEMPORARY,
        content: null,
        contentProps: {}
    },
    ...props
});

const objectify = (props) => (typeof props === 'string' ? { content: props } : props);

export const useDrawerProvider = () => {
    const [drawers, setDrawers] = useState([]);

    const newId = () => `drawer__${generateGuid()}`;

    // Get all Drawer instances
    const getDrawers = () => drawers;

    // Get a Drawer instance
    const getDrawer = (drawer = {}) => getDrawers.find((o) => o.id === drawer.id);

    // Get content of a Drawer
    const getContent = (drawer = {}) => {
        const { id, content, contentProps = {} } = drawer;
        const drawerProps = { drawer: { id, close: close.bind(this, { id }) } };

        if (content === null || content === undefined) {
            return null;
        } else if (typeof content === 'string') {
            return <Typography>{content}</Typography>;
        } else if (React.isValidElement(content)) {
            return React.cloneElement(drawer.content, { ...contentProps, ...drawerProps });
        } else {
            return React.cloneElement(<drawer.content />, { ...contentProps, ...drawerProps });
        }
    };

    // Open a new Drawer
    const open = (drawer = {}) => {
        const id = newId();
        setDrawers(getDrawers().concat({ ...normalizeDimensions(normalize(objectify(drawer))), id }));
        return { id };
    };

    // Open a new Drawer, top side
    const openTop = (drawer = {}) => open({ ...objectify(drawer), anchor: ANCHOR_TOP });

    // Open a new Drawer, right side
    const openRight = (drawer = {}) => open({ ...objectify(drawer), anchor: ANCHOR_RIGHT });

    // Open a new Drawer, bottom side
    const openBottom = (drawer = {}) => open({ ...objectify(drawer), anchor: ANCHOR_BOTTOM });

    // Open a new Drawer, left side
    const openLeft = (drawer = {}) => open({ ...objectify(drawer), anchor: ANCHOR_LEFT });

    // Close an existing Drawer
    const close = (drawer = {}) => {
        if (!drawer.id) return;
        setDrawers(getDrawers().filter((o) => o.id !== drawer.id));
    };

    return {
        getDrawers,
        getDrawer,
        getContent,
        open,
        openTop,
        openRight,
        openBottom,
        openLeft,
        close,
        ANCHOR_TOP,
        ANCHOR_RIGHT,
        ANCHOR_BOTTOM,
        ANCHOR_LEFT,
        TEMPORARY,
        PERSISTENT,
        PERMANENT
    };
};

export const DrawerProvider = ({ children }) => {
    return <DrawerContext.Provider value={useDrawerProvider()}>{children}</DrawerContext.Provider>;
};

export const useDrawer = () => useContext(DrawerContext);
