import React, { useState } from 'react';
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, InputAdornment } from '@mui/material';
import { useDispatch } from 'react-redux';
import TextField from '../../../UI/TextField';
import SearchIcon from '@mui/icons-material/Search';

const CheckBoxForm = (props) => {
    const { title = '', data = [], setData, showSearch = false, size = 'small', isLocation = false } = props;
    const dispatch = useDispatch();
    const [spliceData, setSpliceData] = useState(4);
    const [search, setSearch] = useState('');

    const handelCheckBoxCheck = (e) => {
        const { name, checked } = e.target;
        let checkedData = data.map((ele) => (ele?.value === name ? { ...ele, checked } : ele));
        dispatch(setData(checkedData));
    };
    const handleLoadMore = () => {
        spliceData < data?.length ? setSpliceData(data?.length + 1) : setSpliceData(4);
    };

    const handleSearchChange = (e) => {
        let value = e.target.value;
        setSearch(value);
        let sortedData = [];
        if (value === '') {
            sortedData = [...data].sort((a, b) => {
                return a.label > b.label ? 1 : -1;
            });
        } else {
            sortedData = [...data].sort((a, b) => {
                return a.label?.toLowerCase().includes(value?.toLowerCase()) ? -1 : 1;
            });
        }
        dispatch(setData(sortedData));
    };

    return (
        <div>
            {showSearch && (
                <div className={'mb-4'}>
                    <TextField
                        size="small"
                        type="search"
                        placeholder={'Search field of study'}
                        value={search}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon className="text-17" color="primary" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            )}
            <FormControl fullWidth={true} component="fieldset" variant="standard">
                <FormLabel className="text-17" component="legend">
                    {title}
                </FormLabel>
                <FormGroup>
                    {!!data.length &&
                        data
                            .slice(0, spliceData)
                            ?.map((item, index) => (
                                <FormControlLabel
                                    key={index}
                                    className={`${isLocation ? '' : 'justify-between'} flex  items-center ml-3`}
                                    labelPlacement={isLocation ? 'end' : 'start'}
                                    control={<Checkbox size={size} onChange={handelCheckBoxCheck} name={item?.value} />}
                                    label={item.label}
                                    checked={item.checked}
                                />
                            ))}
                </FormGroup>
            </FormControl>
            {data?.length > 4 && (
                <div className="flex justify-end">
                    <Button onClick={handleLoadMore} size="small">
                        Load {spliceData > data?.length ? 'Less' : 'More'}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default CheckBoxForm;
