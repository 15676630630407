import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getColleges as getCollegesApi,
    getHighSchools as getHighSchoolsApi,
    searchCollege as searchCollegesApi,
    searchHighSchool as searchHighSchoolApi,
} from '../../../services/app/api';
import { getDefaultPaginationValues } from '../../../services/app/pagination';
import { setSelectCollege, setSelectHighSchool } from '../../../store/searchSponsorSlice';
import Autocomplete from '../../UI/Autocomplete';
import TextField from '../../UI/TextField';
import Typography from '../../UI/Typography';

function SearchBySchool(props) {
    const [paginated, setPaginated] = useState();
    const [searchStr, setSearchStr] = useState('');
    const [highSchools, setHighSchools] = useState([]);
    const [colleges, setColleges] = useState([]);
    const [dropdownType, setDropdonType] = useState('');
    const [textLoading, setTextLoading] = useState(false);
    const dispatch = useDispatch();
    const selectHighSchool = useSelector((state) => state.searchSponsorSlice.selectHighSchool);
    const selectCollege = useSelector((state) => state.searchSponsorSlice.selectCollege);

    const handleScroll = (event) => {
        var node = event.target;
        const scrollHeight = Math.round(node.scrollHeight - node.scrollTop);
        const clientHeight = Math.trunc(node.clientHeight);
        const bottom =
            scrollHeight === clientHeight || scrollHeight === clientHeight + 1 || scrollHeight === clientHeight - 1;
        if (bottom && !searchStr) {
            const pagination = paginated ? { ...paginated } : getDefaultPaginationValues();
            pagination.page = pagination.page + 1;
            setPaginated({ ...pagination });
        }
    };

    const loadHighSchoolsDefault = () => {
        setTextLoading(true);
        const data = { params: { page: 1 }, query: '' };
        getHighSchoolsApi({ data })
            .then((response) => {
                Array.isArray(response.data?.data) && setHighSchools(response.data?.data);
            })
            .finally(() => setTextLoading(false));
    };

    const loadHighSchools = () => {
        setTextLoading(true);
        const pagination = paginated ? { ...paginated } : getDefaultPaginationValues();
        const data = { params: { ...pagination }, query: '' };
        getHighSchoolsApi({ data })
            .then((response) => {
                if (response.data?.data.length === 0) loadHighSchoolsDefault();
                else Array.isArray(response.data?.data) && setHighSchools([...highSchools, ...response.data?.data]);
            })
            .catch((err) => {})
            .finally(() => setTextLoading(false));
    };

    const searchHighSchools = (searchValue) => {
        setSearchStr(searchValue);
        if (searchValue) {
            setTextLoading(true);
            const data = { params: { pageSize: -1 }, query: searchValue };
            searchHighSchoolApi({ data })
                .then((res) => {
                    Array.isArray(res.data?.data) && setHighSchools(res.data?.data);
                })
                .finally(() => setTextLoading(false));
        }
    };

    const loadCollegesDefault = () => {
        setTextLoading(true);
        const data = { params: { page: 1 }, query: '' };
        getCollegesApi({ data })
            .then((response) => {
                Array.isArray(response.data?.data) && setColleges(response.data?.data);
            })
            .finally(() => setTextLoading(false));
    };

    const loadColleges = () => {
        setTextLoading(true);
        const pagination = paginated ? { ...paginated } : getDefaultPaginationValues();
        const data = { params: { ...pagination }, query: '' };

        getCollegesApi({ data })
            .then((response) => {
                if (response.data?.data.length === 0) loadCollegesDefault();
                else Array.isArray(response.data?.data) && setColleges([...colleges, ...response.data?.data]);
            })
            .catch((err) => {})
            .finally(() => setTextLoading(false));
    };

    const searchColleges = (searchValue) => {
        setSearchStr(searchValue);
        if (searchValue) {
            setTextLoading(true);
            const data = { params: { pageSize: -1 }, query: searchValue };
            searchCollegesApi({ data })
                .then((res) => {
                    Array.isArray(res.data?.data) && setColleges(res.data?.data);
                })
                .finally(() => setTextLoading(false));
        }
    };

    useEffect(() => {
        if (paginated) {
            if (dropdownType === 'high-school') {
                loadHighSchools();
            } else if (dropdownType === 'college') {
                loadColleges();
            }
        }
    }, [paginated]);

    useEffect(() => {
        if (!searchStr) {
            if (dropdownType === 'high-school') {
                loadHighSchoolsDefault();
            } else if (dropdownType === 'college') {
                loadCollegesDefault();
            }
        }
    }, [searchStr]);

    const reset = () => {
        setPaginated();
        setDropdonType('');
        setSearchStr('');
        textLoading && setTextLoading(false);
    };

    return (
        <div className="flex flex-col gap-2">
            <Autocomplete
                multiple
                ListboxProps={{
                    onScroll: (event) => {
                        handleScroll(event);
                    },
                    role: 'list-box',
                }}
                label={'Search by High School'}
                id="high-schools"
                loading={textLoading}
                loadingText="Loading…"
                onOpen={() => {
                    setDropdonType('high-school');
                    loadHighSchools();
                }}
                onClose={() => {
                    reset();
                    setHighSchools([]);
                }}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                options={highSchools}
                renderOption={(props, option) => (
                    <li {...props}>
                        <Typography className="w-[200px] sm:w-full">
                            {option.name} ({option.zipcode})
                        </Typography>
                    </li>
                )}
                getOptionLabel={(option) => `${option.name} (${option.zipcode})`}
                renderInput={(params) => (
                    <TextField
                        classes={{ root: 'mt-1' }}
                        {...params}
                        placeholder="Type in Zip or High School Name then select"
                        onChange={(e) => searchHighSchools(e.target.value)}
                    />
                )}
                onChange={(e, newValue) => dispatch(setSelectHighSchool(newValue))}
                classes={{ root: 'mt-2 mb-2' }}
            />
            <Autocomplete
                multiple
                ListboxProps={{
                    onScroll: (event) => {
                        handleScroll(event);
                    },
                    role: 'list-box',
                }}
                label={'Search by College'}
                id="colleges"
                loading={textLoading}
                loadingText="Loading…"
                onOpen={() => {
                    setDropdonType('college');
                    loadColleges();
                }}
                onClose={() => {
                    reset();
                    setColleges([]);
                }}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                options={colleges}
                renderOption={(props, option) => (
                    <li {...props}>
                        <Typography>
                            {option.name} ({option.zipcode})
                        </Typography>
                    </li>
                )}
                getOptionLabel={(option) => `${option.name} (${option.zipcode})`}
                renderInput={(params) => (
                    <TextField
                        classes={{ root: 'mt-1' }}
                        {...params}
                        placeholder="Type in Zip or College Name then select"
                        onChange={(e) => searchColleges(e.target.value)}
                    />
                )}
                onChange={(e, newValue) => dispatch(setSelectCollege(newValue))}
                classes={{ root: 'mt-2 mb-1' }}
            />
        </div>
    );
}

export default SearchBySchool;
