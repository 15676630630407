import { Grid, Switch, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/Auth';
import { useSnackbar } from '../../../hooks';
import {
    closeOpportunity as closeOpportunityApi,
    getSponsorApplicant as getSponsorApplicantApi,
    getSponsorScholarships as getSponsorScholarshipsApi,
} from '../../../services/app/api';
import dialogService from '../../../services/app/dialog';
import { setPage, setScrollBarPosition } from '../../../store/appliedCandidatesSlice';
import { downloadExcel, isFutureDate } from '../../../utils/GenericFunctions';
import { META_KEYS } from '../../../utils/GetConstants';
import * as labelConst from '../../../utils/GetLabels';
import { EmptyApplicationResult } from '../../Common/EmptyApplicationResult';
import { OpportunityToggle } from '../../Common/OpportunityToggle';
import StudentProfile from '../../Student/Profile';
import Autocomplete from '../../UI/Autocomplete';
import BlockingLoader from '../../UI/BlockingLoader';
import Button from '../../UI/Button';
import Container from '../../UI/Container';
import TextField from '../../UI/TextField';
import Typography from '../../UI/Typography';
import { AppliedCandidatesList } from './AppliedCandidatesList';
import AskForPayment from './AskForPayment';
import { OpportunitiesDetails } from './OpportunitiesDetails';
import IconButton from '../../UI/IconButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';


const pageSize = -1;
const page = 10;

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const APPLICATION_STATUS = {
    0: 'pending',
    1: 'accepted',
    2: 'rejected',
    3: 'completed',
};

function getCandidateListSerachParams({ title, status, id, check }) {
    let searchQuery = new URLSearchParams();
    searchQuery.append('opportunity', title);
    searchQuery.append('id', id);
    searchQuery.append('status', status);
    searchQuery.append('checked', check);
    return searchQuery;
}

export function AppliedCandidates() {
    const [searchParams, setSearchParams] = useSearchParams();
    const { getProfileId, getRole, askForSubscription, getSubscriptionPlan, getSubscriptionExpireDate, getSubRole } =
        useAuth();
    const location = useLocation();
    const state = location.state || {};
    const profileId = getProfileId();
    const snackbar = useSnackbar();
    const [dataValue, setDataValue] = useState([]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(() => {
        return Number(searchParams.get('status')) || 0;
    });
    const [selectedValue, setSelectedValue] = useState(() => {
        const opportunityParam = searchParams.get('opportunity');
        const idParam = searchParams.get('id');
        if (idParam) {
            return { label: opportunityParam, value: idParam };
        }
        return state.candidateName ? { label: state.candidateName, value: state.candidateId } : null;
    });
    const [applicantData, setApplicantData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [checked, setChecked] = useState(() => {
        if (searchParams.get('checked') !== null) {
            return searchParams.get('checked') === 'true' ? true : false;
        }
        return false;
    });
    const [opportunityQuestions, setOpportunityQuestions] = useState([]);
    const [selectedopportunityQuestions, setSelectedOpportunityQuestions] = useState();
    const { applicants = [] } = applicantData || {};
    const count = applicants?.length;
    const goToPage = useSelector((state) => state.appliedCandidatesSlice.page);
    const scrollBarPosition = useSelector((state) => state.appliedCandidatesSlice.scrollBarPosition);
    const printOutUserData = useSelector((state) => state.genericSlice.printOutUserData);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { value: oppo_type } = applicantData?.metadata?.find(({ key }) => key === META_KEYS.OPP_TYPE) || {};

    const [toggled, setToggled] = useState(true);
    const [isPrinting, setIsPrinting] = useState(false);
    const [isFuture, setIsFuture] = useState(false);

    const handleToggled = (event) => {
        setToggled(event.target.checked);
    };
    const handlePrint = () => {
        setIsPrinting(true);
    };

    useEffect(() => {
        if (isPrinting) {
            window.print();
            setIsPrinting(false);
        }
    }, [isPrinting]);

    useEffect(() => {
        getOpportunityAndCandidates();
    }, []);

    useEffect(() => {
        getRole() === 'Sponsor' && setIsFuture(isFutureDate(getSubscriptionExpireDate()));
    }, [getSubscriptionExpireDate]);

    useEffect(() => {
        window.scrollTo({ left: 0, top: scrollBarPosition, behavior: 'smooth' });
    }, [applicantData, goToPage]);

    const downloadSpreadSheetFun = () => {
        callSponsorApplicantApiForSpreadSheet({ opportunityId: selectedValue.value, status: 'all' });
    };

    function getOpportunityAndCandidates(newSelectedValue) {
        let status = 'active';
        if (searchParams.get('checked') !== null) {
            status = searchParams.get('checked') === 'true' ? 'closed' : 'active';
        }
        callSponsorScholarshipAPI(status).then((data) => {
            if (data.length === 0) {
                setSelectedValue(null);
                setApplicantData({});
                return;
            }
            const { label, value } = data[0];
            let opportunityId = selectedValue?.value ?? value;
            let opportunityLabel = selectedValue?.label ?? label;
            if (newSelectedValue === null) {
                opportunityId = value;
                setSelectedValue({ label, value });
            }
            if (selectedValue === null) {
                setSelectedValue({ label: opportunityLabel, value: opportunityId });
            }
            callSponsorApplicantApi({ opportunityId, status: APPLICATION_STATUS[selectedTabIndex] });

            setOpportunitySearchParams({
                title: opportunityLabel,
                id: opportunityId,
                status: selectedTabIndex,
                check: checked,
            });
        });
    }

    async function callSponsorApplicantApi({ opportunityId, status }) {
        try {
            setIsLoading(true);
            const response = await getSponsorApplicantApi({
                params: profileId,
                oppotunityId: opportunityId,
                status,
            });
            dispatch(setPage(1));
            const data = response.data.data || {};
            setIsLoading(false);
            setApplicantData(data);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    }

    async function callSponsorApplicantApiForSpreadSheet({ opportunityId, status }) {
        try {
            setIsLoading(true);
            const response = await getSponsorApplicantApi({
                params: profileId,
                oppotunityId: opportunityId,
                status,
            });
            const data = response.data?.data?.applicants || {};
            const transformedApplicants = data.map((applicant) => ({
                Status: applicant?.application?.status === 'Reviewed' ? 'Applied' : applicant.application.status,
                'User Id': applicant?.user?.enroll_id,
                'First Name': applicant?.user?.first_name,
                'Last Name': applicant?.user?.last_name,
                'High School': applicant?.high_school?.name,
                'High School Grad Year': applicant?.high_school?.graduation_year,
                College: applicant?.college?.name,
                'College Grad Year': applicant?.college?.graduation_year,
                GPA: applicant?.profile?.key_stats?.gap?.toString(),
                Major1: applicant?.profile?.key_stats.major_1?.category?.name,
                Major2: applicant?.profile?.key_stats.major_2?.category?.name,
            }));
            downloadExcel(transformedApplicants);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    }

    async function callSponsorScholarshipAPI(status) {
        const params = { params: { id: profileId } };
        try {
            setIsLoading(true);
            const response = await getSponsorScholarshipsApi(params, { page, pageSize, status: status });
            const data = response?.data.data || {};
            const question = data.map(({ id, question_1, question_2, question_3 }) => ({
                id,
                question_1,
                question_2,
                question_3,
            }));
            setOpportunityQuestions(question);
            const options = data.map(({ id, title }) => ({ value: id, label: title }));
            setDataValue(options);
            setIsLoading(false);
            return options;
        } catch (erorr) {
            console.error(erorr);
            setIsLoading(false);
        }
    }

    const onScholarshipStatusChange = (event) => {
        const state = event.target.checked ? 'closed' : 'active';
        setChecked(event.target.checked);
        callSponsorScholarshipAPI(state).then((data) => {
            if (data.length === 0) {
                setSelectedValue(null);
                setApplicantData({});
                return;
            }
            const { label, value } = data[0];
            setSelectedValue({ label: label, value: value });
            callSponsorApplicantApi({ opportunityId: value, status: APPLICATION_STATUS[selectedTabIndex] });
            setOpportunitySearchParams({
                title: label,
                id: value,
                status: selectedTabIndex,
                check: event.target.checked,
            });
        });
    };

    function setOpportunitySearchParams({ title, status, id, check }) {
        setSearchParams(createSearchParams(getCandidateListSerachParams({ title, status, id, check })));
    }

    function onOpportuityTitleChange(event, newValue) {
        setSelectedValue(newValue);
        callSponsorApplicantApi({ opportunityId: newValue.value, status: APPLICATION_STATUS[0] });
        setOpportunitySearchParams({
            title: newValue.label,
            id: newValue.value,
            status: 0,
            check: checked,
        });
        setSelectedTabIndex(0);
    }

    useEffect(() => {
        if (!!opportunityQuestions) {
            const questions = opportunityQuestions.find((item) => item.id === selectedValue.value);
            setSelectedOpportunityQuestions(questions);
        }
    }, [selectedValue, opportunityQuestions]);

    function handleChange(event, newValue) {
        dispatch(setScrollBarPosition(1));
        const status = APPLICATION_STATUS[newValue];
        callSponsorApplicantApi({ opportunityId: selectedValue.value, status });
        setSelectedTabIndex(newValue);
        setOpportunitySearchParams({
            title: selectedValue.label,
            id: selectedValue.value,
            status: newValue,
            check: checked,
        });
    }

    function onCloseOpportunity() {
        getOpportunityAndCandidates();
    }

    async function onCloseOpportunityClicked() {
        const isCloseOpportunityConfirmed = await dialogService.confirm(
            'Are you sure you want to close this opportunity?'
        );
        if (!isCloseOpportunityConfirmed) {
            return;
        }

        try {
            await closeOpportunityApi({ id: profileId, sponsorshipId: applicantData.id });
            setSearchParams({});
            getOpportunityAndCandidates(null);
            snackbar.success('Opportunity closed successfully');
        } catch (error) {
            console.error(error);
            snackbar.error('could not close opportunity, Please try again later.');
        }
    }

    const isApplicationEmpty = dataValue?.length === 0;

    if (Object.keys(applicantData).length === 0 && Object.keys(state).length === 0 && !isLoading) {
        return (
            <>
                <Container className="px-3 md:px-8 lg:max-w-6xl mx-auto pt-4">
                    <div>{renderBack()}</div>
                    <div>
                        <EmptyApplicationResult text={labelConst.EMPTY_CANDIDATES_DATA} />
                    </div>
                </Container>
            </>
        );
    }

    function renderBack() {
        return (
            <>
                {getRole() && (
                    <Button onClick={() => navigate('/feeds')}>
                        <span className="material-icons material-icons-outlined mr-2">west</span> Back
                    </Button>
                )}
                <div className="flex flex-col sm:flex-row justify-between">
                    <OpportunityToggle
                        checked={checked}
                        onChange={onScholarshipStatusChange}
                        className="justify-start px-[1rem] py-[0.5rem] bg-slate-50"
                        Active={'Active or Open Opportunities'}
                        Closed={'Expired or Closed Opportunities'}
                    />
                    {!isApplicationEmpty && !checked ? (
                        <Button
                            onClick={onCloseOpportunityClicked}
                            className="bg-primary block sm:hidden font-geometric  p-3 text-white text-sm capitalize rounded-[4px]">
                            Close Opportunity
                        </Button>
                    ) : null}
                </div>
            </>
        );
    }

    const applicantMoreThanOne = applicantData?.applicants_count > 0;
    const isFeeGreaterThanZero = Number(applicantData?.fee_amount) > 0;
    const isEmptyPrint = printOutUserData?.length <= 0;
    const isExpired = applicantData?.applicants?.length > 0 && !isFuture;

    const showPaymentOption =
        (oppo_type === 'Scholars' || oppo_type === 'Students to Sponsor') &&
        (applicantData?.fee_status !== 'PAID' || askForSubscription()) &&
        !isFuture &&
        applicantMoreThanOne &&
        isFeeGreaterThanZero &&
        getRole() === 'Sponsor';

    const withoutSubscriptionShowingStudentList =
        (oppo_type === 'Scholars' || oppo_type === 'Students to Sponsor') && applicantData?.fee_status === 'PAID';

    const showStudentListCard = getSubscriptionPlan() === 'Your29 Plan' && isFuture;

    const showSubscriptionOption =
        getSubscriptionPlan() === 'Your29 Plan' &&
        getRole() === 'Sponsor' &&
        (oppo_type !== 'Scholars' || oppo_type !== 'Students to Sponsor') &&
        applicantMoreThanOne &&
        !isFuture &&
        applicantData?.fee_status !== 'PAID';

    const showSubscriptionCard =
        !isFuture &&
        applicantMoreThanOne &&
        getSubscriptionPlan() !== 'Your29 Plan' &&
        getRole() === 'Sponsor' &&
        applicantData?.fee_status !== 'PAID';

    const getButtonTabValue = (val) => {
        const mapping = {
            Scholars: 'Awarded',
            Employee: 'Hired',
            Event: 'Attended',
            'Grad Assistant': 'Hired',
            Intern: 'Hired',
            Mentee: 'Completed',
            Recruits: 'Recruited',
            Volunteers: 'Attended',
        };

        return mapping[val] || '';
    };

    return (
        <Container className="px-3 md:px-8 lg:max-w-6xl mx-auto pt-4">
            <div className="print:hidden">{renderBack()}</div>
            <Grid container display={'flex'} flexDirection={'column'} className="">
                <Grid className="print:hidden flex justify-between items-center" item xs={12}>
                    <Autocomplete
                        label="Opportunities"
                        options={dataValue}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={'Search Opportunities'}
                                error={false}
                                noHelperText={true}
                            />
                        )}
                        classes={{ root: 'w-[40%]' }}
                        value={selectedValue}
                        onChange={onOpportuityTitleChange}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                    />

                    {!isApplicationEmpty && !checked ? (
                        <Button
                            onClick={onCloseOpportunityClicked}
                            className="print:hidden bg-primary hidden sm:block font-geometric  p-3 text-white text-sm capitalize rounded-[4px]">
                            Close Opportunity
                        </Button>
                    ) : null}
                </Grid>

                {!isApplicationEmpty ? (
                    <div className="print:hidden" style={{ marginTop: '20px' }}>
                        <OpportunitiesDetails
                            profileId={profileId}
                            state={state}
                            dataApplicant={applicantData}
                            onClose={onCloseOpportunity}
                            checked={checked}
                        />
                    </div>
                ) : null}

                {showSubscriptionOption && (
                    <AskForPayment
                        applicantData={applicantData}
                        oppo_type={oppo_type}
                        showPaymentOption={showPaymentOption}
                    />
                )}
                {showSubscriptionCard && (
                    <AskForPayment
                        applicantData={applicantData}
                        oppo_type={oppo_type}
                        showPaymentOption={showPaymentOption}
                    />
                )}

                {(getSubRole() === 'Storefront' || showStudentListCard || withoutSubscriptionShowingStudentList) && (
                    <>
                        <Grid className="print:hidden flex flex-row justify-between items-center">
                            <Tabs
                                onChange={handleChange}
                                value={selectedTabIndex}
                                className="mt-4 mb-6"
                                aria-label="basic tabs example">
                                <Tab
                                    {...a11yProps(0)}
                                    label={`Applied ${
                                        APPLICATION_STATUS[selectedTabIndex] === 'pending' ? `(${count})` : ''
                                    }`}
                                    className="p-1"
                                />
                                <Tab
                                    {...a11yProps(1)}
                                    label={`Selected ${
                                        APPLICATION_STATUS[selectedTabIndex] === 'accepted' ? `(${count})` : ''
                                    }`}
                                    className="p-1"
                                />
                                <Tab
                                    {...a11yProps(2)}
                                    label={`Rejected ${
                                        APPLICATION_STATUS[selectedTabIndex] === 'rejected' ? `(${count})` : ''
                                    }`}
                                    className="p-1"
                                />
                                <Tab
                                    {...a11yProps(3)}
                                    label={`${getButtonTabValue(oppo_type)} ${
                                        APPLICATION_STATUS[selectedTabIndex] === 'completed' ? `(${count})` : ''
                                    }`}
                                    className="p-1"
                                />
                            </Tabs>
                            <div>
                            <div className="flex flex-row  gap-1 justify-center items-center">
                            <Typography className="text-[16px] sm:block hidden mb-1 font-normal font-geometric">
                                Export & Track Applicants
                            </Typography>
                            <IconButton className="cursor-pointer" onClick={() => downloadSpreadSheetFun()}>
                                <FileDownloadIcon className="text-zinc-500" />
                            </IconButton>
                        </div>
                            </div>
                        </Grid>
                        <div className="print:hidden container flex justify-end">
                            <div className="flex flex-row items-center">
                                <Typography className="text-13 font-medium font-geometric text-primary">
                                    Toggle to Hide/Display student profile image & name
                                </Typography>
                                <Switch checked={toggled} onChange={handleToggled} />
                            </div>
                        </div>
                        <div className="print:hidden block">
                            <BlockingLoader loading={isLoading} />
                            {!isLoading && (
                                <AppliedCandidatesList
                                    profileId={profileId}
                                    applicantData={applicantData}
                                    value={selectedTabIndex}
                                    checked={checked}
                                    index={selectedTabIndex}
                                    statusCode={APPLICATION_STATUS[selectedTabIndex]}
                                    loading={isLoading}
                                    status={APPLICATION_STATUS[selectedTabIndex]}
                                    selectVal={selectedValue}
                                    onHandleChange={handleChange}
                                    questions={selectedopportunityQuestions}
                                    toggled={toggled}
                                    getButtonTabValue={getButtonTabValue}
                                    oppo_type={oppo_type}
                                    downloadSpreadSheetFun={downloadSpreadSheetFun}
                                />
                            )}
                        </div>
                    </>
                )}
            </Grid>
            <div className="print:hidden container flex justify-center mt-10 mb-5">
                <Button
                    className={`${
                        isEmptyPrint ? 'bg-gray-400' : 'bg-primary'
                    }  text-white rounded-md font-geometric cursor-pointer`}
                    onClick={handlePrint}
                    disabled={isEmptyPrint}>
                    {`${isEmptyPrint ? 'Select to print' : 'Print Selected Item'}`}
                </Button>
            </div>
            <div className="print:block hidden">
                {printOutUserData?.map(({ profile, id, user }) => {
                    return (
                        <>
                            <div key={id} className="print:page-break-after-always realtive">
                                <StudentProfile
                                    usernameId={profile?.username}
                                    userRole={user?.role}
                                    toggled={toggled}
                                    prId={profile.id}
                                    showBio={true}
                                />
                            </div>
                        </>
                    );
                })}
            </div>
        </Container>
    );
}
