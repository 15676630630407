import React, { useState } from 'react';

const ReadMore = (props) => {
    const { text = '', length = 140, btnClassName = '', font=false } = props;
    const [expanded, setExpanded] = useState(false);

    // Is the text short?
    const isShort = text?.toString()?.length <= length;

    // Do we show the full text?
    const full = expanded || isShort;

    return (
        <>
            {full ? text : text ? `${text.slice(0, length)}...` : ''} <br />
            {!isShort && text && (
                <button
                    onClick={() => setExpanded(!expanded)}
                    className={`text-primary ${font ? 'font-medium mt-1 text:[12px]':'font-bold mt-2'}  ${btnClassName}`}>
                    Read {full ? 'Less' : 'More'}
                </button>
            )}
        </>
    );
};

export default ReadMore;
