import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Autocomplete, FormControl, InputLabel, Modal, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';
import { useSnackbar } from '../../hooks';
import { addNewMailChimpTag, applyToOpportunity as applyToOpportunityApi, getTeams } from '../../services/app/api';
import { getSignUpUserId } from '../../services/app/storage';
import { convertDate, getPopupBoxTitle } from '../../utils/GenericFunctions';
import * as labelConst from '../../utils/GetLabels';
import mailchimpTags from '../../utils/mailchimpTags';
import Button from './Button';
import MenuItem from './MenuItem';
import Paper from './Paper';
import TextField from './TextField';
import Typography from './Typography';
import Upload from './Upload';
function StudentModelConfirmationBox({
    openConfirmationBox,
    handleClose,
    question_1,
    question_2,
    question_3,
    title,
    opportunityId,
    handleAppliedToOpportunity,
    setOpenConfirmationBox,
    virtualApiEvent,
    campusApiData,
    eventName,
    text,
    username,
    date,
    metadata = [],
    deadline,
    percentageCompletion,
}) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
    };

    const [question1, setQuestion1] = useState('');
    const [question2, setQuestion2] = useState('');
    const [question3, setQuestion3] = useState('');
    const [isValidForm, setIsValidForm] = useState(false);
    const [attachment, setAttachment] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [teams, setTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const snackbar = useSnackbar();
    const { getUserName, isAuthenticated, getEmail, getProfileId, getSubRole, getRole } = useAuth();

    const navigate = useNavigate();
    const popupBoxTitle = getPopupBoxTitle(eventName);
    const opportunityType = metadata?.flatMap((item) => (item?.key === 'opp_type' ? [item?.value] : []))[0];
    const oppURL = metadata?.flatMap((item) => (item?.key === 'url' ? [item?.value] : []))[0];
    let successTags = [];
    let extraInfo = `${title}-${username}-${convertDate(date, 'YYYY-MM-DD')}`;

    const fetchTeams = () => {
        let params = {
            page: 1,
            pageSize: -1,
        };
        setIsLoading(true);
        getTeams({ profile_id: getProfileId(), params })
            .then((res) => {
                setTeams(res?.data?.data);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        fetchTeams();
    }, []);

    if (opportunityType === 'Event') {
        successTags = [
            `${mailchimpTags.STUDENT_REGISTRATION_CONFIRMED}-${extraInfo}`,
            `${mailchimpTags.DEADLINE_TO_APPLY}-${convertDate(deadline, 'YYYY-MM-DD')}-${title}`,
        ];
        const eventName = metadata?.flatMap((item) => (item?.key === 'event_name' ? [item?.value] : []))[0];
        if (eventName === 'Interview Request') {
            successTags = [...successTags, `${mailchimpTags.REQUEST_AN_INTERVIEW_SCHEDULED}-${extraInfo}`];
        }
    } else if (opportunityType == 'Intern') {
        successTags = [`${mailchimpTags.APPLY_FOR_SUMMER_INTERNSHIP}-${extraInfo}`];
    } else if (opportunityType == 'Employee') {
        const empType = metadata?.flatMap((item) => (item?.key === 'job_type' ? [item?.value] : []))[0];
        if (empType === 'Full-time') {
            successTags = [`${mailchimpTags.APPLY_FOR_FULL_TIME}-${extraInfo}`];
        }
    } else if (opportunityType == 'Mentee') {
        successTags = [`${mailchimpTags.STUDENT_APPLIES_FOR_MENTORSHIP_CONFIRMED}-${extraInfo}`];
    } else if (opportunityType == 'Scholars') {
        successTags = [`${mailchimpTags.STUDENT_APPLIES_FOR_SCHOLARSHIP_CONFIRMED}-${extraInfo}`];
    }

    useEffect(() => {
        if (question_1 && question_2 && question_3) {
            setIsValidForm(question1 && question2 && question3);
        } else if (question_1 && question_2) {
            setIsValidForm(question1 && question2);
        } else if (question_2 && question_3) {
            setIsValidForm(question2 && question3);
        } else if (question_1 && question3) {
            setIsValidForm(question1 && question3);
        } else if (question_1) {
            setIsValidForm(question1);
        } else if (question_2) {
            setIsValidForm(question2);
        } else if (question_3) {
            setIsValidForm(question3);
        }

        if (getSubRole() === 'Leader' && !selectedTeam?.id) {
            setIsValidForm(false);
        }
    }, [question1, question2, question3, selectedTeam]);

    const handleConfirm = async () => {
        const formData = new FormData();
        if (question1 !== '') {
            formData.append('answer_1', question1);
        }
        if (question2 !== '') {
            formData.append('answer_2', question2);
        }
        if (question3 !== '') {
            formData.append('answer_3', question3);
        }
        if (attachment) {
            formData.append('file', attachment);
        }
        if (getSubRole() === 'Leader') {
            formData.append('team_id', selectedTeam?.id);
        }
        const data = {
            userId: getSignUpUserId() ? getSignUpUserId() : getUserName(),
            opportunityId: opportunityId,
            payload: formData,
        };

        try {
            setIsLoading(true);
            await applyToOpportunityApi({ data });
            if (text === 'event') {
                virtualApiEvent();
                campusApiData();
            }

            const mailchimpPayload = {
                profileId: getProfileId(),
                data: {
                    email: getEmail(),
                    tags: successTags,
                },
            };
            if (successTags?.length) {
                await addNewMailChimpTag(mailchimpPayload).catch(() => {});
            }
            if (!!oppURL) {
                window.open(oppURL, '_blank');
            }
            handleAppliedToOpportunity(opportunityId);
            snackbar.success(<Message />, { autoHideDuration: 10000 });
        } catch (error) {
            const { errorMessage } = error;
            setIsLoading(false);
            if (errorMessage.includes('Key-Stats details are required')) {
                snackbar.error(`unable to apply for ${title} opportunity`, { autoHideDuration: 7500 });
                setOpenDialog(true);
                return;
            }
            snackbar.error(errorMessage, { autoHideDuration: 7500 });
            console.error(error);
        } finally {
            setIsLoading(false);
            setOpenConfirmationBox(false);
            if (!isAuthenticated()) {
                navigate('/');
            }
            setOpenConfirmationBox(false);
            setSelectedTeam(null);
        }
    };

    function Message() {
        const { isAuthenticated } = useAuth();
        const typesOfOpp = {
            Employee: 'Employment',
            Event: 'Event',
            'Grad Assistant': 'Grad Assistantship',
            Intern: 'Internship',
            Mentee: 'Mentorship',
            Recruits: 'Recruitment',
            Scholars: 'Scholarships',
            'Students to Sponsor': 'Sponsorships',
            Volunteers: 'Volunteer Opps',
            Influencers: 'Influencer Opp',
            'Research Assistant': 'Research Assistantship',
            Tutor: 'Tutoring',
        };
        return (
            <>
                {oppURL ? (
                    <p className="text-white">
                        Your profile application has been submitted and can be viewed in{' '}
                        <Link
                            className="text-[#EDEDED] font-bold border-solid border-b-2 border-b-[#EDEDED]"
                            to="/applications">
                            {'  '}
                            My Applications
                        </Link>
                        . The {typesOfOpp[opportunityType]} link and confirmation has been sent to your email.
                    </p>
                ) : (
                    <p className="text-white">
                        {`${
                            isAuthenticated()
                                ? `${labelConst.EVENT_SNACKBAR_TEXT_AUTHORISED} "${title}" and can be viewed in`
                                : popupBoxTitle === labelConst.EVENT_REQUESTING_TITLE
                                ? `${labelConst.EVENT_SNACKBAR_TEXT_UNAUTHORISED} "${title}"`
                                : `${labelConst.EVENT_REGISTRATIONING_MESSAGE} "${title}"`
                        }`}
                        {isAuthenticated() && (
                            <Link
                                className="text-[#EDEDED] font-bold border-solid border-b-2 border-b-[#EDEDED]"
                                to="/applications">
                                {'  '}
                                My Applications
                            </Link>
                        )}
                        .
                    </p>
                )}
            </>
        );
    }

    return (
        <Modal
            className="bg-transparent"
            open={openConfirmationBox}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Paper elevation={4} className="w-[90%] md:w-[55%]" sx={style}>
                <div className="bg-primary px-4 text-white h-16 flex items-center">
                    <Typography id="modal-modal-title" variant="p" className="font-geometric text-17">
                        {`${popupBoxTitle ? popupBoxTitle : 'Confirm Your Application'}`}
                    </Typography>
                </div>
                {question_1 || question_2 || question_3 ? (
                    <div>
                        <div className=" m-2 sm:m-6 mb-3 border-b-1 pb-3 border-gray-500 ">
                            <Typography id="modal-modal-title" variant="p" className="font-geometric text-[16px]">
                                {`Answer the below questions to confirm your  
                                ${
                                    popupBoxTitle === labelConst.EVENT_REQUESTING_TITLE
                                        ? `request for`
                                        : popupBoxTitle === labelConst.EVENT_REGISTRATION_TITLE
                                        ? `registration for `
                                        : !popupBoxTitle && `application for `
                                }
                                 "${title}"`}
                            </Typography>
                        </div>

                        {question_1 && (
                            <div className=" mx-2 sm:mx-8 flex items-center justify-between hover:bg-gray-100 p-2 ">
                                <p className="flex items-center">
                                    <div className="mr-2 min-h-[8px] min-w-[8px] rounded-full bg-primary" />
                                    {question_1}
                                </p>
                                <FormControl className="min-w-[80px] ml-1 " size="small">
                                    <InputLabel id="demo-simple-select-label">Select</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={question1}
                                        label="Select"
                                        onChange={(e) => setQuestion1(e.target.value)}>
                                        <MenuItem value={'Yes'}>Yes</MenuItem>
                                        <MenuItem value={'No'}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        )}
                        {question_2 && (
                            <div className=" mx-2 sm:mx-8 flex items-center justify-between hover:bg-gray-100 p-2 ">
                                <p className="flex items-center">
                                    <div className="mr-2 min-h-[8px] min-w-[8px] rounded-full bg-primary" />
                                    {question_2}
                                </p>
                                <FormControl className="min-w-[80px] ml-1 " size="small">
                                    <InputLabel id="demo-simple-select-label">Select</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={question2}
                                        label="Select"
                                        onChange={(e) => setQuestion2(e.target.value)}>
                                        <MenuItem value={'Yes'}>Yes</MenuItem>
                                        <MenuItem value={'No'}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        )}
                        {question_3 && (
                            <div className=" mx-2 sm:mx-8 flex items-center justify-between hover:bg-gray-100 p-2 ">
                                <p className="flex items-center">
                                    <div className="mr-2 min-h-[8px] min-w-[8px] rounded-full bg-primary" />
                                    {question_3}
                                </p>
                                <FormControl className="min-w-[80px] ml-1 " size="small">
                                    <InputLabel id="demo-simple-select-label">Select</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={question3}
                                        label="Select"
                                        onChange={(e) => setQuestion3(e.target.value)}>
                                        <MenuItem value={'Yes'}>Yes</MenuItem>
                                        <MenuItem value={'No'}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        )}
                        <div className=" mx-2 sm:mx-8 flex items-center justify-between hover:bg-gray-100 p-2 ">
                            <p className="flex items-center">
                                <div className="mr-2 min-h-[8px] min-w-[8px] rounded-full bg-primary" />
                                Add additional attachment
                            </p>
                            <div className="flex items-end flex-col">
                                <div className="min-w-[80px] ml-1 " size="small">
                                    <Upload
                                        allowedExtensions="application/pdf, image/* "
                                        onChange={(e) => setAttachment(e.target.files[0])}
                                        btnComponent={Button}
                                        btnProps={{
                                            variant: 'contained',
                                            startIcon: <AttachFileIcon />,
                                            title: 'Click here to upload file.',
                                        }}
                                        btnChildren="Attach"
                                    />
                                </div>
                                {attachment && <p className="text-xs">{attachment.name}</p>}
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className=" m-2 sm:m-6 mb-3 border-b-1 pb-3 border-gray-500 ">
                            {popupBoxTitle === labelConst.EVENT_REQUESTING_TITLE
                                ? `${labelConst.EVENT_SNACKBAR_TEXT_UNAUTHORISED} "${title}"`
                                : popupBoxTitle === labelConst.EVENT_REGISTRATION_TITLE
                                ? `${labelConst.EVENT_REGISTRATIONING_MESSAGE} "${title}"`
                                : !popupBoxTitle &&
                                  `You are applying for "${title}" and your profile will be submitted.`}
                            <div>
                                {getRole() === 'Student' &&
                                    (percentageCompletion > 80 ? (
                                        <span className="font-medium text-primary font-geometric">
                                            Note: Your profile is {percentageCompletion}% complete and looking great.
                                            You will be among the first applicants to be reviewed. Good luck!
                                        </span>
                                    ) : (
                                        <span className="font-medium text-primary font-geometric">
                                            Note: Your profile is {percentageCompletion}% complete. Consider completing
                                            more of your profile so you will be among the first applicants to be
                                            reviewed.
                                        </span>
                                    ))}
                            </div>
                        </div>

                        <div className=" mx-2 sm:mx-8 flex items-center justify-between hover:bg-gray-100 p-2 ">
                            <p className="flex items-center">
                                <div className="mr-2 min-h-[8px] min-w-[8px] rounded-full bg-primary" />
                                Add any additional documents to this application here.
                            </p>
                            <div className="flex items-end flex-col">
                                <div className="min-w-[80px] ml-1 " size="small">
                                    <Upload
                                        allowedExtensions="application/pdf, image/* "
                                        onChange={(e) => setAttachment(e.target.files[0])}
                                        btnComponent={Button}
                                        btnProps={{
                                            variant: 'contained',
                                            startIcon: <AttachFileIcon />,
                                            title: 'Click here to upload file.',
                                        }}
                                        btnChildren="Attach"
                                    />
                                </div>
                                {attachment && <p className="text-xs">{attachment.name}</p>}
                            </div>
                        </div>
                    </>
                )}
                {getSubRole() === 'Leader' && teams?.length > 0 && (
                    <div className="m-2 sm:m-6 ">
                        <Autocomplete
                            id="scholarship-residency"
                            value={selectedTeam}
                            options={teams}
                            isOptionEqualToValue={(option) => option == selectedTeam}
                            getOptionLabel={(option) => option?.name}
                            onChange={(event, value) => setSelectedTeam(value)}
                            renderOption={(props, option) => <li {...props}>{option?.name}</li>}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    classes={{ root: 'mt-1' }}
                                    {...params}
                                    label={'Select your team or club'}
                                    placeholder={`Select your team or club`}
                                />
                            )}
                        />
                    </div>
                )}
                <div className=" m-2  sm:m-6 mb-3 border-b-1 pb-3 border-gray-500 "></div>
                <div className="flex justify-end m-4 gap-4 ">
                    <Button onClick={handleClose}>Cancel</Button>
                    {question_1 || question_2 || question_3 ? (
                        <Button
                            variant={'contained'}
                            disabled={!isValidForm}
                            loading={isLoading}
                            onClick={handleConfirm}>
                            confirm
                        </Button>
                    ) : (
                        <Button
                            disabled={getSubRole() === 'Leader' && !selectedTeam?.id}
                            loading={isLoading}
                            variant={'contained'}
                            onClick={handleConfirm}>
                            confirm
                        </Button>
                    )}
                </div>
            </Paper>
        </Modal>
    );
}

export default StudentModelConfirmationBox;
