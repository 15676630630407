/* eslint-disable import/no-anonymous-default-export */

export const getEnvironment = () => process.env.NODE_ENV;

export const isProduction = () => getEnvironment() === 'production';

export const getApiBaseUrl = () => process.env.REACT_APP_API_BASE_URL;

export const Environments = {
    FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL,
};

export default { getEnvironment, isProduction, getApiBaseUrl };
