import { TabContext, TabPanel } from '@mui/lab';
import { Box, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import FeedbackData from './FeedbackData';
import FeedbackForm from './FeedbackForm';
import { useAuth } from '../../../contexts/Auth';
import FollowUp from '../Followup'

const Feedback = () => {
    const [value, setValue] = useState(0);
    const [editFeedback, setEditFeedback] = useState({});
    const { getRole } = useAuth();
    const analyticsTabs = [
        {
            label: 'Create',
            tabNo: 0,
        },
        {
            label: 'All Feedbacks',
            tabNo: 1,
        },
        {
            label: 'Follow Up',
            tabNo: 2,
        },
    ];
    const panels = [
        {
            component: (
                <FeedbackForm
                    editFeedback={editFeedback}
                    handleEditFeedback={(data) => {
                        setEditFeedback(data);
                    }}
                />
            ),
            value: 0,
        },
        {
            component: (
                <FeedbackData
                    handleEditFeedback={(data) => {
                        setEditFeedback(data);
                        setValue(0);
                    }}
                />
            ),
            value: 1,
        },
        {
            component: (
                <FollowUp />
            ),
            value: 2,
        },
    ];

    const analyticsTabsForAdmin = [
        {
            label: 'All Feedbacks',
            tabNo: 0,
        },
    ];

    const panelsAdmin = [
        {
            component: (
                <FeedbackData
                    handleEditFeedback={(data) => {
                        setEditFeedback(data);
                    }}
                />
            ),
            value: 0,
        },
    ];

    const renderTap = getRole() === 'SuperAdmin' ? analyticsTabs : analyticsTabsForAdmin;
    const renderPanels = getRole() === 'SuperAdmin' ? panels : panelsAdmin;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div>
            <TabContext value={value}>
                <Box className=" w-full ">
                    <Tabs
                        textColor="secondary"
                        indicatorColor="secondary"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        aria-label="basic tabs example">
                        {renderTap.map(({ label, tabNo }) => (
                            <Tab
                                key={tabNo}
                                label={label}
                                id={`simple-tab-${tabNo}`}
                                aria-controls={`simple-tabpanel-${tabNo}`}
                            />
                        ))}
                    </Tabs>
                </Box>
                {renderPanels.map(({ value, component }) => (
                    <TabPanel sx={{ padding: 0 }} key={value} value={value}>
                        {component}
                    </TabPanel>
                ))}
            </TabContext>
        </div>
    );
};

export default Feedback;
