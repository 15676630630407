import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import { useAuth } from '../../../contexts/Auth';
import pointerImage from '../../../images/pointer.svg';
import {
    fetchColleges,
    getAllSubjects as getAllSubjectsApi,
    getSubjectsCategories as getSubjectsCategoriesApi,
    lookupCityState as lookupCityStateApi,
} from '../../../services/app/api';
import dialog from '../../../services/app/dialog';
import { isString } from '../../../services/core/types';
import { mandatoryField } from '../../../utils/GenericFunctions';
import { META_KEYS, Roles } from '../../../utils/GetConstants';
import * as labelConst from '../../../utils/GetLabels';
import Autocomplete from '../../UI/Autocomplete';
import Button from '../../UI/Button';
import TextField from '../../UI/TextField';
import Typography from '../../UI/Typography';
import {
    LOOKING_FOR_LIST,
    SPONSOR_CANDIDATE_LIST,
    STUDENT_LOOKING_FOR_LIST,
    STUDENT_OPPORTUNITY_IDS,
    getOpportunityList,
} from './LookingFor.utils';

export function generateYearList(start = -8, end = 9) {
    const currentYear = new Date().getFullYear();
    const yearList = [];
    for (let index = start; index <= end; index++) {
        const year = String(currentYear + index);
        yearList.push(year);
    }
    return yearList;
}

const LookingFor = ({
    lookingForList,
    setLookingForList,
    selectedInputs,
    setSelectedInputs,
    value,
    disabled = false,
    column = 4,
    listType = Roles.Sponsor,
    scholarshipErrors,
    runLookingForValidations,
    isSearch = false,
    searchSponsorsApi,
}) => {
    const [subjects, setSubjects] = React.useState([]);

    const { getSubRole } = useAuth();

    function handleChange(event, optionId, fieldValue, key, parent, level) {
        runLookingForValidations({ optionId, fieldValue });
        const { employment_level2, gradAssistantship_level3, event_level1 } = selectedInputs;

        if (optionId === 'event_level2' && fieldValue) {
            setLookingForList(
                getOpportunityList({
                    listType,
                    opportunityType: value,
                    collegesList: [{ name: 'Loading...', id: '', value: '' }],
                    fieldValue: event_level1?.value,
                })
            );
            getSubRole() !== 'Storefront' &&
                fetchColleges({ params: { page: 1, pageSize: -1 } }).then(({ data: collegeList }) => {
                    const collegesList =
                        event_level1?.value === 'Virtual Event'
                            ? [{ name: 'Not Applicable', id: 'not_applicable' }, ...collegeList.data]
                            : collegeList.data;
                    setLookingForList(
                        getOpportunityList({
                            listType,
                            opportunityType: value,
                            collegesList: collegesList,
                            fieldValue: event_level1?.value,
                        })
                    );
                });
        }

        if (optionId === 'event_level1' && fieldValue === 'Official Visit') {
            getAllSubjectsApi().then(({ data: subjectsData }) => {
                setLookingForList(
                    getOpportunityList({
                        listType,
                        opportunityType: value,
                        subjects: subjectsData.data,
                        fieldValue: fieldValue,
                    })
                );
            });
        }
        if (optionId === 'event_level3' && fieldValue) {
            getAllSubjectsApi().then(({ data: subjectsData }) => {
                setLookingForList(
                    getOpportunityList({
                        listType,
                        opportunityType: value,
                        subjects: subjectsData.data,
                        fieldValue: event_level1?.value,
                    })
                );
            });
        }

        if (
            (optionId === 'gradAssistantship_level3' && fieldValue === 'Specific Fields of Study') ||
            ((value === 'Intern' || value === 'Internship') && optionId === 'internship_level2') ||
            ((value === 'Mentee' || value === 'Mentorship') && optionId === 'mentorship_level2')
        ) {
            getAllSubjectsApi().then(({ data: subjectsData }) => {
                setSubjects(subjectsData.data);
                setLookingForList(
                    getOpportunityList({
                        listType,
                        opportunityType: value,
                        subjects: subjectsData.data,
                        fieldValue: employment_level2?.value || value,
                    })
                );
            });
        }

        if (
            optionId === 'employment_level2' &&
            (fieldValue === 'Research Assistant' || fieldValue === 'Research Assistantship')
        ) {
            getAllSubjectsApi().then(({ data: subjectsData }) => {
                setSubjects(subjectsData.data);
                setLookingForList(
                    getOpportunityList({
                        listType,
                        opportunityType: value,
                        subjects: subjectsData.data,
                        fieldValue: fieldValue,
                    })
                );
            });
        }
        if (
            optionId === 'employment_level3' &&
            fieldValue &&
            (employment_level2?.value === 'Research Assistant' || employment_level2?.value === 'Research Assistantship')
        ) {
            getSubjectsCategoriesApi({ category: fieldValue.id }).then(({ data: categories }) => {
                setLookingForList(
                    getOpportunityList({ listType, opportunityType: value, subjects, categories: categories.data })
                );
            });
        }

        if (
            optionId === 'gradAssistantship_level4' &&
            fieldValue &&
            gradAssistantship_level3.value === 'Specific Fields of Study'
        ) {
            getSubjectsCategoriesApi({ category: fieldValue.id }).then(({ data: categories }) => {
                setLookingForList(
                    getOpportunityList({ listType, opportunityType: value, subjects, categories: categories.data })
                );
            });
        }

        setSelectedInputs((prevInputs) => {
            const inputs = { ...prevInputs };
            if (optionId === 'SummerJob_level1' && prevInputs.SummerJob_level1?.value?.length > 0) {
                inputs.SummerJob_level2 = null;
                inputs.SummerJob_level3 = null;
                inputs.SummerJob_level4 = null;
            }
            if (optionId === 'employment_level1' && prevInputs.employment_level1?.value?.length > 0) {
                inputs.employment_level2 = null;
                inputs.employment_level3 = null;
                inputs.employment_level4 = null;
            }
            if (optionId === 'event_level1' && prevInputs.event_level1?.value?.length > 0) {
                inputs.event_level2 = null;
                inputs.event_level3 = null;
                inputs.event_level4 = null;
                inputs.event_level5 = null;
                inputs.event_level6 = null;
            }
            if (optionId === 'event_level2' && prevInputs.event_level2?.value?.length > 0) {
                inputs.event_level3 = null;
                inputs.event_level4 = null;
                inputs.event_level5 = null;
                inputs.event_level6 = null;
            }

            if (
                optionId === 'employment_level2' &&
                (prevInputs.employment_level2?.value?.length > 0 ||
                    prevInputs.employment_level2?.value?.value?.length > 0)
            ) {
                inputs.employment_level3 = null;
                inputs.employment_level4 = null;
            }

            if (optionId === 'gradAssistantship_level3' && prevInputs.gradAssistantship_level3?.value?.length > 0) {
                inputs.gradAssistantship_level4 = null;
                inputs.gradAssistantship_level5 = null;
            }
            if (
                optionId === 'gradAssistantship_level4' &&
                (prevInputs.gradAssistantship_level4?.value?.length > 0 ||
                    prevInputs.gradAssistantship_level4?.value?.value?.length > 0)
            ) {
                inputs.gradAssistantship_level5 = null;
            }
            if (optionId === 'recruits_level2' && prevInputs.recruits_level2?.value?.length > 0) {
                inputs.recruits_level3 = null;
                inputs.recruits_level4 = null;
            }

            if (
                (optionId === 'employment_level3' ||
                    optionId === 'volunteer_level1' ||
                    optionId === 'mentorship_level2' ||
                    optionId === 'internship_level2' ||
                    optionId === 'gradAssistantship_level2') &&
                fieldValue === 'Virtual'
            ) {
                inputs.location = null;
            }

            const optionValue = isSearch
                ? { value: fieldValue, key, parent, optionId, level }
                : { value: fieldValue, key, parent };

            return { ...inputs, [optionId]: optionValue };
        });

        const optionValue = isSearch
            ? { value: fieldValue, key, parent, optionId, level }
            : { value: fieldValue, key, parent };

        isSearch && searchSponsorsApi({ optionValue: { [optionId]: optionValue }, level });
    }

    const showTextFields =
        listType === Roles.Sponsor ? LOOKING_FOR_LIST[value].length > 0 : STUDENT_LOOKING_FOR_LIST[value].length > 0;

    if (!showTextFields) {
        return null;
    }

    return (
        <div className="mt-3 mb-3 items-start">
            <div className="flex gap-4">
                <img src={pointerImage} className="w-6 h-6" alt="" />
                <Typography className="font-geometric text-base text-primary font-semibold">
                    {value === 'Summer Job' ? 'Summer Jobs' : value}
                </Typography>
            </div>
            <div className="lg:pl-10">
                <Grid container rowSpacing={{ xs: 1 }} columnSpacing={{ xs: 1, md: 3 }}>
                    <LookingForList
                        column={column}
                        options={lookingForList}
                        selectedInputs={selectedInputs}
                        id={
                            listType === Roles.Sponsor
                                ? SPONSOR_CANDIDATE_LIST[value].id
                                : STUDENT_OPPORTUNITY_IDS[value].id
                        }
                        label={value === 'Sponsorship' ? 'Select all that applies' : `Select ${value} criteria`}
                        handleChange={handleChange}
                        disabled={disabled}
                        scholarshipErrors={scholarshipErrors}
                    />
                    {selectedInputs.employment_level3?.value === 'On Site' ||
                    selectedInputs.mentorship_level2?.value === 'On Site' ||
                    selectedInputs.volunteer_level1?.value === 'On Site' ||
                    selectedInputs.gradAssistantship_level2?.value === 'On Site' ||
                    selectedInputs?.employment_level2?.value === 'Private Lessons' ||
                    selectedInputs?.internship_level2?.value === 'On Site' ||
                    selectedInputs?.employment_level1?.value === 'Tutoring' ||
                    selectedInputs?.employment_level2?.value === 'Tutor' ? (
                        <ZipCodeInput
                            selectedInputs={selectedInputs}
                            setSelectedInputs={setSelectedInputs}
                            key={value}
                            column={column}
                            scholarshipErrors={scholarshipErrors}
                            runLookingForValidations={runLookingForValidations}
                            searchSponsorsApi={searchSponsorsApi}
                            isSearch={isSearch}
                        />
                    ) : null}
                </Grid>
            </div>
        </div>
    );
};

function LookingForList({
    options,
    selectedInputs,
    id,
    label,
    handleChange,
    newIdx = 0,
    column,
    disabled,
    scholarshipErrors,
}) {
    let showNextInputField = true;
    let latestIndex;
    return (
        <>
            {options.map((item, index) => {
                const { isMultiple = false, level, key, parent, values, label: optionLabel } = item;
                if (!showNextInputField) {
                    return null;
                }

                latestIndex = newIdx + index;
                const optionId = `${id}_level${latestIndex + 1}`;
                const { value = null } = selectedInputs[optionId] || {};
                const newLevel = latestIndex + 1;

                const isValidOptionValue =
                    (isString(value) && value.trim().length > 0) ||
                    (typeof value === 'object' && value?.value?.length > 0);
                const isValidMultiOptionValue = Array.isArray(value) && value.length > 0;

                showNextInputField = value != undefined && (isValidOptionValue || isValidMultiOptionValue);

                const isNestedLevel = typeof values === 'object' && !Array.isArray(values);

                var selectedLevelInputValue = selectedInputs[`${id}_level${latestIndex}`]?.value;
                // (level >= 3 ? selectedInputs.employment_level2?.value : selectedInputs.employment_level1?.value) ||
                // (level === 4
                //     ? selectedInputs.gradAssistantship_level3?.value
                //     : selectedInputs.gradAssistantship_level4?.value) ||
                // selectedInputs.recruits_level2?.value ||
                // (level <= 2 ? selectedInputs.event_level1?.value : selectedInputs.event_level2?.value);

                const optionValue =
                    isMultiple && !selectedInputs[optionId]?.value ? [] : selectedInputs[optionId]?.value;

                const labelValue = optionLabel ? optionLabel : latestIndex === 0 ? label : 'Select one from list';

                if (isNestedLevel && !values[selectedLevelInputValue]) {
                    return null;
                }
                if (isNestedLevel && item?.fieldType !== 'open') {
                    return (
                        <LookingForList
                            key={index}
                            options={values[selectedLevelInputValue]}
                            selectedInputs={selectedInputs}
                            id={id}
                            label={mandatoryField(`Select ${value} criteria`)}
                            handleChange={handleChange}
                            newIdx={latestIndex}
                            column={column}
                            disabled={disabled}
                            scholarshipErrors={scholarshipErrors}
                        />
                    );
                } else if (item?.fieldType === 'open') {
                    return (
                        <Grid key={index} item xs={6} md={column}>
                            <Typography className="mt-1 text-xs text-gray-500 whitespace-nowrap overflow-hidden text-ellipsis">
                                {mandatoryField(labelValue)}
                            </Typography>
                            <TextField
                                error={scholarshipErrors[optionId]?.length > 0 ?? false}
                                noHelperText={true}
                                placeholder={item?.placeholder || ''}
                                value={optionValue || null}
                                onChange={(event, newValue) =>
                                    handleChange(event, optionId, event.target?.value, key, parent, newLevel)
                                }
                            />
                        </Grid>
                    );
                }

                return (
                    <Grid key={index} item xs={6} md={column}>
                        <Typography
                            title={labelValue}
                            className="mt-1 text-xs text-gray-500 whitespace-nowrap overflow-hidden text-ellipsis">
                            {mandatoryField(labelValue)}
                        </Typography>
                        <Autocomplete
                            title={mandatoryField(optionValue)}
                            disabled={disabled}
                            multiple={isMultiple}
                            id={optionId}
                            name={optionId}
                            options={values}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={scholarshipErrors[optionId]?.length > 0 ?? false}
                                    noHelperText={true}
                                    placeholder=""
                                />
                            )}
                            onChange={(event, newValue) =>
                                handleChange(event, optionId, newValue, key, parent, newLevel)
                            }
                            value={optionValue || null}
                            error={scholarshipErrors[optionId]?.length > 0 ?? false}
                            errorMessage={scholarshipErrors[optionId] ?? ''}
                            isOptionEqualToValue={(option, value) => {
                                if (typeof option === 'string') return option === value;
                                return option.value === value.value;
                            }}
                            // getOptionDisabled={(option) => disableOpportunityOptions.includes(option)}
                        />
                    </Grid>
                );
            })}
        </>
    );
}

function ZipCodeInput({
    column,
    selectedInputs,
    setSelectedInputs,
    scholarshipErrors,
    runLookingForValidations,
    isSearch,
    searchSponsorsApi,
}) {
    const [zipcode, setZipcode] = useState(selectedInputs.location?.value?.zipcode ?? '');
    const [zipLoading, setZipLoading] = useState(false);

    const { location } = selectedInputs;
    const { value: locationInfo = {} } = location || {};
    const { state, city } = locationInfo;

    function onSelectZipCode() {
        setZipLoading(true);
        lookupCityStateApi({ params: { zipcode } })
            .then((response) => {
                const {
                    City: resCity,
                    State: resState,
                    Zip5,
                } = response.data?.data?.CityStateLookupResponse?.ZipCode || {};
                if (resCity && resState) {
                    const locationInfo = {
                        key: META_KEYS.LOCATION,
                        value: { city: resCity, state: resState, zipcode: Zip5 },
                        parent: META_KEYS.LOCATION_TYPE,
                    };

                    setSelectedInputs((prevInputs) => ({
                        ...prevInputs,
                        location: locationInfo,
                    }));

                    isSearch && searchSponsorsApi({ optionValue: { location: locationInfo } });
                    runLookingForValidations({
                        optionId: 'location',
                        fieldValue: {
                            key: META_KEYS.LOCATION,
                            value: { city: resCity, state: resState, zipcode: Zip5 },
                            parent: META_KEYS.LOCATION_TYPE,
                        },
                    });
                } else {
                    dialog.error('Zip Code seems to be incorrect.');
                    setSelectedInputs((prevInputs) => ({
                        ...prevInputs,
                        location: {
                            key: META_KEYS.LOCATION,
                            value: { city: '', state: '', zipcode: '' },
                            parent: META_KEYS.LOCATION_TYPE,
                        },
                    }));
                }
                setZipLoading(false);
            })
            .catch(() => {
                setZipLoading(false);
                dialog.error('Zip Code seems to be incorrect.');
            });
    }

    return (
        <Grid item xs={6} md={column} className="lg:mb-4">
            <div className="flex flex-col lg:flex-row lg:items-center lg:gap-3">
                <div className="flex-1">
                    <TextField
                        type="number"
                        label={mandatoryField('Zip Code')}
                        placeholder="What's your 5-digit zip code?"
                        value={zipcode}
                        onChange={(e) => {
                            setZipcode(e.target.value);
                        }}
                        classes={{ root: 'flex-1 lg:mr-2' }}
                        error={scholarshipErrors?.location?.length > 0 ?? false}
                        errorMessage={scholarshipErrors?.location ?? ''}
                    />
                    {city && state && (
                        <Typography>
                            <strong>{city}</strong>, <strong>{state}</strong>.
                        </Typography>
                    )}
                </div>
                <Button
                    className="mt-3 self-start lg:self-center"
                    variant="contained"
                    size="small"
                    disabled={!zipcode || zipcode.length !== 5}
                    loading={zipLoading}
                    onClick={onSelectZipCode}>
                    {labelConst.SELECT}
                </Button>
            </div>
        </Grid>
    );
}

export default LookingFor;
