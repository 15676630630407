const VARIANT = {
    red: {
        lineColor1: '#C54A28',
        lineColor2: '#F0643D',
        bgColor: '#FFF6F3',
        ovalColor: '#FFE7E0',
    },
    blue: {
        lineColor1: '#103B58',
        lineColor2: '#103B58',
        bgColor: '#E6F0F6',
        ovalColor: '#D3EBFC',
    },
};

const NoSearchResult = ({ color, ...rest }) => {
    const { lineColor1, lineColor2, bgColor, ovalColor } = VARIANT[color];

    return (
        <svg width={139} height={74} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <title>Magnifying Search icon</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M133.12 3c3.217 0 5.824 2.601 5.824 5.81 0 3.209-2.607 5.81-5.824 5.81H99.84c3.217 0 5.824 2.601 5.824 5.81 0 3.209-2.607 5.81-5.824 5.81h18.304c3.217 0 5.824 2.601 5.824 5.81 0 3.209-2.607 5.81-5.824 5.81h-8.465c-4.055 0-7.343 2.601-7.343 5.81 0 2.14 1.664 4.076 4.992 5.81 3.217 0 5.824 2.601 5.824 5.81 0 3.209-2.607 5.81-5.824 5.81H38.272c-3.216 0-5.824-2.601-5.824-5.81 0-3.209 2.608-5.81 5.824-5.81H5.824C2.607 49.48 0 46.879 0 43.67c0-3.209 2.607-5.81 5.824-5.81h33.28c3.217 0 5.824-2.601 5.824-5.81 0-3.209-2.607-5.81-5.824-5.81h-20.8c-3.216 0-5.824-2.601-5.824-5.81 0-3.209 2.607-5.81 5.824-5.81h33.28c-3.217 0-5.824-2.601-5.824-5.81 0-3.209 2.608-5.81 5.824-5.81h81.536Zm0 23.24c3.217 0 5.824 2.601 5.824 5.81 0 3.209-2.607 5.81-5.824 5.81-3.217 0-5.824-2.601-5.824-5.81 0-3.209 2.607-5.81 5.824-5.81Z"
                fill={bgColor}
            />
            <path
                d="M57.872 57.61c15.393 0 27.872-12.449 27.872-27.805S73.265 2 57.872 2 30 14.449 30 29.805 42.479 57.61 57.872 57.61Z"
                fill="#F3F7FF"
                stroke="#103B58"
                strokeWidth={2.5}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M53.406 51.38a25.6 25.6 0 0 0 4.466.42c12.177 0 22.048-9.847 22.048-21.994 0-12.148-9.871-21.995-22.048-21.995-3.128 0-6.104.65-8.8 1.821a22.113 22.113 0 0 0-10.838 10.165 21.858 21.858 0 0 0-2.41 10.008 21.869 21.869 0 0 0 5.562 14.605"
                fill="#fff"
            />
            <path
                d="M53.406 51.38a25.6 25.6 0 0 0 4.466.42c12.177 0 22.048-9.847 22.048-21.994 0-12.148-9.871-21.995-22.048-21.995-3.128 0-6.104.65-8.8 1.821a22.113 22.113 0 0 0-10.838 10.165 21.858 21.858 0 0 0-2.41 10.008 21.869 21.869 0 0 0 5.562 14.605M43.977 46.882a22.037 22.037 0 0 0 5.993 3.462"
                stroke="#103B58"
                strokeWidth={2.5}
                strokeLinecap="round"
            />
            <path d="m80.754 51.8 4.992 4.98" stroke="#103B58" strokeWidth={2.5} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M84.939 55.975a4.016 4.016 0 0 0 0 5.689l9.223 9.2a4.04 4.04 0 0 0 5.703 0 4.016 4.016 0 0 0 0-5.688l-9.224-9.2a4.04 4.04 0 0 0-5.702 0Z"
                fill="#E8F0FE"
                stroke="#103B58"
                strokeWidth={2.5}
            />
            <path d="m89.07 57.61 9.152 9.13" stroke="#fff" strokeWidth={2.5} strokeLinecap="round" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M52.465 20.26c0 9.626 7.823 17.43 17.472 17.43 1.896 0 3.72-.301 5.43-.858-2.79 6.906-9.571 11.781-17.494 11.781-10.412 0-18.853-8.42-18.853-18.808 0-9.413 6.93-17.21 15.979-18.591a17.326 17.326 0 0 0-2.534 9.046Z"
                fill={ovalColor}
            />
            <path
                d="M58.288 14.45c-1.059 0-2.094.104-3.095.302m-3.024.923c-5.692 2.386-9.688 8-9.688 14.545"
                stroke={lineColor2}
                strokeWidth={2.5}
                strokeLinecap="round"
            />
            <path
                d="M107.028 33.455h-6.802M112.29 27H98h14.29Zm5.824 0h-1.848 1.848ZM27.648 43h-14.29m-4.846 0H5m26.538 7.285h-6.802"
                stroke={lineColor1}
                strokeWidth={2.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default NoSearchResult;
