import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/Auth';
import { isFutureDate } from '../../../utils/GenericFunctions';
import * as labelConst from '../../../utils/GetLabels';
import Dialog from '../Dialog';
import DialogContent from '../DialogContent';
import DialogTitle from '../DialogTitle';
import Typography from '../Typography';
import SubscriptionBox from './SubscriptionBox';

function SubscriptionDialogBox({ openTo, close, handleSubscribe, disabled }) {
    const { getSubscriptionExpireDate, getSubscriptionPlan } = useAuth();
    const [isFuture, setIsFuture] = useState(false);

    useEffect(() => {
        setIsFuture(isFutureDate(getSubscriptionExpireDate()));
    }, [getSubscriptionExpireDate]);
    const checkPresentSubscription = getSubscriptionPlan === 'Your29 Plan' && isFuture;
    return (
        <Dialog className="h-auto rounded-md" maxWidth={'md'} open={openTo} close={openTo}>
            <DialogTitle className="flex justify-between items-center cursor-pointer">
                <Typography className="text-17 font-semibold">Subscription Plans</Typography>
                <CloseIcon className="text-17 text-gray-400" onClick={close} />
            </DialogTitle>
            <DialogContent>
                <div className="h-auto bg-gray-100 p-2 flex flex-col sm:flex-row sm:justify-center items-center">
                    <div className="w-auto bg-gray-100 p-2 flex justify-center items-center">
                        <SubscriptionBox
                            checked={!checkPresentSubscription}
                            title="Your29 User"
                            descriptionArray={labelConst.YOUR29_USER_SUBSCRIPTION_POINT}
                            onSubscribe={handleSubscribe}
                        />
                    </div>
                    
                    <SubscriptionBox
                        checked={checkPresentSubscription}
                        title="Your29 Plan"
                        noButtonReq={true}
                        descriptionArray={labelConst.YOUR29_PLAN_SUBSCRIPTION_POINT}
                        onSubscribe={handleSubscribe}
                        disabled={disabled}
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default SubscriptionDialogBox;
