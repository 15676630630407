import AddCircleIcon from '@mui/icons-material/AddCircle';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import { Accordion, AccordionDetails, AccordionSummary, Drawer } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllSubjects as getAllSubjectsApi,
    getSubjectsCategories as getSubjectsCategoriesApi,
} from '../../../services/app/api';
import {
    setAllFilter,
    setClearAllFilters,
    setIsFilterApplied,
    setKeyWordFilter,
    setStoredStudentData,
} from '../../../store/searchSponsorSlice';
import { applyFilter } from '../../../utils/GenericFunctions';
import { INITIAL_SELECTED_INPUTS, META_KEYS } from '../../../utils/GetConstants';
import { SPONSOR_CANDIDATE_LIST, getOpportunityList } from '../../Common/LookingFor/LookingFor.utils';

import CollegeProgram from '../../Student/College/CollegeSearch/CollegeProgram';
import Paper from '../../UI/Paper';
import Typography from '../../UI/Typography';

import { useAuth } from '../../../contexts/Auth';
import BlockingLoader from '../../UI/BlockingLoader';
import Button from '../../UI/Button';
import SearchByLocation from '../StundentSearch/SearchByLocation';
import SearchByOpportunities from '../StundentSearch/SearchByOpportunities';
import SearchBySchool from '../StundentSearch/SearchBySchool';
import SearchByStudentLevel from '../StundentSearch/SearchByStudentLevel';
import KeyWordSearchComp from './KeyWordSearchComp';
const initialSelectedInputs = { ...INITIAL_SELECTED_INPUTS };

function clearSelectedInputs(finalSelctedInputs, fieldId, level) {
    for (let index = level; index < 7; index++) {
        const optionId = `${fieldId}_level${index}`;
        if (finalSelctedInputs[optionId]?.key === META_KEYS.LOCATION_TYPE) {
            finalSelctedInputs.location = null;
        }
        if (finalSelctedInputs[optionId]) {
            finalSelctedInputs[optionId] = null;
        }
    }
}

function getSelectedInputs(searchParams) {
    const opportunityParam = searchParams[META_KEYS.OPP_TYPE];
    const selectedInputId = SPONSOR_CANDIDATE_LIST[opportunityParam].id;
    const selectedInputs = { ...initialSelectedInputs };
    let index = 1;
    let prevKey = null;

    for (let param of Object.entries(searchParams)) {
        const optionId = `${selectedInputId}_level${index}`;
        const [key, value] = param;
        let selectedInputValue = value;
        if (key === META_KEYS.COLLEGE_FIELDS_CAT || key === META_KEYS.COLLEGE_FIELDS_SUBJECT) {
            selectedInputValue = { label: value, value };
        }
        if (key === META_KEYS.SPONSORSHIP_TYPE) {
            selectedInputValue = !value ? [] : value.split(',');
        }

        const selectedMetaValue = { key, value: selectedInputValue, parent: prevKey, optionId, level: index };
        prevKey = key;
        if (key === META_KEYS.OPP_TYPE) continue;
        selectedInputs[optionId] = selectedMetaValue;
        index += 1;
    }
    return selectedInputs;
}

export function SponsorSearchBox({ callStudentsApi, filterStudentsByKey }) {
    const [mobileFilter, setMobileFilter] = useState(null);
    const [keyWordInput, setKeyWordInput] = useState('');

    const appliedOpportunityFilters = useSelector((state) => state.searchSponsorSlice.appliedOpportunityFilters);
    const opportunityType = useSelector((state) => state.searchSponsorSlice.selectOpportunityType);
    const zipcode = useSelector((state) => state.searchSponsorSlice.searchByZipcode);
    const collegeProgram = useSelector((state) => state.searchCollegeSlice.collegeProgram);
    const keyWordSearch = useSelector((state) => state.searchSponsorSlice.keyWordSearch);
    const selectStateType = useSelector((state) => state.searchSponsorSlice.selectStateType);
    const selectHighSchool = useSelector((state) => state.searchSponsorSlice.selectHighSchool);
    const selectCollege = useSelector((state) => state.searchSponsorSlice.selectCollege);
    const selectOpportunities = useSelector((state) => state.searchSponsorSlice.selectOpportunities);
    const gradYear = useSelector((state) => state.searchSponsorSlice.gradYear);
    const studentLevel = useSelector((state) => state.searchSponsorSlice.studentLevel);
    const searchStudentName = useSelector((state) => state?.searchSponsorSlice?.searchStudentName);
    const storedStudentData = useSelector((state) => state.searchSponsorSlice.storedStudentData);
    const allFilters = useSelector((state) => state.searchSponsorSlice.allFilters);

    const [selectedInputs, setSelectedInputs] = useState(() => {
        if (!opportunityType?.value) return initialSelectedInputs;
        return getSelectedInputs(appliedOpportunityFilters);
    });
    const [lookingForList, setLookingForList] = useState(() => {
        if (!opportunityType?.value) return [];
        return getOpportunityList({ opportunityType: opportunityType?.value });
    });
    const [keyInput, setKeyInput] = useState('');
    const keySearch = useSelector((state) => state.searchSponsorSlice.keyWordFilter);
    const [accordianExpanded, setAccordianExpanded] = useState({});
    const { getProfileId } = useAuth();
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        getAllSubjects();
        async function getAllSubjects() {
            try {
                const { employment_level1, gradAssistantship_level3 } = selectedInputs;
                const shouldCallSubjectsApi =
                    gradAssistantship_level3?.value === 'Specific Fields of Study' ||
                    employment_level1?.value === 'Research Assistantship';

                if (shouldCallSubjectsApi) {
                    const { data: subjectsData } = await getAllSubjectsApi();
                    const { id: fieldId } = subjectsData.data.find(
                        ({ name }) =>
                            name === selectedInputs.gradAssistantship_level4?.value?.value ||
                            name === selectedInputs.employment_level2?.value?.value
                    );
                    const { data: categories } = await getSubjectsCategoriesApi({ category: fieldId });
                    setLookingForList(
                        getOpportunityList({
                            opportunityType: opportunityType.value,
                            subjects: subjectsData.data,
                            categories: categories.data,
                        })
                    );
                }
            } catch (error) {
                console.error(error);
            }
        }
    }, []);

    const allSearchOptions = [
        {
            searchFor: 'GradYear',
            label: 'By Grad Year',
            component: <SearchByStudentLevel />,
        },
        {
            searchFor: 'location',
            label: 'By Location',
            component: (
                <SearchByLocation
                    label1="Serach By State"
                    label2="Search By Zip Code"
                    placeholder1="Type in State Abbreviation here"
                    placeholder2="Type in Zip Code here"
                />
            ),
        },
        {
            searchFor: 'school',
            label: 'By School',
            component: <SearchBySchool />,
        },
        {
            searchFor: 'fieldOfStudy',
            label: 'By Degree or Field of Study',
            component: <CollegeProgram />,
        },
        {
            searchFor: 'keyWord',
            label: 'By Key Word',
            component: (
                <KeyWordSearchComp filterStudentsByKey={filterStudentsByKey} callStudentsApi={callStudentsApi} />
            ),
        },
        {
            searchFor: 'opportunities',
            label: 'By Opportunity',
            component: <SearchByOpportunities />,
        },
    ];

    const applySponsorSearchFilters = (page = 1, name = '') => {
        if (keySearch.length > 0) {
            const filteredArray = applyFilter(storedStudentData?.data, keySearch);
            return dispatch(setStoredStudentData(filteredArray));
        }
        const arrayToFilterString = (data) => {
            return data
                ?.map((item) => item.checked && item?.value)
                .filter((item) => !!item && item)
                .join(';');
        };

        const arrayToSpacificStringState = (arrayOfObjects) => {
            return arrayOfObjects.map((obj) => obj.state).join(';');
        };
        const arrayToSpacificStringName = (arrayOfObjects) => {
            return arrayOfObjects?.map((obj) => obj.name).join(';');
        };
        const arrayToSpacificStringValue = (arrayOfObjects) => {
            return arrayOfObjects?.map((obj) => obj.value).join(';');
        };
        const trueValueKey = Object.keys(studentLevel).find((key) => studentLevel[key] === true);

        let moreFilters = {
            zipcode: zipcode ? arrayToSpacificStringValue(zipcode) : '',
            gradYear: gradYear ? arrayToSpacificStringValue(gradYear) : '',
            studentLevel: trueValueKey ? trueValueKey : '',
            collegeProgram: collegeProgram ? arrayToFilterString(collegeProgram) : '',
            selectOpportunities: selectOpportunities ? arrayToFilterString(selectOpportunities) : '',
            selectState: selectStateType ? arrayToSpacificStringState(selectStateType) : '',
            selectCollegeValue: selectCollege ? arrayToSpacificStringName(selectCollege) : '',
            selectHighSchoolValue: selectHighSchool ? arrayToSpacificStringName(selectHighSchool) : '',
        };

        callStudentsApi(page, moreFilters);
        dispatch(setAllFilter(moreFilters));
        if (!keyWordSearch && !searchStudentName) {
            dispatch(setIsFilterApplied(true));
        }
        setMobileFilter(null);
    };

    const BottomButtons = () => {
        const clearAllFilters = () => {
            callStudentsApi();
            dispatch(setClearAllFilters());
            setMobileFilter(null);
            dispatch(setKeyWordFilter([]));
            dispatch(setIsFilterApplied(false));
        };

        return (
            <div className="flex justify-end gap-2 mt-2">
                <Button size="small" onClick={clearAllFilters}>
                    clear
                </Button>
                <Button
                    disabled={keySearch.length > 0 || searchStudentName?.length}
                    size="small"
                    variant={'contained'}
                    onClick={() => applySponsorSearchFilters()}>
                    Apply
                </Button>
            </div>
        );
    };

    return (
        <>
            <BlockingLoader loading={isLoading} />
            <Drawer anchor={'right'} open={mobileFilter} onClose={() => setMobileFilter(null)}>
                <div className="w-80 h-full mt-4 p-4">
                    <Typography className="text-17 mb-4 font-semibold text-primary font-geometric">
                        {mobileFilter?.label}
                    </Typography>
                    {mobileFilter?.component}
                    <div className="mb-4">
                        <BottomButtons />
                    </div>
                </div>
            </Drawer>
            <Paper className="h-auto relative p-4 rounded-md">
                <Typography className="text-[19px] font-bold text-primary font-geometric">
                    Start Your Smart Search
                </Typography>
                <hr />
                {allSearchOptions?.map((item) => (
                    <div
                        key={item.searchFor}
                        onClick={() => setMobileFilter(item)}
                        className="sm:hidden p-2 m-1 hover:bg-gray-100 cursor-pointer flex items-center justify-between ">
                        <Typography className="text-17 font-semibold text-primary font-geometric">
                            {item?.label}
                        </Typography>
                        <AddCircleIcon color="primary" />
                    </div>
                ))}

                {allSearchOptions?.map((item) => (
                    <Accordion className="hidden sm:block shadow-none  before:content-none" key={item.searchFor}>
                        <AccordionSummary
                            expandIcon={
                                !accordianExpanded[item.searchFor] ? (
                                    <AddCircleIcon color="primary" />
                                ) : (
                                    <DoDisturbOnIcon color="primary" />
                                )
                            }
                            aria-controls="panel1d-content"
                            onClick={() =>
                                setAccordianExpanded({
                                    ...accordianExpanded,
                                    [item?.searchFor]: !accordianExpanded[item?.searchFor],
                                })
                            }
                            className="p-0"
                            id="panel1d-header">
                            <Typography className="text-17 font-semibold text-primary font-geometric">
                                {item?.label}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="p-0 m-0">{item?.component}</AccordionDetails>
                    </Accordion>
                ))}
                <BottomButtons />
            </Paper>
        </>
    );
}
