import { Button } from '@mui/material';
import React, { Suspense, createContext, lazy, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/Auth';
import { getProfileCompletion as getProfileCompletionApi } from '../../../services/app/api/index';
import { Environments } from '../../../services/core/env';
import { isFutureDate } from '../../../utils/GenericFunctions';
import * as labelConst from '../../../utils/GetLabels';
import Organization from '../../Advisor/Organization';
import { BackButton } from '../../Common/BackButton';
import BoxDivider from '../../Common/BoxDivider';
import PopupVideo from '../../UI/PopupVideo';
import ProfileAttachments from './ProfileAttachments';
import VideoUpload from './VideoUpload';

const Header = lazy(() => import('./Header'));
const UIMenu = lazy(() => import('./Menu'));
const Biography = lazy(() => import('./Biography'));
const Activities = lazy(() => import('./Activities'));
const Accomplishments = lazy(() => import('./Accomplishments'));
const Endorsements = lazy(() => import('./Endorsements'));
const Interests = lazy(() => import('./Interests'));

export const CreateContext = createContext();

const StudentProfile = ({ prId, showBio, toggle, usernameId, userRole }) => {
    const { role, user: username } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { isAuthenticated, getUserName, getRole, getSubRole, getSubscriptionExpireDate, getSubscriptionPlan } =
        useAuth();
    const profileId = username;
    const quickInfoRef = useRef();
    const bioRef = useRef();
    const galleryRef = useRef();
    const activityRef = useRef();
    const accomplishmentRef = useRef();
    const endorsementRef = useRef();
    const interestRef = useRef();
    const organizationRef = useRef();
    const [selected, setSelected] = useState(null);
    const [categories, setCategories] = useState();
    const [studentLevel, setStudentLevel] = useState();
    const [loading, setLoading] = useState(false);
    const [profileCompletion, setProfileCompletion] = useState();
    const [profileStrong, setProfileStrong] = useState();
    const [toggled, setToggled] = useState(getRole() === 'Sponsor' || getSubRole() === 'Storefront' ? true : false);
    const handleToggled = (event) => {
        setToggled(event.target.checked);
    };
    const [isFuture, setIsFuture] = useState(false);

    useEffect(() => {
        if (selected === 'My Goals') scrollToSection(bioRef);
        else if (selected === 'Quick Information') scrollToSection(quickInfoRef);
        else if (selected === 'Gallery') scrollToSection(galleryRef);
        else if (selected === labelConst.ACTIVITIES_CLUBS_SERVICE) scrollToSection(activityRef);
        else if (selected === labelConst.AWARDS_AND_ACCOMPLISHMENTS) scrollToSection(accomplishmentRef);
        else if (selected === 'What They are Saying') scrollToSection(endorsementRef);
        else if (selected === 'More About Me') scrollToSection(interestRef);
        else if (selected === 'Organization') scrollToSection(organizationRef);
    }, [selected]);

    function selectMenuItem(label) {
        setSelected(label);
    }

    function scrollToSection(ref) {
        window.scrollTo({ top: ref.current.offsetTop - 100, behavior: 'smooth' });
    }

    const loadPercentage = () => {
        setLoading(true);
        getProfileCompletionApi({ params: { id: profileId } })
            .then((response) => {
                const perValue = response.data.data;
                setProfileCompletion(perValue.profile_completion_percentage);
                setProfileStrong(perValue.profile_strength_percentage);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (isAuthenticated() && getRole() === 'Student') {
            loadPercentage();
        }
    }, []);

    const [isPrinting, setIsPrinting] = useState(false);

    const handlePrint = () => {
        setIsPrinting(true);
    };

    useEffect(() => {
        if (isPrinting) {
            window.print();
            setIsPrinting(false);
        }
    }, [isPrinting]);

    useEffect(() => {
        setIsFuture(isFutureDate(getSubscriptionExpireDate()));
    }, [getSubscriptionExpireDate]);

    const isPremiumUser = getSubscriptionPlan() === labelConst.PLAN_VAL && isFuture;

    return (
        <CreateContext.Provider value={{ setCategories, setStudentLevel }}>
            <PopupVideo />
            <div className={`px-4 pb-10  md:px-8 lg:max-w-6xl xl:max-w-7xl 2xl:max-w-[1450px] mx-auto`}>
                {state?.from === 'Candidates' ? (
                    <div className="flex justify-between items-center">
                        <BackButton
                            text="Go Back"
                            as="button"
                            className="sticky print:hidden top-[4.5rem] z-10 mb-6 bg-primary text-white px-6 rounded"
                            onClick={() => {
                                if (state.path === 'summer-open-to') {
                                    navigate('/summer-open-to');
                                } else {
                                    navigate(-1);
                                }
                            }}
                        />
                    </div>
                ) : null}

                <Suspense fallback={<p>Loading</p>}>
                    {getUserName() === profileId && (
                        <div className="block print:hidden">
                            <UIMenu id={profileId} selectMenuItem={selectMenuItem} selected={selected} role="student" />
                        </div>
                    )}
                </Suspense>
                <Suspense fallback={<p>Loading</p>}>
                    <div ref={quickInfoRef}>
                        <Header
                            profileStrong={profileStrong}
                            profileCompletion={profileCompletion}
                            id={profileId || prId}
                            editMode={isAuthenticated() && username === getUserName()}
                            toggled={toggled}
                            isPremiumUser={isPremiumUser}
                        />
                    </div>
                </Suspense>
                {role !== 'advisor' && <BoxDivider className="-mt-4 pb-1 md:mt-0 lg:pt-[1.4rem] lg:pb-8 print:pb-4" />}
                {(role === 'student' || showBio) && (
                    <>
                        <Suspense fallback={<p>Loading</p>}>
                            <div ref={bioRef}>
                                <Biography
                                    id={profileId || prId}
                                    selected={selected === 'My Goals'}
                                    editMode={isAuthenticated() && username === getUserName()}
                                    categories={categories}
                                    role={role}
                                    studentLevel={studentLevel}
                                    showBio={showBio}
                                />
                            </div>
                        </Suspense>
                        <BoxDivider className="pt-[30px] pb-7 print:pb-4" />
                    </>
                )}
                {role === 'advisor' && (
                    <>
                        <BoxDivider className="pt-1 pb-7 print:pb-4" />
                        <Suspense fallback={<p>Loading</p>}>
                            <div ref={organizationRef}>
                                <Organization
                                    id={profileId || prId}
                                    selected={selected === 'Organization'}
                                    editMode={isAuthenticated() && username === getUserName()}
                                />
                            </div>
                        </Suspense>
                        <BoxDivider className="pt-[30px] pb-7 print:pb-4" />
                    </>
                )}

                <Suspense fallback={<p>Loading</p>}>
                    <div ref={accomplishmentRef}>
                        <Accomplishments
                            id={profileId || prId}
                            selected={selected === labelConst.AWARDS_AND_ACCOMPLISHMENTS}
                            editMode={isAuthenticated() && username === getUserName()}
                        />
                    </div>
                </Suspense>
                <BoxDivider className="pb-[30px] pt-6 print:pb-4" />
                <Suspense fallback={<p>Loading</p>}>
                    <div ref={endorsementRef}>
                        <Endorsements
                            id={profileId || usernameId}
                            selected={selected === 'What They are Saying'}
                            editMode={isAuthenticated() && username === getUserName()}
                            usernameId={usernameId}
                            userRole={userRole}
                        />
                    </div>
                </Suspense>
                <BoxDivider className="pb-[30px] pt-7 print:pb-4" />
                <Suspense fallback={<p>Loading</p>}>
                    <div ref={activityRef}>
                        <Activities
                            role={role}
                            id={profileId || prId}
                            selected={selected === labelConst.ACTIVITIES_CLUBS_SERVICE}
                            editMode={isAuthenticated() && username === getUserName()}
                        />
                    </div>
                </Suspense>
                <BoxDivider className="pt-4 pb-[1.15rem] lg:pb-[30px] lg:pt-7 print:pb-4" />
                <Suspense fallback={<p>Loading</p>}>
                    <div ref={interestRef}>
                        <Interests
                            id={profileId || prId}
                            selected={selected === 'More About Me'}
                            editMode={isAuthenticated() && username === getUserName()}
                            interestContainerClsName="w-[calc(100%_/_3_-_6px)] lg:w-[24%] flex-shrink bg-[#D9D9D9]"
                            interestImageClsName="w-full h-28 md:h-[124px] object-cover"
                            interestGridClsName="gap-[6px] lg:gap-3"
                        />
                    </div>
                </Suspense>

                <div className="print:hidden">
                    <VideoUpload id={profileId || prId} editMode={isAuthenticated() && username === getUserName()} />
                </div>

                <div className="print:hidden">
                    {role === 'student' && (
                        <ProfileAttachments
                            selected={selected}
                            id={profileId || prId}
                            editMode={isAuthenticated() && username === getUserName()}
                        />
                    )}
                </div>
                {role === 'student' && (
                    <div className="print:hidden container  flex justify-center mt-10">
                        <Button
                            className="bg-primary text-white rounded-md font-geometric cursor-pointer"
                            onClick={handlePrint}>
                            Print Page
                        </Button>
                    </div>
                )}
                <div className="hidden print:mt-1 print:flex print:container print:justify-start py-10">
                    <a
                        class="hyperlink"
                        target="_blank"
                        rel="noreferrer"
                        href={`${Environments.FRONTEND_URL}/student/${username}`}>
                        Your29 Profile
                    </a>
                </div>
            </div>
        </CreateContext.Provider>
    );
};

export default StudentProfile;
