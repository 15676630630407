import React from 'react';
import MuiModal from '../../UI/MuiModal';
import IconButton from '../../UI/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';

const PaymentForm = ({handlePaymentModal,openPaymentModal}) => {
    
    return (
        <>
            <MuiModal width={700} open={openPaymentModal} handleClose={handlePaymentModal}>
                <>
                    <div className="border-b-1 border-gray-300 top-0 h-16 w-full flex items-center justify-between">
                        <Typography className="text-2xl m-2 font-bold text-primary font-geometric">Submit Payment</Typography>
                        <IconButton onClick={handlePaymentModal} title={'Close'} className="m-2">
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div
                        style={{
                            position: 'relative',
                            overflow: 'hidden',
                            width: '100%',
                            height: 500,
                        }}>
                        <iframe
                            title="Donation form powered by Zeffy"
                            style={{
                                position: 'absolute',
                                border: 0,
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                width: '100%',
                                height: '100%',
                            }}
                            src="https://www.zeffy.com/en-US/embed/ticketing/33b7f856-7891-403e-93b1-5c6c2a6f6bc1"
                            allowpaymentrequest
                            allowTransparency="true"
                        />
                    </div>
                </>
            </MuiModal>
        </>
    );
};

export default PaymentForm;
