import { configureStore } from '@reduxjs/toolkit';
import adminSlice from './adminSlice';
import appliedCandidatesSlice from './appliedCandidatesSlice';
import feedsSlice from './feedsSlice';
import genericSlice from './genericSlice';
import messageSlice from './messageSlice';
import searchCollegeSlice from './searchCollegeSlice';
import searchSponsorSlice from './searchSponsorSlice';
import searchStudentSlice from './searchStudentSlice';

const store = configureStore({
    reducer: {
        searchSponsorSlice,
        messageSlice,
        searchStudentSlice,
        appliedCandidatesSlice,
        feedsSlice,
        searchCollegeSlice,
        genericSlice,
        adminSlice,
    },
});

export default store;
