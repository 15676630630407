const Container = (props) => {
    const classes = [];

    if (props.centered) {
        classes.push('w-full p-4 xl:p-0 xl:mx-auto xl:max-w-7xl');
    }

    if(props.mobileCenter){
        classes.push('w-full sm:p-4 xl:p-0 xl:mx-auto xl:max-w-7xl');
    }

    classes.push(props.className);

    return <div className={classes.filter(Boolean).join(' ')}>{props.children}</div>;
};

export default Container;
