import { SnackbarProvider } from 'notistack';
import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import Feedback from '../Admin/Feedback/Feedback';
import TermsAndCondition from '../Privacy/TermsAndCondition';
import { AppliedCandidates } from '../Sponsor/Candidates/AppliedCandidates';
import ScholarshipsEdit from '../Sponsor/ProfileEdit/ScholarShipEdit';
import SponsorSearch from '../Sponsor/Search/SponsorSearch';
import Applications from '../Student/MyApplications/Applications';
import StudentSearch from '../Student/search/student-search/StudentSearch';
import BlockingLoader from '../UI/BlockingLoader';
import RouteScroller from './RouteScroller';

const LeaderWelcome = lazy(() => import('../leader/welcome/LeaderWelcome'));
const AdminLayout = lazy(() => import('../Layouts/AdminLayout'));
const AdminHome = lazy(() => import('../Admin/Home'));
const AdminStudents = lazy(() => import('../Admin/Students'));
const AdminHighSchools = lazy(() => import('../Admin/HighSchools'));
const AdminColleges = lazy(() => import('../Admin/Colleges'));
const AdminInterests = lazy(() => import('../Admin/Interests'));
const AdminActivityTypes = lazy(() => import('../Admin/ActivityTypes'));
const AdminAccomplishmentTypes = lazy(() => import('../Admin/AccomplishmentTypes'));
const AdminScholarships = lazy(() => import('../Admin/Scholarships'));
const SponsorOpportunities = lazy(() => import('../Admin/SponsorOpportunities'));

const MainLayout = lazy(() => import('../Layouts/MainLayout'));
const Signin = lazy(() => import('../Auth/Signin'));
const Signout = lazy(() => import('../Auth/Signout'));
const Signup = lazy(() => import('../Auth/Signup'));
const SignupCongrats = lazy(() => import('../Auth/SignupCongrats'));
const EmailVerification = lazy(() => import('../Auth/EmailVerification'));
const SendEmailVerification = lazy(() => import('../Auth/SendEmailVerification'));
const ForgotPassword = lazy(() => import('../Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('../Auth/ResetPassword'));
const ComingSoon = lazy(() => import('../Auth/ComingSoon'));
const MainStudentLayout = lazy(() => import('../Layouts/MainStudentLayout'));
const StudentWelcome = lazy(() => import('../Student/Welcome'));
const StudentProfile = lazy(() => import('../Student/Profile'));
const StudentBiography = lazy(() => import('../Student/ProfileEdit/Biography'));
const StudentGallery = lazy(() => import('../Student/ProfileEdit/Gallery'));
const StudentActivities = lazy(() => import('../Student/ProfileEdit/Activities'));
const StudentAccomplishments = lazy(() => import('../Student/ProfileEdit/Accomplishments'));
const StudentEndorsements = lazy(() => import('../Student/ProfileEdit/Endorsements'));
const StudentInterests = lazy(() => import('../Student/ProfileEdit/Interests'));
const Attachments = lazy(() => import('../Student/ProfileEdit/Attachments'));
const AdvisorOrganization = lazy(() => import('../Advisor/OrganizationEditor'));
const SponsorWelcome = lazy(() => import('../Sponsor/Welcome'));
const SponsorProfile = lazy(() => import('../Sponsor/Profile'));
const Endorse = lazy(() => import('../Endorse'));
const SubmitEndorsement = lazy(() => import('../Endorse/SubmitEndoresement'));
const EndorsementCongrats = lazy(() => import('../Endorse/EndorsementCongrats'));
const Awarded = lazy(() => import('../Sponsor/ProfileEdit/Awarded'));
const ContactEdit = lazy(() => import('../Sponsor/ProfileEdit/ContactEdit'));
const BiographyEdit = lazy(() => import('../Sponsor/ProfileEdit/Biography'));
const SummerStudentsList = lazy(() => import('../Sponsor/SummerSpecial/SummerStudentsList'));
const NotFound = lazy(() => import('../Common/NotFound'));
const Messages = lazy(() => import('../Messages'));
const WhatTheyAreSaying = lazy(() => import('../Sponsor/ProfileEdit/WhatTheyAreSayingEdit'));
const ConsumerDashboard = lazy(() => import('../Admin/Analytics'));
const Permission = lazy(() => import('../Admin/Permissions'));
const Feeds = lazy(() => import('../Feeds/Feeds'));
const FeedsArticle = lazy(() => import('../Feeds/Resources/ReadArticle'));
const FeedsContent = lazy(() => import('../Feeds/Resources/VideoContent'));
const Podcast = lazy(() => import('../Feeds/Podcast/Podcast'));
const PodcastDetails = lazy(() => import('../Feeds/Podcast/PodcastDetails'));
const FeedsResources = lazy(() => import('../Feeds/Resources/Resources'));
const WriteFeed = lazy(() => import('../Feeds/WriteFeed'));
const AccountSettings = lazy(() => import('../AccountSettings/AccountSetting'));
const CollegeSeach = lazy(() => import('../Student/College'));
const CollegeDetailsPage = lazy(() => import('../Student/College/CollegeDetailsPage'));
const HelpPage = lazy(() => import('../HelpPage/HelpPage'));
const OpportunitiesPage = lazy(() => import('../OpportunitiesPage'));
const SharableOpportunity = lazy(() => import('../OpportunitiesPage/SharableOpportunity'));
const TeamProfilePage = lazy(() => import('../leader/Profile/TeamProfilePage'));
const PaymentSuccess = lazy(() => import('../Common/Payment/PaymentSuccess'));
const PaymentFailed = lazy(() => import('../Common/Payment/PaymentFailed'));
const CreateTeam = lazy(() => import('../leader/team/CreateTeam'));
const TeamInvitation = lazy(() => import('../Student/team/TeamInvitation'));
const TeamProfile = lazy(() => import('../leader/team/TeamProfile'));
const MyTeams = lazy(() => import('../leader/team/MyTeams'));
const SocialMediaAccountsSetting = lazy(() => import('../Student/ProfileEdit/SocialMediaAccountsSetting'));
const StorefrontWelcome = lazy(() => import('../Storefront/Welcome/index.js'));
const ApprovalRequest = lazy(() => import('../Admin/RequestApproval'));
const StorefrontProfile = lazy(() => import('../Storefront/Profile'));
const FollwoUp = lazy(() => import('../Admin/Followup'));

const RoutesWrapper = () => (
    <SnackbarProvider maxSnack={3}>
        <Suspense fallback={<BlockingLoader />}>
            <Routes>
                <Route path="/admin" element={<AdminLayout />}>
                    <Route index element={<AdminHome />} />
                    <Route path="users" element={<AdminStudents />} />
                    <Route path="high-schools" element={<AdminHighSchools />} />
                    <Route path="colleges" element={<AdminColleges />} />
                    <Route path="scholarships" element={<AdminScholarships />} />
                    <Route path="opportunities" element={<SponsorOpportunities />} />
                    <Route path="interests" element={<AdminInterests />} />
                    <Route path="activity-types" element={<AdminActivityTypes />} />
                    <Route path="accomplishment-types" element={<AdminAccomplishmentTypes />} />
                    <Route path="analytics" element={<ConsumerDashboard />} />
                    <Route path="permissions" element={<Permission />} />

                    <Route path="article" element={<FeedsArticle />} />
                    <Route path="podcast" element={<Podcast />} />
                    <Route path="listen-podcast" element={<PodcastDetails />} />
                    <Route path="resources" element={<FeedsResources />} />
                    <Route path="edit-feed" element={<WriteFeed />} />
                    <Route path="feedback" element={<Feedback />} />
                    <Route path="approval" element={<ApprovalRequest />} />
                    <Route path="followup" element={<FollwoUp />} />
                </Route>
                <Route path="/" element={<MainLayout />}>
                    <Route index element={<Signin />} />
                    <Route path="signout" element={<Signout />} />
                    <Route path="signup" element={<Signup />} />
                    <Route path="signup-congrats" element={<SignupCongrats />} />
                    <Route path="send-email-verification" element={<SendEmailVerification />} />
                    <Route path="email-verify" element={<EmailVerification />} />
                    <Route path="forgot-password" element={<ForgotPassword />} />
                    <Route path="password-reset" element={<ResetPassword />} />
                    <Route path="coming-soon" element={<ComingSoon />} />
                    <Route path="opportunities" element={<StudentSearch />} />
                    <Route path="applications" element={<Applications />} />
                    <Route path="messages" element={<Messages />} />
                    <Route path="college" element={<CollegeSeach />} />
                    <Route path="college-details-page" element={<CollegeDetailsPage />} />
                    <Route path="help-page" element={<HelpPage />} />
                    <Route path="terms-and-conditions" element={<TermsAndCondition />} />
                    <Route path="sponsorships/events" element={<OpportunitiesPage />} />
                    <Route path="share-opportunity" element={<SharableOpportunity />} />
                    <Route path="payment-complete" element={<PaymentSuccess />} />
                    <Route path="payment-incomplete" element={<PaymentFailed />} />
                    <Route path="team/:teamId" element={<TeamProfile />} />
                    <Route path="social-account" element={<SocialMediaAccountsSetting />} />
                    <Route path="summer-open-to" element={<SummerStudentsList />} />

                    {/* TODO: below code will be used in future for My Scholarships */}
                    {/* <Route path="student" element={<MainStudentLayout />}>
                        <Route path=":user/scholarships" element={<ScholarshipSearch />} />
                    </Route> */}

                    <Route path="feeds" element={<MainStudentLayout />}>
                        <Route path="" element={<Feeds />} />
                        <Route path="article" element={<FeedsArticle />} />
                        <Route path="content" element={<FeedsContent />} />
                        <Route path="podcast" element={<Podcast />} />
                        <Route path="listen-podcast" element={<PodcastDetails />} />
                        <Route path="resources" element={<FeedsResources />} />
                    </Route>

                    <Route path="sponsor" element={<MainStudentLayout />}>
                        <Route path="welcome" element={<SponsorWelcome />} />
                        <Route path="search" element={<SponsorSearch />} />
                        <Route path="candidates" element={<AppliedCandidates />} />
                        <Route path="messages" element={<Messages />} />
                        <Route path="account-settings" element={<AccountSettings />} />
                        <Route path=":user" element={<SponsorProfile />} />
                        <Route path=":user/award" element={<Awarded />} />
                        <Route path=":user/contact" element={<ContactEdit />} />
                        <Route path=":user/biography" element={<BiographyEdit />} />
                        <Route path=":user/scholarships" element={<ScholarshipsEdit />} />
                        <Route path=":user/sponsor-endorsement" element={<WhatTheyAreSaying />} />
                    </Route>
                    <Route path="highschool" element={<MainStudentLayout />}>
                        <Route path="welcome" element={<StorefrontWelcome />} />
                        <Route path="search" element={<SponsorSearch />} />
                        <Route path="candidates" element={<AppliedCandidates />} />
                        <Route path="account-settings" element={<AccountSettings />} />
                        <Route path=":user" element={<StorefrontProfile />} />
                        <Route path=":user/biography" element={<BiographyEdit />} />
                        <Route path=":user/scholarships" element={<ScholarshipsEdit />} />
                        <Route path=":user/contact" element={<ContactEdit />} />
                    </Route>

                    <Route path="leader" element={<MainStudentLayout />}>
                        <Route path="welcome" element={<LeaderWelcome />} />
                        <Route path=":user" element={<TeamProfilePage />} />
                        <Route path="account-settings" element={<AccountSettings />} />
                        <Route path="create-team" element={<CreateTeam />} />
                        <Route path="my-teams" element={<MyTeams />} />
                        <Route path=":user/contact" element={<ContactEdit />} />
                    </Route>

                    <Route path=":role" element={<MainStudentLayout />}>
                        <Route path="welcome" element={<StudentWelcome />} />
                        <Route path=":user" element={<StudentProfile />} />
                        <Route path="account-settings" element={<AccountSettings />} />
                        <Route path="team-invitation" element={<TeamInvitation />} />
                        <Route path=":user/biography" element={<StudentBiography />} />
                        <Route path=":user/gallery" element={<StudentGallery />} />
                        <Route path=":user/activities" element={<StudentActivities />} />
                        <Route path=":user/accomplishments" element={<StudentAccomplishments />} />
                        <Route path=":user/endorsement" element={<StudentEndorsements />} />
                        <Route path=":user/interests" element={<StudentInterests />} />
                        <Route path=":user/attachments" element={<Attachments />} />
                        <Route path=":user/organization" element={<AdvisorOrganization />} />
                    </Route>
                </Route>

                <Route path="/endorsements" element={<MainLayout />}>
                    <Route index element={<Endorse />} />
                    <Route path="submit-endorsement" element={<SubmitEndorsement />} />
                    <Route path="congrats" element={<EndorsementCongrats />} />
                </Route>
                <Route path="404" element={<NotFound />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Suspense>
        <RouteScroller />
    </SnackbarProvider>
);

export default RoutesWrapper;
