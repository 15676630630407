import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Scroll to the top on route transition
const RouteScroller = () => {
    const location = useLocation();
    useEffect(() => window.scrollTo(0, 0), [location.pathname]);
};

export default RouteScroller;
