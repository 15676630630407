export const PAGE = 1;
export const PAGE_SIZE = 100;
export const TOTAL_PAGES = 0;

export const getDefaultPaginationValues = () => {
    return { page: PAGE, pageSize: PAGE_SIZE, totalPages: TOTAL_PAGES };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { PAGE, PAGE_SIZE, TOTAL_PAGES, getDefaultPaginationValues };
