import React from 'react';
import Pagination from '@mui/material/Pagination';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    const {
        color = 'primary',
        size = 'medium',
        variant = 'outlined',
        shape = 'rounded',
        showFirstButton = true,
        showLastButton = true,
        hidePrevButton = false,
        hideNextButton = false,
        siblingCount = 2,
        boundaryCount = 2,
        hide = false,
        disabled = false,
        className = '',
        count = 0,
        page = 0,
        onChange
    } = props;

    if (hide) return null;

    return (
        <Pagination
            color={color}
            size={size}
            variant={variant}
            shape={shape}
            showFirstButton={showFirstButton}
            showLastButton={showLastButton}
            hidePrevButton={hidePrevButton}
            hideNextButton={hideNextButton}
            siblingCount={siblingCount}
            boundaryCount={boundaryCount}
            disabled={disabled}
            className={className}
            count={count}
            page={page}
            onChange={onChange}
        />
    );
};
