import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, Rating, colors } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useJsonToCsv } from 'react-json-csv';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { getFeedbackResponse } from '../../../services/app/api';
import { convertDate } from '../../../utils/GenericFunctions';
import BlockingLoader from '../../UI/BlockingLoader';
import TextField from '../../UI/TextField';
import FeedbackAnalytics from './FeedbackAnalytics';

const FeedbackResponse = (props) => {
    const { handleOpenResponse, response: feedback_id } = props;
    const [feedbackResponse, setFeedbackResponse] = useState({});
    const [loading, setLoading] = useState(false);
    const snackbar = useSnackbar();
    const { saveAsCsv } = useJsonToCsv();
    const [ratingAnalytics, setRatingAnalytics] = useState({});
    const [yesNoAnalytics, setYesNoAnalytics] = useState({});

    const convertDataForAnalytics = (data) => {
        const ratingQuestions = {};
        const yesNoQuestions = {};
        data?.forEach(({ response }) => {
            response?.forEach((que) => {
                if (que?.question_type === 'rating') {
                    ratingQuestions[que?.question] = ratingQuestions[que?.question] || { sum: 0, length: 0 };
                    ratingQuestions[que?.question]['sum'] += +que?.answer;
                    ratingQuestions[que?.question]['length'] += 1;
                }
                if (que?.question_type === 'yes/no') {
                    yesNoQuestions[que?.question] = {
                        ...(yesNoQuestions[que?.question] || { yes: 0, no: 0, 'Not Answered': 0 }),
                        [que?.answer]: (yesNoQuestions[que?.question]?.[que?.answer] || 0) + 1,
                    };
                }
            });
        });
        const formattedAnalytincsRating = Object.entries(ratingQuestions).map(([question, obj]) => ({
            question,
            avgRating: Math.floor(obj?.sum / obj?.length),
            length: obj?.length,
        }));
        setRatingAnalytics(formattedAnalytincsRating);
        const transformedObject = {};
        colors = {
            yes: '#BBDF32',
            no: '#F35B54',
            'Not Answered': '#565656',
        };
        Object.entries(yesNoQuestions).forEach(([question, answers]) => {
            transformedObject[question] = Object.entries(answers).map(([answer, count]) => [
                answer,
                count,
                colors[answer],
            ]);
            transformedObject[question] = [['vote', 'Users', { role: 'style' }], ...transformedObject[question]];
        });
        const formattedAnalytincsYesNo = Object.entries(transformedObject)?.map(([question, data]) => {
            return { data, options: { title: question } };
        });
        setYesNoAnalytics(formattedAnalytincsYesNo);
    };

    useEffect(() => {
        setLoading(true);
        getFeedbackResponse({ feedback_id })
            .then((res) => {
                const data = res?.data?.data;
                setFeedbackResponse(data);
                convertDataForAnalytics(data?.feedback_response);
            })
            .catch(() => snackbar.error('Failed to fetch feedback response'))
            .finally(() => setLoading(false));
    }, []);

    const getAnswerField = (type, value) => {
        const fields = {
            'yes/no': (
                <div>
                    <FormControl>
                        <RadioGroup
                            disabled
                            value={value}
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group">
                            <FormControlLabel
                                disabled
                                value="yes"
                                control={
                                    <Radio
                                        size="large"
                                        icon={<SentimentVerySatisfiedIcon />}
                                        checkedIcon={<SentimentVerySatisfiedIcon sx={{ color: '#BBDF32' }} />}
                                    />
                                }
                                label="Yes"
                            />
                            <FormControlLabel
                                disabled
                                value="no"
                                control={
                                    <Radio
                                        size="large"
                                        icon={<SentimentVeryDissatisfiedIcon />}
                                        checkedIcon={<SentimentVeryDissatisfiedIcon sx={{ color: '#F35B54' }} />}
                                    />
                                }
                                label="No"
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
            ),
            description: (
                <div>
                    <TextField value={value} multiline minRows={2} maxRows={4} />
                </div>
            ),
            contact: (
                <div>
                    <TextField value={value} />
                </div>
            ),
            rating: (
                <div>
                    <Rating
                        sx={{
                            '& .MuiRating-iconFilled': {
                                color: '#FF3EA5',
                            },
                        }}
                        disabled
                        name="simple-controlled"
                        value={value}
                        size="large"
                    />
                </div>
            ),
        };
        return fields[type];
    };

    const handleExportData = () => {
        const fields = { name: 'name' };
        const data = feedbackResponse?.feedback_response?.map((res) => {
            let fieldData = { name: `${res?.user?.first_name} ${res?.user?.last_name}` };
            res?.response?.forEach((que) => {
                fieldData[que?.question] = que?.answer;
                fields[que?.question] = que?.question;
            });
            return fieldData;
        });
        saveAsCsv({ data, fields, filename: feedbackResponse?.name });
    };

    return (
        <div>
            <Button onClick={() => handleOpenResponse(false)}>
                <span className="material-icons material-icons-outlined mr-2">west</span> Back
            </Button>
            <BlockingLoader loading={loading} />
            {!!Object.keys(feedbackResponse).length && (
                <div className="my-6">
                    <div className="flex items-center justify-between">
                        <p className="text-primary font-geometric text-2xl">{feedbackResponse?.name}</p>
                        <p
                            className={`p-2 py-[3px] rounded-2xl text-base font-geometric ${
                                feedbackResponse?.active ? 'bg-green-200 text-green-700' : 'bg-red-200 text-red-700'
                            } `}>
                            {feedbackResponse?.active ? 'Active' : 'Inactive'}
                        </p>
                    </div>

                    <div className="flex items-center justify-between">
                        <div className="flex flex-wrap gap-4 my-2">
                            <p className="text-base font-geometric text-grayish p-2 border-1 border-primary rounded-3xl ">
                                <span className="text-primary">Created date:</span>{' '}
                                {convertDate(feedbackResponse?.created_at)}
                            </p>
                            <p className="text-base font-geometric text-grayish p-2 border-1 border-primary rounded-3xl ">
                                <span className="text-primary">Feedback for:</span> {feedbackResponse?.feedback_for}
                            </p>
                            <p className="text-base font-geometric text-grayish p-2 border-1 border-primary rounded-3xl ">
                                <span className="text-primary">Total response:</span>{' '}
                                {feedbackResponse?.feedback_response?.length || 0}{' '}
                            </p>
                        </div>
                        <Button
                            disabled={feedbackResponse?.feedback_response?.length === 0}
                            onClick={handleExportData}
                            variant="contained">
                            {' '}
                            Export{' '}
                        </Button>
                    </div>
                    <FeedbackAnalytics yesNoAnalytics={yesNoAnalytics} ratingAnalytics={ratingAnalytics} />
                    <div>
                        {!!feedbackResponse?.feedback_response?.length ? (
                            <>
                                {feedbackResponse?.feedback_response?.map((response, index) => (
                                    <div key={index}>
                                        <Paper className="w-full rounded-lg my-4">
                                            <div className="flex w-full justify-between items-center bg-primary p-4 rounded-t-lg">
                                                <p className="text-white font-geometric text-xl">
                                                    {`${response?.user?.first_name} ${response?.user?.last_name}`}
                                                </p>
                                            </div>
                                            <ol className="list-decimal  p-5">
                                                {response?.response?.map((qa) => (
                                                    <li
                                                        key={qa?.answer_id}
                                                        className="font-geometric text-gray-800 text-base ml-4 my-2 ">
                                                        <p>{qa?.question}</p>
                                                        <div>{getAnswerField(qa?.question_type, qa?.answer)}</div>
                                                    </li>
                                                ))}
                                            </ol>
                                        </Paper>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <p className="text-center text-2xl text-primary"> No Response available </p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FeedbackResponse;
