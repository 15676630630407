import React from 'react';
import MuiDrawer from '@mui/material/Drawer';
import { useDrawer } from '../../../contexts/Drawer';

const Drawer = () => {
    const drawer = useDrawer();

    const renderDrawer = (o = {}) => {
        return (
            <MuiDrawer
                key={o.id}
                open={true}
                anchor={o.anchor}
                PaperProps={{ style: { width: o.width, height: o.height } }}
                ModalProps={{ style: { zIndex: 1202 } }}
                onClose={() => drawer.close(o)}>
                {drawer.getContent(o)}
            </MuiDrawer>
        );
    };

    return drawer.getDrawers().map((o) => renderDrawer(o));
};

export default Drawer;
