import { createSlice } from '@reduxjs/toolkit';
import { INITIAL_SELECTED_INPUTS } from '../utils/GetConstants';
import { opportunitiesTypeFilter, studentLevelFilter } from '../utils/collegeSearchFilters';

const initialState = {
    page: 1,
    scrollBarPosition: 0,
    storedStudentsData: { data: [], paginate: {} },
    keyWordFilter: [],
    selectOpportunityType: null,
    storedStudentData: [],
    appliedOpportunityFilters: INITIAL_SELECTED_INPUTS,
    paginate: {},
    searchStudentName: '',
    keyWordSearch: '',
    searchStudentLevel: 'placeholder',
    studentLevel: studentLevelFilter,
    gradYear: null,
    selectOpportunities: opportunitiesTypeFilter,
    selectCollege: null,
    selectHighSchool: null,
    selectStateType: null,
    searchByZipcode: null,
    isFilterApplied: false,
    allFilters: [],
};

export const searchSponsorSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        setGoToPage: (state, action) => {
            state.page = action.payload;
        },
        setScrollBarPosition: (state, action) => {
            state.scrollBarPosition = action.payload;
        },
        setStoredStudentData: (state, action) => {
            state.storedStudentData = action.payload || {};
            state.paginate = action.payload?.paginate || {};
        },
        setStoredStudentsData: (state, action) => {
            state.storedStudentsData = action.payload || {};
        },
        setKeyWordFilter: (state, action) => {
            state.keyWordFilter = action.payload;
        },
        setSelectOpportunityType: (state, action) => {
            state.selectOpportunityType = action.payload;
        },
        setAppliedOpportunityFilters: (state, action) => {
            state.appliedOpportunityFilters = action.payload;
        },
        setSearchStudentName: (state, action) => {
            state.searchStudentName = action.payload;
        },
        setSponsorPaginate: (state, action) => {
            state.paginate = action.payload || {};
        },
        setSearchStudentLevel: (state, action) => {
            state.searchStudentLevel = action.payload;
        },
        setStudentLevel: (state, action) => {
            state.studentLevel = action.payload;
        },
        setGradYear: (state, action) => {
            state.gradYear = action.payload;
        },
        setSelectOpportunites: (state, action) => {
            state.selectOpportunities = action.payload;
        },
        setSelectCollege: (state, action) => {
            state.selectCollege = action.payload;
        },
        setSelectHighSchool: (state, action) => {
            state.selectHighSchool = action.payload;
        },
        setSelectStateType: (state, action) => {
            state.selectStateType = action.payload;
        },
        setSearchByZipcode: (state, action) => {
            state.searchByZipcode = action.payload;
        },

        setSerachKeyWord: (state, action) => {
            state.keyWordSearch = action.payload;
        },

        setClearAllFilters: (state, action) => {
            state.searchStudentName = '';
            state.studentLevel = studentLevelFilter;
            state.gradYear = null;
            state.selectOpportunities = opportunitiesTypeFilter;
            state.selectCollege = null;
            state.selectHighSchool = null;
            state.selectStateType = null;
            state.searchByZipcode = null;
            state.keyWordSearch = '';
            state.keyWordFilter = [];
        },
        setIsFilterApplied: (state, action) => {
            state.isFilterApplied = action.payload;
        },
        setAllFilter: (state, action) => {
            state.allFilters = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setGoToPage,
    setScrollBarPosition,
    setStoredStudentsData,
    setStoredStudentData,
    setKeyWordFilter,
    setSelectOpportunityType,
    setAppliedOpportunityFilters,
    setSearchStudentName,
    setSearchStudentLevel,
    setStudentLevel,
    setStoreSponsorSearchData,
    setSponsorSearchPaginate,
    setSearchByZipcode,
    setKeyWordSearch,
    setSelectStateType,
    setSelectHighSchool,
    setSelectCollege,
    setSelectOpportunites,
    setGradYear,
    setClearAllFilters,
    setSponsorPaginate,
    setAllFilter,
    setIsFilterApplied,
} = searchSponsorSlice.actions;

export default searchSponsorSlice.reducer;
