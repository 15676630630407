import React, { useState, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import Typography from '../../UI/Typography';
import Button from '../../UI/Button';
import TextField from '../../UI/TextField';
import Menu from '../../UI/Menu';
import MenuItem from '../../UI/MenuItem';
import {
    getStudentGallery as getStudentGalleryApi,
    createStudentGallery as createStudentGalleryApi,
    updateStudentGallery as updateStudentGalleryApi,
    updateStudentGalleryDesc as updateStudentGalleryDescApi,
    deleteStudentGallery as deleteStudentGalleryApi,
} from '../../../services/app/api';
import BlockingLoader from '../../UI/BlockingLoader';
import { toArray, isString, toString } from '../../../services/core/types';
import emptyImage from '../../../images/activities-empty.svg';
import Upload from '../../UI/Upload';
import dialogService from '../../../services/app/dialog';
import fakeImage from '../../../images/fake.png';
import IconButton from '../../UI/IconButton';
import Dialog from '../../UI/Dialog';
import ImageCropper from '../../UI/Cropper/ImageCropper';
import Carousel from '../../UI/Carousel';
import Paper from '../../UI/Paper';
import { useLocation, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { useAuth } from '../../../contexts/Auth';

const leftArrowSrc = require('../../../images/left-arrow.png');
const rightArrowSrc = require('../../../images/right-arrow.png');
const shouldDisplayArrows = false;

const MediaActionsMenu = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    return (
        <>
            <IconButton
                title="Click to open a menu"
                className="text-white bg-primary bg-opacity-25"
                onClick={(e) => setAnchorEl(e.currentTarget)}>
                <span className="material-icons material-icons-outlined">more_vert</span>
            </IconButton>
            <Menu
                id={`media-actions-menu-${props.id}`}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}>
                <MenuItem
                    onClick={() => {
                        document.getElementById(`upload-${props.id}`).click();
                        setAnchorEl(null);
                    }}>
                    Replace with a new {props.isVideo ? 'video' : 'photo'}
                </MenuItem>
                <MenuItem
                    onClick={(e) => {
                        props.onDelete && props.onDelete(e, props.id);
                        setAnchorEl(null);
                    }}>
                    Delete
                </MenuItem>
            </Menu>
            <Upload
                allowedExtensions="image/*, video/*"
                onChange={(e) => props.onReplace && props.onReplace(e, props.id)}
                btnComponent={Button}
                btnProps={{ id: `upload-${props.id}`, className: 'hidden' }}
                btnChildren={props.id}
            />
        </>
    );
};

function filterGalleryDataByContentType(galleryList, type) {
    const filteredGalleryList = galleryList.filter((gallery) => gallery.type === type);
    return filteredGalleryList;
}

const GalleryEditor = (props) => {
    const { profileId, edit = false, contentType = 'image' } = props;

    const { pathname } = useLocation();
    const { getRole, getUserName } = useAuth();

    const { state } = useLocation();
    const [loading, setLoading] = useState(false);
    const [gallery, setGallery] = useState([]);
    const [hoverId, setHoverId] = useState();
    const [uploadedGalleryPic, setUploadedGalleryPic] = useState(null);
    const [modifyImage, setModifyImage] = useState(false);
    const [mediaId, setMediaId] = useState();
    const prevRef = useRef();
    const nextRef = useRef();

    const contentTypeInfo = state?.contentType ?? contentType;
    const galleryData = React.useMemo(() => {
        return contentTypeInfo === 'video' ? filterGalleryDataByContentType(gallery, 'video') : gallery;
    }, [contentTypeInfo, gallery]);

    const cropHeadshot = (file, mediaId) => {
        const isFileTypeVideo = file?.type?.includes('video');
        if (contentTypeInfo === 'video' && !isFileTypeVideo) {
            dialogService.error('Error while saving, Please upload valid video file');
            return;
        }
        if (isFileTypeVideo) {
            save(file, mediaId);
        } else {
            setUploadedGalleryPic(file);
            setModifyImage(true);
        }
    };

    const load = () => {
        setLoading(true);
        getStudentGalleryApi({ params: { id: profileId } })
            .then((resp) => setGallery(toArray(resp.data?.data)))
            .finally(() => setLoading(false));
    };

    const save = (file, mediaId) => {
        setUploadedGalleryPic(null);
        setLoading(true);
        const data = file.type.match('video.*')
            ? { id: profileId, mediaId, file, type: 'video' }
            : { id: profileId, mediaId, file };
        (data.mediaId ? updateStudentGalleryApi : createStudentGalleryApi)({ data })
            .then(() => {
                dialogService.success('Media saved successfully');
                load();
            })
            .catch(() => dialogService.error('Error while saving'))
            .finally(() => setLoading(false));
    };

    const saveDesc = () => {
        setLoading(true);
        const data = { id: profileId, data: gallery.map((o) => ({ id: o.id, description: o.description })) };
        updateStudentGalleryDescApi({ data })
            .then(() => {
                dialogService.success('Your changes saved successfully');
                load();
            })
            .catch(() => dialogService.error('Error while saving'))
            .finally(() => setLoading(false));
    };

    const remove = (mediaId) => {
        setLoading(true);
        deleteStudentGalleryApi({ data: { id: profileId, mediaId } })
            .then(() => load())
            .finally(() => setLoading(false));
    };

    const closeImageCropperDialog = () => {
        setModifyImage(false);
    };

    const isFormValid = () => {
        return true;
    };

    useEffect(() => load(), [props.id]);
    const renderEmpty = () => {
        if (galleryData.length) return null;
        const placeHolderText = contentTypeInfo === 'video' ? 'videos' : 'photos and videos';
        const emptyPlaceholder = `Show your stuff! Add ${placeHolderText} of you in action!`;

        const addFirst =
            edit && uploadedGalleryPic ? (
                <>
                    <Upload
                        allowedExtensions="image/*, video/*"
                        onChange={(e) => cropHeadshot(e.target.files[0])}
                        btnComponent={Button}
                        btnProps={{ title: 'Upload', variant: 'contained', className: 'sm:mt-16 mt-6' }}
                        btnChildren="Upload your first photo"
                    />
                    <Dialog open={modifyImage} onClose={closeImageCropperDialog}>
                        <ImageCropper
                            imgSrc={uploadedGalleryPic}
                            saveMedia={save}
                            onUpload={closeImageCropperDialog}
                            config={{
                                zoomable: true,
                                scalable: false,
                                aspectRatio: 1,
                            }}
                        />
                    </Dialog>
                </>
            ) : null;

        return (
            <div className="relative text-center">
                <BlockingLoader loading={loading} />
                <Typography
                    className={`font-geometric sm:text-xl  ${contentType === 'video' ? 'mt-0' : 'sm:mt-16 mt-6'}`}>
                    {pathname === `/${getRole()?.toLowerCase()}/${getUserName()}` ? emptyPlaceholder : null}
                </Typography>
                <br />
                {addFirst}
            </div>
        );
    };

    const renderEdit = () => {
        if (!edit) return null;

        const addMore = (
            <div className="sm:mt-16 mt-6 text-right">
                <Upload
                    allowedExtensions="image/*, video/*"
                    onChange={(e) => cropHeadshot(e.target.files[0])}
                    btnComponent={Button}
                    btnProps={{
                        variant: 'contained',
                        startIcon: <span className="material-icons material-icons-outlined">add_a_photo</span>,
                        title: 'Click here to upload an image.',
                    }}
                    btnChildren="Upload more"
                />
                {uploadedGalleryPic && (
                    <Dialog open={modifyImage} onClose={closeImageCropperDialog}>
                        <ImageCropper
                            imgSrc={uploadedGalleryPic}
                            saveMedia={save}
                            onUpload={closeImageCropperDialog}
                            config={{
                                zoomable: true,
                                scalable: false,
                            }}
                        />
                    </Dialog>
                )}
                <Button variant="contained" onClick={() => saveDesc()} className="ml-4">
                    Save
                </Button>
            </div>
        );

        return (
            <div className="flex flex-col sm:mt-16 mt-6 bg-lime-100X">
                {galleryData.map((o, index) => renderEditItem(o, index))}
                {addMore}
            </div>
        );
    };

    const renderEditItem = (o, index) => {
        const descPlaceholder = 'Describe your momement of action',
            uploadPlaceholder = 'Upload an image with a max. file size of 3.2 MB.';

        const actionsDom = (
            <div className="absolute top-2 right-2">
                <MediaActionsMenu
                    isVideo={contentTypeInfo === 'video'}
                    id={o.id}
                    onReplace={(e, mediaId) => {
                        console.error('==> onReplace', mediaId);
                        cropHeadshot(e.target.files[0], mediaId);
                    }}
                    onDelete={(e, mediaId) => {
                        console.error('==> onDelete', mediaId);
                        const deleteContentAlertText = `Are you sure you want to delete this ${
                            contentTypeInfo === 'video' ? 'video' : 'gallery'
                        } item?`;
                        dialogService.confirm(deleteContentAlertText).then((yes) => yes && remove(mediaId));
                    }}
                />
            </div>
        );

        const media =
            o.file && isString(o.file) ? (
                <div className="relative">
                    {o.type === 'image' ? (
                        <img style={{ width: '100%', height: isMobile ? 200 : 250 }} alt={o.title} src={o.file} />
                    ) : (
                        <video style={{ width: '100%', height: isMobile ? 200 : 250 }} src={o.file} controls />
                    )}
                    {actionsDom}
                </div>
            ) : null;

        return (
            <div className="flex flex-col lg:flex-row sm:mt-8 mt-6" key={o.id}>
                <div className="w-full lg:max-w-md">{media}</div>
                <div className="mt-2 lg:mt-0 lg:ml-8 lg:flex-1">
                    <TextField
                        label="Description"
                        placeholder={descPlaceholder}
                        multiline
                        minRows={2}
                        value={toString(o.description)}
                        onChange={(e) => {
                            setGallery(gallery.map((g) => (g.id === o.id ? { ...g, description: e.target.value } : g)));
                        }}
                    />
                </div>
            </div>
        );
    };

    function renderGallaryVideoView() {
        const className = isMobile ? 'h-[240px] mt-4' : galleryData.length <= 4 ? 'h-[190px]' : 'h-[210px]';
        return (
            <>
                <Swiper
                    onInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                    }}
                    className={`${galleryData.length <= 4 ? 'grid sm:justify-center' : ''} ${className}`}
                    modules={[Pagination, Navigation]}
                    speed={800}
                    spaceBetween={13}
                    slidesPerGroup={2}
                    pagination={{
                        clickable: true,
                        renderBullet: function (index, className) {
                            return '<span class="' + className + '">' + index + '</span>';
                        },
                    }}
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }}
                    breakpoints={{
                        300: {
                            slidesPerView: 1,
                            slidesPerGroup: 1,
                        },
                        580: {
                            slidesPerView: 2,
                            slidesPerGroup: 2,
                        },
                        864: {
                            slidesPerView: 3,
                            slidesPerGroup: 3,
                        },
                        1024: {
                            slidesPerView: 4,
                            slidesPerGroup: 4,
                        },
                    }}>
                    {galleryData.map((o, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <div style={{ width: '100%' }}>
                                    <video
                                        style={{
                                            background: 'white',
                                            width: '100%',
                                            height: isMobile ? 183 : 163,
                                            objectFit: 'contain',
                                        }}
                                        src={`${o.file}#t=0.001`}
                                        controls
                                    />
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
                {shouldDisplayArrows && (
                    <>
                        <button
                            className={`absolute -left-2 top-[calc(50%_-_30px)] flex items-center justify-center  z-30 rounded-full w-6 h-6  bg-[#38A4D0CC]`}
                            ref={prevRef}>
                            <img className="h-4" src={leftArrowSrc} alt="Prev Arrow" />
                        </button>
                        <button
                            className={`absolute -right-2 top-[calc(50%_-_30px)] flex items-center justify-center  z-30 rounded-full w-6 h-6  bg-[#38A4D0CC]`}
                            ref={nextRef}>
                            <img className="h-4" src={rightArrowSrc} alt="Next Arrow" />
                        </button>
                    </>
                )}
            </>
        );
    }

    const renderView = () => {
        if (edit || !galleryData.length) return null;

        if (contentTypeInfo === 'video') {
            return renderGallaryVideoView();
        }

        return isMobile ? (
            <Carousel autoPlay={false} indicators={false} navButtonsAlwaysVisible={true}>
                {galleryData.map((o, index) => {
                    return renderViewItem(o, index);
                })}
            </Carousel>
        ) : (
            <div className="flex flex-col lg:flex-row lg:flex-wrap sm:mt-16 mt-6">
                {galleryData.map((o, index) => {
                    return renderViewItem(o, index);
                })}
            </div>
        );
    };

    const renderViewItem = (o, index) => {
        const media =
            o.type === 'image' ? (
                <img
                    alt={o.title}
                    src={fakeImage}
                    style={{ width: '100%', height: 200, backgroundImage: `url("${o.file}")` }}
                    className="bg-cover bg-center bg-no-repeat"
                />
            ) : (
                <video style={{ width: '100%', height: 200 }} src={o.file} controls />
            );

        return (
            <div
                className="relative sm:mt-8 mt-6 lg:w-1/2 lg:m-0 lg:p-2 xl:w-1/4"
                key={o.id}
                onMouseEnter={() => setHoverId(o.id)}
                onMouseLeave={() => setHoverId(null)}>
                <div className="relative">
                    {media}
                    {!isMobile && o.description && o.id === hoverId && (
                        <Typography className="p-2 absolute left-0 right-0 bottom-0 backdrop-brightness-50 text-white">
                            {o.description}
                        </Typography>
                    )}
                    {isMobile && o.description && (
                        <Paper
                            elevation={0}
                            style={{ height: '100px', overflow: 'scroll', paddingTop: '10px', textAlign: 'center' }}>
                            {o.description}
                        </Paper>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="relative">
            {props.editMode && renderEmpty()}
            {renderEdit()}
            {renderView()}
        </div>
    );
};

export default GalleryEditor;
