import { getApiBaseUrl } from '../../core/env';
import http, { CONTENT_TYPE_FORM } from '../../core/http';
import { isFileObject } from '../../core/types';

const id = `users/${http}/opportunity/interested/follow_up/list`;

export const makeApiUrl = (url) => `${getApiBaseUrl()}${url}`;

/** **************************************************
 *  Auth
 ************************************************** */

// Signin
export const signin = ({ data } = {}) => {
    return http.post({ url: makeApiUrl('login'), data });
};

// Signup
export const signup = ({ data } = {}) => {
    return http.post({ url: makeApiUrl('signup'), data });
};

// Signout
export const signout = ({ data } = {}) => {
    return http.post({ url: makeApiUrl('logout'), data });
};

// Send email verification link
export const sendEmailVerificationLink = ({ data } = {}) => {
    return http.post({ url: makeApiUrl('users/emailverification/send'), data });
};

// Validate email verification link (post signup)
export const validateEmailVerificationLink = ({ data } = {}) => {
    return http.post({ url: makeApiUrl('users/emailverification'), data });
};

// Send password reset link
export const sendPasswordResetLink = ({ data } = {}) => {
    return http.post({ url: makeApiUrl('users/password/send'), data });
};

// Update password
export const updatePassword = ({ data } = {}) => {
    return http.put({ url: makeApiUrl('users/password'), data });
};

// Username availablity
export const usernameAvailablity = ({ data } = {}) => {
    return http.post({ url: makeApiUrl('users/students/username/availability'), data });
};

/** **************************************************
 *  High Schools
 ************************************************** */

export const fetchHighSchools = ({ params } = {}) => {
    return http.get({ url: makeApiUrl(`highschools`), params });
};

export const getHighSchools = ({ data } = {}) => {
    const { params } = data;
    return http.get({ url: makeApiUrl(`highschools`), params });
};

// Search high school
export const searchHighSchool = ({ data } = {}) => {
    const { params } = data;
    return http.get({ url: makeApiUrl(`highschools?search=${data.query}`), params });
};

// Get a specific high school
export const getHighSchool = ({ params } = {}) => {
    return http.get({ url: makeApiUrl(`highschools/${params.id}`), params });
};

// Create a new high school
export const createHighSchool = ({ data } = {}) => {
    return http.post({ url: makeApiUrl('highschools'), data });
};

// Update a specific high school
export const updateHighSchool = ({ data } = {}) => {
    return http.put({ url: makeApiUrl(`highschools/${data.id}`), data });
};

// Upload high schools via CSV file
export const uploadHighSchools = ({ data } = {}) => {
    const formData = new FormData();
    formData.append('file', data.file);
    return http.post({
        url: makeApiUrl('highschools/upload'),
        data: formData,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

/** **************************************************
 *  Colleges
 ************************************************** */

export const fetchColleges = ({ params } = {}) => {
    return http.get({ url: makeApiUrl(`colleges`), params });
};

export const getColleges = ({ data } = {}) => {
    const { params } = data;
    return http.get({ url: makeApiUrl(`colleges${data.query}`), params });
};

// Search college
export const searchCollege = ({ data } = {}) => {
    const { params } = data;
    return http.get({ url: makeApiUrl(`colleges?search=${data.query}`), params });
};

// Get a specific college
export const getCollege = ({ params } = {}) => {
    return http.get({ url: makeApiUrl(`colleges/${params.id}`), params });
};

// Create a new college
export const createCollege = ({ data } = {}) => {
    return http.post({ url: makeApiUrl('colleges'), data });
};

// Update a specific college
export const updateCollege = ({ data } = {}) => {
    return http.put({ url: makeApiUrl(`colleges/${data.id}`), data });
};

// Upload colleges via CSV file
export const uploadColleges = ({ data } = {}) => {
    const formData = new FormData();
    formData.append('file', data.file);
    return http.post({
        url: makeApiUrl('colleges/upload'),
        data: formData,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

export const updateCollegeOverview = (data) => {
    const { college_id, payload } = data;
    return http.put({
        url: makeApiUrl(`colleges/${college_id}/overview`),
        data: payload,
    });
};

export const addCollegeImages = (data) => {
    const { college_id, payload } = data;
    return http.post({
        url: makeApiUrl(`colleges/${college_id}/images`),
        data: payload,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

export const updateCollegeImage = (data) => {
    const { college_id, image_id, payload } = data;
    return http.put({
        url: makeApiUrl(`colleges/${college_id}/images/${image_id}`),
        data: payload,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

export const deleteCollegeImage = (data) => {
    const { college_id, image_id } = data;
    return http.delete({
        url: makeApiUrl(`colleges/${college_id}/images/${image_id}`),
    });
};

/** **************************************************
 *  Scholarships
 ************************************************** */

// Get all scholarships
export const getScholarships = ({ data } = {}) => {
    return http.get({ url: makeApiUrl(`scholarships`), data });
};
// Get a specific scholarship
export const getScholarship = ({ params } = {}) => {
    return http.get({ url: makeApiUrl(`scholarships/${params.id}`), params });
};
// Upload scholarships via CSV file
export const uploadScholarships = ({ data } = {}) => {
    const formData = new FormData();
    formData.append('file', data.file);
    return http.post({
        url: makeApiUrl('scholarships/upload'),
        data: formData,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};
// Update a specific Scholarship
export const updateScholarship = ({ data } = {}) => {
    const updateData = { ...data };
    delete updateData.id;
    return http.put({ url: makeApiUrl(`scholarships/${data.id}`), data: { ...updateData } });
};

// Approve Scolarships
export const approveScholarships = ({ data } = {}) => {
    return http.put({ url: makeApiUrl('scholarships/approve'), data });
};

// Get all Pinned Scholarships
export const getPinnedScholarships = ({ data } = {}) => {
    return http.get({ url: makeApiUrl(`users/${data.id}/scholarships`) });
};

// Pin Scholarships
export const pinScholarship = (id, scholarship_id, { data } = {}) => {
    return http.post({ url: makeApiUrl(`/users/${id}/scholarships/${scholarship_id}/pin`), data: { ...data } });
};

/** **************************************************
 *  Interests
 ************************************************** */

// Get all interests
export const getInterests = ({ params } = {}) => {
    return http.get({ url: makeApiUrl(`interests`), params });
};

// Get an interest
export const getInterest = ({ params } = {}) => {
    return http.get({ url: makeApiUrl(`interests/${params.id}`), params });
};

// Create an interest
export const createInterest = ({ data } = {}) => {
    return http.post({ url: makeApiUrl(`interests`), data });
};

// Update an interest
export const updateInterest = ({ data } = {}) => {
    return http.put({ url: makeApiUrl(`interests/${data.id}`), data: { ...data, id: undefined } });
};

// Upload an interest media
export const uploadInterestMedia = ({ data } = {}) => {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('type', data.type || 'image');
    return http.put({
        url: makeApiUrl(`interests/${data.id}/media`),
        data: formData,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

// Upload high schools via CSV file
export const uploadInterests = ({ data } = {}) => {
    const formData = new FormData();
    formData.append('file', data.file);
    return http.post({
        url: makeApiUrl('interests/upload'),
        data: formData,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

// Delete an interest
export const deleteInterest = ({ data } = {}) => {
    return http.delete({ url: makeApiUrl(`interests/${data.id}`) });
};

/** **************************************************
 *  Activity Types
 ************************************************** */

// Get all activity types
export const getActivityTypes = ({ params } = {}) => {
    return http.get({ url: makeApiUrl(`activity_type`), params });
};

// Get an activity type
export const getActivityType = ({ params } = {}) => {
    return http.get({ url: makeApiUrl(`activity_type/${params.id}`), params });
};

// Create an activity type
export const createActivityType = ({ data } = {}) => {
    return http.post({ url: makeApiUrl(`activity_type`), data });
};

// Update an activity type
export const updateActivityType = ({ data } = {}) => {
    return http.put({ url: makeApiUrl(`activity_type/${data.id}`), data: { ...data, id: undefined } });
};

// Delete an activity type
export const deleteActivityType = ({ data } = {}) => {
    return http.delete({ url: makeApiUrl(`activity_type/${data.id}`) });
};

/** **************************************************
 *  Accomplishment Types
 ************************************************** */

// Get all accomplishment types
export const getAccomplishmentTypes = ({ params } = {}) => {
    return http.get({ url: makeApiUrl(`accomplishment_type`), params });
};

// Get an accomplishment type
export const getAccomplishmentType = ({ params } = {}) => {
    return http.get({ url: makeApiUrl(`accomplishment_type/${params.id}`), params });
};

// Create an accomplishment type
export const createAccomplishmentType = ({ data } = {}) => {
    return http.post({ url: makeApiUrl(`accomplishment_type`), data });
};

// Update an accomplishment type
export const updateAccomplishmentType = ({ data } = {}) => {
    return http.put({ url: makeApiUrl(`accomplishment_type/${data.id}`), data: { ...data, id: undefined } });
};

// Delete an accomplishment type
export const deleteAccomplishmentType = ({ data } = {}) => {
    return http.delete({ url: makeApiUrl(`accomplishment_type/${data.id}`) });
};

/** **************************************************
 *  Location
 ************************************************** */

// Get city and state from zipcode
export const lookupCityState = ({ params } = {}) => {
    return http.get({ url: makeApiUrl('locations/citystatelookup'), params });
};

/** **************************************************
 *  User Onboarding
 ************************************************** */

export const completeOnboarding = ({ data } = {}) => {
    return http.put({ url: makeApiUrl(`/users/${data.id}/onboarding/complete`), data: { ...data, id: undefined } });
};

/** **************************************************
 *  Profile - Student
 ************************************************** */

// Get all student profiles
export const getStudents = ({ params } = {}) => {
    return http.get({ url: makeApiUrl('users/students'), params });
};

// Get a specific student profile
export const getUser = ({ params } = {}) => {
    return http.get({ url: makeApiUrl(`users/${params.id}`) });
};

// Create a new student profile
export const createUser = ({ data } = {}) => {
    return http.post({ url: makeApiUrl('users'), data });
};

// Update a specific student profile
export const updateUser = ({ data } = {}) => {
    return http.put({ url: makeApiUrl(`users/${data.id}`), data: { ...data, id: undefined } });
};

/* Headshot */

// Get a specific student headshot
export const getUserHeadshot = ({ params } = {}) => {
    return http.get({ url: makeApiUrl(`users/${params.id}/headshot`) });
};

// Create a new student headshot
export const createUserHeadshot = ({ data } = {}) => {
    const formData = new FormData();
    formData.append('file', data.file);
    return http.post({
        url: makeApiUrl('users/headshot'),
        data: formData,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

// Update a specific student headshot
export const updateUserHeadshot = ({ data } = {}) => {
    const formData = new FormData();
    formData.append('file', data.file);
    return http.put({
        url: makeApiUrl(`users/${data.id}/headshot`),
        data: formData,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

/* Cover Media */

// Get all cover media of a student
export const getStudentCoverMedia = ({ params } = {}) => {
    return http.get({ url: makeApiUrl(`users/students/${params.id}/covermedia`), params });
};

// Create a new cover media for a student
export const createStudentCoverMedia = ({ data } = {}) => {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('type', data.type || 'image');
    return http.post({
        url: makeApiUrl(`users/students/${data.id}/covermedia`),
        data: formData,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

// Update a specific cover media of a student
export const updateStudentCoverMedia = ({ data } = {}) => {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('type', data.type || 'image');
    return http.put({
        url: makeApiUrl(`users/students/${data.id}/covermedia/${data.mediaId}`),
        data: formData,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

// Delete a specific cover media of a student
export const deleteStudentCoverMedia = ({ data } = {}) => {
    return http.delete({ url: makeApiUrl(`users/students/${data.id}/covermedia/${data.mediaId}`), data });
};

/* Social Accounts */

// Get all social accounts of a student
export const getStudentSocialAccounts = ({ params } = {}) => {
    return http.get({ url: makeApiUrl(`users/students/${params.id}/socialaccounts`), params });
};

// Update social accounts of a specific student
export const updateStudentSocialAccounts = ({ data } = {}) => {
    return http.put({ url: makeApiUrl(`users/students/${data.id}/socialaccounts`), data: { ...data, id: undefined } });
};

/* Interests */

// Get all interests of a student
export const getStudentInterests = ({ params } = {}) => {
    return http.get({ url: makeApiUrl(`users/students/${params.id}/interests`), params });
};

// Map a new interest with the student
export const createStudentInterest = ({ data } = {}) => {
    return http.post({ url: makeApiUrl(`users/students/${data.id}/interests`), data: { id: data.interestId } });
};

// Update a selected interest of a student with new media
export const updateStudentInterest = ({ data } = {}) => {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('type', data.type || 'image');
    return http.put({
        url: makeApiUrl(`users/students/${data.id}/interests/${data.interestId}`),
        data: formData,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

// Delete a selected interest of a student
export const deleteStudentInterest = ({ data } = {}) => {
    return http.delete({ url: makeApiUrl(`users/students/${data.id}/interests/${data.interestId}`), data });
};

/* Biography */

// Get biography of a student
export const getStudentBiography = ({ params } = {}) => {
    return http.get({ url: makeApiUrl(`users/${params.id}/my_goals`), params });
};

// Update biography of a student
export const updateStudentBiography = ({ data } = {}) => {
    return http.put({ url: makeApiUrl(`users/${data.id}/my_goals`), data: data.payload });
};

// upload biography of sponsor

export const updateSponsorBiography = ({ data } = {}) => {
    return http.put({ url: makeApiUrl(`users/${data.id}/my_goals`), data: data.payload });
};

export const getSponsorBiographyApi = ({ params } = {}) => {
    return http.get({ url: makeApiUrl(`users/${params.id}/my_goals`), params });
};

/* Key Stats */

// Get key stats of a student
export const getStudentKeyStats = ({ params } = {}) => {
    return http.get({ url: makeApiUrl(`users/${params.id}/keystats`), params });
};

// Update key stats of a student
export const updateStudentKeyStats = ({ data } = {}) => {
    return http.put({ url: makeApiUrl(`users/${data.id}/keystats`), data: { ...data, id: undefined } });
};

/* Username */

// Get username of a student
export const getStudenUsername = ({ params } = {}) => {
    return http.get({ url: makeApiUrl(`users/students/${params.id}/username`), params });
};

// Update username of a student
export const updateStudentUsername = ({ data } = {}) => {
    return http.put({ url: makeApiUrl(`users/students/${data.id}/username`), data: { ...data, id: undefined } });
};

export const getProfileVideo = ({ params }) => {
    return http.get({ url: makeApiUrl(`users/${params.id}/my_goals/media`), params });
};

export const updateProfileVideo = ({ data }) => {
    const formData = new FormData();
    formData.append('file', data.file);
    return http.put({
        url: makeApiUrl(`users/${data.id}/my_goals/media`),
        data: formData,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

/* Gallery */

export const getStudentGallery = ({ params } = {}) => {
    return http.get({ url: makeApiUrl(`users/${params.id}/gallery`), params });
};

export const createStudentGallery = ({ data } = {}) => {
    const formData = new FormData();
    isFileObject(data.file) && formData.append('file', data.file);
    formData.append('type', data.type || 'image');
    return http.post({
        url: makeApiUrl(`users/${data.id}/gallery`),
        data: formData,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

export const updateStudentGallery = ({ data } = {}) => {
    const formData = new FormData();
    isFileObject(data.file) && formData.append('file', data.file);
    formData.append('type', data.type || 'image');
    return http.put({
        url: makeApiUrl(`users/${data.id}/gallery/${data.mediaId}`),
        data: formData,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

export const updateStudentGalleryDesc = ({ data } = {}) => {
    return http.put({
        url: makeApiUrl(`users/${data.id}/gallery/description`),
        data: { ...data, id: undefined },
    });
};

export const deleteStudentGallery = ({ data } = {}) => {
    return http.delete({ url: makeApiUrl(`users/${data.id}/gallery/${data.mediaId}`) });
};

/* Activities */

// Get activities of a student
export const getStudentActivities = ({ params } = {}) => {
    return http.get({ url: makeApiUrl(`users/students/${params.id}/activity`), params });
};

// Get an activity of a student
export const createStudentActivity = ({ data } = {}) => {
    const formData = new FormData();
    isFileObject(data.file) && formData.append('file', data.file);
    data.typeId && formData.append('type_id', data.typeId);
    formData.append('title', data.title);
    formData.append('role', data.role);
    formData.append('date', data.date);
    if (data.description) {
        formData.append('description', data.description);
    }
    data.reference && formData.append('reference', data.reference);
    formData.append('add_to_gallery', Boolean(data.add_to_gallery));
    return http.post({
        url: makeApiUrl(`users/students/${data.id}/activity`),
        data: formData,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

// Get an activity of a student
export const updateStudentActivity = ({ data } = {}) => {
    const formData = new FormData();
    isFileObject(data.file) && formData.append('file', data.file);
    formData.append('type_id', data.typeId);
    formData.append('title', data.title);
    formData.append('role', data.role);
    formData.append('date', data.date);
    if (data.description) {
        formData.append('description', data.description);
    }
    data.reference && formData.append('reference', data.reference);
    return http.put({
        url: makeApiUrl(`users/students/${data.id}/activity/${data.activityId}`),
        data: formData,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

// Delete an activity of a student
export const deleteStudentActivity = ({ data } = {}) => {
    return http.delete({ url: makeApiUrl(`users/students/${data.id}/activity/${data.activityId}`) });
};

/* Accomplishment */

// Get accomplishments of a student
export const getStudentAccomplishments = ({ params } = {}) => {
    return http.get({ url: makeApiUrl(`users/students/${params.id}/accomplishments`), params });
};

// Get an accomplishment of a student
export const createStudentAccomplishment = ({ data } = {}) => {
    const formData = new FormData();
    isFileObject(data.file) && formData.append('file', data.file);
    formData.append('type_id', data.typeId);
    formData.append('title', data.title);
    formData.append('year', data.year);
    if (data.description) {
        formData.append('description', data.description);
    }
    data.reference && formData.append('reference', data.reference);
    formData.append('add_to_gallery', Boolean(data.add_to_gallery));
    return http.post({
        url: makeApiUrl(`users/students/${data.id}/accomplishments`),
        data: formData,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

// Get an accomplishment of a student
export const updateStudentAccomplishment = ({ data } = {}) => {
    const formData = new FormData();
    isFileObject(data.file) && formData.append('file', data.file);
    formData.append('type_id', data.typeId);
    formData.append('title', data.title);
    formData.append('year', data.year);
    if (data.description) {
        formData.append('description', data.description || '');
    }
    data.reference && formData.append('reference', data.reference);
    return http.put({
        url: makeApiUrl(`users/students/${data.id}/accomplishments/${data.accomplishmentId}`),
        data: formData,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

// Delete an accomplishment of a student
export const deleteStudentAccomplishment = ({ data } = {}) => {
    return http.delete({ url: makeApiUrl(`users/students/${data.id}/accomplishments/${data.accomplishmentId}`) });
};

/** **************************************************
 *  Endorsements
 ************************************************** */

export const getStudentEndorsements = ({ params } = {}) => {
    return http.get({
        url: makeApiUrl(`users/${params.id}/endorsements?active=${params.active}&?status=${params.status}`),
    });
};

export const getIncompletedEndorsements = ({ params } = {}) => {
    return http.get({
        url: makeApiUrl(`users/${params.id}/endorsements?status=${params.status}`),
    });
};

export const createEndorsementInvite = ({ data } = {}) => {
    return http.post({
        url: makeApiUrl(`users/${data.id}/endorsements/invite`),
        data: data.payload,
    });
};

export const resendEndorsementInvite = ({ params } = {}) => {
    return http.put({
        url: makeApiUrl(`users/${params.id}/endorsements/${params.endorse_id}/resend`),
    });
};

export const updateEndorsementActiveStatus = ({ data } = {}) => {
    return http.put({
        url: makeApiUrl(`users/${data.id}/endorsements/${data.endorse_id}/active`),
        data: { active: data.active },
    });
};

export const getEndorsementInvite = ({ code } = {}) => {
    return http.get({
        url: makeApiUrl(`users/endorsements/invite/${code}`),
        code,
    });
};

export const submitEndorsementInvite = ({ data } = {}) => {
    return http.post({
        url: makeApiUrl(`users/endorsements/invite/${data.code}/submit`),
        data: { description: data.description },
    });
};

/*****************Sponsor Award*******************/

export const createSponsorAwardApi = ({ data = {} }) => {
    return http.post({
        url: makeApiUrl(`users/${data.id}/awards`),
        data: data.values,
    });
};

export const getSponsorAwardApi = (param) => {
    return http.get({
        url: makeApiUrl(`users/${param?.param}/awards`),
    });
};

export const updateSponsorAward = (data) => {
    const Id = data.params;
    const datas = data.params.data;
    return http.put({ url: makeApiUrl(`/v1/users/${Id.id}/awards/${Id.award_id}`), data: { ...datas, id: undefined } });
};

export const deleteSponsorAwardApi = ({ data } = {}) => {
    return http.delete({ url: makeApiUrl(`users/${data.id}/awards/${data.awardId}`) });
};

export const getAdvisorEndorsements = ({ data } = {}) => {
    return http.get({
        url: makeApiUrl(`users/${data.id}/endorser${data.query}`),
    });
};

export const rejectEndorsementIvite = ({ data } = {}) => {
    return http.put({ url: makeApiUrl(`users/${data.id}/endorsements/${data.endorse_id}/decline`) });
};

/** **************************************************
 *  Organization
 ************************************************** */

export const getOrganizationDesc = ({ id } = {}) => {
    return http.get({ url: makeApiUrl(`users/${id}/organization/description`) });
};

export const updateOrganizationDesc = ({ data } = {}) => {
    return http.put({
        url: makeApiUrl(`users/${data.id}/organization/description`),
        data: { organization_description: data.desc },
    });
};

/** **************************************************
 *  Users
 ************************************************** */

// Get all user profiles
export const getAllUsers = ({ params } = {}) => {
    return http.get({ url: makeApiUrl('users'), params });
};

// Update a specific user role
export const updateUserRole = ({ data } = {}) => {
    return http.put({ url: makeApiUrl(`users/${data.id}/role`), data: { role: data.role } });
};

/************************************************** *
 * Sponsor Contact
 *************************************************** */

//update of contact form

export const updateContactDetail = ({ data } = {}) => {
    return http.put({ url: makeApiUrl(`users/${data.id}/contact`), data: { ...data, id: undefined } });
};

export const updateContactLogo = ({ data } = {}) => {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('type', data.type || 'image');
    return http.put({
        url: makeApiUrl(`users/${data.id}/logo`),
        data: formData,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

export const getContactDetail = (data) => {
    const id = data.params.id;
    return http.get({ url: makeApiUrl(`users/${id}/contact`), data: { role: data.role } });
};

/*************************************************************
 * 
            Profile Attachment
 *
 ************************************************************/

export const addAttachmentsApi = ({ data } = {}) => {
    const formData = new FormData();
    formData.append('file', data?.datas?.file);
    formData.append('title', data?.datas?.title);
    return http.post({
        url: makeApiUrl(`users/${data?.id}/attachments`),
        data: formData,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

export const getProfileAttachmentsApi = (param) => {
    return http.get({
        url: makeApiUrl(`users/${param?.param}/attachments`),
    });
};

export const updateAttachment = ({ params } = {}) => {
    const formData = new FormData();
    formData.append('file', params?.data?.file);
    formData.append('title', params?.data?.title);
    return http.put({ url: makeApiUrl(`users/${params?.id}/attachments/${params?.attachment_id}`), data: formData });
};

export const deleteAttachmentsApi = ({ data } = {}) => {
    return http.delete({ url: makeApiUrl(`users/${data?.id}/attachments/${data?.attachmentId}`) });
};

// Sponsor Scholarship API'S
export const getSponsorScholarships = ({ params }, query) => {
    return http.get({
        url: makeApiUrl(
            `users/${params.id}/sponsorships?status=${query.status}&page=${query.page}&pageSize=${query.pageSize}`
        ),
        params,
    });
};

export const createSponsorScholarship = ({ id, data } = {}) => {
    return http.post({ url: makeApiUrl(`users/${id}/sponsorships`), data });
};

export const updateSponsorScholarship = ({ id, scholarshipId, data } = {}) => {
    return http.put({ url: makeApiUrl(`users/${id}/sponsorships/${scholarshipId}`), data });
};
export const getSponsorScholarship = ({ id, scholarshipId } = {}) => {
    return http.get({ url: makeApiUrl(`users/${id}/sponsorships/${scholarshipId}`) });
};
export const getSharedSponsorScholarship = ({ scholarshipId } = {}) => {
    return http.get({ url: makeApiUrl(`sponsorships/${scholarshipId}/share`) });
};

export const deleteSponsorScholarship = (data) => {
    return http.delete({ url: makeApiUrl(`users/${data.id}/sponsorships/${data.scholarshipId}`) });
};

export const getAllSubjects = () => {
    return http.get({ url: makeApiUrl(`subjects/categories`) });
};

export const getSubjectsCategories = ({ category }) => {
    return http.get({ url: makeApiUrl(`subjects?category=${category}`) });
};

export const getGraduateDegrees = () => {
    return http.get({
        url: makeApiUrl('/graduate_degrees/categories'),
    });
};

export const getGraduateDegreesSecondLevel = ({ category }) => {
    return http.get({
        url: makeApiUrl(`/graduate_degrees?category=${category}`),
    });
};

export const getFieldOfStudy = () => {
    return http.get({
        url: makeApiUrl('/subjects/categories'),
    });
};

export const getFieldOfStudySecondLevel = ({ category }) => {
    return http.get({
        url: makeApiUrl(`/subjects?category=${category}`),
    });
};

export const getStudentOpportunities = ({ params }, query) => {
    return http.get({
        url: makeApiUrl(
            `users/${params.id}/opportunities?status=${query.status}&page=${query.page}&pageSize=${query.pageSize}`
        ),
        params,
    });
};

export const createStudentOpportunity = ({ id, data } = {}) => {
    return http.post({ url: makeApiUrl(`users/${id}/opportunities`), data });
};

export const updateStudentOpportunity = ({ id, opportunityId, data } = {}) => {
    return http.put({ url: makeApiUrl(`users/${id}/opportunities/${opportunityId}`), data });
};

export const deleteStudentOpportunity = (data) => {
    return http.delete({ url: makeApiUrl(`users/${data.id}/opportunities/${data.opportunityId}`) });
};

export const searchSponsors = ({ params, page, pageSize = 10 }) => {
    return http.get({ url: makeApiUrl(`sponsorships?page=${page}&pageSize=${pageSize}&${params}`) });
};

export const searchStudents = ({ params, page = 1, pageSize = -1 }) => {
    return http.get({ url: makeApiUrl(`opportunities?page=${page}&pageSize=${pageSize}&${params}`) });
};

export const applyToOpportunity = ({ data }) => {
    const { userId, opportunityId, payload } = data;

    return http.post({
        url: makeApiUrl(`users/${userId}/sponsorships/${opportunityId}/apply`),
        data: payload,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

export const getStudentApplications = ({ userId, status }) => {
    return http.get({ url: makeApiUrl(`users/${userId}/sponsorships/applications?status=${status}`) });
};

export const getSponsorApplicant = ({ params, oppotunityId, status } = {}) => {
    return http.get({ url: makeApiUrl(`users/${params}/sponsorships/${oppotunityId}/applicants?status=${status}`) });
};

export const acceptDenyApplicant = ({ id, applicationId, data } = {}) => {
    return http.put({ url: makeApiUrl(`users/${id}/sponsorships/applications/${applicationId}/approve`), data });
};

export const closeOpportunity = ({ id, sponsorshipId }) => {
    return http.put({ url: makeApiUrl(`users/${id}/sponsorships/${sponsorshipId}/close`) });
};

export const sendMessage = ({ data } = {}) => {
    return http.post({
        url: makeApiUrl(`users/${data.id}/messages/send`),
        data: data.payload,
    });
};

export const getAllMessages = ({ data } = {}) => {
    let createUrl = `users/${data.id}/connections/${data.connection_id}/messages?page=${data.page}`;
    if (!!data.search) {
        createUrl = `users/${data.id}/connections/${data.connection_id}/messages?page=${data.page}&search=${data.search}`;
    }
    return http.get({
        url: makeApiUrl(createUrl),
        data: data.payload,
    });
};

export const getChatConnections = ({ data } = {}) => {
    let createUrl = `users/${data.id}/connections?page=${data.page}`;
    if (!!data.search) {
        createUrl = `users/${data.id}/connections?page=${data.page}&search=${data.search}`;
    }
    if (!!data.recipient_profile_id) {
        createUrl = `users/${data.id}/connections?page=${data.page}&recipient_profile_id=${data.recipient_profile_id}`;
    }
    return http.get({
        url: makeApiUrl(createUrl),
        data: data.payload,
    });
};

export const appproveRejectConnectionReq = ({ data } = {}) => {
    return http.put({
        url: makeApiUrl(`users/${data.id}/connections/${data.connection_id}/approve`),
        data: data.payload,
    });
};

export const editMessage = ({ data } = {}) => {
    return http.put({
        url: makeApiUrl(`users/${data.id}/connections/${data.connection_id}/messages/${data.message_id}`),
        data: data.payload,
    });
};

export const deleteMessage = ({ data } = {}) => {
    return http.delete({
        url: makeApiUrl(`users/${data.id}/connections/${data.connection_id}/messages/${data.message_id}`),
    });
};

export const totalNewMessage = ({ data } = {}) => {
    return http.get({
        url: makeApiUrl(`users/${data.id}/messages/new`),
    });
};

export const connectionAcceptedNotification = ({ data } = {}) => {
    return http.put({
        url: makeApiUrl(`users/${data.id}/connections/${data.connection_id}/is_old`),
        data: data.payload,
    });
};

export const undoRejectedCompletedApplicant = ({ id, applicationId, payload } = {}) => {
    return http.put({
        url: makeApiUrl(`users/${id}/sponsorships/applications/${applicationId}/status`),
        data: payload,
    });
};

export const editOpportunityAttachment = ({ data }) => {
    const { userId, opportunityId } = data;
    const formData = new FormData();
    formData.append('file', data.file);
    return http.put({
        url: makeApiUrl(`users/${userId}/sponsorships/${opportunityId}/attachment`),
        data: formData,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

export const softDeleteUser = ({ data }) => {
    return http.delete({
        url: makeApiUrl(`users/${data.userId}`),
    });
};

export const getSponsorsList = ({ data } = {}) => {
    const baseUrl = 'users?pageSize=-1&role=Sponsor&sponsorships=true';
    return http.get({ url: makeApiUrl(baseUrl) });
};

export const getAllSponsorshipApplicationsPayment = ({ data } = {}) => {
    const baseUrl = `sponsorships/applications/payment?page=${data.page}`;
    return http.get({ url: makeApiUrl(baseUrl) });
};

export const updateSponsorshipApplicationsPayment = ({ data } = {}) => {
    const baseUrl = `sponsorships/applications/${data.application_id}/payment`;
    return http.put({ url: makeApiUrl(baseUrl), data: data.payload });
};
export const deleteSponsorshipApplicationsPayment = ({ data } = {}) => {
    const baseUrl = `sponsorships/applications/${data.application_id}/payment`;
    return http.delete({ url: makeApiUrl(baseUrl) });
};
// Profile Completion Api

export const getProfileCompletion = ({ params }) => {
    return http.put({
        url: makeApiUrl(`users/${params.id}/profile/percentage`),
    });
};

// Analytics Data Api

export const getAnalyticsData = () => {
    return http.get({
        url: makeApiUrl(`users/analytics`),
    });
};
export const getMyFavouritesStudent = ({ data }) => {
    return http.get({
        url: makeApiUrl(`users/${data.id}/pin`),
    });
};
export const addRemoveMyFavouritesStudent = ({ data }) => {
    return http.post({
        url: makeApiUrl(`users/${data.id}/pin`),
        data: data.payload,
    });
};

export const getAllFeeds = (profile_id, page, params = {}) => {
    let baseUrl = `users/${profile_id}/feeds?page=${page}&pageSize=20`;
    if (!!params?.content_for) {
        baseUrl += `&content_for=${params.content_for}`;
    }
    if (!!params?.file_type) {
        baseUrl += `&file_type=${params.file_type}`;
    }
    if (!!params?.content_topic) {
        baseUrl += `&content_topic=${params.content_topic}`;
    }
    if (!!params?.key_word) {
        baseUrl += `&key_word=${params.key_word}`;
    }
    return http.get({
        url: makeApiUrl(baseUrl),
    });
};
export const getSingleFeeds = (data) => {
    const { profile_id, post_id } = data;
    return http.get({
        url: makeApiUrl(`users/${profile_id}/feeds/${post_id}`),
    });
};
export const writeNewFeed = ({ data }) => {
    const { id, payload } = data;
    const formData = new FormData();
    if (payload?.file) formData.append('file', payload?.file);
    if (payload?.file2) formData.append('file2', payload?.file2);
    formData.append('content_for', payload?.content_for);
    formData.append('content_topic', payload?.content_topic);
    if (payload?.file_type) formData.append('file_type', payload?.file_type);
    if (payload?.file2_type) formData.append('file2_type', payload?.file2_type);
    if (payload?.content) formData.append('content', payload?.content);
    if (payload?.title) formData.append('title', payload?.title);
    if (payload?.season) formData.append('season', payload?.season);
    if (payload?.episode) formData.append('episode', payload?.episode);
    if (payload?.guest_bio) formData.append('guest_bio', payload?.guest_bio);
    if (payload?.embed_content) formData.append('embed_content', payload?.embed_content);

    return http.post({
        url: makeApiUrl(`users/${id}/feeds`),
        data: formData,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};
export const editFeed = ({ data }) => {
    const { id, payload, post_id } = data;
    const formData = new FormData();
    if (payload?.file) formData.append('file', payload?.file);
    if (payload?.file2) formData.append('file2', payload?.file2);
    formData.append('content_for', payload?.content_for);
    formData.append('content_topic', payload?.content_topic);
    if (payload?.file_type) formData.append('file_type', payload?.file_type);
    if (payload?.file2_type) formData.append('file2_type', payload?.file2_type);
    if (payload?.content) formData.append('content', payload?.content);
    if (payload?.title) formData.append('title', payload?.title);
    if (payload?.season) formData.append('season', payload?.season);
    if (payload?.episode) formData.append('episode', payload?.episode);
    if (payload?.guest_bio) formData.append('guest_bio', payload?.guest_bio);
    if (payload?.embed_content) formData.append('embed_content', payload?.embed_content);
    if (payload?.delete_file) formData.append('delete_file', payload?.delete_file);
    return http.put({
        url: makeApiUrl(`users/${id}/feeds/${post_id}`),
        data: formData,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

export const deleteFeed = (data) => {
    const { profile_id, post_id } = data;
    return http.delete({
        url: makeApiUrl(`users/${profile_id}/feeds/${post_id}`),
    });
};

export const updateUserBasicDetails = (data) => {
    const { id, payload } = data;
    return http.put({
        url: makeApiUrl(`users/${id}/basic-details`),
        data: payload,
    });
};

export const getCollegeList = (data) => {
    //  a
    let baseUrl = `users/${data?.profileId}/colleges?page=${data.page}&pageSize=${data.pageSize}`;
    if (data?.name) {
        baseUrl += `&name=${data.name}`;
    }
    if (data?.state) {
        baseUrl += `&state=${data.state}`;
    }
    if (data?.zipcode) {
        baseUrl += `&zipcode=${data.zipcode}`;
    }
    if (data?.locationType) {
        baseUrl += `&location_type=${data.locationType}`;
    }
    if (data?.collegeType) {
        baseUrl += `&college_type=${data.collegeType}`;
    }
    if (data?.degreeType) {
        baseUrl += `&degree_type=${data.degreeType}`;
    }
    if (data?.collegeSize) {
        baseUrl += `&college_size=${data.collegeSize}`;
    }
    if (data?.collegeProgram) {
        baseUrl += `&college_program=${data.collegeProgram}`;
    }
    if (data?.religiousAffiliation) {
        baseUrl += `&religious_affiliation=${data.religiousAffiliation}`;
    }
    if (data?.minorityDistinction) {
        baseUrl += `&minority_distinction=${data.minorityDistinction}`;
    }
    if (data?.avgActScore) {
        baseUrl += `&avg_act_score=${data.avgActScore}`;
    }
    if (data?.avgSatScore) {
        baseUrl += `&avg_sat_score=${data.avgSatScore}`;
    }
    if (data?.avgNetPrice) {
        baseUrl += `&avg_net_price=${data.avgNetPrice}`;
    }
    if (data?.fourYearEnrollments) {
        baseUrl += `&four_year_enrollment=${data.fourYearEnrollments}`;
    }
    if (data?.twoYearEnrollments) {
        baseUrl += `&two_year_enrollment=${data.twoYearEnrollments}`;
    }
    if (data?.distance) {
        baseUrl += `&distance=${data.distance}`;
    }
    return http.get({
        url: makeApiUrl(baseUrl),
    });
};

export const getCollegeDetails = (data) => {
    const IDS = data?.data;
    return http.get({
        url: makeApiUrl(`users/${IDS.profileId}/colleges/${IDS.collegeId}/preview`),
    });
};
export const getStatusCode = () => {
    return http.get({
        url: makeApiUrl(`colleges/upload/status`),
    });
};
export const requestForVerificationCode = (data) => {
    const { id, payload } = data;
    return http.post({
        url: makeApiUrl(`users/${id}/contact/verification`),
        data: payload,
    });
};

export const requestForVerificationCodeOnSignUp = (data) => {
    const { payload } = data;
    return http.post({
        url: makeApiUrl(`users/verification/mobile_no`),
        data: payload,
    });
};

export const verifyMobileVerificationCode = (data) => {
    const { id, payload } = data;
    return http.put({
        url: makeApiUrl(`user/${id}/verify/mobile_number`),
        data: payload,
    });
};

export const verifyEmailVerificationCode = (data) => {
    const { id, payload } = data;
    return http.put({
        url: makeApiUrl(`user/${id}/verify/email`),
        data: payload,
    });
};

// Likes And Comments

export const sendReaction = ({ data } = {}) => {
    return http.post({
        url: makeApiUrl(`users/${data.profileId}/feeds/${data.postId}/reaction`),
        data: data.payload,
    });
};

export const getReactionPost = ({ data } = {}) => {
    return http.get({
        url: makeApiUrl(`users/${data.profileId}/feeds/${data.postId}/reaction`),
    });
};

export const deleteRection = ({ data } = {}) => {
    return http.delete({
        url: makeApiUrl(`users/${data.profileId}/feeds/${data.postId}/reaction`),
    });
};

export const sendComment = ({ data } = {}) => {
    return http.post({
        url: makeApiUrl(`users/${data.profileId}/feeds/${data.postId}/comments`),
        data: data.payload,
    });
};

export const getPostComment = ({ data } = {}) => {
    return http.get({
        url: makeApiUrl(`users/${data.profileId}/feeds/${data.postId}/comments`),
    });
};

export const deletePostComment = ({ data } = {}) => {
    return http.delete({
        url: makeApiUrl(`users/${data.profileId}/feeds/${data.postId}/comments/${data.commentId}`),
    });
};

export const updatePostComment = ({ data } = {}) => {
    return http.put({
        url: makeApiUrl(`users/${data.profileId}/feeds/${data.postId}/comments/${data.commentId}`),
        data: data.payload,
    });
};

export const sendReplyComment = ({ data } = {}) => {
    return http.post({
        url: makeApiUrl(`users/${data.profileId}/feeds/${data.postId}/comments/${data.commentReplyId}/replies`),
        data: data.payload,
    });
};

export const getReplyComment = ({ data } = {}) => {
    return http.get({
        url: makeApiUrl(`users/${data.profileId}/feeds/${data.postId}/comments/${data.commentReplyId}/replies`),
    });
};

export const deleteReplyComment = ({ data } = {}) => {
    return http.delete({
        url: makeApiUrl(
            `users/${data.profileId}/feeds/${data.postId}/comments/${data.commentId}/replies/${data.commentReplyId}`
        ),
    });
};

export const updateReplyComment = ({ data } = {}) => {
    return http.put({
        url: makeApiUrl(
            `users/${data.profileId}/feeds/${data.postId}/comments/${data.commentId}/replies/${data.commentReplyId}`
        ),
        data: data.payload,
    });
};

export const addFavoriteCollege = (data) => {
    const { profileId, collegeId, payload } = data;
    return http.post({
        url: makeApiUrl(`users/${profileId}/colleges/${collegeId}/pin`),
        data: payload,
    });
};

export const getFavoriteCollege = (data) => {
    const { profileId } = data;
    return http.get({
        url: makeApiUrl(`users/${profileId}/colleges/pin`),
    });
};

export const sendRequestToCollege = ({ params = {} }) => {
    const { profileId, collegeId } = params;
    return http.post({
        url: makeApiUrl(`users/${profileId}/colleges/${collegeId}/contact/request`),
    });
};

export const studentRequestList = ({ params = {} }) => {
    return http.get({
        url: makeApiUrl(`users/${params.id}/colleges/contact/list`),
    });
};
export const addOpportunitiesImage = ({ data } = {}) => {
    const { profileId, sponsorshipId, file } = data;
    const formData = new FormData();
    formData.append('file', file);
    return http.put({
        url: makeApiUrl(`users/${profileId}/sponsorships/${sponsorshipId}/image`),
        data: formData,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

export const deleteOpportunityImage = (data) => {
    return http.delete({
        url: makeApiUrl(`users/${data.id}/sponsorships/${data.scholarshipId}/image`),
    });
};

export const vertualEventList = ({ data }) => {
    return http.get({
        url: makeApiUrl(`sponsorships/events/virtual?sponsor=${data?.id}`),
    });
};

export const campusEventList = ({ params }) => {
    return http.get({
        url: makeApiUrl(`sponsorships/events/campus?sponsor=${params?.id}&college=${params?.search}`),
    });
};

/** Rolling Ribbon  API */

export const updateRollingRibbonTitle = (data) => {
    const { payload, id } = data;
    return http.put({
        url: makeApiUrl(`users/${id}/info`),
        data: payload,
    });
};

export const rollingRibbonList = () => {
    return http.get({
        url: makeApiUrl(`rolling_ribbon`),
    });
};

export const addNewUserTOMailchimp = ({ profileId }) => {
    return http.post({
        url: makeApiUrl(`users/${profileId}/mailchimp/members`),
    });
};

export const addNewMailChimpTag = ({ profileId, data }) => {
    return http.post({
        url: makeApiUrl(`users/${profileId}/mailchimp/members/tags`),
        data,
    });
};

export const updateVideoTour = (data) => {
    const { id, payload } = data;
    return http.put({
        url: makeApiUrl(`users/${id}/tour/videos`),
        data: payload,
    });
};

export const getStudentList = (data) => {
    let baseUrl = `users/${data?.profileId}/opportunities/students?page=${data.page}&pageSize=${data.pageSize}`;
    if (data?.studentLevel) {
        baseUrl += `&student_levels=${data.studentLevel}`;
    }
    if (data?.gradYear) {
        if (data.studentLevel === 'High School') {
            baseUrl += `&high_school_year=${data.gradYear}`;
        } else if (data.studentLevel === 'College') {
            baseUrl += `&college_year=${data.gradYear}`;
        } else {
            baseUrl += `&graduate_year=${data.gradYear}`;
        }
    }
    if (data?.collegeProgram) {
        baseUrl += `&college_fields_cats=${data.collegeProgram}`;
    }
    if (data?.selectOpportunities) {
        baseUrl += `&opp_types=${data.selectOpportunities}`;
    }
    if (data?.selectState) {
        baseUrl += `&states=${data.selectState}`;
    }
    if (data?.selectCollegeValue) {
        baseUrl += `&colleges=${data.selectCollegeValue}`;
    }
    if (data?.selectHighSchoolValue) {
        baseUrl += `&high_schools=${data.selectHighSchoolValue}`;
    }
    if (data?.zipcode) {
        baseUrl += `&student_zipcodes=${data.zipcode}`;
    }
    return http.get({
        url: makeApiUrl(baseUrl),
    });
};

// student_zipcodes

export const trackViews = ({ data }) => {
    return http.post({
        url: makeApiUrl(`users/track/views`),
        data,
    });
};

export const getTrackedViews = ({ params = { pageSize: -1 } }) => {
    return http.get({
        url: makeApiUrl(`users/track/views`),
        params,
    });
};

export const createFeedback = ({ data }) => {
    return http.post({
        url: makeApiUrl(`feedback`),
        data,
    });
};
export const getAllFeedbacks = ({ params }) => {
    return http.get({
        url: makeApiUrl(`feedback`),
        params,
    });
};

export const deleteFeedback = ({ feedback_id }) => {
    return http.delete({
        url: makeApiUrl(`feedback/${feedback_id}`),
    });
};
export const updateFeedback = ({ feedback_id, data }) => {
    return http.put({
        url: makeApiUrl(`feedback/${feedback_id}`),
        data,
    });
};

export const getActiveFeedback = ({ profile_id }) => {
    return http.get({
        url: makeApiUrl(`users/${profile_id}/feedback`),
    });
};

export const answerToFeedback = ({ profile_id, data }) => {
    return http.post({
        url: makeApiUrl(`users/${profile_id}/feedback`),
        data,
    });
};

export const showFeedback = ({ profile_id }) => {
    return http.get({
        url: makeApiUrl(`users/${profile_id}/show_feedback`),
    });
};

export const getFeedbackResponse = ({ feedback_id }) => {
    return http.get({
        url: makeApiUrl(`feedback/${feedback_id}/answers`),
    });
};

//Admin dashboard Permissions Api

export const addRole = ({ data }) => {
    return http.post({
        url: makeApiUrl(`admin_panel/users/roles`),
        data,
    });
};

export const addedRoleList = () => {
    return http.get({
        url: makeApiUrl(`admin_panel/users/roles`),
    });
};

export const addPage = ({ data }) => {
    return http.post({
        url: makeApiUrl(`admin_panel/pages`),
        data,
    });
};
export const addedPagesList = () => {
    return http.get({
        url: makeApiUrl(`admin_panel/pages`),
    });
};

export const updateAddedPageList = ({ data }) => {
    const { id, payload } = data;
    return http.put({
        url: makeApiUrl(`admin_panel/pages/${id}`),
        data: payload,
    });
};

export const deleteAdminPageName = ({ data }) => {
    return http.delete({
        url: makeApiUrl(`admin_panel/pages/${data}`),
    });
};

export const mappingPermissions = ({ data }) => {
    const { roleId, pageId } = data || {};
    return http.post({
        url: makeApiUrl(`admin_panel/users/roles/${roleId}/permissions`),
        data: { page: pageId },
    });
};
export const getMappingPermissions = () => {
    return http.get({
        url: makeApiUrl(`admin_panel/users/permissions`),
    });
};

export const deleteMappingPermissions = ({ data }) => {
    const { roleId, pageId } = data || {};
    return http.delete({
        url: makeApiUrl(`admin_panel/users/roles/${roleId}/permissions`),
        data: { page: pageId },
    });
};

export const updateAdminRole = ({ data }) => {
    return http.post({
        url: makeApiUrl(`admin_panel/users`),
        data: data,
    });
};

export const showPaymentForm = ({ user_id, sponsorship_id }) => {
    return http.post({
        url: makeApiUrl(`users/${user_id}/sponsorships/${sponsorship_id}/payment/fee`),
    });
};
export const updatePaymentStatus = ({ user_id, sponsorship_id, data }) => {
    return http.put({
        url: makeApiUrl(`users/${user_id}/sponsorships/${sponsorship_id}/payment/fee/status`),
        data,
    });
};

// Get Sponsorship fee payement history

export const getFeePaymentHistory = ({ id }) => {
    return http.get({
        url: makeApiUrl(`users/${id}/sponsorships/payment/fee/history`),
    });
};

export const getUsersList = ({ profileId, params = {} }) => {
    return http.get({
        url: makeApiUrl(`users/${profileId}/get_users`),
        params,
    });
};

export const createNewTeam = ({ profileId, data }) => {
    return http.post({
        url: makeApiUrl(`users/${profileId}/teams`),
        data,
    });
};
export const updateTeam = ({ profileId, team_id, data }) => {
    return http.put({
        url: makeApiUrl(`users/${profileId}/teams/${team_id}`),
        data,
    });
};
export const deleteTeam = ({ profileId, team_id }) => {
    return http.delete({
        url: makeApiUrl(`users/${profileId}/teams/${team_id}`),
    });
};
export const updateTeamProfilePhoto = ({ teamId, data }) => {
    const { profile_photo } = data;
    const formData = new FormData();
    formData.append('profile_photo', profile_photo);
    return http.put({
        url: makeApiUrl(`teams/${teamId}/profile_photo`),
        data,
        headers: { 'Content-Type': CONTENT_TYPE_FORM },
    });
};

export const getTeamInvitations = ({ profileId, params = {} }) => {
    return http.get({
        url: makeApiUrl(`users/${profileId}/teams_invitations`),
        params,
    });
};
export const updateTeamInvitation = ({ profileId, teamId, data }) => {
    return http.put({
        url: makeApiUrl(`users/${profileId}/teams/${teamId}/invitation_status`),
        data,
    });
};

export const getTeam = (teamId) => {
    return http.get({
        url: makeApiUrl(`teams/${teamId}`),
    });
};

export const getTeams = ({ profile_id, params }) => {
    return http.get({
        url: makeApiUrl(`users/${profile_id}/teams`),
        params,
    });
};

export const deleteTeamMember = ({ teamId, memberId }) => {
    return http.delete({
        url: makeApiUrl(`teams/${teamId}/members/${memberId}`),
    });
};
export const getSummerOpenStudents = (params) => {
    return http.get({
        url: makeApiUrl(`opportunities/students`),
        params,
    });
};

export const connectWithStudent = ({ sponsorId, studentId }) => {
    return http.post({
        url: makeApiUrl(`users/${sponsorId}/opportunity/students/${studentId}/interested`),
    });
};

export const submitFollowUp = ({ user_id, data }) => {
    return http.post({
        url: makeApiUrl(`users/${user_id}/opportunity/interested/follow_up`),
        data,
    });
};

export const followUpQuestionAndAnswer = ({ user_id }) => {
    return http.get({
        url: makeApiUrl(`users/${user_id}/opportunity/interested/follow_up/list`),
    });
};

export const updateSubcriptionModel = ({ payload } = {}) => {
    return http.put({ url: makeApiUrl(`users/subscription`), data: { ...payload } });
};

export const updateSubcriptionModelRequest = ({ payload, userId } = {}) => {
    return http.put({ url: makeApiUrl(`users/${userId}/subscription`), data: { ...payload } });
};

export const getSubscriptionActivation = ({ params }) => {
    return http.get({
        url: makeApiUrl(`users/${params}/subscription`),
    });
};
