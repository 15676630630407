import React from 'react';
import CheckBoxForm from './CheckBoxForm';
import { useSelector } from 'react-redux';
import { setCollegeProgram } from '../../../../store/searchCollegeSlice';

const CollegeProgram = () => {
    let collegeProgram = useSelector((state) => state.searchCollegeSlice.collegeProgram);
    return (
        <div>
            <div className="mb-4 border-b-1 border-gray-500">
                <CheckBoxForm
                    title={'Field of study'}
                    data={collegeProgram}
                    setData={setCollegeProgram}
                    showSearch={true}
                />
            </div>
        </div>
    );
};

export default CollegeProgram;
