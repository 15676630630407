import { META_KEYS } from '../../../utils/GetConstants';
import Typography from '../../UI/Typography';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import PaymentIcon from '@mui/icons-material/PaidOutlined';
import JobTypeIcon from '@mui/icons-material/WorkOutline';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import { format, fromUnixTime } from 'date-fns';

export function OpportunitiesDetails({ state, dataApplicant, checked }) {
    const { amount, title, created_at, metadata, deadline } = dataApplicant;
    const postedDate = format(fromUnixTime(Number(created_at ? created_at : null)), 'dd MMM yyyy');
    const deadlineDate = format(fromUnixTime(Number(deadline ? deadline : null)), 'dd MMM yyyy');
    return (
        <div>
            <div>
                <Typography className="font-geometric text-3xl flex items-center font-semibold">
                    {title ? title : state?.candidateName}{' '}
                    {checked && (
                        <span className="text-base ml-2 px-4 py-1 rounded-md text-[#6F2813] bg-red-200">Closed</span>
                    )}
                </Typography>
                <Typography className="text-base text-gray-500 font-geometric font-medium">{postedDate}</Typography>
            </div>
            <div className="sm:flex flex flex-row gap-7">
                <div className="flex flex-row items-center gap-2  sm:mt-4">
                    <Typography className="text-sm">Amount :</Typography>
                    <Typography className="text-base font-medium">
                        {amount
                            ? isNaN(amount)
                                ? amount
                                : `$${Number(amount).toLocaleString()}`
                            : `$${state?.candidateAmount}`}
                    </Typography>
                </div>
                <div className="flex flex-row items-center gap-2 sm:mt-4">
                    <Typography className="text-sm">Deadline :</Typography>
                    <Typography className="text-base font-medium">
                        {deadlineDate ? deadlineDate : state?.candidateDeadline}
                    </Typography>
                </div>
            </div>
            <div className="rounded-lg flex mt-2  sm:mt-5">
                <OpportunityInfoList metadata={metadata} />
            </div>
        </div>
    );
}

const iconClassName = 'w-5 text-[#575656]';
const META_ICONS = {
    [META_KEYS.LOCATION_TYPE]: <LocationIcon className={iconClassName} />,
    [META_KEYS.JOB_TYPE]: <JobTypeIcon className={iconClassName} />,
    [META_KEYS.PAYMENT_TYPE]: <PaymentIcon className={iconClassName} />,
    [META_KEYS.COLLEGE_FIELDS_CAT]: <DescriptionIcon className={iconClassName} />,
    [META_KEYS.COLLEGE_FIELDS_SUBJECT]: <DescriptionIcon className={iconClassName} />,
};
const featuresMetaKeys = Object.keys(META_ICONS);

function OpportunityInfoList({ metadata }) {
    const isMetadataEmpty = !metadata || metadata?.length <= 0;

    if (isMetadataEmpty) {
        return null;
    }

    const featuresList = metadata.filter(({ key }) => featuresMetaKeys.includes(key));

    if (featuresList.length <= 0) {
        return null;
    }
    const { value: location } = metadata.find(({ key }) => key === META_KEYS.LOCATION) || {};

    return (
        <ul className=" w-full sm:w-auto  sm:flex flex flex-wrap  rounded-lg bg-[#F3F5F9] items-center px-4 py-4 gap-4">
            {featuresList.map(({ key, value }) => {
                const icon = META_ICONS[key];

                return (
                    <li key={key} className="m-0">
                        <div className="flex items-center font-geometric gap-x-1">
                            {icon}
                            <p className="text-sm text-[#484848] font-medium">
                                {value}
                                {value === 'On Site' && location && location.city
                                    ? `, ${location.city}, ${location.state}`
                                    : ''}
                            </p>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
}
