import { createContext, useContext, useState } from 'react';
import { signin as signinApi, signup as signupApi } from '../services/app/api';
import storage, { getDescription } from '../services/app/storage';

export const AuthContext = createContext();

export const ROLE_SUPERADMIN = 'SuperAdmin';
export const ROLE_AUTHOR = 'Author';
export const ROLE_ADMIN = 'Admin';
export const ROLE_STUDENT = 'Student';
export const ROLE_ADVISOR = 'Advisor';
export const ROLE_SPONSOR = 'Sponsor';
export const ROLE_COLLEGE = 'College';
export const ROLE_HIGHSCHOOL = 'High School';
export const ROLE_SPECIALIST = 'Specialist';
export const SUB_ROLE_LEADER = 'Leader';
export const SUB_ROLE_STOREFRONT = 'Storefront';

export const useAuthProvider = () => {
    const [token, setAuthToken] = useState(storage.getAuthToken());
    const [userId, setUserId] = useState(storage.getUserId());
    const [profileId, setProfileId] = useState(storage.getProfileId());
    const [role, setRole] = useState(storage.getRole());
    const [subRole, setSubRole] = useState(storage.getSubRole());
    const [email, setEmail] = useState(storage.getEmail());
    const [firstName, setFirstName] = useState(storage.getFirstName());
    const [lastName, setLastName] = useState(storage.getLastName());
    const [onboardingStatus, setOnboardingStatus] = useState(storage.getOnboardingStatus());
    const [userName, setUserName] = useState(storage.getUserName());
    const [studentLevel, setStudentLevel] = useState(storage.getStudentLevel());

    const isAuthenticated = () => Boolean(storage.getAuthToken());
    const getAuthToken = () => storage.getAuthToken();
    const getUserId = () => storage.getUserId();
    const getProfileId = () => storage.getProfileId();
    const getRole = () => storage.getRole();
    const getSubRole = () => storage.getSubRole();
    const getEmail = () => storage.getEmail();
    const getFirstName = () => storage.getFirstName();
    const getLastName = () => storage.getLastName();
    const getOnboardingStatus = () => storage.getOnboardingStatus();
    const getUserName = () => storage.getUserName();
    const getStudentLevel = () => storage.getStudentLevel();
    const getDateOfBirth = () => storage.getDateOfBirth();
    const getMobileNo = () => storage.getMobileNo();
    const getSubscriptionPlan = () => storage.getSubscriptionPlan();
    const getSubscriptionExpireDate = () => storage.getSubscriptionExpireDate();

    const askForSubscription = () =>
        storage.getAskForSubscription() == 'true' ? true : storage.getAskForSubscription() === 'false' ? false : true;

    const signin = ({ data }) => {
        return new Promise((resolve, reject) => {
            signinApi({ data })
                .then((response) => {
                    const { token } = response.data?.data?.auth || {};
                    const ask_for_subscription = response.data?.data?.fee_payment;
                    const sub_plan = response.data?.data?.subscription?.plan;
                    const sub_expire = response.data?.data?.subscription?.expired_at;
                    const {
                        id: userId,
                        role,
                        email,
                        first_name: firstName,
                        last_name: lastName,
                        onboarding_status: onboardingStatus,
                        student_level: studentLevel,
                        date_of_birth: dateOfBirth,
                        mobile_no,
                        sub_role,
                    } = response.data?.data?.user || {};
                    const { id: profileId, username, description } = response.data?.data?.profile || {};

                    storage.setAskForSubscription(ask_for_subscription);
                    storage.setSubscriptionPlan(sub_plan);
                    storage.setSubscriptionExpireDate(sub_expire);
                    storage.setAuthToken(token);
                    storage.setUserId(userId);
                    profileId && storage.setProfileId(profileId);
                    storage.setRole(role);
                    storage.setSubRole(sub_role);
                    storage.setEmail(email);
                    storage.setFirstName(firstName);
                    storage.setLastName(lastName);
                    storage.setOnboardingStatus(onboardingStatus);
                    storage.setUserName(username);
                    storage.setUserName(description);
                    dateOfBirth && storage.setDateOfBirth(dateOfBirth);
                    role?.toLowerCase() === 'student' && storage.setStudentLevel(studentLevel);
                    storage.setMobileNo(mobile_no === null ? '' : mobile_no);

                    setAuthToken(token);
                    setUserId(userId);
                    profileId && setProfileId(profileId);
                    setRole(role);
                    setSubRole(sub_role);
                    setEmail(email);
                    setFirstName(firstName);
                    setLastName(lastName);
                    setOnboardingStatus(onboardingStatus);
                    setUserName(username);
                    setStudentLevel(studentLevel);
                    resolve(response);
                })
                .catch(reject);
        });
    };

    const signup = ({ data }) => {
        return new Promise((resolve, reject) => {
            signupApi({ data }).then(resolve).catch(reject);
        });
    };

    const signout = () => {
        return new Promise((resolve, reject) => {
            storage.clear();
            setAuthToken(null);
            resolve();
        });
    };

    const updateUserDetails = (data) => {
        const {
            id: userId,
            role,
            email = getEmail(),
            first_name: firstName,
            last_name: lastName,
            onboarding_status: onboardingStatus,
            student_level: studentLevel,
            date_of_birth: dateOfBirth = getDateOfBirth(),
            mobile_no = getMobileNo(),
        } = data?.user || {};
        userId && storage.setUserId(userId);
        role && storage.setRole(role);
        email && storage.setEmail(email);
        firstName && storage.setFirstName(firstName);
        lastName && storage.setLastName(lastName);
        onboardingStatus && storage.setOnboardingStatus(onboardingStatus);
        storage.setUserName(data?.username);
        dateOfBirth && storage.setDateOfBirth(dateOfBirth);
        storage.setDescription(data?.description);
        role?.toLowerCase() === 'student' && studentLevel && storage.setStudentLevel(studentLevel);
        storage.setMobileNo(mobile_no === null ? '' : mobile_no);
    };

    return {
        isAuthenticated,
        getAuthToken,
        getUserId,
        getProfileId,
        getRole,
        getSubRole,
        getEmail,
        getFirstName,
        getLastName,
        getOnboardingStatus,
        getUserName,
        signin,
        signup,
        signout,
        updateUserDetails,
        getStudentLevel,
        getDateOfBirth,
        getMobileNo,
        askForSubscription,
        getDescription,
        getSubscriptionExpireDate,
        getSubscriptionPlan,
    };
};

export const AuthProvider = ({ children }) => {
    const auth = useAuthProvider();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context == undefined) {
        throw new Error('useAuth must be used within AuthProvider');
    }
    return context;
};
