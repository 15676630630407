import { LinearProgress, Modal, Paper } from '@mui/material';
import Typography from './Typography';
import BrandLogo from '../../icons/BrandLogo';

const BlockingLoader = (props) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        p: 4,
        bgcolor: 'background.paper',
        boxShadow: 24,
    };

    return (
        <Modal
            disableAutoFocus={true}
            open={props.loading}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Paper sx={style}>
                <BrandLogo width="356" height="95" />
                <LinearProgress />
                {props.message ? <Typography className="mt-m">{props.message}</Typography> : null}
            </Paper>
        </Modal>
    );
};

export default BlockingLoader;
