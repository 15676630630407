import MuiButton from '@mui/material/Button';
import Progress from './Progress';

function Button({ loading = false, disabled = false, loaderProps = {}, children, ...rest }) {
    const _children = loading ? <Progress size={24} {...loaderProps} /> : children;

    const _disabled = loading ? true : disabled;

    return (
        <MuiButton {...rest} disabled={_disabled}>
            {_children}
        </MuiButton>
    );
}

export default Button;
