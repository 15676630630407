/* eslint-disable import/no-anonymous-default-export */

import axios from 'axios';
import storage from '../../app/storage';

export const TIMEOUT = 30000; // ms

export const CONTENT_TYPE_JSON = 'application/json';
export const CONTENT_TYPE_OCTET = 'application/octet-stream';
export const CONTENT_TYPE_TEXT = 'text/plain';
export const CONTENT_TYPE_HTML = 'text/html';
export const CONTENT_TYPE_FORM = 'multipart/form-data';

export const ERROR_UNKNOWN =
    'An unknown server error has occurred or maybe the server is unreachable. Please get in touch with the admin team.';

/**
 * Prep a default headers object.
 */
const getDefaultHeaders = () => {
    const headers = {},
        token = storage.getAuthToken();

    headers['Content-Type'] = CONTENT_TYPE_JSON;
    token && (headers.Authorization = `${token}`);

    return headers;
};

/**
 * Make and fire the request.
 *
 * @param {object}  headers         Custom headers
 * @param {string}  method          Request method (get, post, put, delete)
 * @param {string}  url             Endpoint URL
 * @param {object}  data            Data to be sent as the request body
 *                                  (only for request methods 'PUT', 'POST', 'DELETE , and 'PATCH')
 * @param {object}  params          URL parameters to be sent with the request
 *                                  (must be a plain object or a URLSearchParams object)
 * @param {string}  responseType    Type of data that the server will respond with
 *                                  (options are: 'arraybuffer', 'document', 'json', 'text', 'stream')
 * @param {number}  timeout         Number of milliseconds before the request times out
 */

const makeRequest = ({
    headers = {},
    method = 'get',
    url = '/',
    data = {},
    params = {},
    paramsSerializer,
    responseType = 'json',
}) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: { ...getDefaultHeaders(), ...headers },
            method,
            url,
            data,
            params,
            paramsSerializer,
            responseType,
        })
            .then(({ status, data }) => resolve({ status, data }))
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    if (
                        (error.response.status === 401 ||
                            error.response.status === 403 ||
                            error.response.status === 408) &&
                        window.location.pathname !== '/'
                    ) {
                        storage.clear();
                        window.location = '/';
                    } else {
                        const errorMessage =
                            error.response.data?.message?.error || error.response.data?.message || ERROR_UNKNOWN;
                        reject({
                            status: error.response.status,
                            data: error.response.data,
                            error: new Error(errorMessage),
                            errorMessage,
                            errorCode: error.response.data?.message?.code,
                        });
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    reject({ error: new Error(ERROR_UNKNOWN) });
                } else {
                    // Something happened in setting up the request that triggered an Error
                    reject({ error: new Error(ERROR_UNKNOWN) });
                }
                console.error(error.toJSON());
            });
    });
};

const getRequest = (params) => makeRequest({ ...params, method: 'get' });

const postRequest = (params) => makeRequest({ ...params, method: 'post' });

const putRequest = (params) => makeRequest({ ...params, method: 'put' });

const deleteRequest = (params) => makeRequest({ ...params, method: 'delete' });

export default {
    get: getRequest,
    post: postRequest,
    put: putRequest,
    delete: deleteRequest,
};
