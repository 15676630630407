import { Paper, Rating } from '@mui/material';
import React from 'react';
import Chart from 'react-google-charts';

const FeedbackAnalytics = (props) => {
    const { yesNoAnalytics, ratingAnalytics } = props;
    return (
        <div>
            {!!yesNoAnalytics?.length && (
                <p className="text-base text-primary font-geometric"> Analytics for Yes/No questions </p>
            )}
            <div className="flex p-5 gap-6 overflow-auto">
                {!!yesNoAnalytics?.length &&
                    yesNoAnalytics?.map((question, index) => (
                        <Paper key={index} className="w-65">
                            <p className="font-geometric ml-10 mt-5 text-grayish">{question?.options?.title}</p>
                            <Chart chartType="ColumnChart" data={question?.data} width={'600px'} height={'400px'} />
                        </Paper>
                    ))}
            </div>
            {!!ratingAnalytics?.length && (
                <p className="text-base text-primary font-geometric mt-10"> Analytics for Rating questions </p>
            )}
            <div className="flex p-5 gap-6 overflow-auto">
                {!!ratingAnalytics?.length &&
                    ratingAnalytics?.map((question, index) => (
                        <Paper key={index} className="p-5 " sx={{ minWidth: '300px' }}>
                            <p className="font-geometric mb-3 text-base text-grayish w-full">{question?.question}</p>
                            <div className="w-65">
                                <Rating
                                    disabled
                                    sx={{
                                        '& .MuiRating-iconFilled': {
                                            color: '#FF3EA5',
                                        },
                                    }}
                                    name="simple-controlled"
                                    value={question?.avgRating}
                                    size="large"
                                />
                            </div>
                            <div>
                                <p className="font-geometric text-grayish">Average rating: {question?.avgRating}</p>
                                <p className="font-geometric text-grayish">Total response: {question?.length}</p>
                            </div>
                        </Paper>
                    ))}
            </div>
        </div>
    );
};

export default FeedbackAnalytics;
