import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuth } from '../../../../contexts/Auth';

export function InformationDialog({ title, open, setOpen }) {
    const navigate = useNavigate();
    const { getUserName } = useAuth();

    const handleClose = () => {
        setOpen(false);
    };

    function onInfoItemClicked() {
        navigate(`/student/${getUserName()}/biography`, {
            state: { fromApplyOpportunity: true },
        });
        handleClose();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title" className="bg-red-800 text-white font-geometric text-base">
                Error! Complete your profile
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" className="font-geometric pt-5">
                    <div>
                        <p className="text-base text">
                            Please complete your user profile for applying to{' '}
                            <span className="font-bold text-primary">"{title}"</span>
                        </p>
                        <ul type="1" className="list-disc pl-10 pt-4 ">
                            <li>
                                <button
                                    className="font-semibold text-[#5e5d5d] hover:text-primary border-b border-b-primary"
                                    onClick={onInfoItemClicked}>
                                    Fill up Kestats Details
                                </button>
                            </li>
                        </ul>
                    </div>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}
