import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { createFeedback, updateFeedback } from '../../../services/app/api';
import BlockingLoader from '../../UI/BlockingLoader';
import MuiModal from '../../UI/MuiModal';
import TextField from '../../UI/TextField';

const FeedbackForm = (props) => {
    const { editFeedback, handleEditFeedback } = props;
    const [feedbackName, setFeedbackName] = useState('');
    const [feedbackFor, setFeedbackFor] = useState('Student');
    const [active, setActive] = useState(false);
    const [questions, setQuestions] = useState([]);
    const snackbar = useSnackbar();
    const [errors, setErrors] = useState({ feedbackName: '' });
    const [loading, setLoading] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        const isEdit = !!Object.keys(editFeedback).length;
        setEditMode(isEdit);
        if (isEdit) {
            setFeedbackName(editFeedback?.name);
            setFeedbackFor(editFeedback?.feedback_for);
            setActive(editFeedback?.active);
            setQuestions(
                editFeedback?.questions?.map((q) => ({
                    question: q.question,
                    id: q.id,
                    question_type: q.question_type,
                }))
            );
        }
    }, [editFeedback]);

    const QuestionForm = (question) => {
        const [formQuestion, setFormQuestion] = useState(question?.question);
        const [formQuestionType, setFormQuestionType] = useState(question?.question_type);
        const handleRemoveQuestion = () => {
            setQuestions(questions.filter((q, i) => i !== question.index));
        };
        const handleQuestionChange = (e) => {
            setQuestions(questions.map((q, i) => (i === question?.index ? { ...q, question: e.target.value } : q)));
        };
        const handleQuestionTypeChange = (e) => {
            setQuestions(
                questions.map((q, i) => (i === question?.index ? { ...q, question_type: e.target.value } : q))
            );
        };
        return (
            <div className="flex justify-between items-end gap-4">
                <div className="w-[70%]">
                    <TextField
                        label={`Question ${question?.index + 1}`}
                        value={formQuestion}
                        onBlur={handleQuestionChange}
                        placeholder={'Enter feedback name'}
                        onChange={(e) => setFormQuestion(e.target.value)}
                    />
                </div>
                <div className="w-[30%] flex justify-between items-center gap-4">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Question Type</InputLabel>
                        <Select
                            disabled={editMode}
                            onBlur={handleQuestionTypeChange}
                            onChange={(e) => setFormQuestionType(e.target.value)}
                            value={formQuestionType}
                            label="Payment Status">
                            <MenuItem value={'yes/no'}>Yes/No</MenuItem>
                            <MenuItem value={'rating'}>Rating</MenuItem>
                            <MenuItem value={'description'}>Description</MenuItem>
                            <MenuItem value={'contact'}>Contact</MenuItem>
                        </Select>
                    </FormControl>
                    <div>
                        <IconButton disabled={editMode} onClick={handleRemoveQuestion}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </div>
                </div>
            </div>
        );
    };

    const handleAddQuestion = () => {
        setQuestions((ini) => [
            ...ini,
            {
                question: '',
                question_type: '',
            },
        ]);
    };

    const handleCreateFeedback = () => {
        let hasError = false;
        if (!feedbackName) {
            setErrors((ini) => ({ ...ini, feedbackName: 'Please enter name' }));
            hasError = true;
        }
        let hasRatingQuestion = false;
        questions.forEach((q, index) => {
            if (!q.question) {
                hasError = true;
            }
            if (!q.question_type) {
                hasError = true;
            }
            if (q.question_type === 'rating') {
                hasRatingQuestion = true;
            }
        });
        if (questions?.length === 0) {
            snackbar.error('Please add at least one question');
            return;
        }
        if (!hasRatingQuestion) {
            snackbar.error('Please add at least one rating question');
            return;
        }
        if (hasError) {
            snackbar.error('Please add all questions and their type');
            return;
        }
        const data = {
            questions,
            name: feedbackName,
            feedback_for: feedbackFor,
            active,
        };
        setLoading(true);
        const payload = editMode ? { data, feedback_id: editFeedback?.id } : { data };
        const formApi = editMode ? updateFeedback : createFeedback;
        formApi(payload)
            .then((res) => {
                snackbar.success(`Feedback form ${editMode ? 'updated' : 'created'} successfully`);
                setFeedbackName('');
                setQuestions([]);
                setActive(false);
                if (editMode) {
                    handleEditFeedback({});
                    setEditMode(false);
                }
            })
            .catch(() => {
                snackbar.error(`Failed to ${editMode ? 'update' : 'create'} feedback form`);
            })
            .finally(() => setLoading(false));
        if (active) {
            setOpenConfirmation(false);
        }
    };

    return (
        <div className="mt-10">
            <MuiModal
                handleClose={() => setOpenConfirmation(false)}
                handleConfirm={handleCreateFeedback}
                open={openConfirmation}
                title={'Activate Feedback'}
                message={'If you make this feedback as active older one will become inactive'}
            />
            <BlockingLoader loading={loading} />
            <p className="text-primary font-geometric text-3xl">Create New Feedback</p>
            <div className="flex justify-between items-end gap-4">
                <div className="w-[80%]">
                    <TextField
                        label={'Name'}
                        value={feedbackName}
                        onChange={(e) => setFeedbackName(e.target.value)}
                        placeholder={'Enter feedback name'}
                        error={errors?.feedbackName ? true : false}
                        errorMessage={errors?.feedbackName}
                        onFocus={() => setErrors((ini) => ({ ...ini, feedbackName: '' }))}
                    />
                </div>
                <div className="w-[20%]">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Feedback For</InputLabel>
                        <Select
                            onChange={({ target }) => setFeedbackFor(target.value)}
                            value={feedbackFor}
                            label="Payment Status">
                            <MenuItem value={'Student'}>Student</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            className="text-message"
                            checked={active}
                            onChange={(e) => setActive(e.target.checked)}
                        />
                    }
                    label="Make as active feedback"
                    className="mt-4 text-message text-grayish"
                />
            </div>
            <div className="flex justify-center flex-col gap-4 ">
                {!!questions.length &&
                    questions.map((item, index) => <QuestionForm key={index} {...{ ...item, index }} />)}
                {!editMode && (
                    <Button className="w-fit m-auto" onClick={handleAddQuestion}>
                        Add Question
                    </Button>
                )}
            </div>
            <div className="flex justify-end mt-4">
                <Button
                    onClick={() => {
                        if (active) {
                            setOpenConfirmation(true);
                        } else {
                            handleCreateFeedback();
                        }
                    }}
                    variant="contained">
                    Create
                </Button>
            </div>
        </div>
    );
};

export default FeedbackForm;
