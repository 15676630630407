import React, { useEffect, useState } from 'react';
import TextField from '../../UI/TextField';
import Autocomplete from '../../UI/Autocomplete';
import Label from '../../UI/Label';
import { getDefaultPaginationValues } from '../../../services/app/pagination';
import {
    getHighSchools as getHighSchoolsApi,
    searchHighSchool as searchHighSchoolApi,
    getColleges as getCollegesApi,
} from '../../../services/app/api';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '../../UI/Typography';
import { useDispatch } from 'react-redux';
import { setSearchByZipcode, setSelectStateType } from '../../../store/searchSponsorSlice';

function SearchByLocation(props) {
    const { placeholder1, label1, label2, placeholder2 } = props;
    const [textLoading, setTextLoading] = useState(false);
    const [paginated, setPaginated] = useState();

    const [colleges, setColleges] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        loadColleges();
    }, []);

    const loadColleges = () => {
        setTextLoading(true);
        const pagination = paginated ? { ...paginated } : getDefaultPaginationValues();
        const data = { params: { ...pagination }, query: '' };

        getCollegesApi({ data })
            .then((response) => {
                Array.isArray(response.data?.data) && setColleges([...colleges, ...response.data?.data]);
            })
            .catch((err) => {})
            .finally(() => setTextLoading(false));
    };

    const HandleStateChange = (event, newVal) => {
        dispatch(setSelectStateType(newVal));
    };

    const [inputValue, setInputValue] = useState('');
    const [arrayOfObjects, setArrayOfObjects] = useState([]);

    const deleteObject = (objectId) => {
        const newData = arrayOfObjects.filter((obj, index) => index !== objectId);
        setArrayOfObjects(newData);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === ' ' || event.key === 'Spacebar') {
            event.preventDefault(); // Prevents the default behavior of space or enter key
            if (inputValue.trim() !== '') {
                // Only add to the array if the input is not empty
                setArrayOfObjects((prevArray) => [...prevArray, { value: inputValue.trim() }]);
                setInputValue(''); // Clear the input field
            }
        }
    };

    useEffect(() => {
        dispatch(setSearchByZipcode(arrayOfObjects));
    }, [inputValue]);

    return (
        <div className="flex flex-col gap-2">
            <Autocomplete
                multiple
                id="tags-outlined"
                options={colleges}
                getOptionLabel={(option) => option.state}
                filterSelectedOptions
                renderInput={(params) => (
                    <>
                        <Label>Search By Zip Code</Label>
                        <TextField {...params} label={label1} placeholder={placeholder1} className="p-0" />
                    </>
                )}
                onChange={(event, newVal) => HandleStateChange(event, newVal)}
            />
            <TextField
                inputProps={{ maxLength: 5 }}
                label="Search by Zip Code"
                placeholder="Type in Zip Code Here"
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
            />
            <ul className="grid grid-cols-3 sm:grid-cols-2 gap-2">
                {arrayOfObjects.map((item, index) => (
                    <li
                        className="bg-zinc-200 rounded-full p-2 px-3 flex flex-row items-center justify-around gap-2"
                        key={index}>
                        <p className=" text-12 text-black">{item.value}</p>
                        <CloseIcon
                            onClick={() => deleteObject(index)}
                            className="text-17 cursor-pointer bg-zinc-400 font-semibold rounded-full text-white p-[1px]"
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default SearchByLocation;
