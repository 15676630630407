import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    refreshFeeds: false,
    allFeeds: {
        data: [],
        paginate: { page: 0 },
    },
    adminScrollPosition: 0,
};

const feedsSlice = createSlice({
    name: 'feeds slice',
    initialState: initialState,
    reducers: {
        setRefreshFeeds: (state) => {
            state.allFeeds = {
                data: [],
                paginate: { page: 0 },
            };
            state.refreshFeeds = !state.refreshFeeds;
        },
        setAllFeeds: (state, actions) => {
            state.allFeeds = actions.payload;
        },
        setAdminScrollPosition: (state, action) => {
            state.adminScrollPosition = action.payload;
        },
    },
});

export const { setRefreshFeeds, setAllFeeds, setAdminScrollPosition } = feedsSlice.actions;
export default feedsSlice.reducer;
