import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Button, Card, CardActions, CardContent, Checkbox, Typography } from '@mui/material';
import React from 'react';

const SubscriptionBox = ({
    disabled = false,
    title,
    price,
    onSubscribe,
    descriptionArray = [],
    checked,
    noButtonReq = false,
}) => {
    return (
        <Card className="p-2 cursor-pointer flex h-72 justify-between flex-col  my-4 shadow-lg ease-linear hover:shadow-slate-600 border-2 border-gray-200 hover:border-primary-light rounded-lg">
            <CardContent className="relative">
                <Typography component="div" className="text-17 font-semibold text-gray-800 mb-2">
                    {title}
                </Typography>
                <div className="w-full border-b-1 mb-2"></div>
                <div>
                    <Checkbox
                        className="absolute -right-1 -top-1"
                        icon={<RadioButtonUncheckedIcon disabled={true} />}
                        checkedIcon={<RadioButtonCheckedIcon disabled={true} />}
                        defaultChecked={checked}
                        disabled={true}
                        size="small"
                    />
                    {descriptionArray?.map((item) => {
                        return (
                            <div variant="body2" color="text.secondary" className="text-gray-600 leading-tight">
                                <span className="text-13">{item}</span>
                            </div>
                        );
                    })}
                </div>
            </CardContent>
            <CardActions>
                {noButtonReq && (
                    <Button className="bg-primary-light text-white hover:bg-primary mx-auto" onClick={onSubscribe}>
                        {disabled ? 'Requested' : 'Subscribe'}
                    </Button>
                )}
            </CardActions>
        </Card>
    );
};

export default SubscriptionBox;
