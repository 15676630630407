import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showCollegeCsvUplaodStatus: false,
    profilePicture: '',
    openShareBox: false,
    userSearch: '',
    fileData: null,
    checked: false,
    highSchoolValue: {},
    userDetailData: {},
    printOutUserData: [],
};

const genericSlice = createSlice({
    name: 'Generic Data',
    initialState,
    reducers: {
        setShowCollegeCsvUplaodStatus: (state, action) => {
            state.showCollegeCsvUplaodStatus = action.payload;
        },
        setProfilePicture: (state, action) => {
            state.profilePicture = action.payload;
        },
        setReplyCount: (state, action) => {
            state.replyCount = action.payload;
        },
        setOpenShareBox: (state, action) => {
            state.profilePicture = action.payload;
        },
        setUserSearch: (state, action) => {
            state.userSearch = action.payload;
        },
        setFileData: (state, action) => {
            state.fileData = action.payload;
        },
        setChecked: (state, action) => {
            state.checked = action.payload;
        },
        setHighSchoolValue: (state, action) => {
            state.highSchoolValue = action.payload;
        },
        setUserDetailData: (state, action) => {
            state.userDetailData = action.payload;
        },
        setPrintOutUserData: (state, action) => {
            state.printOutUserData = action.payload;
        },
    },
});

export const {
    setShowCollegeCsvUplaodStatus,
    setProfilePicture,
    setOpenShareBox,
    setUserSearch,
    setFileData,
    setChecked,
    setHighSchoolValue,
    setUserDetailData,
    setPrintOutUserData,
} = genericSlice.actions;
export default genericSlice.reducer;
