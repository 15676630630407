import { Grid } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/Auth';
import EditIcon from '../../../images/edit-icon';
import { getProfileAttachmentsApi } from '../../../services/app/api';
import BlockingLoader from '../../UI/BlockingLoader';
import IconButton from '../../UI/IconButton';
import Typography from '../../UI/Typography';
import * as labelConst from '../../../utils/GetLabels';

const ProfileAttachments = ({ editMode, id }) => {
    const { getProfileId } = useAuth();
    const [attachments, setAttachments] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        load();
    }, []);

    function load() {
        setLoading(true);
        getProfileAttachmentsApi({ param: id })
            .then((resp) => setAttachments(resp.data?.data))
            .finally(() => setLoading(false));
    }

    const listItem = (arr, chunkSize) => {
        const result = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            result.push(chunk);
        }
        return [...result];
    };

    const renderAttachments = (attachments, size) => {
        const list =
            attachments?.length >= 0 &&
            attachments?.map(({ id, title, file }) => {
                return (
                    <Grid key={id} item xs={size}>
                        <div className="mb-[0.6rem] md:mb-[0.45rem]">
                            <Typography className="break-words text-sm md:text-lg font-geometric font-semibold text-[#2398DA]">
                                <a className="border-none" href={file} target="_blank" rel="noreferrer">
                                    {title}
                                </a>
                            </Typography>
                        </div>
                    </Grid>
                );
            });
        return list;
    };

    const renderList = () => {
        if (attachments?.length <= 0) {
            return null;
        }
        let attachmentsArr = attachments?.length > 0 ? listItem(attachments, 3) : [];
        return (
            <>
                <Grid container spacing={{ sm: 1, md: 2 }}>
                    {!isMobile &&
                        attachmentsArr?.length > 0 &&
                        attachmentsArr?.map((attachments, index) => {
                            return (
                                <Grid container key={index} item xs={3}>
                                    {renderAttachments(attachments, 12)}
                                </Grid>
                            );
                        })}
                    {isMobile && renderAttachments(attachments, 6)}
                </Grid>
            </>
        );
    };

    return (
        <>
            <div className="flex justify-between items-center  mt-[26px] mb-4 sm:mb-[17px]">
                <Typography className="font-geometric font-extrabold text-base sm:text-[23px] text-primary">
                    Additional Attachments :
                </Typography>
                {editMode && (
                    <IconButton
                        className="bg-[#EDEDED] p-2 md:p-3 group print:hidden"
                        title="Edit your attachments"
                        component={Link}
                        to={`attachments`}>
                        <EditIcon />
                    </IconButton>
                )}
            </div>

            {attachments?.length === 0 && editMode && (
                <Typography className="font-geometric sm:text-xl text-center">
                    {labelConst.ADDITIONAL_INSTRUCTION}
                </Typography>
            )}

            <div>
                <BlockingLoader loading={loading} />
                <div>{renderList()}</div>
            </div>
        </>
    );
};

export default ProfileAttachments;
