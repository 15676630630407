import React, { useState } from 'react';
import FeedbackResponse from './FeedbackResponse';
import FeedbacksList from './FeedbacksList';

const FeedbackData = (props) => {
    const { handleEditFeedback } = props;
    const [openResponse, setOpenResponse] = useState(false);
    return (
        <div>
            {!!openResponse ? (
                <FeedbackResponse handleOpenResponse={(data) => setOpenResponse(data)} response={openResponse} />
            ) : (
                <FeedbacksList
                    handleEditFeedback={handleEditFeedback}
                    handleOpenResponse={(data) => setOpenResponse(data)}
                />
            )}
        </div>
    );
};

export default FeedbackData;
