import React from 'react';
import { useAuth } from '../../contexts/Auth';
import fakeImage from '../../images/fake.png';
import IconButton from './IconButton';
import Upload from './Upload';

const Headshot = (props) => {
    /* const { url = 'https://api.uifaces.co/our-content/donated/ukegoVAy.jpg', onChange } = props; */
    const {
        url,
        readonly = true,
        size,
        onChange,
        border = 4,
        mask = false,
        isSmall = false,
        user,
        title = 'My Profile, Account Settings, Help & Sign Out',
    } = props;
    const auth = useAuth();

    let headSize = '';
    if (window.innerWidth >= 522) {
        headSize = 'large';
    } else {
        headSize = 'small';
    }

    const empty = !url ? (
        <span
            className={`material-icons material-icons-outlined absolute left-1/2 top-1/2 ${
                isSmall ? 'sm:text-4xl' : 'sm:text-9xl'
            } text-10 -translate-x-1/2 -translate-y-1/2 text-slate-200`}>
            account_box
        </span>
    ) : null;

    const image = url ? (
        <img
            alt="Headshot"
            src={url ? url : fakeImage}
            style={{ width: '100%', height: '100%' }}
            className="bg-contain bg-center bg-no-repeat rounded-full"
        />
    ) : null;

    const camera = !readonly ? (
        <div className="inline-block absolute right-0 bottom-0 sm:right-4 sm:bottom-4 shadow-md rounded-full">
            <Upload
                allowedExtensions="image/*"
                onChange={onChange}
                btnComponent={IconButton}
                btnProps={{
                    size: `${headSize}`,
                    className: `${
                        auth.getRole() === 'Student'
                            ? 'bg-secondary'
                            : auth.getRole() === 'High School'
                            ? 'bg-pinkish'
                            : auth.getRole() === 'Sponsor'
                            ? 'bg-limish'
                            : user === 'Leader'
                            ? `bg-primary`
                            : null
                    }
                     hover:bg-primary text-white border-${border} border-solid border-white rounded-full`,
                }}
                btnChildren={<span className="material-icons material-icons-outlined">add_a_photo</span>}
            />
        </div>
    ) : null;

    const borderClasses =
        user === 'College' || user === 'High School'
            ? `border-${border} border-solid border-pinkish`
            : user === 'Sponsor'
            ? `border-${border} border-solid border-limish`
            : user === 'Student'
            ? `border-${border} border-solid border-secondary`
            : null;

    // ${mask ? 'headshotMask' : null}

    return (
        <div
            title={title}
            className={[`sm:mt-0 relative bg-slate-100 rounded-full`, borderClasses].join(' ')}
            style={{ width: size, height: size, borderWidth: `${border}px` }}>
            {/* Empty */}
            {empty}
            {/* Image */}
            {image}
            {/* Camera Icon */}
            {camera}
        </div>
    );
};

export default Headshot;
