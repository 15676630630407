import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';
import Button from '../UI/Button';

export function BackButton({ to, className = '', as = Link, text = 'Back', ...rest }) {
    const { getRole, getUserName } = useAuth();
    const navigate = useNavigate();

    const redirectPath = useMemo(() => {
        if (getRole()) return to ?? `/${getRole().toLowerCase()}/${getUserName()}`;
    }, [getRole, getUserName, to]);

    const buttonProps = as !== 'button' ? { to: redirectPath } : {};

    return (
        <Button className={className} onClick={() => navigate(-1)} {...rest}>
            <span className="material-icons material-icons-outlined mr-2">west</span> {text}
        </Button>
    );
}
