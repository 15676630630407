import CloseIcon from '@mui/icons-material/CloseOutlined';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../../contexts/Auth';
import {
    getAllSubjects as getAllSubjectsApi,
    getSubjectsCategories as getSubjectsCategoriesApi,
} from '../../../../services/app/api';
import {
    setAppliedOpportunityFilters,
    setGoToPage,
    setKeyWordFilter,
    setOpportunityStatus,
    setSelectOpportunityType,
} from '../../../../store/searchStudentSlice';
import { generateSearchParams } from '../../../../utils/GenericFunctions';
import {
    INITIAL_SELECTED_INPUTS,
    LEADER_SCHOLARSHIP_TYPES_LIST,
    META_KEYS,
    Roles,
    STUDENT_OPPORTUNITY_TYPES,
} from '../../../../utils/GetConstants';
import * as labelConst from '../../../../utils/GetLabels';
import LookingFor from '../../../Common/LookingFor/LookingFor';
import { STUDENT_OPPORTUNITY_IDS, getOpportunityList } from '../../../Common/LookingFor/LookingFor.utils';
import { OpportunityToggle } from '../../../Common/OpportunityToggle';
import Autocomplete from '../../../UI/Autocomplete';
import SearchMultipleSelect from '../../../UI/SearchMultipleSelect';
import TextField from '../../../UI/TextField';
import Typography from '../../../UI/Typography';

const initialSelectedInputs = { ...INITIAL_SELECTED_INPUTS };

function clearSelectedInputs(finalSelctedInputs, fieldId, level) {
    for (let index = level; index < 7; index++) {
        const optionId = `${fieldId}_level${index}`;
        if (finalSelctedInputs[optionId]?.key === META_KEYS.LOCATION_TYPE) {
            finalSelctedInputs.location = null;
        }
        if (finalSelctedInputs[optionId]) {
            finalSelctedInputs[optionId] = null;
        }
    }
}

function getSelectedInputs(searchParams) {
    const opportunityParam = searchParams[META_KEYS.OPP_TYPE];
    const selectedInputId = STUDENT_OPPORTUNITY_IDS[opportunityParam].id;
    const selectedInputs = { ...initialSelectedInputs };
    let index = 1;
    let prevKey = null;

    for (let param of Object.entries(searchParams)) {
        const optionId = `${selectedInputId}_level${index}`;
        const [key, value] = param;
        if (key === 'sponsor') {
            continue;
        }
        let selectedInputValue = value;
        if (
            key === META_KEYS.COLLEGE_FIELDS_CAT ||
            key === META_KEYS.COLLEGE_FIELDS_SUBJECT ||
            key === META_KEYS.COLLEGE_LIST
        ) {
            selectedInputValue = { label: value, value };
        }
        if (key === META_KEYS.SPONSORSHIP_TYPE) {
            selectedInputValue = !value ? [] : value.split(',');
        }

        const selectedMetaValue = { key, value: selectedInputValue, parent: prevKey, optionId, level: index };
        prevKey = key;
        if (key === META_KEYS.OPP_TYPE) continue;
        selectedInputs[optionId] = selectedMetaValue;
        index += 1;
    }
    return selectedInputs;
}

export function SearchBox({ callSponsorsApi, filterOpportunityByKey }) {
    const [searchParams] = useSearchParams();
    const auth = useAuth();
    const navigation = useNavigate();
    const appliedOpportunityFilters = useSelector((state) => state.searchStudentSlice.appliedOpportunityFilters);
    const opportunityType = useSelector((state) => state.searchStudentSlice.selectOpportunityType);

    const [selectedInputs, setSelectedInputs] = useState(() => {
        if (!opportunityType?.value) return initialSelectedInputs;
        return getSelectedInputs(appliedOpportunityFilters);
    });
    const [lookingForList, setLookingForList] = useState(() => {
        if (!opportunityType?.value) return [];
        return getOpportunityList({ opportunityType: opportunityType?.value, listType: Roles.Student });
    });
    const [keyInput, setKeyInput] = useState('');
    const dispatch = useDispatch();
    const keySearch = useSelector((state) => state.searchStudentSlice.keyWordFilter);
    const opportunityStatus = useSelector((state) => state.searchStudentSlice.opportunityStatus);

    useEffect(() => {
        getAllSubjects();

        async function getAllSubjects() {
            try {
                const { employment_level2, gradAssistantship_level3 } = selectedInputs;
                const shouldCallSubjectsApi =
                    gradAssistantship_level3?.value === 'Specific Fields of Study' ||
                    employment_level2?.value === 'Research Assistantship';

                if (shouldCallSubjectsApi) {
                    const { data: subjectsData } = await getAllSubjectsApi();
                    const { id: fieldId } = subjectsData.data.find(
                        ({ name }) =>
                            name === selectedInputs.gradAssistantship_level4?.value?.value ||
                            name === selectedInputs.employment_level3?.value?.value
                    );
                    const { data: categories } = await getSubjectsCategoriesApi({ category: fieldId });
                    setLookingForList(
                        getOpportunityList({
                            opportunityType: opportunityType.value,
                            subjects: subjectsData.data,
                            categories: categories.data,
                            listType: Roles.Student,
                        })
                    );
                }
            } catch (error) {
                console.error(error);
            }
        }
    }, []);

    function onOpportunityStatusChange(event, newStatus) {
        dispatch(setOpportunityStatus(newStatus));
        dispatch(setKeyWordFilter([]));
        searchSponsors({ opportunityInfo: opportunityType, status: newStatus });
    }

    function onOpportunityTypeChange(event, newValue) {
        if (opportunityType !== newValue) {
            setSelectedInputs(initialSelectedInputs);
            const { value: opportunityType } = newValue;
            setLookingForList(getOpportunityList({ opportunityType, listType: Roles.Student }));
        }
        dispatch(setSelectOpportunityType(newValue));
        dispatch(setGoToPage(1));
        searchSponsors({ opportunityInfo: newValue, status: opportunityStatus });
    }

    function searchSponsors({ status, opportunityInfo, optionValue = {}, level, excludeSponsor } = {}) {
        const isOpportunitySelected = opportunityInfo !== null;
        const isOpportunityChanged = opportunityInfo !== undefined && opportunityInfo !== opportunityType;
        const opportuityFilter = isOpportunitySelected
            ? { ...(opportunityInfo || opportunityType), key: META_KEYS.OPP_TYPE }
            : null;

        const finalSelctedInputs = {
            opportunityType: opportuityFilter,
            ...(isOpportunityChanged ? { ...initialSelectedInputs } : selectedInputs),
            ...optionValue,
        };

        const fieldId =
            opportunityType !== null
                ? STUDENT_OPPORTUNITY_IDS[finalSelctedInputs.opportunityType?.value || opportunityType.value].id
                : undefined;

        // Todo: Refactor
        if (
            fieldId &&
            optionValue &&
            optionValue[`${fieldId}_level${level}`]?.value === 'Virtual' &&
            finalSelctedInputs.location
        ) {
            finalSelctedInputs.location = null;
        }

        clearSelectedInputs(finalSelctedInputs, fieldId, level + 1);
        const selectedSearchFilters = Object.values(finalSelctedInputs).filter(Boolean);
        const searchFilterParams = generateSearchParams(selectedSearchFilters, searchParams, excludeSponsor);
        callSponsorsApi({
            filters: createSearchParams(searchFilterParams),
            status: status === undefined ? opportunityStatus : status,
        });
        dispatch(setGoToPage(1));
        dispatch(setAppliedOpportunityFilters(searchFilterParams));
        // Todo: Refactor this code
        delete finalSelctedInputs.opportunityType;
        setSelectedInputs(finalSelctedInputs);
    }

    function resetFilters() {
        navigation('/opportunities', { state: {} });
        searchSponsors({ opportunityInfo: null, excludeSponsor: true });
        dispatch(setGoToPage(1));
        dispatch(setSelectOpportunityType(null));
        setSelectedInputs(initialSelectedInputs);
        dispatch(setKeyWordFilter([]));
    }

    // Todo: Refactor
    function onClearFilter(key, optionId, level) {
        dispatch(setGoToPage(1));
        if (optionId === 'opportunity') {
            dispatch(setSelectOpportunityType(null));
            searchSponsors({ opportunityInfo: null });
            setSelectedInputs(initialSelectedInputs);
            return;
        }
        if (key === 'location') {
            setSelectedInputs((prevInputs) => ({
                ...prevInputs,
                location: null,
            }));
            searchSponsors({ optionValue: { location: null }, level });
            return;
        }
        if (key === META_KEYS.LOCATION_TYPE) {
            searchSponsors({
                optionValue: { [optionId]: null, location: null },
                level,
            });
            return;
        }
        searchSponsors({ optionValue: { [optionId]: null }, level });
        const newSelectedInputs = { ...selectedInputs };
        const fieldId = STUDENT_OPPORTUNITY_IDS[opportunityType.value].id;
        clearSelectedInputs(newSelectedInputs, fieldId, level + 1);
        if (key === META_KEYS.LOCATION_TYPE) {
            newSelectedInputs.location = null;
        }
        setSelectedInputs((prevInputs) => ({
            ...prevInputs,
            ...newSelectedInputs,
            [optionId]: null,
        }));
    }
    const handleKeyInputKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            let input = keyInput.trim().toLocaleLowerCase();
            if (input && !keySearch.includes(input)) {
                dispatch(setKeyWordFilter([...keySearch, input]));
                filterOpportunityByKey([...keySearch, input]);
            }
            setKeyInput('');
        }
    };

    const handleRemoveKeyInput = (indexToRemove) => {
        const filteredArray = keySearch.filter((_, index) => index !== indexToRemove);
        dispatch(setKeyWordFilter(filteredArray));
        filterOpportunityByKey(filteredArray);
    };

    return (
        <>
            <div
                style={{ boxShadow: '0px 4px 3px rgba(0,0,0,0.05)' }}
                className="relative bg-white py-6 px-3 lg:px-8 rounded">
                <h2 className="font-geometric text-base leading-5 lg:text-lg font-bold text-primary mb-4">
                    Select as many of the criteria below in which you want to match or search Your29 Sponsor Profiles:
                </h2>

                <OpportunityToggle
                    checked={opportunityStatus}
                    onChange={onOpportunityStatusChange}
                    className="justify-end"
                />

                <Grid container>
                    <Grid item xs={12}>
                        <div className="flex flex-col sm:flex-row gap-4 justify-between">
                            {keySearch.length === 0 && (
                                <div className=" w-full  order-1 sm:order-0 ">
                                    <Autocomplete
                                        id="type"
                                        name="type"
                                        label="Select Opportunity Type"
                                        disabled={false}
                                        options={
                                            auth?.getSubRole() === 'Leader'
                                                ? LEADER_SCHOLARSHIP_TYPES_LIST
                                                : STUDENT_OPPORTUNITY_TYPES
                                        }
                                        getOptionLabel={(option) => option.label}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder={labelConst.PLACEHOLDER_SCHOLARSHIP_OPPORTUNITY}
                                                error={false}
                                                noHelperText={true}
                                            />
                                        )}
                                        renderOption={(props, option) =>
                                            !option?.depreciated && (
                                                <li {...props}>
                                                    <Typography className="w-[200px] sm:w-full">
                                                        {option.label}
                                                    </Typography>
                                                </li>
                                            )
                                        }
                                        classes={{ root: 'mt-1' }}
                                        onChange={onOpportunityTypeChange}
                                        value={opportunityType}
                                        error={false}
                                        errorMessage={''}
                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                    />
                                </div>
                            )}
                            <div className="w-full order-0 sm:order-1">
                                {!opportunityType && (
                                    <SearchMultipleSelect
                                        placeholder={labelConst.STUDENT_KEY_SEARCH_INSTRUCTION}
                                        value={keyInput}
                                        onChange={({ target }) => setKeyInput(target.value)}
                                        label={'Key Word Search'}
                                        disabled={!!opportunityType}
                                        onKeyDown={handleKeyInputKeyDown}
                                        keySearch={keySearch}
                                        CloseHandle={handleRemoveKeyInput}
                                        resetHandleFilters={resetFilters}
                                    />
                                )}
                            </div>
                        </div>
                        {opportunityType && (
                            <>
                                <LookingFor
                                    isSearch
                                    disabled={false}
                                    setSelectedInputs={setSelectedInputs}
                                    selectedInputs={selectedInputs}
                                    value={appliedOpportunityFilters[META_KEYS.OPP_TYPE]}
                                    lookingForList={lookingForList}
                                    setLookingForList={setLookingForList}
                                    scholarshipErrors={{}}
                                    runLookingForValidations={() => {}}
                                    searchSponsorsApi={searchSponsors}
                                    listType={Roles.Student}
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
            </div>

            <div className="lg:px-8">
                {opportunityType ? (
                    <ul className="inline-flex justify-start flex-wrap gap-x-3 gap-y-2 mt-3 mb-6">
                        {[{ ...opportunityType, optionId: 'opportunity' }]
                            .concat(Object.values(selectedInputs))
                            .filter(Boolean)
                            .map(({ key, value: optionValue, level, optionId }, index) => {
                                let value =
                                    typeof optionValue === 'object' && !Array.isArray(optionValue)
                                        ? optionValue.value || `${optionValue.city},${optionValue.state}`
                                        : optionValue;

                                if (value.toString() === '') {
                                    return null;
                                }
                                return (
                                    <li
                                        key={`${optionId}${index}`}
                                        className="inline-flex rounded-full text-[0.78rem] lg:text-sm font-geometric font-medium items-center 
                                                           gap-3 px-[10px] lg:px-4 py-[6px] bg-[#efd2ca] text-[#604942] cursor-pointer">
                                        <span>{value.toString()}</span>
                                        <button onClick={() => onClearFilter(key, optionId, level)}>
                                            <CloseIcon className="w-5" />
                                        </button>
                                    </li>
                                );
                            })}
                    </ul>
                ) : null}
            </div>
        </>
    );
}
