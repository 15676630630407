/* eslint-disable import/no-anonymous-default-export */

import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

export default (props) => {
    const { flavor = 'circular' } = props;

    const Component = flavor === 'circular' ? CircularProgress : LinearProgress;

    return <Component {...props}>{props.children}</Component>;
};
