import React, { useState } from 'react';
import { generateGuid } from '../../services/core/utils';
import Button from './Button';

const Upload = (props) => {
    const {
        btnComponent = Button,
        btnProps = { variant: 'contained' },
        btnChildren = 'Upload',
        allowedExtensions = '*',
        multiple = false,
        onChange,
        disable = false,
        error = false,
        errorMessage = '',
    } = props;

    const [inputId] = useState(generateGuid());

    const Component = btnComponent;

    return (
        <label htmlFor={inputId} className="inline-block">
            <input
                /* accept="image/*" */
                disabled={disable}
                accept={allowedExtensions}
                id={inputId}
                multiple={multiple}
                type="file"
                className="hidden"
                onChange={onChange}
                // https://stackoverflow.com/questions/4109276/how-to-detect-input-type-file-change-for-the-same-file
                onClick={(e) => (e.target.value = '')}
            />
            <Component {...btnProps} style={{ borderColor: error ? 'red' : '' }} component="span">
                {btnChildren}
            </Component>
            {errorMessage && (
                <div className="container ">
                    <p className="text-xs text-red-500">{errorMessage}</p>
                </div>
            )}
        </label>
    );
};

export default Upload;
