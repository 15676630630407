import JoditEditor from 'jodit-react';
import React, { useRef } from 'react';
import './textEditor.css';

export default function TextEditor(props) {
    const editor = useRef(null);

    function removeBackgroundColorStyle(node) {
        if (node.style.backgroundColor) {
            node.style.backgroundColor = '';
        }
        var children = node.children;
        for (var i = 0; i < children.length; i++) {
            removeBackgroundColorStyle(children[i]);
        }
    }

    const removeBackgroundColor = (editor) => {
        const selection = editor.selection;
        if (selection && selection.range) {
            removeBackgroundColorStyle(selection.range.commonAncestorContainer);
        }
    };
    const config2 = {
        //  defaultActionOnPaste: 'insert_clear_html',
        i18n: 'en',
        language: 'auto',
        useSearch: false,
        spellcheck: true,
        enter: 'br',
        defaultMode: '1',
        toolbarAdaptive: false,
        showCharsCounter: false,
        showWordsCounter: false,
        showXPathInStatusbar: false,
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        toolbarSticky: true,
        toolbarStickyOffset: 60,
        minHeight: 250,
        minWidth: null,
        buttons: [
            'bold',
            'strikethrough',
            'underline',
            'italic',
            '|',
            'superscript',
            'subscript',
            '|',
            'ul',
            'ol',
            'outdent',
            'indent',
            '|',
            'font',
            'fontsize',
            'brush',
            'link',
            '|',
            'align',
            'undo',
            'redo',
            '|',
            'eraser',
            {
                name: 'removeBackgroundColor',
                icon: 'cancel',
                exec: removeBackgroundColor,
                tooltip: 'Remove text background',
            },
        ],
        editorCssClass: false,
        placeHolder: '',
    };
    const config = {
        // defaultActionOnPaste: 'insert_clear_html',
        i18n: 'en',
        language: 'auto',
        useSearch: false,
        spellcheck: true,
        enter: 'br',
        defaultMode: '1',
        toolbarAdaptive: false,
        showCharsCounter: false,
        showWordsCounter: false,
        showXPathInStatusbar: false,
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        toolbarSticky: true,
        toolbarStickyOffset: 60,
        minHeight: 120,
        minWidth: null,
        buttons: ['bold', 'strikethrough', 'underline', 'italic', 'link'],
        editorCssClass: false,
        placeHolder: '',
    };

    return (
        <div>
            <JoditEditor
                height={100}
                readOnly={props.isDisabled}
                ref={editor}
                value={props?.value}
                tabIndex={4}
                // onChange={(e) => {
                //     if (e.includes('<ul') || e.includes('<ol')) {
                //         if (!e.includes('<ul class="ml-8')) {
                //             let data = e.replaceAll('<ul', '<ul class="ml-8" ');
                //             props.onChange(data);
                //         }
                //         if (!e.includes('<ol class="ml-8"')) {
                //             let data = e.replaceAll('<ol', '<ol class="ml-8" ');
                //             props.onChange(data);
                //         }
                //     }
                // }}
                config={props.config === 'simple' ? config : config2}
                onBlur={(e) => {
                    props.onChange(e);
                }}
                className="border-2 border-primary hover:border-bluish rounded-sm"
            />
        </div>
    );
}
