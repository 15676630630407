import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

const CustomSwitch = styled(Switch)(({ theme, ...props }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        transform: 'translateX(0px)',
        color: props.editor ? '#103B58' : '#ACE849',
        '&.Mui-checked': {
            color: props.editor ? '#ACE849' : 'red',
            transform: 'translateX(28px)',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: props.editor ? '#DEEFC2' : '#FFDCDC',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        width: 16,
        height: 16,
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: props.editor ? '#889dac' : '#DEEFC2',
        borderRadius: 20 / 2,
    },
}));

export function OpportunityToggle({ editor, checked, onChange, className = '', Active, Closed }) {
    return (
        <div className={`flex gap-2 items-center  ${className}`}>
            <Typography className="font-geometric font-bold text-[#76AE19]">{Active}</Typography>
            <CustomSwitch editor={editor} checked={checked} onChange={onChange} />
            <Typography className="font-geometric font-bold text-[#E04444]">{Closed}</Typography>
        </div>
    );
}
