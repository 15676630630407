import React from 'react';
import SectionHeader from '../../../components/Common/SectionHeader';
import GalleryEditor from '../ProfileEdit/GalleryEditor';

function VideoUpload({ id, editMode }) {
    return (
        <>
            <SectionHeader
                headingTitle="Video Uploads :"
                iconTitle="Edit your video uploads"
                editMode={editMode}
                linkTo={{
                    pathname: `gallery`,
                }}
                state={{ contentType: 'video' }}
                className="mb-1 mt-4 md:my-7"
            />
            <GalleryEditor editMode={editMode} profileId={id} contentType="video" />
        </>
    );
}

export default VideoUpload;
