import React, { useState } from 'react';
import TextField from '../../UI/TextField';
import Autocomplete from '../../UI/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { setStudentLevel } from '../../../store/searchSponsorSlice';
import { generateGraduationYearRange } from '../../../utils/GenericFunctions';
import { Checkbox, FormControlLabel } from '@mui/material';
import { setGradYear } from '../../../store/searchSponsorSlice';
import { useEffect } from 'react';

function SearchByStudentLevel() {
    const dispatch = useDispatch();
    const todayYear = new Date().getFullYear();
    const [checkboxValues, setCheckboxValues] = useState({
        'High School': false,
        College: false,
        Graduate: false,
    });

    const handleCheckboxChange = (checkbox) => {
        // Disable other checkboxes and toggle the selected checkbox
        setCheckboxValues((prevValues) => ({
            ['High School']: checkbox === 'High School' ? !prevValues['High School'] : false,
            College: checkbox === 'College' ? !prevValues.College : false,
            Graduate: checkbox === 'Graduate' ? !prevValues.Graduate : false,
        }));
    };

    useEffect(() => {
        dispatch(setStudentLevel(checkboxValues));
    }, [checkboxValues]);

    const gardYearNUm = generateGraduationYearRange(18).map((i) => todayYear + i);

    const arrayOfObjects = gardYearNUm.map((item, index) => ({
        id: index, // You can use a unique identifier as per your requirement
        value: item,
    }));

    const containsTrue = Object.values(checkboxValues).some((value) => value === true);

    const handleYearChange = (e, newValue) => {
        dispatch(setGradYear(newValue));
    };

    return (
        <div>
            <div>
                <FormControlLabel
                    labelPlacement="start"
                    className="flex justify-between items-center"
                    control={
                        <Checkbox
                            checked={checkboxValues[`High School`]}
                            onChange={() => handleCheckboxChange('High School')}
                            disabled={checkboxValues.College || checkboxValues.Graduate}
                        />
                    }
                    label="High School"
                />

                <FormControlLabel
                    labelPlacement="start"
                    className="flex justify-between items-center"
                    control={
                        <Checkbox
                            checked={checkboxValues.College}
                            onChange={() => handleCheckboxChange('College')}
                            disabled={checkboxValues['High School'] || checkboxValues.Graduate}
                        />
                    }
                    label="College"
                />

                <FormControlLabel
                    labelPlacement="start"
                    className="flex justify-between items-center"
                    control={
                        <Checkbox
                            checked={checkboxValues.Graduate}
                            onChange={() => handleCheckboxChange('Graduate')}
                            disabled={checkboxValues['High School'] || checkboxValues.College}
                        />
                    }
                    label="Graduate"
                />
            </div>
            <Autocomplete
                multiple
                id="tags-outlined"
                options={arrayOfObjects}
                getOptionLabel={(option) => option.value}
                filterSelectedOptions
                onChange={handleYearChange}
                renderInput={(params) => <TextField {...params} placeholder={'Type in Grad Years here'} />}
                disabled={!containsTrue}
            />
        </div>
    );
}

export default SearchByStudentLevel;
