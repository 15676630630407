import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from './Typography';
import { useAuth } from '../../contexts/Auth';

const BubbleTooltip = ({ children, title, description }) => {
    const { getRole } = useAuth();
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip arrow {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: getRole()?.toLowerCase() === 'sponsor' ? '#F35B54' : '#a3e635',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            borderRadius: '50px 0px 50px 0px',
            padding: '1rem',
            boxShadow: '10px 10px 5px 0px rgba(0,0,0,0.5)',
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: getRole()?.toLowerCase() === 'sponsor' ? '#F35B54' : '#a3e635',
            fontSize: '50px',
            '&:before': {
                clipPath: 'polygon(20% 0, 60% 20%, 70% 100%)',
            },
        },
    }));

    if (!title && !description) {
        return children;
    }

    return (
        <HtmlTooltip
            title={
                <div className={`text-center text-${getRole()?.toLowerCase() === 'sponsor' ? 'white' : 'primary'}`}>
                    {title && (
                        <div className="mb-1">
                            <Typography className="font-bold text-[16px] ">{title}</Typography>
                        </div>
                    )}
                    {description && (
                        <div>
                            <Typography className="font-semibold text-[12px]">{description}</Typography>
                        </div>
                    )}
                </div>
            }>
            {children}
        </HtmlTooltip>
    );
};

export default BubbleTooltip;
