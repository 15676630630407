import React from 'react';
import { useSelector } from 'react-redux';
import CheckBoxForm from '../../Student/College/CollegeSearch/CheckBoxForm';
import { setSelectOpportunites } from '../../../store/searchSponsorSlice';

function SearchByOpportunities() {
    const selectOpportunities = useSelector((state) => state.searchSponsorSlice.selectOpportunities);

    return (
        <div>
            <div className="mb-4 border-b-1 border-gray-500">
                <CheckBoxForm data={selectOpportunities} setData={setSelectOpportunites} />
            </div>
        </div>
    );
}

export default SearchByOpportunities;
