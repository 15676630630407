import React, { useEffect } from 'react';
import Typography from '../UI/Typography';
import { useLocation } from 'react-router-dom';
import { hmtlTextPrivacyValue, hmtlTermsAndConditionValue } from '../HelpPage/htmlText';

function TermsAndCondition() {
    const location = useLocation();
    const stateValue = location?.state;
    const renderDataValue = stateValue ? hmtlTextPrivacyValue : hmtlTermsAndConditionValue;

    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }, [renderDataValue]);
    return (
        <div className="flex flex-col items-center justify-center mt-10 gap-5 2xl:max-w-[1450px] mx-auto lg:px-44">
            <Typography className="text-4xl text-center font-semibold font-geometric">{`${
                stateValue ? 'Privacy' : 'Our Terms of Service'
            }`}</Typography>
            <div className="flex flex-col justify-start gap-5 px-4 md:px-8 lg:px-0">
                <div className="py-2">
                    <Typography className="text-left text-base font-medium text-primary">
                        {'Last updated November 06, 2023'}
                    </Typography>
                </div>
                <div>{renderDataValue}</div>
            </div>
        </div>
    );
}

export default TermsAndCondition;
