export default {
    //sign up page
    STUDENT_JOINED: 'Student',
    STUDENT_LEVEL: 'Student Level',
    BUSINESS_JOINED: 'Sponsor',
    COLLEGE_JOINED: 'College',
    HIGH_SCHOOL_JOINED: 'High School',
    ACCOUNT_ACTIVATED: 'Activated',
    //student onboarding
    STARTED_PROFILE: 'Student Started',
    STUDENT_FILLED_OUT_STEP_1: 'Student SU1 Complete',
    STUDENT_FILLED_OUT_STEP_2: 'Student SU2 Complete',
    STUDENT_FILLED_OUT_STEP_3: 'Student SU3 Complete',
    STUDENT_COMPLETED_ONBOARDING: 'Student Onboarding Complete',
    //sponsor onboarding
    SPONSOR_STARTED_PROFILE: 'Sponsor Started',
    SPONSOR_FILLED_OUT_SIGN_UP_STEP_1: 'Sponsor SU1 Complete',
    SPONSOR_FILLED_OUT_SIGN_UP_STEP_2: 'Sponsor SU2 Complete',
    SPONSOR_FILLED_OUT_SIGN_UP_STEP_3: 'Sponsor SU3 Complete',
    SPONSOR_COMPLETED_ONBOARDING: 'Sponsor Onboarding Complete',
    SPONSOR_CATEGORY: 'Sponsor SU',
    //student profile completion
    STUDENT_FILLED_OUT_MY_GOALS: 'Student Goals Complete',
    STUDENT_FILLED_OUT_WHY_YOUR29: 'Student Why Your29 Complete',
    STUDENT_FILLED_OUT_WHAT_MAKES_ME_DIFFERENT: 'Student What makes me different complete',
    STUDENT_FILLED_OUT_MY_BACKGROUND: 'Student My background complete',
    STUDENT_FILLED_OUT_KEY_STATS: 'Student Key stats complete',
    STUDENT_FILLED_OUT_VIDEO_UPLOAD: 'Student Video upload complete',
    STUDENT_FILLED_OUT_AWARDS_AND_ACCOMPLISHMENTS: 'Student Awards and accomplishments complete',
    STUDENT_FILLED_OUT_WHAT_THEY_RE_SAYING_ABOUT_ME: "Student What they're saying about me complete",
    STUDENT_FILLED_OUT_ACTIVITIES_AND_CLUBS: 'Student Activities and clubs complete',
    //sponsor profile completion
    SPONSOR_FILLED_OUT_WHY_YOUR29: 'Sponsor Why Your29 Complete',
    SPONSOR_FILLED_OUT_MORE_ABOUT_ME: 'Sponsor More About Me Complete',
    SPONSOR_STARTS_TO_ADD_OPPORTUNITY: 'Sponsor Clicks Add Opportunity',
    SPONSOR_ADDS_FIRST_OPPORTUNITY: 'Sponsor Created First Opportunity',
    //sponsor add opp
    SPONSOR_ADDS_OPPORTUNITY: 'Sponsor Adds',

    // 'P& G tracking',
    INFO_SESSION_LEARN_MORE_BUTTON_PRESSED: 'VIS Learn About',
    INFO_SESSION_WATCH_NOW_BUTTON_PRESSED: 'VIS Began',
    ASSESSMENT_TRAINING_LEARN_MORE: 'AT Learn About',
    ASSESSMENT_TRAINING_WATCH_NOW: 'AT Began',
    APPLY_FOR_SUMMER_INTERNSHIP: 'Applied',
    APPLY_FOR_FULL_TIME: 'Applied',
    APPLY_FOR_FULL_TIME_MANUFACTURING_ENGINEERING: 'Applied',
    REQUEST_AN_INTERVIEW: 'Interview Request Started',
    REQUEST_AN_INTERVIEW_SCHEDULED: 'Interview Requested',
    INTERVIEW_COACHING_LEARN_MORE: 'Interview Coaching Learn About',
    INTERVIEW_COACHING_WATCH_NOW: 'Interview Coaching Began',

    // scholarship opportunity
    STUDENT_APPLICATION_FOR_SCHOLARSHIP_STARTED: 'Student Started',
    LEADER_APPLICATION_FOR_SCHOLARSHIP_STARTED: 'Leader Started',
    STUDENT_APPLIES_FOR_SCHOLARSHIP_CONFIRMED: 'Student Applied',
    SPONSOR_ACCEPTS_STUDENT_SCHOLAR: 'Sponsor Accepted',
    SPONSOR_REJECTS_STUDENT_SCHOLAR: 'Sponsor Rejected',
    STUDENT_ACCEPTED_FOR_SCHOLARSHIP: 'Student Accepted',
    STUDENT_REJECTED_FOR_SCHOLARSHIP: 'Student Rejected',

    // mentorship opportunity
    MENTORSHIP_APPLICATION_STARTED: 'Student Started',
    LEADER_MENTORSHIP_APPLICATION_STARTED: 'Leader Started',
    STUDENT_APPLIES_FOR_MENTORSHIP_CONFIRMED: 'Student Applied',
    SPONSOR_ACCEPTS_MENTEE: 'Sponsor Accepted',
    STUDENT_ACCEPTED_FOR_MENTORSHIP: 'Student Accepted',
    SPONSOR_REJECTS_MENTEE: 'Sponsor Rejected',
    STUDENT_REJECTED_FOR_MENTORSHIP: 'Student Rejected',

    // event steps,
    STUDENT_REGISTRATION_STARTED: 'Student Started',
    LEADER_REGISTRATION_STARTED: 'Leader Started',
    STUDENT_REGISTRATION_CONFIRMED: 'Student Registered',
    DEADLINE_TO_APPLY: 'Deadline to apply to',
    // leader onboarding
    LEADER_STARTED_PROFILE: 'Leader Started',
};
