import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import * as React from 'react';
import Typography from './Typography';

export default function MuiModal(props) {
    const {
        handleClose,
        open,
        title,
        message,
        handleConfirm,
        width,
        hideBackdrop = false,
        bgcolor,
        borderRadius,
        hideButtons = false,
        subscribe = false,
    } = props;
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width ? width : 400,
        bgcolor: bgcolor || 'background.paper',
        boxShadow: 24,
        borderRadius: borderRadius || 0,
    };

    const MessageBox = () => (
        <>
            <div className="bg-primary px-4 text-white h-16 flex items-center">
                <Typography id="modal-modal-title" variant="p" className="font-geometric text-17">
                    {title}
                </Typography>
            </div>
            {message && (
                <div className=" m-2 sm:m-6 mb-3 pb-1">
                    <Typography id="modal-modal-title" variant="p" className="font-geometric text-[16px]  ">
                        {message}
                    </Typography>
                </div>
            )}
            <div className=" m-2  sm:m-6 mb-3 border-b-1 pb-3 border-gray-500 " />
            {!hideButtons && (
                <div className="flex justify-end m-4 mr-2 sm:mr-6 gap-4 ">
                    <Button onClick={handleClose} variant="outlined">
                        Cancel
                    </Button>
                    {subscribe !== 'Requested' ? <Button onClick={handleConfirm} variant="contained" className={`${subscribe === 'Requested' && 'bg-slate-500'} `}>
                        {subscribe === 'Subscribe' ? 'Subscribe' : 'Confirm'}
                    </Button>: null}
                </div>
            )}
        </>
    );
    return (
        <div>
            <Modal
                disableAutoFocus={true}
                hideBackdrop={hideBackdrop}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>{props.children ? props.children : <MessageBox />}</Box>
            </Modal>
        </div>
    );
}
