import { META_KEYS } from '../../utils/GetConstants';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import PaymentIcon from '@mui/icons-material/PaidOutlined';
import JobTypeIcon from '@mui/icons-material/WorkOutline';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';

const iconClassName = 'w-5 text-[#575656]';
const META_ICONS = {
    [META_KEYS.LOCATION_TYPE]: <LocationIcon className={iconClassName} />,
    [META_KEYS.JOB_TYPE]: <JobTypeIcon className={iconClassName} />,
    [META_KEYS.PAYMENT_TYPE]: <PaymentIcon className={iconClassName} />,
    [META_KEYS.COLLEGE_FIELDS_CAT]: <DescriptionIcon className={iconClassName} />,
    [META_KEYS.COLLEGE_FIELDS_SUBJECT]: <DescriptionIcon className={iconClassName} />,
};
const featuresMetaKeys = Object.keys(META_ICONS);

export function OpportunityInfoList({ metadata }) {
    const isMetadataEmpty = !metadata || metadata?.length <= 0;

    if (isMetadataEmpty) {
        return null;
    }

    const featuresList = metadata.filter(({ key }) => featuresMetaKeys.includes(key));

    if (featuresList.length <= 0) {
        return null;
    }
    const { value: location } = metadata.find(({ key }) => key === META_KEYS.LOCATION) || {};

    return (
        <ul className="flex flex-wrap items-center gap-x-3 mt-5  -ml-1">
            {featuresList.map(({ key, value }) => {
                const icon = META_ICONS[key];

                return (
                    <li key={key}>
                        <div className="flex items-center font-geometric gap-x-1">
                            {icon}
                            <p className="text-sm text-[#484848] font-medium">
                                {value}
                                {value === 'On Site' && location && location.city
                                    ? `, ${location.city}, ${location.state}`
                                    : ''}
                            </p>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
}
