import { format, fromUnixTime } from 'date-fns/esm';
import HTMLReactParser from 'html-react-parser';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../contexts/Auth';
import { useSnackbar } from '../../../../hooks';
import {
    addNewMailChimpTag,
    getProfileCompletion as getProfileCompletionApi,
    getUser as getUserApi,
} from '../../../../services/app/api';
import storage from '../../../../services/app/storage';
import {
    convertDate,
    formatCurrencyRange,
    generateLink,
    getEventAppliedFun,
    getEventFun,
    getSubMetaDataValue,
    virtualFunction,
} from '../../../../utils/GenericFunctions';
import { META_KEYS } from '../../../../utils/GetConstants';
import mailchimpTags from '../../../../utils/mailchimpTags';
import { OpportunityInfoList } from '../../../Common/OpportunityInfoList';
import Button from '../../../UI/Button';
import StudentModelConfirmationBox from '../../../UI/StudentModelConfirmationBox';
import Typography from '../../../UI/Typography';
import { InformationDialog } from './InformationDialog';
const excludedMetaOppType = ['Scholars', 'Students to Sponsor', 'Volunteers', 'Influencers'];

const SUB_METADATA_KEYS = {
    Employee: META_KEYS.EMP_TYPE,
    Recruits: META_KEYS.RECRUITS_TYPE,
    'Grad Assistant': META_KEYS.GRAD_ASSISTNT_TYPE,
    Mentee: META_KEYS.COLLEGE_FIELDS_CAT,
    'Private Lessons': META_KEYS.PVT_LESSON_TYPE,
    Intern: META_KEYS.COLLEGE_FIELDS_CAT,
    'Research Assistant': META_KEYS.COLLEGE_FIELDS_CAT,
    Tutor: META_KEYS.TUTOR_TYPE,
};

function getSubMetaData(metadata) {
    const { value: opportunityType } = metadata.find(({ key }) => key === META_KEYS.OPP_TYPE) || {};
    if (!opportunityType) {
        return null;
    }

    const { value } = getSubMetaDataValue(metadata, opportunityType, SUB_METADATA_KEYS, excludedMetaOppType);
    return value;
}

function Message() {
    const { isAuthenticated } = useAuth();
    return (
        <p className="text-white">
            {`Your profile application has been submitted  ${isAuthenticated() ? 'and can be viewed in' : ''}`}
            {isAuthenticated() && (
                <Link
                    className="text-[#EDEDED] font-bold border-solid border-b-2 border-b-[#EDEDED]"
                    to="/applications">
                    {'  '}
                    My Applications
                </Link>
            )}
            .
        </p>
    );
}

function Badge({ variant, text }) {
    const classNames = {
        success: 'bg-green-100 text-green-700',
        error: 'bg-red-100 text-red-700',
    };

    return (
        <div>
            <span
                className={`absolute px-3 py-1 right-14 rounded text-sm font-medium font-geometric ${classNames[variant]}`}>
                {text}
            </span>
        </div>
    );
}

const eligibilityComponents = (value, title) => {
    return (
        <div className="flex flex-row gap-2">
            <Typography className="font-geometric text-sm font-medium">{title}</Typography>
            <Typography>{value}</Typography>
        </div>
    );
};

export function StudentSearchCard({
    id: opportunityId,
    title,
    user,
    file,
    profile,
    description,
    opp_for,
    amount,
    deadline,
    metadata,
    is_applied,
    handleAppliedToOpportunity,
    searchParams,
    question_1,
    question_2,
    question_3,
    created_at,
    eligibility_criteria,
    allow_colleges_list,
    allow_high_schools_list,
    allow_hs_grad_years,
    allow_clg_grad_years,
    allow_zipcodes,
}) {
    const { first_name, last_name, sub_role } = user;
    const { profile_photo, organization, category, website, username } = profile;
    const [isLoading, setIsLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
    const [percentageCompletion, setPercentageCompletion] = useState('');
    const [commonHighSchoolId, setCommonHighSchoolId] = useState('');

    const checkIdMatch = (id) => {
        return allow_high_schools_list?.some((scholarship) => scholarship.id === id);
    };

    const { isAuthenticated, getRole, getSubRole, getUserName, getProfileId, getEmail } = useAuth();
    const navigate = useNavigate();
    const snackbar = useSnackbar();

    const eventVal = metadata.filter(({ key }) => key === 'event_name');
    const eventTypeVal = metadata.filter(({ key }) => key === 'event_type');

    const matchedCollegeObjects = allow_colleges_list.map((value) => {
        return value.name;
    });
    const matchedSchoolObjects = allow_high_schools_list.map((value) => {
        return value.name;
    });

    function replaceSemicolon(string) {
        if (string) {
            return string.replace(/;/g, ', ');
        } else {
            return '';
        }
    }
    const getEventVal = eventVal[0]?.value || [];
    const getEventTypeVal = eventTypeVal[0]?.value || [];
    const oppURL = metadata?.flatMap((item) => (item?.key === 'url' ? [item?.value] : []))[0];
    const eventDate = metadata?.flatMap((item) => (item?.key === 'event_date' ? [item?.value] : []))[0];
    const currentDate = new Date();
    const validEventDate = new Date(Number(eventDate * 1000));
    const eventDatePassed = Boolean(currentDate > validEventDate);
    const virtualEvent = getEventTypeVal === 'Virtual Event';
    const campusEvent = getEventTypeVal === 'Campus Event';
    const intervieRequestEvent = getEventVal === 'Interview Request';
    const intervieRequestEventApplied = getEventVal === 'Interview Request' && is_applied;

    const loadPercentage = () => {
        getProfileCompletionApi({ params: { id: getProfileId() } })
            .then((response) => {
                const perValue = response.data.data;
                setPercentageCompletion(perValue.profile_completion_percentage);
            })
            .finally(() => {});
    };

    const load = () => {
        getUserApi({ params: { id: getProfileId() } })
            .then((resp) => {
                const { high_school } = resp.data?.data || {};
                const { id } = high_school || {};
                setCommonHighSchoolId(id);
            })
            .finally();
    };

    useEffect(() => {
        if (isAuthenticated() && getRole() === 'Student') {
            loadPercentage();
            load();
        }
    }, []);

    const toApplyValue =
        virtualEvent && !intervieRequestEvent ? virtualFunction(is_applied, eventDatePassed) : getEventFun(getEventVal);
    const aplliedValue =
        virtualEvent && !intervieRequestEvent
            ? virtualFunction(is_applied, eventDatePassed)
            : getEventAppliedFun(getEventVal);

    const getSignUpUserId = () => storage.getSignUpUserId();

    const isScholarshipDeadlinePassed = Date.now() / 1000 > Number(deadline);
    const deadlineDate = format(fromUnixTime(Number(deadline)), 'dd MMM yyyy');
    const userProfileLink = sub_role === 'Storefront' ? `/highschool/${username}` : `/sponsor/${username}`;
    const storeFrontHighSchoolMatched = !checkIdMatch(commonHighSchoolId);

    const metaValue = getSubMetaData(metadata);
    const editorDescription = HTMLReactParser(description);

    const handleClose = () => setOpenConfirmationBox(false);
    const disabledForCampusAfterApplied = campusEvent && is_applied;

    const buttonText = is_applied
        ? aplliedValue
            ? aplliedValue
            : 'Applied'
        : toApplyValue
        ? toApplyValue
        : 'Apply Now';

    async function onApplyClicked() {
        const opportunityType = metadata.flatMap((item) => (item?.key === 'opp_type' ? [item?.value] : []))[0];
        let extraInfo = `${title}-${username}-${convertDate(created_at, 'YYYY-MM-DD')}`;
        let tags =
            getRole() === 'Student'
                ? {
                      Event: [`${mailchimpTags.STUDENT_REGISTRATION_STARTED}-${extraInfo}`],
                      Scholars: [`${mailchimpTags.STUDENT_APPLICATION_FOR_SCHOLARSHIP_STARTED}-${extraInfo}`],
                      Mentee: [`${mailchimpTags.MENTORSHIP_APPLICATION_STARTED}-${extraInfo}`],
                  }
                : getSubRole() === 'Leader'
                ? {
                      Event: [`${mailchimpTags.LEADER_REGISTRATION_STARTED}-${extraInfo}`],
                      Scholars: [`${mailchimpTags.LEADER_APPLICATION_FOR_SCHOLARSHIP_STARTED}-${extraInfo}`],
                      Mentee: [`${mailchimpTags.LEADER_MENTORSHIP_APPLICATION_STARTED}-${extraInfo}`],
                  }
                : {};

        if (is_applied && oppURL) {
            window.open(oppURL, '_blank');
        }

        if (tags[opportunityType]) {
            const mailchimpPayload = {
                profileId: getProfileId(),
                data: {
                    email: getEmail(),
                    tags: tags[opportunityType],
                },
            };
            await addNewMailChimpTag(mailchimpPayload).catch(() => {});
        }
        if (opportunityType === 'Event') {
            let extraInfo = `${title}-${username}-${convertDate(created_at, 'YYYY-MM-DD')}`;
            const eventName = metadata?.flatMap((item) => (item?.key === 'event_name' ? [item?.value] : []))[0];
            let tags = {
                'Info Session': [`${mailchimpTags.INFO_SESSION_WATCH_NOW_BUTTON_PRESSED}-${extraInfo}`],
                'Assessment Training': [`${mailchimpTags.ASSESSMENT_TRAINING_WATCH_NOW}-${extraInfo}`],
                'Interview Coaching': [`${mailchimpTags.INTERVIEW_COACHING_WATCH_NOW}-${extraInfo}`],
                'Interview Request': [`${mailchimpTags.REQUEST_AN_INTERVIEW}-${extraInfo}`],
            };
            const mailchimpPayload = {
                profileId: getProfileId(),
                data: {
                    email: getEmail(),
                    tags: tags[eventName],
                },
            };
            if (tags[eventName]) {
                await addNewMailChimpTag(mailchimpPayload).catch(() => {});
            }
        }

        if (isScholarshipDeadlinePassed) {
            snackbar.error(`opportunity ${title} is Expired`, { autoHideDuration: 3500 });
            return;
        }

        if (!getSignUpUserId() && !isAuthenticated()) {
            const returnURL = `opportunities?${searchParams.toString()}`;
            navigate(`/signup?returnUrl=${returnURL}`);
            return;
        }

        if (getRole() === 'Student') {
            !is_applied && setOpenConfirmationBox(true);
        } else if (getSubRole() === 'Leader') {
            setOpenConfirmationBox(true);
        }
    }

    function onReviewClicked() {
        navigate(userProfileLink, { state: { from: 'Opportunity' } });
    }
    const handleGoToOpportunity = () => {
        navigate(`/share-opportunity?opportunity=${opportunityId}`, {
            state: { goToOpportunity: opportunityId },
        });
    };

    const addTagsToMailchimpOnLearnMore = () => {
        const opportunityType = metadata?.flatMap((item) => (item?.key === 'opp_type' ? [item?.value] : []))[0];
        if (opportunityType === 'Event') {
            const eventName = metadata?.flatMap((item) => (item?.key === 'event_name' ? [item?.value] : []))[0];
            let extraInfo = `${title}-${username}-${convertDate(created_at, 'YYYY-MM-DD')}`;
            let tags = {
                'Info Session': [`${mailchimpTags.INFO_SESSION_LEARN_MORE_BUTTON_PRESSED}-${extraInfo}`],
                'Assessment Training': [`${mailchimpTags.ASSESSMENT_TRAINING_LEARN_MORE}-${extraInfo}`],
                'Interview Coaching': [`${mailchimpTags.INTERVIEW_COACHING_LEARN_MORE}-${extraInfo}`],
            };
            const mailchimpPayload = {
                profileId: getProfileId(),
                data: {
                    email: getEmail(),
                    tags: tags[eventName],
                },
            };
            if (tags[eventName]) {
                addNewMailChimpTag(mailchimpPayload).catch(() => {});
            }
        }
        onReviewClicked();
    };

    return (
        <>
            <StudentModelConfirmationBox
                title={title}
                handleClose={handleClose}
                openConfirmationBox={openConfirmationBox}
                opportunityId={opportunityId}
                setOpenConfirmationBox={setOpenConfirmationBox}
                question_1={question_1}
                question_2={question_2}
                question_3={question_3}
                handleAppliedToOpportunity={handleAppliedToOpportunity}
                metadata={metadata}
                username={profile?.username}
                date={created_at}
                deadline={deadline}
                percentageCompletion={percentageCompletion}
            />
            <article
                style={{ boxShadow: '0px 4px 3px rgba(0,0,0,0.05)' }}
                className={`relative pt-9 pb-7 pl-4 pr-4 lg:pl-6 lg:pr-12 rounded flex flex-col lg:flex-row my-4 ${
                    isScholarshipDeadlinePassed ? 'bg-white opacity-50' : 'bg-white'
                }`}>
                {isScholarshipDeadlinePassed ? <Badge variant="error" text="Expired" /> : null}

                <div className="flex items-center flex-row lg:flex-col  w-full lg:w-[22%]">
                    <div className="w-full flex lg:flex-col items-center text-left lg:text-center">
                        {getRole() !== 'Sponsor' ? (
                            <Link to={userProfileLink} state={{ from: 'Opportunity' }} className="border-b-0">
                                <img
                                    src={`${profile_photo}`}
                                    alt={`Profile of ${first_name} ${last_name}`}
                                    loading="lazy"
                                    className="object-contain min-w-[80px] h-[80px] lg:w-[150px] lg:h-[150px] rounded-full"
                                />
                            </Link>
                        ) : (
                            <img
                                src={`${profile_photo}`}
                                alt={`Profile of ${first_name} ${last_name}`}
                                loading="lazy"
                                className="object-contain min-w-[80px] h-[80px] lg:w-[150px] lg:h-[150px] rounded-full"
                            />
                        )}
                        <div className="ml-4 lg:ml-0 lg:mt-3 w-48">
                            <h3 className="capitalize font-geometric font-medium text-base text-[#272729]">
                                {getRole() === 'Sponsor' ? (
                                    `${first_name} ${last_name}`
                                ) : (
                                    <Link
                                        to={userProfileLink}
                                        state={{ from: 'Opportunity' }}
                                        title={`${first_name} ${last_name}`}
                                        className="border-b-0 hover:underline">
                                        {first_name} {last_name}
                                    </Link>
                                )}
                            </h3>
                            <h4 className="font-geometric font-medium text-[#464250] text-sm">{organization}</h4>
                            {website &&
                                (getRole() === 'Sponsor' ? (
                                    <p className="text-sm lg:text-base underline border-0 break-words break-all">
                                        {website}
                                    </p>
                                ) : (
                                    <a
                                        href={generateLink(website)}
                                        title={website}
                                        target="_blank"
                                        className="text-sm lg:text-base text-[#4641C0] underline border-0 break-words break-all"
                                        rel="noreferrer">
                                        {website}
                                    </a>
                                ))}
                        </div>
                    </div>
                    {/* <div className="flex justify-end lg:hidden w-full">
                        {file && (
                            <img
                                alt="headshot"
                                src={file}
                                style={{height:'80px', width:'80px'}}
                                className="object-cover rounded-full"
                            />
                        )}
                    </div> */}
                </div>

                <div className="mt-3 lg:mt-0 flex-1 lg:ml-5 text-lg">
                    <div className="lg:flex gap-4">
                        <div className="w-full  lg:w-[78%] ">
                            {metaValue ? (
                                <div className="bg-[#F3E7E4] inline-block px-3 py-1 rounded lg:-ml-2">
                                    <h3 className="text-xs font-semibold font-geometric text-[#A56B5D]">
                                        Looking for {metaValue}
                                    </h3>
                                </div>
                            ) : null}
                            <div>
                                <h3 className="text-lg lg:text-xl font-bold font-geometric text-[#3A3A3F]">{title}</h3>
                            </div>
                        </div>
                        <div className="hidden lg:block w-[20%]">
                            {file && (
                                <img
                                    alt="headshot"
                                    src={file}
                                    className="object-cover rounded-full sm:rounded-md w-full h-auto"
                                />
                            )}
                        </div>
                    </div>

                    <p className="font-geometric text-sm text-[#7d7d7e] font-medium">
                        {organization && `By ${organization}`}
                        {organization && ','} {category}
                    </p>

                    <OpportunityInfoList metadata={metadata} />

                    <p
                        style={{ maxWidth: '100%' }}
                        className="mt-5 font-geometric mb-2  text-sm lg:text-base  max-h-[140px] overflow-x-hidden overflow-y-scroll">
                        {editorDescription}
                    </p>

                    <div className="flex items-center gap-x-5 mt-4">
                        {amount ? (
                            <div className="font-geometric flex gap-x-2 items-center text-sm lg:text-base text-[#3C3C3C]">
                                <h4>Amount:</h4>
                                <p className="text-[#282828] font-medium">
                                    {' '}
                                    {isNaN(amount)
                                        ? formatCurrencyRange(amount)
                                        : `$${formatCurrencyRange(Number(amount).toLocaleString())}`}
                                </p>
                            </div>
                        ) : null}

                        <div className="font-geometric flex gap-x-2 items-center text-sm lg:text-base text-[#3C3C3C]">
                            <h4>Deadline:</h4>
                            <p
                                className={`${
                                    isScholarshipDeadlinePassed ? 'text-[#DA0E0E]' : 'text-[#282828]'
                                } font-medium`}>
                                {deadlineDate}
                            </p>
                        </div>
                    </div>
                    <div className="my-1">
                        <Typography className="font-geometric text-sm font-semibold">Eligibility Criteria:</Typography>
                        {allow_hs_grad_years &&
                            eligibilityComponents(
                                replaceSemicolon(allow_hs_grad_years),
                                `${eligibility_criteria}  For HS Grad Year:`
                            )}
                        {allow_clg_grad_years &&
                            eligibilityComponents(
                                replaceSemicolon(allow_clg_grad_years),
                                `${eligibility_criteria} For College Grad Year:`
                            )}
                        {allow_zipcodes?.length > 0 &&
                            eligibilityComponents(replaceSemicolon(allow_zipcodes), 'Zipcode For:')}
                        {allow_colleges_list?.length > 0 &&
                            eligibilityComponents(matchedCollegeObjects.join(', '), 'College For:')}
                        {allow_high_schools_list?.lenght > 0 &&
                            eligibilityComponents(matchedSchoolObjects.join(', '), 'High School For:')}
                        {!allow_hs_grad_years && !allow_clg_grad_years && !!eligibility_criteria && (
                            <Typography className="font-geometric text-sm font-medium">
                                {eligibility_criteria}
                            </Typography>
                        )}
                    </div>
                    <div className="flex gap-4 lg:justify-end mr-2 mt-4">
                        <div className="flex items-center gap-2">
                            <Button
                                className="border border-[#C54A28] border-solid text-[#C54A28] rounded font-geometric capitalize"
                                onClick={() => addTagsToMailchimpOnLearnMore()}>
                                {sub_role === 'Storefront' ? 'HS Storefront' : 'About Sponsor'}
                            </Button>
                            <Button
                                className="border border-[#C54A28] border-solid text-[#C54A28] rounded font-geometric capitalize"
                                onClick={() => handleGoToOpportunity()}>
                                Learn More
                            </Button>
                            {getRole() === 'Student' ? (
                                <Button
                                    loaderProps={{ sx: { color: 'white' } }}
                                    loading={isLoading}
                                    disabled={
                                        sub_role === 'Storefront'
                                            ? storeFrontHighSchoolMatched || buttonText === 'Applied'
                                            : isScholarshipDeadlinePassed ||
                                              disabledForCampusAfterApplied ||
                                              intervieRequestEventApplied ||
                                              buttonText === 'Applied'
                                    }
                                    variant="contained"
                                    className={`${
                                        is_applied || isScholarshipDeadlinePassed || storeFrontHighSchoolMatched
                                            ? toApplyValue === 'Watch Again'
                                                ? 'bg-secondary-lighter'
                                                : ''
                                            : 'bg-[#C54A28]'
                                    }  font-geometric capitalize rounded`}
                                    onClick={onApplyClicked}>
                                    {buttonText}
                                </Button>
                            ) : (
                                <Button
                                    loaderProps={{ sx: { color: 'white' } }}
                                    loading={isLoading}
                                    variant="contained"
                                    className={`bg-[#C54A28] font-geometric capitalize rounded`}
                                    onClick={onApplyClicked}>
                                    Apply Now
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </article>
            <InformationDialog title={title} open={openDialog} setOpen={setOpenDialog} />
        </>
    );
}
