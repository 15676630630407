const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    viewsTrack: [],
    rolesLookUp: {},
    allUsers: [],
    roleList: [],
    pagesList: [],
    permissionRoleList: [],
    approvalRequestList:[]
};

const adminSlice = createSlice({
    name: 'admin slice',
    initialState,
    reducers: {
        setViewsTrack: (state, action) => {
            state.viewsTrack = action.payload;
        },
        setRolesLookUp: (state, action) => {
            state.rolesLookUp = action.payload;
        },
        setAllUsers: (state, action) => {
            state.allUsers = action.payload;
        },
        setPagesList: (state, action) => {
            state.pagesList = action.payload;
        },
        setRoleList: (state, action) => {
            state.roleList = action.payload;
        },
        setPermissionRoleList: (state, action) => {
            state.permissionRoleList = action.payload;
        },
        setApprovalRequestList: (state, action) => {
            state.approvalRequestList = action.payload;
        },
    },
});

export const { setViewsTrack, setRolesLookUp, setAllUsers, setPagesList, setRoleList, setPermissionRoleList,setApprovalRequestList } =
    adminSlice.actions;
export default adminSlice.reducer;
