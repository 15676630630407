import moment from 'moment';
import * as XLSX from 'xlsx';
import { generateRange } from '../services/core/utils';
import { MAPPED_OPPORTUNITY_VALUES, META_KEYS, Roles, SPONSOR_SEARCH_MAPPED_OPPORTUNITIES } from './GetConstants';
//This method will convert date
export const convertDate = (timestamp, format = 'MM-DD-YYYY') => {
    if (!timestamp) return '-';
    return moment.unix(timestamp).format(format);
};

export function generateLink(link) {
    return link?.includes('http') ? link : `https://${link}`;
}

export function getOpportunityListSearchParams(searchParams, searchType = Roles.Student, status) {
    let searchQuery = new URLSearchParams();
    for (let searchParam of searchParams.entries()) {
        const [key, value] = searchParam;
        const param =
            key === META_KEYS.SPONSORSHIP_TYPE
                ? undefined
                : searchType === Roles.Student
                ? MAPPED_OPPORTUNITY_VALUES[value]
                : SPONSOR_SEARCH_MAPPED_OPPORTUNITIES[value];
        searchQuery.append(key, param || value);
    }
    if (status) searchQuery.append('status', status);
    return searchQuery;
}

export function generateSearchParams(searchFilters, searchParams, excludeSponsor) {
    let searchFilterParams = {};
    if (searchParams?.has('sponsor') && !excludeSponsor) {
        searchFilterParams['sponsor'] = searchParams.get('sponsor');
    }
    searchFilters.forEach(({ key, value }) => {
        let searchValue = value;
        let searchKey = key;
        if (key === META_KEYS.LOCATION) {
            searchValue = value.zipcode;
            searchKey = 'zipcode';
        }
        if (
            key === META_KEYS.COLLEGE_FIELDS_CAT ||
            key === META_KEYS.COLLEGE_FIELDS_SUBJECT ||
            key === META_KEYS.COLLEGE_LIST
        ) {
            searchValue = value.value;
        }
        if (key === META_KEYS.SPONSORSHIP_TYPE) {
            searchValue = value.toString();
        }
        searchFilterParams[searchKey] = searchValue;
    });
    return searchFilterParams;
}

function findMetaDataFromKey(metadata = [], metaKey, searchType = 'key') {
    const metaDataRow = metadata.find(({ key, parent }) => {
        if (searchType === 'key') {
            return key === metaKey;
        }
        return parent === metaKey;
    });
    return metaDataRow;
}

const parentKeySearchValues = ['Recruits', 'Recruitment', 'Grad Assistant', 'Grad Assistantship'];
export function getSubMetaDataValue(metadata, opportunityType, SUB_METADATA_KEYS, excludedMetaOppType) {
    if (excludedMetaOppType.includes(opportunityType)) {
        return { value: opportunityType, keys: [] };
    }
    const metaKey = SUB_METADATA_KEYS[opportunityType];
    const keySearchType = parentKeySearchValues.includes(opportunityType) ? 'parent' : 'key';
    let selectedMetaData = findMetaDataFromKey(metadata, metaKey, keySearchType, opportunityType);

    if (parentKeySearchValues.includes(opportunityType) && !selectedMetaData) {
        selectedMetaData = findMetaDataFromKey(metadata, metaKey, 'key', opportunityType);
    }

    if (
        selectedMetaData &&
        selectedMetaData.key === META_KEYS.EMP_TYPE &&
        !excludedMetaOppType.includes(selectedMetaData.value)
    ) {
        const selectedEmployeeKey = SUB_METADATA_KEYS[selectedMetaData.value];
        const keySearchType = selectedEmployeeKey === META_KEYS.PVT_LESSON_TYPE ? 'parent' : 'key';
        const employeeValue = findMetaDataFromKey(metadata, selectedEmployeeKey, keySearchType, opportunityType);
        if (employeeValue) {
            return {
                value: `${selectedMetaData.value} ${employeeValue.value ? ` - ${employeeValue.value}` : ''}`,
                keys: [selectedMetaData.key, employeeValue.key],
            };
        }
    }

    const finalValue = `${opportunityType} ${selectedMetaData?.value ? ` - ${selectedMetaData.value}` : ''}`;
    const finalKey = selectedMetaData?.key || META_KEYS.OPP_TYPE;

    return { value: finalValue, keys: [finalKey] };
}

export function getFilteredHoverData(data, keys) {
    const filteredData = data.filter(({ key }) => {
        return !(key === META_KEYS.OPP_TYPE || keys.includes(key));
    });
    return filteredData;
}

export async function copyToClipboard(text) {
    if (!navigator.clipboard) {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.append(textArea);
        textArea.select();
        document.execCommand('copy');
        textArea.remove();
        return true;
    }

    try {
        await navigator.clipboard.writeText(text);
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export const getTimeFromUTC = (timestamp) => {
    return moment.unix(timestamp).format('hh:mm a');
};

//function return date in format eg. feb 23rd, 23
export const dateWithMonthStr = (timestamp) => moment.unix(timestamp).format('MMMM D, YYYY');

export const lastSeenDate = (timestamp) => {
    return moment.unix(timestamp).fromNow();
};

const checkValue = (obj, value) => {
    if (typeof obj !== 'object' || obj === null) {
        return String(obj).toLowerCase().includes(value.toLowerCase());
    }
    if (Array.isArray(obj)) {
        return obj.some((item) => checkValue(item, value));
    }
    return Object.keys(obj).some((key) => checkValue(obj[key], value));
};

//to apply filter on array of object
export const applyFilter = (data, searchArray) => {
    return data.filter((obj) => searchArray.some((value) => checkValue(obj, value)));
};

export const isEmptyObject = (obj) => Object.values(obj).length === 0;

export const dashForEmptyData = (data) => (!data ? '-' : data);

export const firstLetterOfLastName = (fullName) => {
    const nameParts = fullName.split(' ');
    const firstName = nameParts[0];
    const middleInitial = nameParts[1]?.charAt(0);
    const lastInitial = nameParts[2]?.charAt(0);
    const displayName = `${firstName} ${middleInitial}. ${lastInitial ? `${lastInitial}.` : ''}`;
    return displayName;
};

export const removeHTMLTags = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent;
};

export const bytesToMegaBytes = (size) => {
    return size / 1024 / 1024;
};

export const tableAveScoreAndFinancialFunc = (data) => {
    const {
        financial_details,
        test_scores: TestScore,
        college_loc_type,
        college_size,
        college_type,
        degree_type,
        religious_affiliation,
        study_fields,
        enrollment_count,
    } = data || {};
    const {
        avg_attendance_cost,
        book_supplies,
        instate_tuition_fees,
        outstate_tuition_fees,
        room_cost_offcampus,
        room_cost_oncampus,
        avg_net_price,
    } = financial_details || {};

    const FinancialsData = [
        {
            value: 'Average Net Price:',
            score: avg_net_price,
        },
        {
            value: 'Average Cost of Attendance:',
            score: avg_attendance_cost,
        },
        {
            value: 'Institute Tuition and Fees:',
            score: instate_tuition_fees,
        },
        {
            value: 'Out of State Tuition and Fees:',
            score: outstate_tuition_fees,
        },
        {
            value: 'Cost of Attendance Room and Board - On Campus:',
            score: room_cost_oncampus,
        },
        {
            value: 'Cost of Attendance Room and Board - Off Campus:',
            score: room_cost_offcampus,
        },
        {
            value: 'Books & Supplies:',
            score: book_supplies,
        },
    ];

    const SATScore = [
        {
            value: 'Average SAT Score:',
            score: TestScore?.sat_avg_score,
        },
        {
            value: 'Reading 50th Percentile:',
            score: TestScore?.sat_reading_50th,
            val: {
                value: '75th Percentile:',
                score: TestScore?.sat_reading_75th,
            },
        },
        {
            value: 'Math 50th Percentile:',
            score: TestScore?.sat_math_50th,
            val: {
                value: '75th Percentile:',
                score: TestScore?.sat_math_75th,
            },
        },
        {
            value: 'Written 50th Percentile:',
            score: TestScore?.sat_written_50th,
            val: {
                value: '75th Percentile:',
                score: TestScore?.sat_written_75th,
            },
        },
    ];

    const APTScore = [
        {
            value: 'Average ACT Score:',
            score: TestScore?.act_avg_score,
        },
        {
            value: 'Reading 50th Percentile:',
            score: TestScore?.act_english_50th,
            val: {
                value: '75th Percentile:',
                score: TestScore?.act_english_75th,
            },
        },
        {
            value: 'Math 50th Percentile:',
            score: TestScore?.act_math_50th,
            val: {
                value: '75th Percentile:',
                score: TestScore?.act_math_75th,
            },
        },
        {
            value: 'Written 50th Percentile:',
            score: TestScore?.act_written_50th,
            val: {
                value: '75th Percentile:',
                score: TestScore?.act_written_75th,
            },
        },
    ];

    const categoryData = [
        {
            type: 'College Type',
            value: college_type,
        },
        {
            type: 'Degree Type',
            value: degree_type,
        },
        {
            type: 'Location Type',
            value: college_loc_type,
        },
        {
            type: 'Institute Size',
            value: college_size,
        },
        {
            type: 'Enrollment',
            value: formatToNumber(enrollment_count),
        },
        {
            type: 'Avg SAT Score',
            value: TestScore?.sat_avg_score,
        },
        {
            type: 'Avg ACT Score',
            value: TestScore?.act_avg_score,
        },
        {
            type: 'Avg Net Price',
            value: formatToUSMoney(avg_net_price),
        },
        {
            type: 'Religious Affiliation',
            value: religious_affiliation,
        },
    ];

    const StudyField = study_fields?.sort();

    return { FinancialsData: FinancialsData, APTScore: APTScore, SATScore: SATScore, categoryData, StudyField };
};

export const EmbedRegex = /^<iframe.+?src="https:\/\/www\.youtube\.com\/embed\/([A-Za-z0-9_-]+)".+?<\/iframe>$/;
export const spotifyEmbedRegex = /src="([^"]+)"/;
export const getCountReaction = (data) => {
    let countFunny = 0;
    let countLove = 0;
    let countLike = 0;

    data.forEach((item) => {
        if (item.reaction === 'funny') {
            countFunny++;
        } else if (item.reaction === 'love') {
            countLove++;
        } else {
            countLike++;
        }
    });
    return { countFunny, countLove, countLike };
};

export const formatToUSMoney = (numberString) => {
    // Convert the number string to a number
    const number = parseFloat(numberString);
    if (isNaN(number)) {
        return null;
    }
    const formattedMoney = number.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    return formattedMoney;
};

export const formatToNumber = (numberString) => {
    const number = parseFloat(numberString);
    if (isNaN(number)) {
        return null;
    }
    const formattedMoney = number.toLocaleString('en-US');
    return formattedMoney;
};

export const dummyData = [
    {
        id: 1,
        title: 'Coming Soon',
    },
];

export const dummyObjectData = {
    title: 'Coming Soon',
};

export const objectDataApply1 = {
    title: 'Paid Summer Internship',
    subTitle: 'College Engineering Majors',
    announce: 'Coming Soon: July 2024',
};

export const objectDataApply2 = {
    title: 'Full-Time Employment',
    subTitle: 'Baby Care Engineer',
    announce: 'Coming Soon:  July 2024',
};

export const objectDataApply3 = {
    title: 'Full-Time Employment',
    subTitle: 'Manufacturing Process Engineer',
    announce: 'Coming Soon:  July 2024',
};

export const getOpportunitesFilterData = (virtualEvents, fullTimeOPP) => {
    const filterForExplore = [];
    const filterForPrepare = [];
    const filterForApply = [];
    const filterForInterview = [];

    const filterInfoAndTestimonial = virtualEvents?.find((event) => event.event_name === 'Info Session');
    const filterInteviewAndAssessment = virtualEvents?.find((event) => event.event_name === 'Interview Coaching');
    const filterAssessment = virtualEvents?.find((event) => event.event_name === 'Assessment Training');
    const filterInterview = virtualEvents?.find((event) => event.event_name === 'Interview Request');
    const filterTestimonial = virtualEvents?.find((event) => event.event_name === 'Testimonials');
    const fullTime = virtualEvents
        ?.filter(({ event_name, event_type }) => (event_name === null) & (event_type === null))
        .slice(0, 3);

    filterForExplore.push(
        // ...(filterInfoAndTestimonial ? [filterInfoAndTestimonial] : dummyData),
        ...(filterTestimonial ? [filterTestimonial] : dummyData)
    );
    filterForPrepare.push(
        ...(filterAssessment ? [filterAssessment] : dummyData),
        ...(filterInteviewAndAssessment ? [filterInteviewAndAssessment] : dummyData)
    );
    filterForInterview.push(...(filterInterview ? [filterInterview] : dummyData));
    if (fullTime?.length === 3) {
        filterForApply.push(...fullTime);
    } else if (fullTime?.length === 1) {
        filterForApply.push(...fullTime, objectDataApply1, objectDataApply2);
    } else if (fullTime?.length === 2) {
        filterForApply.push(...fullTime, objectDataApply1);
    } else {
        filterForApply.push(objectDataApply1, objectDataApply2, objectDataApply3);
    }
    return { filterForExplore, filterForPrepare, filterForApply, filterForInterview };
};

export const virtualFunction = (applied, datePassed) => {
    if (applied && (datePassed || !datePassed)) {
        return 'Watch Again';
    } else if (!applied && datePassed) {
        return 'Watch Now';
    } else {
        return 'Register Now';
    }
};

export const getEventFun = (val) => {
    const eventMap = {
        'Info Session': 'Register Now',
        'Interview Request': 'Register Now',
        Testimonials: 'Watch Now',
        'Assessment Training': 'Watch Now',
        'Interview Coaching': 'Watch Now',
    };

    return eventMap[val] || '';
};

export const getEventAppliedFun = (val) => {
    const eventMap = {
        'Info Session': 'Registered',
        'Interview Request': 'Registered',
        Testimonials: 'Watched',
        'Assessment Training': 'Watched',
        'Interview Coaching': 'Watched',
    };

    return eventMap[val] || '';
};

export const getPopupBoxTitle = (val) => {
    const eventMap = {
        'Info Session': 'Confirm Your Registration',
        'Interview Request': 'Confirm Your Registration',
        Testimonials: 'Confirm Your Request',
        'Assessment Training': 'Confirm Your Request',
        'Interview Coaching': 'Confirm Your Request',
    };

    return eventMap[val] || '';
};

export function DescriptionTrim({ text, maxLength }) {
    if (text?.length <= maxLength) {
        return <p>{text}</p>;
    }
    const trimmedText = text?.slice(0, maxLength) + '...';
    return <p>{trimmedText}</p>;
}

export const generateGraduationYearRange = (year, rangYear = 14) => {
    return [].concat(
        generateRange(rangYear)
            .slice(1)
            .reverse()
            .map((n) => -n),
        generateRange(year)
    );
};

export function isFormValid(formData) {
    for (const value of Object.values(formData)) {
        if (typeof value === 'string' && value.trim() === '') {
            return false;
        }
    }
    return true;
}

function normalizeDateToMidnight(date) {
    date.setHours(0, 0, 0, 0);
    return date;
}

export function compareDates(epochTimestamp) {
    let currentDate = new Date();
    const currentDateValue = normalizeDateToMidnight(currentDate);
    let backendDate = new Date(epochTimestamp * 1000);
    const backendDateValue = normalizeDateToMidnight(backendDate);
    return { currentDate: currentDateValue, comapareDate: backendDateValue };
}

export function mandatoryField(val) {
    return (
        <>
            {val}
            <span style={{ marginLeft: 2, color: 'red' }}>*</span>
        </>
    );
}

const formatCurrency = (value) => {
    const cleanValue = value.replace(/[^0-9,]/g, '');
    const numericValue = cleanValue.replace(/,/g, '');
    const intValue = parseInt(numericValue, 10);
    if (isNaN(intValue)) {
        return '';
    }
    return intValue.toLocaleString('en-US');
};

export const formatCurrencyRange = (value) => {
    const parts = value.split('-');
    if (parts.length === 2) {
        const start = formatCurrency(parts[0]);
        const end = formatCurrency(parts[1]);
        return `${start}-${end}`;
    } else if (parts.length === 1) {
        return formatCurrency(parts[0]);
    }
    return value;
};

export const addDaysToCurrentDate = (days) => {
    const currentDate = new Date();
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + days);
    return newDate;
};

export const daysFromCurrentToFutureDate = (epochTimestamp) => {
    const futureDate = new Date(epochTimestamp * 1000);
    const currentDate = new Date();
    const timeDifference = futureDate.getTime() - currentDate.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
    return daysDifference;
};

export const isFutureDate = (targetEpochTimestamp) => {
    const currentEpochTimestamp = Math.floor(Date.now() / 1000);
    return targetEpochTimestamp > currentEpochTimestamp;
};

export const getDifferenceInDays = (futureEpochTimestamp) => {
    const currentEpochTimestamp = Math.floor(Date.now() / 1000);

    const futureDate = new Date(futureEpochTimestamp * 1000);

    const currentDate = new Date(currentEpochTimestamp * 1000);

    const timeDifference = futureDate.getTime() - currentDate.getTime();

    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
    return daysDifference;
};

export const downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.download = 'data.xlsx';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
};
