import React, { useRef } from 'react';
// import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';
import Cropper from 'react-cropper';
import Button from '../Button';

const ImageCropper = ({ imgSrc, saveMedia, onUpload, config }) => {
    const cropperRef = useRef();

    const handleClose = () => {
        if (!cropperRef.current) {
            return;
        }
        cropperRef.current.getCroppedCanvas().toBlob((blob) => {
            const file = new File([blob], 'image.png');
            saveMedia(file);
            onUpload();
        });
    };

    return (
        <div className="w-3/4 h-1/4 sm:w-full sm:h-1/2 p-2">
            <Cropper
                {...config}
                className="h-105 sm:w-115 w-[300px]"
                src={URL.createObjectURL(imgSrc)}
                onInitialized={(instance) => {
                    cropperRef.current = instance;
                }}
            />
            <div className="flex justify-end">
                <Button className="mt-4" variant="contained" onClick={handleClose}>
                    Upload
                </Button>
            </div>
        </div>
    );
};

export default ImageCropper;
