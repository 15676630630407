import { outlinedInputClasses } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import moment from 'moment';
import * as React from 'react';
import Label from './Label';

export default function MuiDate({
    limitionValue = false,
    value,
    onChange,
    label,
    defaultValue,
    variant = 'DatePicker',
    disabled = false,
    helperMessage = '',
    bottomMargin = false,
    disablePast = false,
}) {
    const currentDate = new Date();
    const fourteenYearsAgo = new Date(currentDate.getFullYear() - 14, currentDate.getMonth(), currentDate.getDate());
    return (
        <>
            <Label bottomMargin={bottomMargin} value={label} />
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DemoContainer components={['DatePicker', 'DateTimePicker']}>
                    {variant === 'DatePicker' && (
                        <DatePicker
                            label={null}
                            disablePast={disablePast}
                            value={value ? moment(value) : null}
                            defaultValue={defaultValue ? moment(defaultValue) : null}
                            onChange={onChange}
                            sx={{
                                width: '100%',
                                [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
                                    borderColor: '#103B58',
                                    border: '2px solid #103B58',
                                },
                                [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
                                    borderColor: '#103B58',
                                },
                                [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
                                    {
                                        borderColor: '#38A4D0',
                                    },
                            }}
                            disabled={disabled}
                            maxDate={limitionValue ? moment(fourteenYearsAgo) : false}
                        />
                    )}
                    {variant === 'DateTimePicker' && (
                        <DateTimePicker
                            label={null}
                            viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                            }}
                            defaultValue={moment(defaultValue)}
                            onChange={onChange}
                            value={value ? moment(value) : null}
                            sx={{
                                [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
                                    borderColor: '#103B58',
                                    border: '2px solid #103B58',
                                },
                                [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
                                    borderColor: '#103B58',
                                },
                                [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
                                    {
                                        borderColor: '#38A4D0',
                                    },
                            }}
                        />
                    )}
                </DemoContainer>
            </LocalizationProvider>
            {helperMessage && <Label flavor="helper" value={helperMessage} />}
        </>
    );
}
