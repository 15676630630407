import PaginationItem, { paginationItemClasses } from '@mui/material/PaginationItem';
import Pagination, { paginationClasses } from '@mui/material/Pagination';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const StyledPagination = styled(Pagination)(() => ({
    [`&.${paginationClasses.root}`]: {
        marginBlock: 20,
    },
    [`& > .${paginationClasses.ul}`]: {
        justifyContent: 'center',
    },
}));

export function SearchPagination({ page, totalPages = 0, onPageChange }) {
    if (totalPages <= 0) {
        return null;
    }

    return (
        <StyledPagination
            count={totalPages}
            page={page}
            className="font-geometric"
            onChange={onPageChange}
            renderItem={(item) => (
                <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                    sx={{
                        [`&.${paginationItemClasses.selected}`]: {
                            backgroundColor: '#C54A28',
                            color: 'white',
                            fontWeight: 'bold',
                        },
                    }}
                />
            )}
        />
    );
}
