/* eslint-disable import/no-anonymous-default-export */

import { base64Decode, base64Encode } from '../../core/crypto';

const AUTH_TOKEN_KEY = base64Encode('__t');
const USER_ID_KEY = base64Encode('__uid');
const PROFILE_ID_KEY = base64Encode('__pid');
const ROLE_KEY = base64Encode('__r');
const SUB_ROLE_KEY = base64Encode('__sr');
const EMAIL_KEY = base64Encode('__e');
const FIRST_NAME_KEY = base64Encode('__fn');
const LAST_NAME_KEY = base64Encode('__ln');
const ONBOARDING_STATUS_KEY = base64Encode('__os');
const USER_NAME_KEY = base64Encode('__un');
const STUDENT_LEVEL = base64Encode('__sl');
const DOB = base64Encode('__dob');
const MOBILE = base64Encode('__mob');
const SIGNUPID = base64Encode('__sni');
const SHOW_FEEDBACK = base64Encode('__sff');
const ASK_SUB = base64Encode('__ask_sub');
const SUB_PLAN = base64Encode('__sub_plan');
const SUB_EXP = base64Encode('__sub_exp');

const DES = base64Encode('__des');

const encodeAndSet = (key, val) => window.localStorage.setItem(key, base64Encode(val));

const decodeAndGet = (key) => {
    const item = window.localStorage.getItem(key);
    return item !== null ? base64Decode(item) : item;
};

export const clear = () => window.localStorage.clear();

export const remove = (key) => window.localStorage.removeItem(key);

export const setAuthToken = (val) => encodeAndSet(AUTH_TOKEN_KEY, val);
export const getAuthToken = () => decodeAndGet(AUTH_TOKEN_KEY);

export const setUserId = (val) => encodeAndSet(USER_ID_KEY, val);
export const getUserId = () => decodeAndGet(USER_ID_KEY);

export const setProfileId = (val) => encodeAndSet(PROFILE_ID_KEY, val);
export const getProfileId = () => decodeAndGet(PROFILE_ID_KEY);

export const setRole = (val) => encodeAndSet(ROLE_KEY, val);
export const getRole = () => decodeAndGet(ROLE_KEY);
export const setSubRole = (val) => encodeAndSet(SUB_ROLE_KEY, val);
export const getSubRole = () => decodeAndGet(SUB_ROLE_KEY);

export const setEmail = (val) => encodeAndSet(EMAIL_KEY, val);
export const getEmail = () => decodeAndGet(EMAIL_KEY);

export const setFirstName = (val) => encodeAndSet(FIRST_NAME_KEY, val);
export const getFirstName = () => decodeAndGet(FIRST_NAME_KEY);

export const setLastName = (val) => encodeAndSet(LAST_NAME_KEY, val);
export const getLastName = () => decodeAndGet(LAST_NAME_KEY);

export const setOnboardingStatus = (val) => encodeAndSet(ONBOARDING_STATUS_KEY, val);
export const getOnboardingStatus = () => decodeAndGet(ONBOARDING_STATUS_KEY);

export const setUserName = (val) => encodeAndSet(USER_NAME_KEY, val);
export const getUserName = () => decodeAndGet(USER_NAME_KEY);

export const setStudentLevel = (val) => encodeAndSet(STUDENT_LEVEL, val);
export const getStudentLevel = () => decodeAndGet(STUDENT_LEVEL);

export const setDateOfBirth = (val) => encodeAndSet(DOB, val);
export const getDateOfBirth = () => decodeAndGet(DOB);

export const setMobileNo = (val) => encodeAndSet(MOBILE, val);
export const getMobileNo = () => decodeAndGet(MOBILE);

export const setSignUpUserId = (val) => encodeAndSet(SIGNUPID, val);
export const getSignUpUserId = () => decodeAndGet(SIGNUPID);

export const setShowFeedback = (val) => encodeAndSet(SHOW_FEEDBACK, val);
export const getShowFeedback = () => decodeAndGet(SHOW_FEEDBACK);

export const setAskForSubscription = (val) => encodeAndSet(ASK_SUB, val);
export const getAskForSubscription = () => decodeAndGet(ASK_SUB);

export const setSubscriptionPlan = (val) => encodeAndSet(SUB_PLAN, val);
export const getSubscriptionPlan = () => decodeAndGet(SUB_PLAN);

export const setSubscriptionExpireDate = (val) => encodeAndSet(SUB_EXP, val);
export const getSubscriptionExpireDate = () => decodeAndGet(SUB_EXP);

export const setDescription = (val) => encodeAndSet(DES, val);
export const getDescription = () => decodeAndGet(DES);

export default {
    clear,
    remove,
    setAuthToken,
    getAuthToken,
    setUserId,
    getUserId,
    setProfileId,
    getProfileId,
    setRole,
    getRole,
    setEmail,
    getEmail,
    setFirstName,
    getFirstName,
    setLastName,
    getLastName,
    setOnboardingStatus,
    getOnboardingStatus,
    setUserName,
    getUserName,
    setStudentLevel,
    getStudentLevel,
    setDateOfBirth,
    getDateOfBirth,
    setMobileNo,
    getMobileNo,
    getSignUpUserId,
    setSignUpUserId,
    setSubRole,
    getSubRole,
    setAskForSubscription,
    getAskForSubscription,
    setDescription,
    getDescription,
    getSubscriptionExpireDate,
    setSubscriptionExpireDate,
    getSubscriptionPlan,
    setSubscriptionPlan,
};
