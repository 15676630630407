import { Button, Card, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/Auth';
import { useSnackbar } from '../../../hooks';
import { getSubscriptionActivation, showPaymentForm, updateSubcriptionModelRequest } from '../../../services/app/api';
import { formatToUSMoney } from '../../../utils/GenericFunctions';
import * as labelConst from '../../../utils/GetLabels';
import BlockingLoader from '../../UI/BlockingLoader';
import MuiModal from '../../UI/MuiModal';
import SubscriptionDialogBox from '../../UI/SubscriptionModel/SubscriptionDialogBox';

const AskForPayment = ({ applicantData, oppo_type, showPaymentOption }) => {
    const [loading, setLoading] = useState(false);
    const auth = useAuth();
    const snackbar = useSnackbar();
    const [openBox, setOpenBox] = useState(false);
    const [openSubscribeBox, setOpenSubscribeBox] = useState(false);
    const [data, setData] = useState({});
    const { activated, expired_at, plan } = data || {};

    const openPaymentForm = () => {
        setLoading(true);
        showPaymentForm({ user_id: auth?.getProfileId(), sponsorship_id: applicantData?.id })
            .then((res) => {
                localStorage.setItem('redirectTo', window.location.href);
                window.open(res?.data?.data?.url, '_self');
            })
            .catch(() => {
                snackbar.error('Failed to open payment form');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const subscribedPlan = () => {
        updateSubcriptionModelRequest({
            userId: auth?.getProfileId(),
            payload: {
                plan: 'Your29 Plan',
            },
        })
            .then(() => {
                snackbar.success(labelConst.SUBSCRIPTION_CONFIRMATION_MESSAGE, { autoHideDuration: 10000 });
                subscriptionActivation();
                setOpenBox(false);
                setOpenSubscribeBox(false);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {});
    };

    const openSubscriptionBox = () => {
        if(activated === false){
            snackbar.success(`${labelConst.ALREADY_SUBSCRIPTION_REQUESTED}`, { autoHideDuration: 10000 });   
        }else{
            setOpenSubscribeBox(true);
        }
    };

    const openConfirmationBox = () => {
        if (activated === null || activated === true) {
            setOpenBox(true);
        } else {
            snackbar.success(`${labelConst.ALREADY_SUBSCRIPTION_REQUESTED}`, { autoHideDuration: 10000 });
        }
    };

    useEffect(() => {
        auth?.getProfileId() && auth?.getRole() === 'Sponsor' && subscriptionActivation();
    }, [auth?.getProfileId()]);

    const subscriptionActivation = () => {
        getSubscriptionActivation({ params: auth?.getProfileId() })
            .then((res) => setData(res?.data?.data))
            .catch((error) => console.log(error));
    };

    return (
        <>
            <MuiModal
                handleClose={() => setOpenBox(false)}
                handleConfirm={subscribedPlan}
                open={openBox}
                title={'Confirm Subscription'}
                message={'Please click the Confirm button below to request a Your29 subscription.'}
            />
            <SubscriptionDialogBox
                openTo={openSubscribeBox}
                close={() => setOpenSubscribeBox(false)}
                handleSubscribe={openConfirmationBox}
                disabled={activated === false}
            />

            <Paper
                className="flex flex-col sm:flex-row justify-between gap-5"
                elevation={2}
                sx={{ padding: 5, margin: '25px auto', borderRadius: '8px' }}>
                <BlockingLoader loading={loading} />
                {applicantData?.applicants_count > 0 && (
                    <div className="flex flex-col justify-center items-center max-w-md">
                        <p className="text-center text-3xl font-semibold text-primary font-geometric">
                            You have {applicantData?.applicants_count || 0} new applicant!
                        </p>
                        <p className="text-center text-md mt-4 text-primary font-geometric">
                            To review these applicants and more in the future, pay the posting fee or subscribe to
                            Your29 now.
                        </p>
                    </div>
                )}
                {showPaymentOption && (
                    <Card className="max-w-sm p-3 flex justify-between flex-col">
                        <div className="m-auto">
                            <p className="text-center text-xl mt-4 text-primary font-geometric">
                                To reveal your current and future applicants for this opportunity, pay the posting fee
                                now.
                            </p>
                            {/* <p className="text-center text-xl mt-4 text-primary font-geometric">
                                You have {applicantData?.applicants_count || 0} new applicant!
                            </p> */}
                            <p className="text-2xl text-center my-5 ">
                                Total amount to pay:{' '}
                                {applicantData?.fee_amount ? formatToUSMoney(applicantData?.fee_amount) : 0}{' '}
                            </p>
                        </div>
                        <div className="m-auto w-60">
                            <Button
                                onClick={openPaymentForm}
                                className=""
                                variant="contained"
                                color="secondary"
                                fullWidth>
                                Pay Now
                            </Button>
                        </div>
                    </Card>
                )}
                {auth?.getRole() === 'Sponsor' && (
                    <Card className="w-md p-3">
                        <p className="text-center text-3xl font-semibold text-primary font-geometric">
                            Get Your29 Plan
                        </p>
                        <p className="text-center font-medium text-primary font-geometric max-w-md">
                            Subscribe now to reveal current and future applicants and gain full access to the platform.
                        </p>
                        <div className="container flex justify-center">
                            <div className="flex flex-col my-5">
                                {labelConst.YOUR29_PLAN_SUBSCRIPTION_POINT?.map((item) => {
                                    return (
                                        <div
                                            variant="body2"
                                            color="text.secondary"
                                            className="text-gray-600 leading-tight text-left">
                                            <span className="text-13">{item}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="container flex justify-center">
                            <Button
                                onClick={openSubscriptionBox}
                                variant="contained"
                                disabled={activated === false}
                                color="primary"
                                className='w-60 py-2 px-3'
                                fullWidth>
                               {activated === false ? 'Pending Request':'Subscribe'} 
                            </Button>
                        </div>
                    </Card>
                )}
            </Paper>
        </>
    );
};

export default AskForPayment;
