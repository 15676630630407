/* eslint-disable import/no-anonymous-default-export */

export const isString = (val) => typeof val === 'string';

export const isNumber = (val) => typeof val === 'number';

// Check if a string is a valid number
// https://stackoverflow.com/questions/175739/how-can-i-check-if-a-string-is-a-valid-number
export const isNumeric = (val) => {
    if (typeof val != 'string') return false;
    return !isNaN(val) && !isNaN(parseFloat(val));
};

export const isBoolean = (val) => typeof val === 'boolean';

export const isArray = (val) => Array.isArray(val);

export const isObject = (val) => typeof val === 'object' && !isArray(val);

export const isFunction = (val) => typeof val === 'function';

export const isJson = (val) => {
    try {
        JSON.parse(val);
    } catch (e) {
        return false;
    }
    return true;
};

export const isFileObject = (file) => file instanceof File;

export const toArray = (val) => {
    return val === undefined || val === null ? [] : isArray(val) ? val : [val];
};

export const toString = (val) => {
    return isString(val) ? val : '';
};

export function isUrl(val) {
    let url;

    try {
        url = new URL(val);
    } catch (_) {
        return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
}

export default {
    isString,
    isNumber,
    isBoolean,
    isObject,
    isArray,
    isFunction,
    isJson,
    isFileObject,
    toArray,
    toString,
    isUrl,
};
