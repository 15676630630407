import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import BlockingLoader from '../UI/BlockingLoader';
import IconButton from '../UI/IconButton';
import Typography from '../UI/Typography';
import { getOrganizationDesc as getOrganizationDescApi } from '../../services/app/api';
import { ORG_PLACEHOLDER } from '../../utils/GetLabels';
import ReadMore from '../Common/ReadMore';
import EditIcon from '../../images/edit-icon';
import * as labelConst from '../../utils/GetLabels';

const orgPlaceholder =
    'Tell us more about the organization you represent. This should include the name of the organization, the type and when it was founded. Then a sentence or two about what you offer your clients/customers, and anything that makes you different (this could be something you specialize in). If you organization has a goal or a mission, you can include that as well. Then last but not least, include the website or any contact details where clients/customers can learn more. ';

const Organization = ({ id, selected, editMode }) => {
    const [loading, setLoading] = useState(false);
    const [organisation, setOrganisation] = useState('');

    const load = () => {
        getOrganizationDescApi({ id }).then((resp) => {
            setOrganisation(resp.data?.data?.organization_description || '');
        });
    };

    useEffect(() => {
        load();
    }, [id]);

    const renderHeader = () => {
        return (
            <div className="flex justify-between">
                <Typography
                    className={`font-geometric font-extrabold  text-base sm:text-[32px] ${
                        selected ? 'text-secondary' : 'text-primary'
                    }`}>
                    {labelConst.ORGANIZATION} :
                </Typography>
                {editMode && (
                    <IconButton
                        title="Edit your organization"
                        component={Link}
                        to={`organization`}
                        className="bg-[#EDEDED] p-2 group">
                        <EditIcon />
                    </IconButton>
                )}
            </div>
        );
    };

    const renderOrg = () => {
        return (
            <>
                <Typography
                    className={[
                        'sm:mt-4 mt-2 whitespace-pre-line font-geometric sm:text-xl',
                        organisation ? 'text-left' : 'text-center',
                    ].join(' ')}>
                    {editMode ? (
                        !isMobile ? (
                            organisation || ORG_PLACEHOLDER
                        ) : organisation ? (
                            <ReadMore length={250} text={organisation} />
                        ) : (
                            ORG_PLACEHOLDER
                        )
                    ) : !isMobile ? (
                        organisation
                    ) : (
                        <ReadMore length={250} text={organisation} />
                    )}
                </Typography>
            </>
        );
    };

    return (
        <div className="relative">
            <BlockingLoader loading={loading} />
            {renderHeader()}
            {renderOrg()}
        </div>
    );
};

export default Organization;
