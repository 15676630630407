import React from 'react';
import TextField from './TextField';
import CloseIcon from '@mui/icons-material/CloseOutlined';

function SearchMultipleSelect({
    value,
    onChange,
    label,
    disabled = false,
    onKeyDown,
    placeholder = '',
    CloseHandle,
    resetHandleFilters,
    keySearch,
    typeOfOpportunities,
    size,
    collegeTag,
    sponsorTag,
}) {
    return (
        <div>
            <TextField
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                label={label}
                classes={{ root: 'mt-[1px]' }}
                disabled={disabled}
                onKeyDown={onKeyDown}
                size={size}
            />
            {!!keySearch && (
                <ul
                    className={`${
                        collegeTag ? 'lg:px-4 gap-x-1 mb-1' : 'lg:px-8 gap-x-3 mb-6'
                    } flex justify-start flex-wrap gap-y-2 mt-3  `}>
                    {keySearch?.map((item, index) => (
                        <li
                            key={index}
                            className={`${
                                collegeTag || sponsorTag
                                    ? 'py-[3px] px-[5px] bg-primary-lightest text-primary'
                                    : ' py-[6px] px-[10px] bg-[#efd2ca] text-[#604942]'
                            } inline-flex rounded-full text-[0.78rem] lg:text-sm font-geometric font-medium items-center 
                               gap-3 lg:px-4  cursor-pointer`}>
                            <span>{collegeTag ? item.toUpperCase() : item}</span>
                            <button onClick={() => CloseHandle(index)}>
                                <CloseIcon className={`${collegeTag ? 'w-3' : 'w-5'}`} />
                            </button>
                        </li>
                    ))}
                </ul>
            )}

            {keySearch?.length > 0 && resetHandleFilters && (
                <button
                    onClick={resetHandleFilters}
                    className={`${
                        collegeTag
                            ? 'bg-primary-lightest text-primary gap-3 px-[5px] lg:px-2 py-[3px] lg:text-xs'
                            : 'bg-[#efd2ca] text-[#604942] gap-3 px-[10px] lg:px-4 py-[6px] lg:text-sm '
                    } mt-3 mx-3 inline-flex rounded-full text-[0.78rem] font-geometric font-medium items-center 
                                                     cursor-pointer`}>
                    <span>Reset</span>
                </button>
            )}
        </div>
    );
}

export default SearchMultipleSelect;
