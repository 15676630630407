import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import { Button, IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/Auth';
import { useSnackbar } from '../../../hooks/useSnackbar';
import awardIcon from '../../../images/award_icon.png';
import messageIcon from '../../../images/message_icon_light.svg';
import placeholderImage from '../../../images/placeholder-image.jpg';
import { addRemoveMyFavouritesStudent } from '../../../services/app/api';
import { getStudentLevel } from '../../../services/app/storage';
import { isBoolean } from '../../../services/core/types';
import { setSelectedUser } from '../../../store/messageSlice';
import { setScrollBarPosition } from '../../../store/searchSponsorSlice';
import { getSubMetaDataValue, isFutureDate } from '../../../utils/GenericFunctions';
import { GRADUATE_PROGRAM, META_KEYS } from '../../../utils/GetConstants';
import * as labelConst from '../../../utils/GetLabels';
import ReadMore from '../../Common/ReadMore';
import Typography from '../../UI/Typography';

const excludedMetaOppType = ['Scholarships', 'Sponsorships', 'Volunteer Opps', 'Influencer Opp'];

const STUDENT_SUB_METADATA_KEYS = {
    Employment: META_KEYS.EMP_TYPE,
    Recruitment: META_KEYS.RECRUITS_TYPE,
    'Grad Assistantship': META_KEYS.GRAD_ASSISTNT_TYPE,
    Mentorship: META_KEYS.COLLEGE_FIELDS_CAT,
    'Private Lessons': META_KEYS.PVT_LESSON_TYPE,
    Internship: META_KEYS.COLLEGE_FIELDS_CAT,
    'Research Assistantship': META_KEYS.COLLEGE_FIELDS_CAT,
    Tutoring: META_KEYS.TUTOR_TYPE,
};

export function SponsorSearchCards({
    combineArraysByMatchingMyFav,
    getMyStudents,
    user,
    profile,
    opportunities,
    high_school,
    college,
    setOpenTo,
}) {
    const { first_name, last_name, gender } = user;
    const {
        profile_photo,
        username,
        my_background,
        key_stats,
        location,
        profile_strength_percentage: profileStrength,
        profile_completion_percentage: profileCompletion,
    } = profile;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const snackbar = useSnackbar();
    const { getProfileId, getSubscriptionPlan, getSubscriptionExpireDate } = useAuth();

    const [isFuture, setIsFuture] = useState(false);

    useEffect(() => {
        setIsFuture(isFutureDate(getSubscriptionExpireDate()));
    }, [getSubscriptionExpireDate]);

    const allowMessage = getSubscriptionPlan() === labelConst.PLAN_VAL && isFuture;

    const handleGoToMessage = () => {
        if (!allowMessage) {
            setOpenTo(true);
        } else {
            let data = { ...user, user_id: user.id, ...profile, profile_id: profile.id };
            navigate('../messages');
            dispatch(setSelectedUser(data));
        }
    };

    const {
        name: highSchoolName,
        graduation_year: highSchoolGradYear,
        city: schoolCityAdd,
        state: schooleStateAdd,
    } = high_school || {};
    const {
        name: collegeName,
        graduation_year: collegeGradYear,
        city: collegeCityAdd,
        state: collegeStateAdd,
    } = college || {};

    const userProfileLink = `/student/${username}`;
    const cityValue = location?.city;
    const stateValue = location?.state;

    const renderOpenToList = () => {
        return opportunities.map(({ metadata }, index) => {
            const { value: opportunityType } = metadata.find(({ key }) => key === META_KEYS.OPP_TYPE) || {};
            if (!opportunityType) {
                return null;
            }
            const { value } = getSubMetaDataValue(
                metadata,
                opportunityType,
                STUDENT_SUB_METADATA_KEYS,
                excludedMetaOppType
            );

            if (!value) {
                return null;
            }

            return (
                <div key={index}>
                    <Typography className="font-geometric font-medium sm:font-bold text-[#3A3A3F] text-[13px] lg:text-sm">
                        {value}
                    </Typography>
                </div>
            );
        });
    };

    const handleReviewClick = () => {
        dispatch(setScrollBarPosition(window.scrollY));
    };

    const handleFavourites = () => {
        const pin = profile?.pin ? 'false' : 'true';
        const data = {
            id: getProfileId(),
            payload: { favorite_profile_id: profile.id, pin: pin },
        };
        addRemoveMyFavouritesStudent({ data })
            .then((response) => {
                combineArraysByMatchingMyFav(!profile?.pin, profile.id);
                snackbar.success(pin === 'true' ? 'User added to My Favorites.' : 'User removed from My Favorites.');
                getMyStudents();
            })
            .catch((error) => {
                snackbar.error(error.data.message || 'Failed to add user in My Favorites');
                console.error(error);
            });
    };

    return (
        <article
            id="card"
            style={{ boxShadow: '0px 4px 3px rgba(0,0,0,0.05)' }}
            className="bg-white p-4 rounded flex flex-col lg:flex-row mb-4">
            <div className="w-full lg:w-[22%] flex justify-between lg:flex-col lg:justify-start items-center text-left lg:text-center">
                <div className="flex items-center lg:flex-col">
                    <Link to={userProfileLink} className="border-b-0" target="_blank">
                        <div className="relative">
                            <img
                                src={`${profile_photo ? profile_photo : placeholderImage}`}
                                alt={`Profile of ${first_name} ${last_name}`}
                                loading="lazy"
                                className="object-contain min-w-[3.5rem] h-[3.5rem] lg:w-[150px] lg:h-[150px] rounded-full"
                            />
                            {profileCompletion === 100 && profileStrength === 100 && profile_photo && (
                                <img
                                    src={awardIcon}
                                    className="absolute h-5 w-5 sm:h-10 sm:w-10 sm:bottom-1 bottom-0 right-0 sm:right-1"
                                    alt="award"
                                />
                            )}
                        </div>
                    </Link>
                    <div className="ml-4 lg:ml-0 lg:mt-3">
                        <h3 className="capitalize font-geometric font-medium text-base text-[#272729]">
                            <Link
                                to={userProfileLink}
                                title={`${allowMessage ? `${first_name} ${last_name}` : ''}`}
                                target="_blank"
                                className={`${allowMessage ? '' : 'blur-[3px]'} border-b-0 hover:underline`}>
                                {`${first_name} ${last_name} ${gender ? `(${gender})` : ''}`}
                            </Link>
                        </h3>
                        {cityValue && stateValue && (
                            <h4 className="font-geometric sm:mt-1 font-medium text-[#464250] text-sm">
                                {cityValue},{stateValue}
                            </h4>
                        )}
                    </div>
                </div>
                <div className="m-2">
                    <Button
                        onClick={handleGoToMessage}
                        startIcon={<img src={messageIcon} alt="message_icon" height={'90%'} width={'90%'} />}
                        variant="contained"
                        size="small"
                        color="primary">
                        Message
                    </Button>
                </div>
            </div>
            <div className="flex-1 mt-3 lg:mt-0 lg:ml-2 text-lg ">
                <div className="lg:grid lg:grid-cols-4">
                    <div className="lg:col-span-1">
                        <h2 className="text-base lg:text-lg font-geometric font-bold text-[#3A3A3F]">I'm Open to</h2>
                    </div>
                    <div className="grid grid-cols-2 col-span-2  lg:grid-cols-3 lg:col-span-3 items-start gap-2">
                        {renderOpenToList()}
                    </div>
                </div>

                {highSchoolName && (
                    <div className="flex sm:flex-col flex-row lg:flex-row gap-y-2 sm:gap-x-5 mt-4">
                        <div className="font-geometric flex gap-x-2 text-base text-[#3C3C3C]">
                            <h4 className="sm:text-base text-sm">{isMobile ? 'HS:' : 'High School:'}</h4>
                            <Tooltip title={highSchoolName}>
                                <p className="text-[#282828] break-words text-sm sm:text-base font-medium sm:w-[340px] w-[160px] mr-4 text-ellipsis whitespace-nowrap overflow-hidden sm:mr-2">
                                    {highSchoolName}
                                </p>
                            </Tooltip>
                        </div>

                        <div className="font-geometric flex gap-x-2  text-base text-[#3C3C3C]">
                            <p className="text-[#282828] text-sm sm:text-base font-medium">
                                {`${schoolCityAdd ? `${schoolCityAdd},` : ''} ${
                                    schooleStateAdd ? schooleStateAdd : ''
                                } ${highSchoolGradYear}`}
                            </p>
                        </div>
                    </div>
                )}

                {collegeName &&
                    (user?.student_level === labelConst.COLLEGE || user?.student_level === labelConst.GRADUATE) && (
                        <div className="flex sm:flex-col flex-row lg:flex-row gap-y-2  sm:gap-x-5 mt-2">
                            <div className="font-geometric flex gap-x-2 sm:text-sm  text-base text-[#3C3C3C]">
                                <h4 className="sm:text-base text-sm">{isMobile ? 'Col:' : 'College:'}</h4>
                                <Tooltip title={collegeName}>
                                    <p className="text-[#282828] break-words text-sm sm:text-base font-medium sm:w-[340px] w-[160px] mr-4 text-ellipsis whitespace-nowrap overflow-hidden sm:mr-2">
                                        {collegeName}
                                    </p>
                                </Tooltip>
                            </div>

                            <div className="font-geometric flex gap-x-2  text-base text-[#3C3C3C]">
                                <p className="text-[#282828] text-sm sm:text-base font-medium">
                                    {`${collegeCityAdd ? `${collegeCityAdd},` : ''} ${
                                        collegeStateAdd ? collegeStateAdd : ''
                                    } ${collegeGradYear}`}
                                </p>
                            </div>
                        </div>
                    )}

                {my_background && (
                    <p className="mt-2 font-geometric opacity-70 text-sm lg:text-base">
                        <ReadMore text={my_background} length={200} />
                    </p>
                )}

                <div className="font-geometric flex gap-x-2 items-center text-sm lg:text-base text-[#3C3C3C]">
                    <KeyStateTableList keySats={key_stats} user={user} />
                </div>
                <div className="container flex justify-end mt-2 ">
                    <div className="m-1">
                        <IconButton
                            onClick={handleFavourites}
                            title={profile?.pin ? 'Remove from Favorite' : 'Add to Favorite'}
                            className="text-primary rotate-45">
                            {profile?.pin === true ? <PushPinIcon /> : <PushPinOutlinedIcon />}
                        </IconButton>
                    </div>
                    <Button
                        onClick={handleReviewClick}
                        className="bg-primary font-geometric p-3 text-[#FFFFFF] text-sm font-semibold rounded-[4px]"
                        state={{ from: 'Candidates' }}
                        component={Link}
                        to={userProfileLink}>
                        Review
                    </Button>
                </div>
            </div>
        </article>
    );
}

function KeyStateTableList({ keySats, user }) {
    const { student_level } = user;
    const studentLevel = getStudentLevel() || student_level;
    const { gpa = null } = keySats || {};
    let items = [];
    if (studentLevel === GRADUATE_PROGRAM) {
        const { graduate_degree, major_1, major_2, minor_1 = {} } = keySats || {};
        items = [
            { label: labelConst.GPA, value: gpa },
            { label: labelConst.GRAD_DEGREE, value: graduate_degree?.name || graduate_degree?.category?.name },
            { label: labelConst.UNDER_GRAD_MAJOR, value: major_1?.name || major_1?.category?.name },
            { label: labelConst.SECOND_UNDER_GRAD_MAJOR, value: major_2?.name || major_2?.category?.name },
            { label: labelConst.UNDER_GRAD_MINOR, value: minor_1?.name || minor_1?.category?.name },
        ];
    } else if (studentLevel === labelConst.COLLEGE) {
        const { major_1, major_2, minor_1 = {}, minor_2 } = keySats || {};
        items = [
            { label: labelConst.GPA, value: gpa },
            { label: labelConst.MAJOR, value: major_1?.name || major_1?.category?.name },
            { label: labelConst.SECOND_MAJOR, value: major_2?.name || major_2?.category?.name },
            { label: labelConst.MINOR, value: minor_1?.name || minor_1?.category?.name },
            { label: labelConst.SECOND_MINOR, value: minor_2?.name || minor_2?.category?.name },
        ];
    } else {
        const { sat_score, act_score, ap_credit_eligible, honors_credit_eligible } = keySats || {};
        items = [
            { label: 'GPA', value: gpa },
            { label: 'SAT Score', value: sat_score },
            { label: 'ACT Score', value: act_score },
            { label: 'AP Credit Eligible', value: ap_credit_eligible },
            { label: 'Honors Credit Eligible', value: honors_credit_eligible },
        ];
    }

    const renderItems = items
        .map(({ label, value }) => {
            return label && value ? (
                <Typography
                    key={label}
                    className={`text-[0.8rem] flex md:flex-grow last-of-type:flex-grow-0 bg-[#DDE7F5] justify-center items-center px-4 ${
                        label === 'GPA' ? 'md:px-4' : 'md:px-[10px]'
                    } rounded pt-2 pb-2 sm:pt-2 sm:pb-2 text-center font-geometric font-medium sm:text-[0.89rem]`}>
                    {isBoolean(value) ? label : `${label} - ${value}`}
                </Typography>
            ) : null;
        })
        .filter(Boolean);
    if (!items.length) return null;

    return <div className="flex sm:mt-5 mt-1 gap-y-2 gap-x-2 rounded flex-wrap">{renderItems}</div>;
}
