export const locationTypeFilter = [
    {
        label: 'City',
        value: 'City',
        checked: false,
    },
    {
        label: 'Town',
        value: 'Town',
        checked: false,
    },
    {
        label: 'Suburb',
        value: 'Suburb',
        checked: false,
    },
    {
        label: 'Rural',
        value: 'Rural',
        checked: false,
    },
];
export const degreeTypeFilter = [
    {
        label: "4 Years: Bachelor's Degree",
        value: "Bachelor's Degree",
        checked: false,
    },
    {
        label: "2 Years: Associate's Degree",
        value: "Associate's Degree",
        checked: false,
    },
    {
        label: "Graduate's Degree",
        value: "Graduate's Degree",
        checked: false,
    },
    {
        label: 'Certificate',
        value: 'Certificate',
        checked: false,
    },
];
export const collegeTypeFilter = [
    {
        label: 'Public',
        value: 'Public',
        checked: false,
    },
    {
        label: 'Private',
        value: 'Private',
        checked: false,
    },
];
export const twoYearCollegeSizeFilter = [
    {
        label: 'Small',
        value: 'Two-year: Small',
        checked: false,
    },
    {
        label: 'Medium',
        value: 'Two-year: Medium',
        checked: false,
    },
    {
        label: 'Large',
        value: 'Two-year: Large',
        checked: false,
    },
];

export const twoYearEnrollments = [
    {
        label: 'Under 500',
        value: '100-500',
        checked: false,
    },
    {
        label: '501 - 1,000',
        value: '501-1000',
        checked: false,
    },
    {
        label: '1,001 - 2,000',
        value: '1001-2000',
        checked: false,
    },
    {
        label: '2,001 - 5,000',
        value: '2001-5000',
        checked: false,
    },
    {
        label: 'Over 5,000',
        value: '5000-1000000',
        checked: false,
    },
];
export const fourYearEnrollments = [
    {
        label: 'Very Small (Under 1,000)',
        value: '100-1000',
        checked: false,
    },
    {
        label: 'Small (1,001 - 5,000)',
        value: '1001-5000',
        checked: false,
    },
    {
        label: 'Medium (5,001 - 10,000)',
        value: '5001-10000',
        checked: false,
    },
    {
        label: 'large (10,001 - 15,000)',
        value: '10001-15000',
        checked: false,
    },
    {
        label: 'Very large (Over 15,000)',
        value: '15000-1000000',
        checked: false,
    },
];
export const fourYearCollegeSizeFilter = [
    {
        label: 'Small, Primarily Residential',
        value: 'Four-year: Small, Primarily Residential',
        checked: false,
    },
    {
        label: 'Small, Primarily Nonresidential',
        value: 'Four-year: Small, Primarily Nonresidential',
        checked: false,
    },
    {
        label: 'Medium, Primarily Residential',
        value: 'Four-year: Medium, Primarily Residential',
        checked: false,
    },
    {
        label: 'Medium, Primarily Nonresidential',
        value: 'Four-year: Medium, Primarily Nonresidential',
        checked: false,
    },
    {
        label: 'Large, Primarily Residential',
        value: 'Four-year: Large, Primarily Residential',
        checked: false,
    },
    {
        label: 'Large, Primarily Nonresidential',
        value: 'Four-year: Large, Primarily Nonresidential',
        checked: false,
    },
];

export const allCollegeProgramsFilter = [
    { label: 'Agriculture', value: 'Agriculture', checked: false },
    { label: 'Architecture', value: 'Architecture', checked: false },
    {
        label: 'Biological and Biomedical Sciences',
        value: 'Biological and Biomedical Sciences',
        checked: false,
    },
    {
        label: 'Business, Management, and Marketing',
        value: 'Business, Management, and Marketing',
        checked: false,
    },
    {
        label: 'Communication and Journalism',
        value: 'Communication and Journalism',
        checked: false,
    },
    {
        label: 'Communications Technologies / Technicians',
        value: 'Communications Technologies / Technicians',
        checked: false,
    },
    {
        label: 'Computer and Information Sciences',
        value: 'Computer and Information Sciences',
        checked: false,
    },
    {
        label: 'Construction Trades',
        value: 'Construction Trades',
        checked: false,
    },
    { label: 'Education', value: 'Education', checked: false },
    { label: 'Engineering', value: 'Engineering', checked: false },
    {
        label: 'English Language and Literature',
        value: 'English Language and Literature',
        checked: false,
    },
    {
        label: 'Ethnic, Cultural, Gender, and Group Studies',
        value: 'Ethnic, Cultural, Gender, and Group Studies',
        checked: false,
    },
    {
        label: 'Family and Consumer Sciences / Human Sciences',
        value: 'Family and Consumer Sciences / Human Sciences',
        checked: false,
    },
    {
        label: 'Foreign Languages',
        value: 'Foreign Languages',
        checked: false,
    },
    {
        label: 'Health Professions',
        value: 'Health Professions',
        checked: false,
    },
    { label: 'History', value: 'History', checked: false },
    {
        label: 'Homeland Security / Law Enforcement / Protective Services',
        value: 'Homeland Security / Law Enforcement / Protective Services',
        checked: false,
    },
    {
        label: 'Legal Professions and Studies',
        value: 'Legal Professions and Studies',
        checked: false,
    },
    {
        label: 'Liberal Arts & Sciences, General Studies & Humanities',
        value: 'Liberal Arts & Sciences, General Studies & Humanities',
        checked: false,
    },
    {
        label: 'Library Science',
        value: 'Library Science',
        checked: false,
    },
    {
        label: 'Mathematics and Statistics',
        value: 'Mathematics and Statistics',
        checked: false,
    },
    {
        label: 'Mechanic and Repair Technologies/Technicians',
        value: 'Mechanic and Repair Technologies/Technicians',
        checked: false,
    },
    {
        label: 'Military Technologies and Applied Sciences',
        value: 'Military Technologies and Applied Sciences',
        checked: false,
    },
    {
        label: 'Multi/Interdisciplinary Studies',
        value: 'Multi/Interdisciplinary Studies',
        checked: false,
    },
    {
        label: 'Natural Resources and Conservation',
        value: 'Natural Resources and Conservation',
        checked: false,
    },
    {
        label: 'Parks, Recreation, Leisure, and Fitness Studies',
        value: 'Parks, Recreation, Leisure, and Fitness Studies',
        checked: false,
    },
    {
        label: 'Personal and Culinary Services',
        value: 'Personal and Culinary Services',
        checked: false,
    },
    {
        label: 'Philosophy and Religious Studies',
        value: 'Philosophy and Religious Studies',
        checked: false,
    },
    {
        label: 'Physical Sciences',
        value: 'Physical Sciences',
        checked: false,
    },
    {
        label: 'Precision Production',
        value: 'Precision Production',
        checked: false,
    },
    { label: 'Psychology', value: 'Psychology', checked: false },
    {
        label: 'Public Administration and Social Service Professions',
        value: 'Public Administration and Social Service Professions',
        checked: false,
    },
    {
        label: 'Science Technologies/Technicians',
        value: 'Science Technologies/Technicians',
        checked: false,
    },
    {
        label: 'Social Sciences',
        value: 'Social Sciences',
        checked: false,
    },
    {
        label: 'Theology and Religious Vocations',
        value: 'Theology and Religious Vocations',
        checked: false,
    },
    {
        label: 'Transportation and Materials Moving',
        value: 'Transportation and Materials Moving',
        checked: false,
    },
    {
        label: 'Visual and Performing Arts',
        value: 'Visual and Performing Arts',
        checked: false,
    },
];

export const religiousAffiliationFilter = [
    { label: 'Baptist', value: 'Baptist', checked: false },
    { label: 'Brethren', value: 'Brethren', checked: false },
    { label: 'Catholic', value: 'Catholic', checked: false },
    { label: 'Christian', value: 'Christian', checked: false },
    { label: 'Episcopal', value: 'Episcopal', checked: false },
    { label: 'Evangelica', value: 'Evangelica', checked: false },
    { label: 'Greek Orthodox', value: 'Greek Orthodox', checked: false },
    {
        label: 'Interdenominational',
        value: 'Interdenominational',
        checked: false,
    },
    { label: 'Jewish', value: 'Jewish', checked: false },
    {
        label: 'Latter Day Saints (Mormon)',
        value: 'Latter Day Saints (Mormon)',
        checked: false,
    },
    { label: 'Lutheran', value: 'Lutheran', checked: false },
    { label: 'Mennonite', value: 'Mennonite', checked: false },
    { label: 'Methodist', value: 'Methodist', checked: false },
    { label: 'Missionary', value: 'Missionary', checked: false },
    { label: 'Pentecostal', value: 'Pentecostal', checked: false },
    {
        label: 'Presbyterian and Protestant',
        value: 'Presbyterian and Protestant',
        checked: false,
    },
    {
        label: 'Reformed Church',
        value: 'Reformed Church',
        checked: false,
    },
    {
        label: 'Seventh Day Adventists',
        value: 'Seventh Day Adventists',
        checked: false,
    },
    {
        label: 'Undenominational',
        value: 'Undenominational',
        checked: false,
    },
    { label: 'Other', value: 'Other', checked: false },
];

export const minorityDistinctionFilter = [
    {
        label: 'Alaska Native, Native Hawaiian Serving Institution',
        value: 'Alaska Native, Native Hawaiian Serving Institution',
        checked: false,
    },
    {
        label: 'Asian American, Native American Pacific Islander Serving Institution',
        value: 'Asian American, Native American Pacific Islander Serving Institution',
        checked: false,
    },
    {
        label: 'HBCU: Historically Black College',
        value: 'HBCU: Historically Black College',
        checked: false,
    },
    {
        label: 'Hispanic Serving Institution',
        value: 'Hispanic Serving Institution',
        checked: false,
    },
    {
        label: 'Native American Non-Tribal Institution',
        value: 'Native American Non-Tribal Institution',
        checked: false,
    },
    {
        label: 'Predominantly Black Serving Institution',
        value: 'Predominantly Black Serving Institution',
        checked: false,
    },
    {
        label: 'Tribal College and University',
        value: 'Tribal College and University',
        checked: false,
    },
];

export const avgSatScoreFilter = [
    {
        label: 'SAT 1290 - 1600',
        value: '1290-1600',
        checked: false,
    },
    {
        label: 'SAT 1190 - 1280',
        value: '1190-1280',
        checked: false,
    },
    {
        label: 'SAT 1120 - 1180',
        value: '1120-1180',
        checked: false,
    },
    {
        label: 'SAT 1060 - 1110',
        value: '1160-1110',
        checked: false,
    },
    {
        label: 'SAT 1010 - 1050',
        value: '1010-1050',
        checked: false,
    },
    {
        label: 'SAT 960 and Below',
        value: '0-960',
        checked: false,
    },
];

export const avgActScoreFilter = [
    {
        label: 'ACT 29 - 36',
        value: '29-36',
        checked: false,
    },
    {
        label: 'ACT 25 - 28',
        value: '25-28',
        checked: false,
    },
    {
        label: 'ACT 23 - 24',
        value: '23-24',
        checked: false,
    },
    {
        label: 'ACT 21 - 22',
        value: '21-22',
        checked: false,
    },
    {
        label: 'ACT 19 - 20',
        value: '19-20',
        checked: false,
    },
    {
        label: 'ACT 18 and Below',
        value: '0-18',
        checked: false,
    },
];

export const avgNetPriceFilter = [
    {
        label: 'Under $5000',
        value: '0-5000',
        checked: false,
    },
    {
        label: '$5001 - $10,000',
        value: '5001-10000',
        checked: false,
    },
    {
        label: '$10,001 - $15,000',
        value: '10001-15000',
        checked: false,
    },
    {
        label: '$15,001 - $20,000',
        value: '15001-20000',
        checked: false,
    },
    {
        label: '$20,001 - $25,000',
        value: '20001-25000',
        checked: false,
    },
    {
        label: '$25,001 - $30,000',
        value: '25001-30000',
        checked: false,
    },
    {
        label: 'Over $30,000 per year',
        value: '30000-10000000',
        checked: false,
    },
];

export const studentLevelFilter = {
    'High School': false,
    College: false,
    Graduate: false,
};

export const opportunitiesTypeFilter = [
    { label: 'Employee', value: 'Employment', checked: false },
    { label: 'Event', value: 'Event', checked: false },
    { label: 'Grad Assistant', value: 'Grad Assistantship', checked: false },
    { label: 'Intern', value: 'Internship', checked: false },
    { label: 'Mentee', value: 'Mentorship', checked: false },
    { label: 'Recruits', value: 'Recruitment', checked: false },
    { label: 'Scholars', value: 'Scholarships', checked: false },
    { label: 'Students to Sponsor', value: 'Sponsorships', checked: false },
    { label: 'Volunteers', value: 'Volunteer Opps', checked: false },
];

export const distance = [
    {
        label: '60 miles (1 hour or less)',
        value: '60',
        checked: false,
    },
    {
        label: '200 miles (3 hours or less)',
        value: '200',
        checked: false,
    },
    {
        label: '350 miles (5 hours or less)',
        value: '350',
        checked: false,
    },
    {
        label: '500 miles (7 hours or less)',
        value: '500',
        checked: false,
    },
];
