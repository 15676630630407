import ReplyIcon from '@mui/icons-material/Reply';
import { Grid } from '@mui/material';
import { format, fromUnixTime } from 'date-fns/esm';
import parse from 'html-react-parser';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useAuth } from '../../../contexts/Auth';
import { useSnackbar } from '../../../hooks';
import { showPaymentForm } from '../../../services/app/api';
import { Environments } from '../../../services/core/env';
import { compareDates, copyToClipboard, formatCurrencyRange, formatToUSMoney } from '../../../utils/GenericFunctions';
import {
    META_KEYS,
    STUDENT_SCHOLARSHIP_TYPES_LIST,
    TEAM_SCHOLARSHIP_TYPES_LIST,
    applyCriteriaList,
} from '../../../utils/GetConstants';
import * as labelConst from '../../../utils/GetLabels';
import BlockingLoader from '../../UI/BlockingLoader';
import BubbleTooltip from '../../UI/BubbleTooltip';
import Button from '../../UI/Button';
import Typography from '../../UI/Typography';

function getOrderedMetaList(opportuityMeta) {
    const metaList = [];
    let opportunity = opportuityMeta.find(({ key }) => key === META_KEYS.OPP_TYPE);
    if (!opportunity) return [];
    metaList.push(opportunity);
    let parentKey = opportunity.key;
    opportuityMeta.forEach(({ key }, index) => {
        if (key === META_KEYS.OPP_TYPE) return;
        const parentMetaData = opportuityMeta.find(({ parent }) => parent === parentKey);
        if (!parentMetaData?.key) return;
        if (parentMetaData) metaList.push(parentMetaData);
        parentKey = parentMetaData.key;
    });

    return metaList;
}

function getOpportunityDetails(metaList) {
    const opportunityInfo = [];
    const metadata = getOrderedMetaList(metaList);

    for (let index = 0; index < metaList.length; index++) {
        const { key, value } = metaList[index];
        const noBracketsValue =
            value !== 'Influencer Opp' &&
            value !== 'Other' &&
            value !== 'Snow Removal' &&
            value !== 'Office Assistant' &&
            value !== 'Babysitter' &&
            value !== 'Pet Care' &&
            value !== 'Lawn Mower' &&
            value !== 'Restaurant Help';
        const isEmploymentDataRow = metaList[index - 1]?.value === 'Employee' && key === META_KEYS.EMP_TYPE;
        const isLastRow = index === metaList.length - 1 && metaList.length > 1;
        const shouldAppendComma = (index === metaList.length - 1 || !isEmploymentDataRow) && !isLastRow;

        let str = '';
        if (key === META_KEYS.OPP_TYPE) {
            str = `${value}${value !== 'Scholars' ? ':' : ''}`;
        } else if (key === 'event_date') {
            try {
                let eventDate = format(fromUnixTime(Number(value)), 'MM / dd / yyyy');
                let time = moment.unix(value).format('h:mm A');
                str = `Event Date: ${eventDate}, Event Time: ${time}  `;
            } catch (error) {
                str = value === 'null' ? '' : 'Not a valid Date';
            }
        } else {
            const comma = shouldAppendComma ? ',' : '';
            str = typeof value != 'string' ? `${value.city} ${value.state}, ${value.zipcode}` : `${value}${comma}`;
        }

        opportunityInfo.push(str);
        if (
            (key === META_KEYS.OPP_TYPE && metadata.length > 1 && value !== 'Employee') ||
            (isEmploymentDataRow && noBracketsValue)
        ) {
            opportunityInfo.push('(');
        }
        isLastRow && noBracketsValue && opportunityInfo.push(')');
    }
    return opportunityInfo.join(' ');
}

function ScholarshipCard({
    scholarship: {
        id,
        title,
        amount,
        deadline,
        eligibility_criteria,
        apply_criteria,
        opp_for,
        description,
        metadata,
        is_closed,
        question_1,
        question_2,
        question_3,
        file,
        fee_status,
        fee_amount,
        start_at,
        amount_unit,
    },
    onDelete,
    onEdit,
    index,
    isFormVisible,
    isEditing,
}) {
    const [isScheduled, setIsScheduled] = useState();
    const { currentDate, comapareDate } = compareDates(start_at);
    // let currentDate = new Date();
    // currentDate.setHours(0, 0, 0, 0);
    // let backendDate = new Date(start_at * 1000);
    // backendDate.setHours(0, 0, 0, 0);

    useEffect(() => {
        if (currentDate < comapareDate) {
            setIsScheduled(true);
        } else if (currentDate === comapareDate) {
            setIsScheduled(false);
        } else {
            setIsScheduled(false);
        }
    }, [start_at]);

    const isScholarshipDeadlinePassed = Date.now() / 1000 > Number(deadline);
    const formattedDeadline = format(fromUnixTime(Number(deadline)), 'MM / dd / yyyy');
    const areActionButtonsVisible = !isEditing && !isFormVisible;
    const { value: type } = metadata.find(({ key }) => key === META_KEYS.OPP_TYPE) || {};
    const scholarshipTypes = opp_for === 'student' ? STUDENT_SCHOLARSHIP_TYPES_LIST : TEAM_SCHOLARSHIP_TYPES_LIST;
    const { label: labelType } = scholarshipTypes?.find(({ value }) => value === type) || 'deleted';
    const descriptionEditorValue = parse(description);
    const snackbar = useSnackbar();
    const auth = useAuth();
    const [loading, setLoading] = useState(false);

    const metaDataValues = useMemo(() => getOpportunityDetails(metadata), [metadata]);

    function onDeleteScholarship() {
        onDelete(id);
    }

    function onEditScholarship() {
        onEdit(id, index);
    }
    async function shareLink(link) {
        try {
            await copyToClipboard(link);
            snackbar.success('Link copied to clipboard');
        } catch (e) {
            console.log(e);
        }
    }

    const statusClassNames =
        isScholarshipDeadlinePassed || is_closed ? 'border-[#F09494]' : 'border-[#A3E635] bg-[#FDFEFB]';

    const showPaymentOption =
        auth.askForSubscription() &&
        (type === 'Scholars' || type === 'Students to Sponsor') &&
        !is_closed &&
        !isScholarshipDeadlinePassed &&
        fee_status === 'PENDING';

    const openPaymentForm = () => {
        setLoading(true);
        showPaymentForm({ user_id: auth?.getProfileId(), sponsorship_id: id })
            .then((res) => {
                localStorage.setItem('redirectTo', window.location.href);
                window.open(res?.data?.data?.url, '_self');
            })
            .catch(() => {
                snackbar.error('Failed to open payment form');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div
            className={`relative rounded-lg lg:px-4 pt-6 lg:pt-8 pb-4 lg:pb-6 
            mb-4  lg:bg-white border-solid lg:border-none border-[1.3px] ${statusClassNames}`}>
            {file && (
                <div className="hidden sm:block absolute right-2 top-20">
                    <img src={file} alt="scholarshipImage" className="object-contain rounded-md w-44 max-h-24" />
                </div>
            )}
            <BlockingLoader loading={loading} />
            <div className="px-5 lg:px-0">
                <Grid>
                    <Grid container gap={{ lg: 8 }}>
                        <Grid item className="max-w-full lg:max-w-[50%]">
                            <Grid className="flex flex-row items-center">
                                <ScholarshipRow
                                    text={labelConst.LABEL_SPONSOR_EDIT_NAME}
                                    value={title}
                                    descriptionClassName="lg:max-w-[80%] text-primary text-base mb-2 lg:mb-0 leading-[22px] lg:leading-6 font-semibold"
                                    textClassName="hidden lg:block text-sm lg:text-base"
                                />
                                {isMobile && (
                                    <Button
                                        disabled
                                        className={`${
                                            is_closed || isScholarshipDeadlinePassed
                                                ? 'bg-[#FCDDDD] text-[#E04444]'
                                                : isScheduled
                                                ? 'bg-[#e6cdb0] text-[#322818]'
                                                : 'bg-[#D5ECB1] text-[#116004]'
                                        } rounded-md text-sm mb-1 px-1 ml-4`}>
                                        {is_closed || isScholarshipDeadlinePassed
                                            ? 'Closed'
                                            : isScheduled
                                            ? 'Scheduled'
                                            : 'Active'}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item className="w-full lg:w-auto">
                            <ScholarshipRow
                                text={labelConst.LABEL_SPONSOR_EDIT_AMOUNT}
                                value={
                                    isNaN(amount)
                                        ? `$${formatCurrencyRange(amount)}/${amount_unit}`
                                        : `$${formatCurrencyRange(Number(amount).toLocaleString())}`
                                }
                                textClassName="text-sm lg:text-base w-[85px]"
                                descriptionClassName="text-sm lg:text-base"
                            />
                        </Grid>
                        {!!fee_amount && showPaymentOption && (
                            <Grid item className="w-full lg:w-auto">
                                <ScholarshipRow
                                    text={'Fees:'}
                                    value={formatToUSMoney(fee_amount)}
                                    textClassName="text-sm lg:text-base w-[85px]"
                                    descriptionClassName="text-sm lg:text-base"
                                />
                            </Grid>
                        )}
                        <Grid item className="mt-2 lg:mt-0 flex flex-row">
                            <ScholarshipRow
                                text={labelConst.LABEL_SPONSOR_EDIT_DEADLINE}
                                value={formattedDeadline}
                                textClassName="text-sm lg:text-base w-[85px]"
                                descriptionClassName={`text-sm lg:text-base ${
                                    isScholarshipDeadlinePassed ? 'text-[#E04444]' : ''
                                }`}
                            />

                            {!isMobile && (
                                <Button
                                    disabled
                                    className={`${
                                        is_closed || isScholarshipDeadlinePassed
                                            ? 'bg-[#FCDDDD] text-[#E04444]'
                                            : isScheduled
                                            ? 'bg-[#e6cdb0] text-[#322818] w-28'
                                            : 'bg-[#D5ECB1] text-[#116004]'
                                    } rounded-md ml-5 items-center h-8 text-xs`}>
                                    {is_closed || isScholarshipDeadlinePassed
                                        ? 'Closed'
                                        : isScheduled
                                        ? 'Scheduled'
                                        : 'Active'}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container gap={{ lg: 8 }}>
                        <Grid item className="mt-2 lg:mt-0">
                            <ScholarshipRow
                                text={labelConst.LABEL_SPONSOR_EDIT_TYPE}
                                value={labelType}
                                className="flex-nowrap lg:mt-4"
                                textClassName="text-sm lg:text-base w-[85px]"
                                descriptionClassName="lg:ml-0 text-sm lg:text-base"
                            />
                        </Grid>
                    </Grid>
                    <Grid container gap={{ lg: 8 }}>
                        <Grid item className="max-w-full lg:max-w-[50%]">
                            <ScholarshipRow
                                text={'For'}
                                value={<span className="capitalize">{opp_for}</span>}
                                className="flex-nowrap lg:mt-4"
                                textClassName="text-sm lg:text-base w-full"
                                descriptionClassName="text-sm lg:text-base break-words"
                            />
                        </Grid>
                        <Grid item className="w-full lg:w-auto">
                            <ScholarshipRow
                                text={labelConst.LABEL_SPONSOR_EDIT_ELIGIBILITY}
                                value={eligibility_criteria}
                                className="flex-nowrap lg:mt-4"
                                textClassName="text-sm lg:text-base w-[85px]"
                                descriptionClassName="text-sm lg:text-base break-words"
                            />
                        </Grid>
                        <Grid item className="w-full lg:w-auto">
                            <ScholarshipRow
                                text={'Criteria:'}
                                value={
                                    apply_criteria
                                        ? applyCriteriaList.find(({ value }) => value === apply_criteria)?.label
                                        : 'N/A'
                                }
                                className="flex-nowrap lg:mt-4 first-letter:capitalize"
                                textClassName="text-sm lg:text-base w-[85px]"
                                descriptionClassName="text-sm lg:text-base break-words"
                            />
                        </Grid>
                    </Grid>
                    <ScholarshipRow
                        text={labelConst.LABEL_SPONSOR_EDIT_DESCRIPTION}
                        value={
                            isMobile ? (
                                <Grid className="max-h-[140px] overflow-y-scroll">{descriptionEditorValue} </Grid>
                            ) : (
                                descriptionEditorValue
                            )
                        }
                        className="flex-col lg:flex-row mt-2 flex-nowrap lg:mt-12 max-w-[100%]"
                        textClassName="text-sm lg:text-base"
                        descriptionClassName="text-sm lg:text-base text-left lg:text-left leading-[21px] lg:leading-[25px] w-[100%] lg:mt-0 sm:max-h-36 sm:overflow-auto sm:no-scrollbar sm:overflow-x-hidden mt-[-6px]"
                    />

                    <ScholarshipRow
                        text="Opportunity Details:"
                        value={metaDataValues}
                        className="flex-nowrap mt-2 lg:mt-4"
                        textClassName="text-sm lg:text-base w-[85px]"
                        descriptionClassName="text-sm lg:text-base break-words w-[70%]  sm:max-w-[90%]"
                    />

                    <Grid container className="flex flex-col sm:flex-row mt-2" gap={2}>
                        <div>
                            {(question_1 || question_2 || question_3) && (
                                <Typography className="font-geometric text-sm lg:text-base font-semibold lg:w-[100px] ">
                                    Yes / No Questions:
                                </Typography>
                            )}
                        </div>
                        <div className=" w-full  sm:w-[90%]">
                            {question_1 && (
                                <div className="flex">
                                    <div className="mr-2 mb-auto mt-2 sm:mt-2 2xl:mt-3 h-2 w-2 flex-none rounded-full bg-primary"></div>
                                    <Typography className="font-geometric  font-[500] break-words text-sm lg:text-base">
                                        {question_1}
                                    </Typography>
                                </div>
                            )}
                            {question_2 && (
                                <div className="flex">
                                    <div className="mr-2 mb-auto mt-2 sm:mt-2 2xl:mt-3 h-2 w-2 flex-none rounded-full bg-primary"></div>
                                    <Typography className="font-geometric  font-[500] break-words text-sm lg:text-base">
                                        {question_2}
                                    </Typography>
                                </div>
                            )}
                            {question_3 && (
                                <div className="flex">
                                    <div className="mr-2 mb-auto mt-2 sm:mt-2 2xl:mt-3 h-2 w-2 flex-none rounded-full bg-primary"></div>
                                    <Typography className="font-geometric  font-[500] break-words text-sm lg:text-base">
                                        {question_3}
                                    </Typography>
                                </div>
                            )}
                        </div>
                        {file && (
                            <div className="block sm:hidden w-24 h-16">
                                <img src={file} alt="scholarshipImage" className="object-cover rounded-md h-16" />
                            </div>
                        )}
                    </Grid>
                </Grid>

                {areActionButtonsVisible ? (
                    <div className="text-right mt-4 lg:mt-0">
                        <Button onClick={onDeleteScholarship}>Delete</Button>
                        <Button onClick={onEditScholarship}>Edit</Button>
                        {showPaymentOption && (
                            <BubbleTooltip
                                title={'Pay Now'}
                                description={
                                    'Pay for your posting now to reveal applicants as soon as they submit their application.'
                                }>
                                <span className="pb-4">
                                    <Button onClick={openPaymentForm}>Pay {formatToUSMoney(fee_amount)}</Button>
                                </span>
                            </BubbleTooltip>
                        )}
                        <Button
                            variant={'contained'}
                            className="p-1 text-sm sm:py-2 sm:px-3"
                            endIcon={
                                isMobile ? null : (
                                    <ReplyIcon
                                        sx={{
                                            transform: 'rotateY(180deg)',
                                        }}
                                    />
                                )
                            }
                            onClick={() =>
                                shareLink(`${Environments.FRONTEND_URL}/share-opportunity?opportunity=${id}`)
                            }>
                            Share
                        </Button>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

function ScholarshipRow({ text, value, className = '', textClassName = '', descriptionClassName = '', ...rest }) {
    return (
        <Grid container gap={2} className={className}>
            <Typography className={`font-geometric font-semibold lg:w-[100px] ${textClassName}`}>{text}</Typography>
            <Typography className={`font-geometric font-[500] break-words ${descriptionClassName}`}>{value}</Typography>
        </Grid>
    );
}

export default ScholarshipCard;
