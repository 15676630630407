import Typography from '../components/UI/Typography';

export const SCHOLARSHIPS = 'Scholarships';
export const SEARCH = 'Search';
export const SEARCH_TITLE = 'Enter a search term and hit Enter';
export const NAME = 'Name';
export const WEBSITE = 'Website';
export const EMAIL = 'Email';
export const PHONE_NUMBER = 'Phone Number';
export const SCHOLARSHIP_DESCRIPTION = 'Scholarship Description';
export const SCHOLARSHIP_DETAILS = 'Scholarship Details';
export const NO_DATA = 'No Data.';
export const CLOSE = 'Close';
export const ON_BOARDED = 'On Boarded';
export const APPROVE = 'Approve';
export const START_DATE = 'Start date';
export const DUE_DATE = 'Due Date';
export const AMOUNT = 'Amount';
export const MINIMUM_GPA = 'Minimum GPA (Grade Point Average)';
export const GENDER = 'Gender';
export const MAILING_ADDRESS = 'Mailing Address';
export const STATUS = 'Status';
export const APPROVED = 'Approved';
export const SCHOLARSHIP_SWEEPSTAKES_LOTTERY = 'Is the scholarship a sweepstakes or lottery?';
export const SCHOLARSHIP_AWARD_ANNOUNCEMENT = 'Scholarship Award Announcement';
export const FAFSA = 'FAFSA';
export const PELL_GRANT_ELIGIBLE = 'Pell Grant Eligible';
export const FINANCIAl_NEED = 'Any financial need?';
export const DEMONSTRATE_FINANCIAL_NEED = 'Demonstrate financial need';
export const RESIDENCY = 'Residency';
export const RESIDENCY_STATE = 'Residency State';
export const COLLEGE_ENROLLMENT_STATUS = 'College Enrollment Status';
export const MIN_CREDIT_ENROLLMENT = 'Minimum Credit Enrollment';
export const DEMOGRAPHIC_REQUIREMENTS = 'Demographic Requirements';
export const ACADEMIC_LEVEL = 'Academic Level';
export const ADDITIONAL_ELIGIBLE_CRITERIA = 'Additional Eligible Criteria';
export const FIELD_OF_STUDY = 'Field of Study';
export const ATTENDANCE_COLLEGE_NAME = 'Attendance at a specific college';
export const REAPPLY = 'Can you re-apply?';
export const REAPPLY_ATTEMPTS = 'Re-apply attempts';
export const STUDY_ABROAD = 'Study Abroad';
export const ONLINE_CLASSES = 'Online Classes Availability';
export const SCHOLARSHIP_DISPERSED = 'Scholarship dispersed to';
export const SCHOLARSHIPS_COVERS = 'Scholarships covers';
export const SCHOLARSHIP_FEES = 'Scholarship Fees';
export const SCHOLARSHIP_WEBPAGE_URL = 'Scholarship Webpage URL';
export const SCHOLARSHIP_APPLICATION_URL = 'Scholarship Application URL';
export const ROLE = 'Role';
export const USERNAME = 'Username';
export const MOBILENUMBER = 'Phone Number';
export const LOCATION = 'Location';
export const CLEAR = 'Clear';
export const SELECT = 'Select';
export const SPONSOR_ONBOARDING_TEXT = [
    'Upload your personal or business logo profile',
    'picture and add your basic information.',
    'Click the blue Next button when you are finished.',
];
export const LEADER_ONBOARDING_TEXT = [
    'Upload your personal or school logo profile',
    'picture and add your basic information.',
    'Click the blue Next button when you are finished.',
];
export const BIOPLACEHOLDER_INDIVISUAL_INSTRUCTION = `Start with the basics! We recommend including your name, current role or tagline, the organization/institution you work for (if any), future goals and aspirations, and what criteria or things you are looking for when deciding which students to support.`;
export const BIOPLACEHOLDER_ORGANISATION_INSTRUCTION = `Tell us more about the organization you represent. This should include the name of the organization, the type and when it was founded.Then a sentence or two about what you offer your clients/customers, and anything that makes you different (this could be something you specialize in).If you organization has a goal or a mission, you can include that as well.Then last but not least, criteria or things you are looking for when deciding which students to support.`;
export const BIO_EXAMPLE_ORGANISATION = `Imagine the world’s largest education fair where everyone is invited, free of charge–all students, colleges, trade schools, foundations and corporate and individual sponsors. Your29 is that place, 
                                         where students find the educational opportunities that match them, and discover sponsors and scholarships to fund those opportunities after high school. We are on a mission to give students the power to seize educational 
                                         opportunities without barriers. Founded in 2021, when a Division I coach and a tech executive walked into a shared workspace with a common goal of making the world a little bit better. Kim Schuette, a 20-year coaching veteran and 
                                         College Administrator, had grown tired of the archaic, exclusive nature of higher education and wanted to reimagine the way it could operate. To do so, she enlisted the help of Brynn Moynihan, a 15-year tech and digital media executive
                                          who desired to build a better future for her kids where students could find their place and pay for their educational journeys.`;
export const BIO_EXAMPLE_INDIVISUAL = `Hi, I'm Brynn! I started Your29 in 2022 as a way to help students seize their educational opportunities without barriers--especially financial barriers. I'm a firm believer that talent is distributed across zip codes equally,
                                       but opportunities are not, so am actively working to change that. I'm looking to sponsor students that are women, minorities and/or people of color that want to break into industries where they are under represented and believe representation matters.`;
export const ABOUT = 'About';
export const ABOUT_ME = 'About Me';
export const YES = 'Yes';
export const NO = 'No';
export const RESIDENCY_DROPDOWN = [
    'US Citizen',
    'DACA Recipient',
    'State Resident',
    'Non-State Resident',
    'Non-US Citizen',
];
export const COLLEGE_ENROLL_DROPDOWN = ['Part Time', 'Full Time', 'Other'];
export const GENDER_OPTIONS = [
    'No Gender Requirement',
    'Male',
    'Female',
    'Transgender',
    'Gender Neutral',
    'Non-binary',
    'A-gender',
    'Pan-gender',
    'Gender Queer',
    'Third Gender',
    'Two Spirit',
];
export const DEMOGRAPHIC_REQUIREMENTS_DROPDOWN = [
    'No demographic requirement',
    'White/Caucasian',
    'Black or African American',
    'American Indian and Alaska Native',
    'Cherokee tribal grouping',
    'Chippewa tribal grouping',
    'Navajo tribal grouping',
    'Sioux tribal grouping',
    'Asian',
    'Asian Indian',
    'Chinese',
    'Filipino',
    'Japanese',
    'Korean',
    'Vietnamese',
    'Other Asian',
    'Native Hawaiian and Other Pacific Islander',
    'Native Hawa',
    'Guamanian or Chamorro',
    'Samoan',
    'Other Pacific Islander',
    'Hispanic or latino',
    'Mexican',
    'Puerto Rican',
    'Cuban',
    'Other Hispanic or Latino',
];
export const ACADEMIC_OPTIONS = [
    'No academic level requirement',
    'High School freshman',
    'High School Sophomore',
    'High School Junior',
    'High School Senior',
    'College Freshman',
    'College Sophomore',
    'College Junior',
    'College Senior',
];
export const SCHOLARSHIP_DISPERSED_OPTIONS = ['College', 'Student', 'Other'];
export const SCHOLARSHIP_COVERS_OPTIONS = [
    'Tuition',
    'College Fees',
    'Room & Board',
    'Books',
    'Meal plan',
    'Transportation',
    'Other',
];
export const SELECT_STUDENT_LEVEL = 'Select Student Level';
export const STUDENT_LEVEL_OPTIONS = ['High School', 'College', 'Graduate Program', 'Doctoral Program'];
export const HIGH_SCHOOL = 'High School';
export const EXPECTED_HIGH_SCHOOL_GRADUATION_YEAR = 'Expected High School Graduation Year';
export const HIGH_SCHOOL_GRADUATION_YEAR = 'High School Graduation Year';
export const EXPECTED_COLLEGE_GRADUATION_YEAR = 'Expected College Graduation Year';
export const COLLEGE = 'College';
export const GRADUATE = 'Graduate Program';
export const GRADUATION_YEAR_ERROR_MESSAGE = 'High School Graduation Year should not be greater than current year';
export const EXPECTED_CLG_GRADUATION_YEAR_ERROR_MESSAGE =
    'College Graduation Year should not be lesser than or equal to High School Graduation Year';
export const COLLEGES = 'Colleges';
export const NEXT = 'Next Social Platform';
export const SEARCH_SCHOLARSHIPS = 'Search Scholarships';
export const START_SMART_SEARCH = 'Start Your Smart Search';
export const MY_SCHOLARSHIPS = 'My Scholarships';
export const SEARCH_SCHOLARSHIPS_PLACEHOLDER = 'Looking for a specific scholarship?';
export const BACK_TO_PROFILE = 'Back to profile';
export const MENU_TITLE = 'Click to open a menu';
export const UNPIN_SCHOLARSHIP = 'Unpin Scholarship';
export const MTACHES = 'You have Matches!';
export const BIO_ADVISOR_INSTRUCTION = `Start with the basics! We recommend including your name, current role or tagline, the organization/institution you work for (if any), future goals and aspirations, and what criteria or things you are looking for when deciding which students to support.`;
export const BIO_EXAMPLE_ADVISOR = `Hi, I'm Brynn! I started Your29 in 2022 as a way to help students seize their educational opportunities without barriers--especially financial barriers. I'm a firm believer that talent is distributed across zip codes equally, but opportunities are not, so am actively working to change that. I'm looking to sponsor students that are women, minorities and/or people of 
color that want to break into industries where they are under represented and believe representation matters.`;
export const ORG_PLACEHOLDER = `Tell us more about your organization. `;
export const ORG_EDIT_PLACEHOLDER = `Tell us more about the organization, school or program you represent. This should include it's name, the type and when it was founded (if relevant). 
Then a sentence or two about your mission, what you offer your clients/customers, and anything that makes 
you different (this could be something you specialize in).`;
export const ADVISOR_ONBOARDING_MESSAGE = `Let's get started with building your Your29 profile, so you can help students discover and connect with
the best colleges, scholarships, sponsors and more.`;
export const STUDENT_ONBOARDING_MESSAGE = `Let's get started building your profile so you can find, match and connect with students, colleges and sponsors.`;
export const ADVISOR_EXAMPLE_MESSAGE = [
    'High School Example:',
    'Redondo Union High School is a public high school in Redondo Beach, California, and part of the Redondo Beach Unified School District. It’s RUHS’ mission to provide a safe educational environment that supports the needs and co-curricular interests of a diverse population of students in meeting high academic expectations and post-secondary goals.',
    'Travel Team Example:',
    "Princeton FC (PFC) was founded in 2007 as the new and progressive youth soccer club in Princeton, NJ. PFC is Princeton’s premier soccer club with 30 travel soccer teams competing at a high level both inside and outside New Jersey.  We are focused on developing youth soccer players in a fun and challenging environment where learning the game's technical elements and tactical principles takes place. Our teams compete in EDP, EDP Futures, JAGS and Mid NJ Leagues, as well as NJYS State Cup, US Club State Cup and other elite tournaments.",
    'We believe soccer is a lifelong game to be enjoyed by all. We recognize the inherent value of positive competition as a means to prepare youth for the future. To this end, we endeavor to provide a positive, supportive and structured player development environment. Our player centered development environment is supported by well qualified, educated and competent professional coaching staff.',
];
export const STUDENT_EXAMPLE_MESSAGE = [
    "Hi, I'm Kim and I'm a senior in high school looking to attend a 4-year college or university within five hours of home where I hope to play competitive volleyball at the highest level, join the performance band, and earn a degree in Music Education and a coaching certification.",
    'Enrique grew up in Los Angeles and recently relocated to Redwood City, California. He is a first-generation college student who currently attends Cal Berkeley, is majoring in English, and plans to continue on to Law School. He works two part-time jobs to help pay for his college education and hopes to secure an internship.',
    "Hi, I'm Sam. I am in the 2023 high school graduating class and am looking forward to finding a college that is the right fit for me where I can major in Chemical Engineering. I am currently the Senior Class President, a member of the National Honors Society, a member of the Engineering Club at the my school, and have also enjoyed starting our after-school Stem Study Club.",
];
export const ABOUT_INSTRUCTION = 'Tell us more about you.';
export const INFO_MISSING_TEXT = 'It looks like some information is missing';
export const STUDENT_EDIT_INSTRUCTION = [
    'Let’s start with the easy stuff first. Make your first sentence your reason for creating this profile--your objective.',
    'Then, 2-3 sentences about who you are today. Include things like the clubs, activities, and groups you are involved in, and any awards or recognitions you’ve received.',
    'Finish the section by talking about what you like to do in your free time--your passions and hobbies.',
    'Let the reader get to know you and who you truly are.',
    'Remember, your clubs, activities, sports, and even your major are WHAT you do. Colleges, sponsors, and scholarships want to know WHO you are. Use this profile to shine with words, photos, and videos that showcase how unique and amazing your are.',
];
export const STUDENT_MESSAGE = [
    'Drop in your best profile pic and some',
    'basic information that will make it easier ',
    'for students, colleges, scholarships and',
    'sponsors to find you.',
];
export const SIGNUP_MESSAGE = 'Create Your Account';
export const SIGNUP_NEXT_MESSAGE = "and Let's Get Started in 30 seconds or less!";
export const USERNAME_HELPERMESSAGE = ' Your username will become the end of your web address for your profile.';
export const STUDENT_ONBOARDING_INSTRUCTION = [
    'Upload your profile picture ',
    'and add your basic information.',
    'Click the blue Next button when you are finished.',
];
export const ONBOARDING_COVERMEDIA_INSTRUCTION =
    'Great! Now, add a few pictures here so viewers can get to know you showing your favorite people, doing your favorite things, your favorite moments or your favorite places.';
export const SHOUTOUTS_MESSAGE = `We know you're amazing, so invite others to share their praise!`;
export const SHOUTOUTS_COMPLETE_MESSAGE = `There is no any Endorsement Request`;
export const SHOUTOUTS_PANDING_MESSAGE = `There is no any Pending Endorsement`;
export const SOCIALACCOUNTS_INSTRUCTION = `Stay connected by linking your other profiles. `;
export const STUDENT_SOCIALACCOUNTS_INSTRUCTION = [
    'Please know, uploading your other social account links is entirely optional',
    'and simply allows others to learn more about you if you choose.',
];
export const SOCIALACCOUNT_HEAD = `Almost finished!`;
export const INTERESTSECTION_ONBOARDING = 'Last Section!';
export const INTERESTINSTRUCTION_ONBOARDING =
    'What are your hobbies and interests? Let people get to know more about you.';
export const AWARDS_AND_ACCOMPLISHMENTS = 'Awards & Accomplishments';
export const MY_GOALS = 'My Goals';
export const GOAL_INSTRUCTION = 'Tell us more about your goals.';
export const WHY_YOUR_29 = 'Why Your29';
export const OPEN_TO = 'I Am Open To';
export const WHY_YOUR_29_INSTRUCTION = 'Tell us more about why Your 29.';
export const WHATS_DIFFERENT = 'What Makes Me Different';
export const WHATS_DIFFERENT_INSTRUCTION = 'Tell us more about what maks you different.';
export const MY_BACKGROUND = 'My Background';
export const MY_BACKGROUND_INSTRUCTION = 'Tell us more about your background.';
export const GOAL_INSTRUCTION_ON_EDIT =
    "Write 1-2 sentences about what you plan to do next. Include things like:  'Upon graduating in 2024, I plan to...' Use action words like 'I plan to' or 'I dream to' or 'I aspire to.' Be sure to include your intended major or career, where you hope to attend or what you hope to do with that major, and if you plan to participate in any activities while in college (band, athletics, cheer, dance, theatre, etc).";
export const WHY_YOUR29_INSTRUCTION_ON_EDIT =
    "Write 1-2 sentences about why you have joined the Your29 platform. Use words like 'I hope Your29 can' or 'It would be amazing if Your29 can' or 'I am in search of.'' Use action words like find, connect and discover and add words like mentorship, internship, scholarship or sponsorship.";
export const WHATS_DIFF_INSTRUCTION_ON_EDIT =
    'Write 1-2 sentences on what makes you special or different than other students similar in age, degree and/or interest. Consider briefly explaining why you are deserving or are needing this opportunity.';
export const BACKGROUND_INSTRUCTION_ON_EDIT =
    "Write 2-3 sentences about who you are now - what you currently do, the activities or clubs in which you participate and/or the jobs you currently have. Consider mentioning some of the awards you've won and a final sentence about what you like to do in your free time or a fun fact about yourself. Let people get to know you and what makes you special.";
export const SHOUT_OUTS = 'What They Are Saying About Me';
export const ACTIVITIES_CLUBS_SERVICE = 'Activities, Clubs & Service';
export const ACTIVITY_DESC_ERROR = 'Maximum 40 characters allowed.';
export const YOUR_FEED = 'Your Feed';
export const YOUR_PROFILE = 'Your Profile';
export const YOUR_ACCOUNT = 'Your Account';
export const SIGNOUT = 'Sign Out';
export const MYGOAL_EXAM =
    'Upon graduating high school in the spring of 2023, I hope to attend a large 4-year college, play soccer and earn a degree in Entrepreneurship and Global Business. It is my dream to represent my universtiy as a standout goalie on the soccer field and one day open my own Venture Capitalist Firm.';
export const WHYY29_EXAM = `I hope Your29 can connect me with standout female leaders in the Business world who want to mentor an aspiring college student who is a hard worker and dreams big. I dream to be able to find a scholarship or sponsorship to help my family financially after suffering great loss to our family business during Covid. `;
export const WHAT_MAKEME_EXAM = `I am a first-generation minority college student who is on a mission to become a strong yet compassionate and fun female leader and show my little sister - we can do this!`;
export const BACKGROUND_EXAM = `Growing up in the St. Louis area, I have always played volleyball and soccer and last season our high school soccer team won state and I was awarded All State. I'm thankful for my parents always pushing me academically where I have maintained a strong GPA and been a part of the National Honors Society. Even though I am the Student Council Vice President and captain for both teams, I definitely like to have fun at other sporting events, smile often and go out for cookie dough icecream with friends.`;
export const VIDEO_EDIT_INSTRUCTION = `Consider adding quick highlight clips, skills videos, speeches, and special moments you or someone else caught on video. Recruiters, program leaders, coaches and business leaders love to see you in action and showcasing your personality and abilities. (The best videos are those under 15 seconds with a close up view of you.)`;
export const ATTACHMENT_EDIT_INSTRUCTION = `This section is where you can add additional documents and information to your profile. Examples of these may include a picture of your course schedule, your transcript, your upcoming performances or game schedule, your personal essay, etc.`;
export const VIDEO_INSTRUCTION =
    'Upload a video introducing yourself and highlighting your personality. This short, creative video will bring your profile page to life, help you stand out and allow others to see your face, hear you speak and leave a lasting impression. (Recommended length: 45 sec or less)';
export const SIGNUP_USERNAME =
    '(Username cannot consist of any capital letters, spaces or symbols and must be less than 20 characters)';
export const WHAT_THEY_ARE_SAYING_INSTRUCTION = `Invite your favorite teachers, professors, program leaders, coaches and mentors to write a few quick sentences about you. An email will then be sent directly to them so they can quickly and easily respond.`;
export const WHAT_THEY_ARE_SAYING_INSTRUCTION_NEXTLINE = `The more recommendations or endorsements you have in this section, the stronger your profile becomes, the higher you are in student searches, and the more likely you are to Be Found and Be  Selected. `;
export const QUICK_INFORMATION = 'Quick Information';
export const WHY_YOUR29 = 'Why Your29';
export const SPONSORSHIPS_AND_SCHOLARSHIPS = 'Sponsorships & Scholarships';
export const FOR_MORE_INFORMATION = 'For More Information';
export const LEADER_GALLERY_TEXT = (role = '') =>
    `Please upload your ${role?.toLocaleLowerCase()}, department, or team logo or picture that best represents you and your initiative with Your29.`;
export const SPONSOR_GALLERY_TEXT =
    "Please upload your company's logo and/or a picture that best represents you and your initiative with Your29.";
export const LEADER_SOCIALICON_TEXT = (role = '') =>
    `Consider sharing your ${role?.toLocaleLowerCase()}, club or team social media pages on your Your29 page. `;
export const STOREFRONT_GALLERY_TEXT =
    'Please upload 1-3 pictures of your high school, your logo or your motto so students know they are on the right homepage. Then click REQUEST FOR APPROVAL.';
export const SPONSOR_SOCIALICON_TEXT = 'Consider sharing your other personal or business profiles on your page.';
export const MAX_ATTACHMENT_MSG = 'Attachment upload exceeds the maximum limit (12).';
export const ORGANISATION_ONBOARDING_INPUTFIELD = 'ABC Law Firm or The Jackson Family Sponsorship';
export const SPECIFIC_TYPE_OF_SPONSOR_PLACEHOLDER =
    'CEO / Managing Partner / Mom / Head Coach / Owner / Director of Human Resources / Professor / Administrative Assistant';
export const SPECIFIC_TYPE_OF_SPONSOR_LABEL = 'Who Am I';
export const TAGLINE_LABEL = 'Brief Description or Company Tagline';
export const TAGLINE_PLACEHOLDER =
    'Your29 - The Student Network: The trusted place for Students, Businesses and Colleges to connect or The Jackson Family believes there is no greater gift than the gift of education and opportunity.';
export const WEBSITE_PLACEHOLDER = 'Your29.com or The Jackson Family Sponsorship Page Blog (Optional)';
export const CATEGORY_LIST = ['Individual', 'Business', 'Non-Profit Organization', 'Foundation', 'Other'];
export const YOUR29_EDIT_INSTRUCTION = `Write 1-2 sentences about why you have joined the Your29 platform. Use words like "I hope to find..." or "I am in search of..." or "I hope to empower students to..." Use action words like find, connect, fund and discover and add words like mentorship, internship, scholarship or sponsorship.`;
export const YOUR29_EDIT_PLACEHOLDER =
    'I am on a mission to empower future female high school and college head coaches, principals and athletic directors through the sponsorship of their college education, a professional mentorship and the sharing of my network connections.';
export const MORE_ABOUT_ME_EDIT_INSTRUCTION =
    'Write 1-2 sentences about your mission, your foundation, your background and/or the sharing of your story as to why it is important to join Your29:  The Student Network.';
export const MORE_ABOUT_ME_EDIT_PLACEHOLDER = `After 20 years as a college coach and Division I administrator, my why is to teach, mentor, inspire and empower students to achieve their greatness. What better investment than investing in another's education.`;
export const FIRST_NAME = 'First Name';
export const LAST_NAME = 'Last Name';
export const REGISTERED_DATE = 'Registered Date';
export const COLLEGE_GRAD = 'College Grad Year';
export const HIGH_SCHOOL_GRAD = 'High School Grad Year';
export const ORGANIZATION_NAME = 'Company Name';
export const ORGANIZATION = 'Organization';
export const SPONSOR_PROFILE_SCHOLARSHIPS_HEADING = 'Your29 Opportunities';
export const LABEL_FOR_WHO = 'For Who';
export const LABEL_SCHOLARSHIP_TITLE = 'Name of Opportunity';
export const LABEL_SPONSOR_DESCRIPTION = 'Brief Description of Opportunity';
export const LABEL_SPONSOR_END_DATE = 'Deadline to Apply';
export const LABEL_SPONSOR_START_DATE = 'Date Opp is Posted';
export const LABEL_SPONSOR_AMOUNT = 'Amount';
export const LABEL_STOREFRONT_SCHOLARSHIP_AMOUNT = 'Scholarship Amount or Range';
export const LABEL_SPONSOR_OPPORTUNITY = 'Type of Opportunity';
export const PLACEHOLDER_SCHOLARSHIP_TITLE = '2024 Cape County Lions Club Scholarship';
export const PLACEHOLDER_SCHOLARSHIP_DESCRIPTION =
    'This scholarship is to support females in pursuit of leadship positions in education and in sport. Applicant must be an Education, Administration or KSS major.';
export const PLACEHOLDER_SCHOLARSHIP_AMOUNT = 'Amount of Each Scholarship';
export const PLACEHOLDER_SPONSORSHIP_AMOUNT = 'Amount of Each Sponsorship';
export const PLACEHOLDER_OPPORTUNITY_AMOUNT = 'Amount of Opportunity';
export const PLACEHOLDER_SCHOLARSHIP_NUMBER = 'Number of Scholarships to be Awarded';
export const PLACEHOLDER_SPONSORSHIP_NUMBER = 'Number of Sponsorships to be Awarded';

export const PLACEHOLDER_SCHOLARSHIP_OPPORTUNITY = 'Select Type of opportunity';
export const INSTRUCTION_SPONSOR_SCHOLARSHIP =
    'Are you looking for full-time hires, part-time help, interns or graduate assistants? Are you hosting a scholarship, an info session or an event? Are you looking to sponsor students, a team or club? Create your type of opportunity here and begin receiving applications and being found by amazing Your29 students.';
export const LABEL_SPONSOR_EDIT_NAME = 'Name:';
export const LABEL_SPONSOR_EDIT_AMOUNT = 'Amount:';
export const LABEL_SPONSOR_EDIT_DEADLINE = 'Deadline:';
export const LABEL_SPONSOR_EDIT_DESCRIPTION = 'Description:';
export const LABEL_SPONSOR_EDIT_ELIGIBILITY = 'Eligibility:';
export const LABEL_SPONSOR_EDIT_TYPE = 'Type:';
export const INSTRUCTION_SCHOLARSHIP_EMPTY =
    'Are you looking for full-time hires, part-time help, interns or graduate assistants? Are you hosting a scholarship, an info session or an event? Are you looking to sponsor students, a team or club? Create your type of opportunity here and begin receiving applications and being found by amazing Your29 students.';
export const INSTRUCTION_SCHOLARSHIP_CREATE =
    'Are you looking for full-time hires, part-time help, interns or graduate assistants? Are you hosting a scholarship, an info session or an event? Are you looking to sponsor students, a team or club? Create your opportunity here and begin receiving applications and being found by amazing Your29 students.';

export const LABEL_WHY_YOUR29 = 'Why Your29';
export const LOOKING_FOR = "I'm Looking For";
export const LABEL_EMPTY_OPPORTUNITY = 'Add your first Opportunity';
export const TEXT_ADD_OPPORTUNITY = 'Add Opportunity';
export const CURR_CLG_GPA = 'Current College GPA';
export const GPA = 'GPA';
export const MAJOR = 'Major';
export const SECOND_MAJOR = 'Second Major';
export const MINOR = 'Minor';
export const SECOND_MINOR = 'Second Minor';
export const CURR_GRAD_GPA = 'Current Graduate GPA';
export const GRAD_DEGREE = 'Graduate Degree';
export const UNDER_GRAD_MAJOR = 'Undergrad Major';
export const SECOND_UNDER_GRAD_MAJOR = 'Second Undergrad Major';
export const UNDER_GRAD_MINOR = 'Undergrad Minor';
export const LOOKING_FOR_EDIT_INSTRUCTION =
    'Select below the category of the student you are looking for and then each subsequent level accordingly. If you are looking for multiple students of the same category with different subsequent levels (ie. Graduate Assistant Marketing; Graduate Assistant Business), add  additional categories. Select save at the bottom of the page and then return to profile.';
export const YOUR29_OPPORTUNITIES = 'Your29 Opportunities';
export const LIST = 'List Of Team';
export const LABEL_SPONSOR_MORE_ABOUT_ME = 'More About Me';
export const LABEL_STOREFRONT_MORE_ABOUT_ME = 'More About Us';
export const GRADUATE_SCHOOL = 'Graduate School';
export const EXPECTED_GRADUATION_YEAR = 'Expected Graduation Year';
export const COLLEGE_GRADUATION_YEAR = 'College Graduation Year';
export const EXPECTED_GRADUATION_YEAR_ERROR_MESSAGE =
    'Graduation Year should not be lesser than or equal to College Graduation Year';
export const EXPORT = 'Export';
export const DELETE_TEXT = 'Image deleted successfully';
export const UPLOAD_TEXT = 'Image uploaded successfully';
export const UPDATE_TEXT = 'Image updated successfully';
export const IM_OPENTO_EDIT_INSTRUCTION =
    'The Your29 Student Network is to help you Find & Be Found for opportunities. Select below what you are looking for and hoping to find with Your29. Choose below how Your29 can help you Be Found. (Example: Sponsorship, Scholarship, Internship, Recruitment, Employment)';
export const SECOND_LEVEL = '2nd Level';
export const SPECIALIZATION = 'Specialization';
export const ENDORSEMENT_INSTRUCTION_1 =
    'Professor Smith, Can you please write a few sentences about me as a student speaking directly on my performance in your class, work ethic, attendance, character and personality? I am searching for graduate assistantships and your written recommendation will greatly help and will then appear directly on my Your29 student profile. Thank you Professor Smith.';
export const ENDORSEMENT_INSTRUCTION_2 =
    'Coach Jackson, Can you please write a few sentences about me as an athlete and as a person who played on your Varsity Basketball team the last 3 years possibly speaking directly to my skills, work ethic, hustle, determination, and leadership as a captain? I am hoping to be recruited to play basketball in college. Once submitted, your recommendation will appear directly on my Your29 profile and help very much. Thank you very much, Coach.';
export const ENDORSEMENT_INSTRUCTION_3 =
    'Mrs. Thomas, Can you please write a few sentences about me as a person since I have babysat for your family the last 2 years? I am hoping to find more babysitting, tutoring, and private lesson opportunities when I go away to college and your recommendation would be really helpful. Once submitted, your comments will directly appear on my Your29 student profile and help me find and be found for more employment opportunities to pay for my college education. Thank you Mrs. Thomas.';
export const ENDORSEMENT_INSTRUCTION_EAMPLES = 'Examples for you:';
export const EMPTY_CANDIDATES_DATA = 'There are no opportunities !';
export const CONNECT_INVITATION_MESSAGE =
    ', I wanted to reach out, as it seems like you would be the right person to discuss opportunities.';
export const HI = 'Hi';
export const SPONSOR_START_CONVERSATION = "Click on an existing chat or click 'Add' to create a new conversation";
export const STUDENT_START_CONVERSATION = 'Click on an existing chat to start conversation';
export const KEY_STATS = 'Key Stats';
export const UPLOAD_SELFIE_VIDEO = 'Upload a Selfie Video';
export const GOALS_SAVED = 'My Goals Saved Successfully';
export const WHY_YOUR29_SAVED = 'Why Your29 Saved Successfully';
export const WHAT_MAKES_ME_DIFFERENT_SAVED = 'What Makes Me Different Saved Successfully';
export const MY_BACKGROUND_SAVED = 'My Background Saved Successfully';
export const KEY_STATS_SAVED = 'Key Stats Saved Successfully';
export const SELFIE_VIDEO_SAVED = 'Selfie Video Saved Successfully';
export const MAX_CHARACTER_400 = 'Max. of 400 characters';
export const MAX_CHARACTER_500 = 'Max. of 500 characters';
export const KEY_STATS_EDIT_INSTRUCTION =
    'The Your29 Student Network is all about helping you Find & Be Found for opportunities. Consider adding your key stats below to help you promote yourself and be found by sponsors, employers, recruiters and colleges searching for applicants on Your29.';
export const MAX_CHARACTER_15 = 'Max. of 15 characters';
export const ADDITIONAL_INSTRUCTION = `Add and upload any additional documents or attachments here.`;
export const SHARE_ICON_STUDENT_INSTRUCTION =
    "Click here to share your profile over email, text, and on your social handles with employers, colleges, recruiters and coaches. Simply add your custom url to your signature line. It's everything they want to know and you want to tell.";
export const COACH_KIM_TIP = 'Coach Kim Tip:';
export const ONBOARDING_THREEDOTS_INSTRUCTION =
    'If you ever need to edit or delete your selection, click these three dots.';
export const SEARCH_OPPORTUNITIES_INSTRUCTION =
    'Click here to search scholarships, sponsorships, mentorships & employment opportunities.';
export const MYAPPLICATION_INSTRUCTION =
    'After applying to opportunities, click here to track the updated status of your applications (applied, accepted, rejected and archived).';
export const MESSAGE_INSTRUCTION =
    'Sponsors (businesses & individuals) can request to connect and message with you directly on the Your29 platform. Keep checking back for new messages here.';
export const OPEN_TO_INSTRUCTION =
    'Businesses and Individuals (Sponsors) are looking for you. Select the types of opportunities here you are looking for so you can be found. (Scholarships, sponsorships, jobs, internships, etc.)';
export const KEY_STATS_INSTRUCTION = 'Enter your Key Stats so you can quickly be found';
export const SEARCH_STUDENT_INSTRUCTION =
    'Click here to search for scholars, students to sponsor, students to hire, intern, mentor and recruit. Also, "pin" and save your favorite students for quick reference.';
export const CANDIDATES_INSTRUCTION =
    'Track, review and file applications received here. Also, message top candidates directly with one click.';
export const SPONSOR_MESSAGE_INSTRUCTION =
    'Request to connect and message students directly on the Your29 platform. Keep checking back for new messages and updates here.';
export const I_M_LOOKING_FOR_INSTRUCTION =
    'Key words in this section will automatically populate each time you add a Your29 Opportunity in the section below. These key words will help students find, match and apply to your opportunities.';
export const SHARE_ICON_SPONSOR_INSTRUCTION =
    'Click here to share your profile and your opportunities over email, text and on your social handles. No need to write out descriptions and qualifications. Simply share your opportunities and students will have everything they need to know and can apply with one click. And now you can better find the students you are looking for.';
export const POPOVER_TEXT_MYGOAL = `Hi, guys and congratulations on now having your very own custom URL address that you can access edit and share 
from anywhere Anytime Anyplace and as often as you like I'm coach Kim and here's my tips for you my goals here you want to write two or three sentences 
as specific and direct as you possibly can about what you hope to achieve in the next six months 12 months or even a few years try and answer the questions 
who what where when why and how let's try this I want to go to college no I want to go to a four-year College better I hope to attend a four-year University in the midwest 
earn a spot on the soccer team and possibly earn an athletic scholarship playing division one soccer while earning my degree in education with the hopes of becoming a high school 
math teacher and coach at my former High School there that tells people exactly what's on your mind what's on your heart and what 
you are working to achieve okay be direct be specific you can do this it's go time .`;
export const POPOVER_TEXT_MYBACKGROUND = `Quick tips for my background tell your story if someone had 30 seconds to introduce you and tell who you have been and who you are 
today what would they say youngest of five grew up in a military family have lived in seven different states as much as I compete on the court and love playing sports I'm even
more nerdy in the classroom competing for good grades I love to play cards and I have a soft spot for puppies and movies there give someone an inside look as to who you are they
want to get to know you tell your story.`;
export const POPOVER_TEXT_WHAT_MAKE_ME_DEFFERENT = `Quick tips for what makes me different ask yourself the question why you if a sponsor is considering five other students similar to 
you what makes you stand out what makes you different what makes you special think about words like this first generation foster kid single parent speak multiple languages Captain leader 
plays multiple Sports fast tall play several position what makes you special employers recruiters coaches sponsors are all looking for special let them get to know you and why they should 
choose you help them find you help yourself be found help them find special go .`;
export const POPOVER_TEXT_WHY_YOUR29 = `Quick tips for why you're 29 why are you creating this profile right now be direct be specific I'm hoping for a sponsorship and mentorship so I can 
afford new scrubs and a stethoscope as I begin the accelerated nursing program I'm hoping to be found for a band scholarship and also give private lessons to Young students and gain experience 
for my music education degree I'm hoping for a professional mentorship and a sponsorship to afford a new laptop and also be able to travel home for the holidays when I begin College next fall be 
direct be specific be respectful now go write down your why.`;
export const POPOVER_WHAT_THEY_ARE_SAYING_TEXT = `Okay what they are saying about me instead of having to ask someone to write a recommendation letter upload it share it attach it no more think of these as quick endorsements you want to have at least three and then keep adding to them who should you ask a favorite teacher Professor coach leader of an organization maybe it's your pastor maybe it's the family you've babysat forthe last three years think about who is in your corner and who knows you well and can speak about your skills character work ethic or personality okay now send out those four or five requests as soon as they submit them voila it's on your profile for everyone to see okay get after it go .`;
export const POPOVER_ACTIVITIES_TEXT = `Activities clubs and services this is the section where you earn bonus points after 20 years of being a college coach and administrator I was looking for good who we could make great but then something special something special that that person brought to the table and would add to our program this is where you show special include things like Mentor tutor Big Brothers Big Sisters student council student government class Council include other things like drama musical band choir multiple Sports volunteer at the animal shelter. Fellowship of Christian athletes youth group and don't forget to list all those part-time jobs that you've had that shows work commitment work ethic and drive show everyone how special you are and don't forget to include those pictures Everyone likes pictures ready go .`;
export const POPOVER_ACCOMPLISHMENT_TEXT = `Okay quick tips on Awards and accomplishments don't think of this section as bragging instead think of this section as showing and telling the world all that you already have done consider things like this all conference all area all district all academic and also Captain secretary vice president president chair of the trumpet section remember to include those honors classes AP classes and any induction into honors societies also if you've earned any certifications like your CPR or your CNA make sure you include those here alright now go check out those trophy shelves and those certificates and tell the world how awesome you are .`;
export const POPOVER_VIDEO_TEXT = `Quick tips for video uploads performers and athletes this is your section athletes coaches do not want to watch full game film instead grab those highlights edit them down into 15 second clips that highlight and showcase you not everyone else make sure it's of clear and good quality then no need to go ask for and pay for a professional video to be made save your money use your smartphone zoom in and showcase your skills again 15 second Clips Clear and good quality showcasing what you are good at if you have speed Show Yourself running so the viewer can get out their stopwatch if you have power show your power if you are a pitcher for example grab a radar gun show five pitches and after each pitch show that radar gun and how hard you throw let your videos speak for yourself performers grab those ensembles grab those speeches grab grab those highlights and those solos make highlight Clips 15 seconds long to really showcase your talents and your skills let those videos speak for you you want these recruiters coaches directors to watch you get excited about you and reach out to learn more you can do this get these videos going here we go .`;
export const POPOVER_ATTACHMENT_TEXT = `Quick tips for additional attachments upload any additional documents here save them as a PDF or a JPEG image think about things like this transcript course schedule entrance exam results test score results career statistics game stats upcoming game schedule personal essay personal writing sample there you have it make sure you save it as a very descriptive name so the reader knows exactly what it is alright make that profile amazing you're almost done let's finish this.`;
export const POPOVER_SELFIESVIDEO_TEXT = `Quick tips for the selfie video let's bring that profile to life 45 seconds or less clear and of good quality and sound introduce yourself briefly explain your goals and then let people get to know you something they can't learn on your profile let me give it a shot hi I'm coach Kim founder and CEO of year29 teaching and coaching is my background inspiring and empowering others to achieve their greatness is definitely my passion I'm a wife a mom a competitor a leader a fighter and a hard worker I have a soft spot for people and Hallmark Christmas movies there you go there's your example clear and of good quality make sure it's classy and appropriate but definitely have fun and show your personality alright lights camera and record .`;
export const WHAT_THEY_ARE_SAYING = 'What They Are Saying';
export const STUDENT_APPLIED_OPP = 'Student Applied Opportunities';
export const SPONSOR_OPP = 'Sponsor Opportunities';

export const POPOVER_PROFILE_COMPLETION =
    'Be sure to complete each section of your Student Profile to earn a 100% Completion Rate and appear first so you can Be Found in student searches and Be Selected for opportunities. Request and receive recommendations or endorsements in your "What They Are Saying About Me" section, and earn a special gold badge here on your profile pic for all to see.';
export const POPOVER_PROFILE_STRENGTH =
    'The more recommendations or endorsements you have in your "What They are Saying About Me" section, the stronger your profile is and more likely you are to be selected.Get both your Profile Completion and Profile Strength to 100% and earn a gold badge for all to see and to be listed at the top of student searches';
export const SONSOR_KEY_SEARCH_INSTRUCTION =
    'Enter any word to search Your29 students (zip code, high school, college, major, sport, etc)';
export const STUDENT_KEY_SEARCH_INSTRUCTION =
    'Enter any word to search Your29 Opportunities (zip code, company, major, sport, etc)';
export const PAYMENT_TIP = 'Click here to submit payment to Your29 and award the Your29 Student.';
export const COMPLETE_PROGRAM_TIP =
    'Click here after payment has been submitted and the student has completed this Your29 opportunity.';
export const MESSAGE_TIP = 'My Messages';
export const STUDENT_SEARCH_TIP = 'Search Students';
export const MY_CANDIDATES_TIP = 'My Candidates';
export const SHARE_TIP = 'Share Your Profile';
export const OPPORTUNITIES_SEARCH_TIP = 'Search Opportunities';
export const CREATE_OPPORTUNITY = 'Add Opportunities';
export const CREATE_OPPORTUNITY_MESSAGE =
    'Add your opportunities and events for students here (Sponsorships, Scholarships, Events, Internships, Jobs)';
export const STUDENT_MY_APPLICATION_TIP = 'My Applications';
export const COLLEGE_SEARCH = 'Search Colleges';
export const COLLEGE_SEARCH_TIP =
    'Click here to search and match with colleges all over the country to find your right fit.';
export const MY_FAV_TIP =
    'Pin your favorite student profiles and your top 10 will be saved up here for quick reference.';
export const SIZE_LESS_THAN = 'file size should be less than 100 MB.';
export const MAX_FILE_SIZE = 100;
export const HERE_FOR_YOU = 'Have a question? We are here for you. Click here.';
export const ADD_MOBILE_NO_MSG = 'Please go to account settings -> security and add your Mobile Number.';
export const HELP = 'Help Section';
export const STUDENT_PROFILE_HELPSECTION = 'How to create my Student Profile';
export const SPONSOR_PROFILE_HELPSECTION = 'How to create my Sponsor Profile';
export const STUDENT_FEED_HELPSECTION = 'How to navigate and use the Student Feed';
export const SPONSOR_FEED_HELPSECTION = 'How to navigate and use the Sponsor Feed';
export const STOREFRONT_PROFILE_HELPSECTION = 'How to create my Homepage Profile';
export const STOREFRONT_FEED_HELPSECTION = 'How to navigate and use the Homepage Feed';
export const MY_FAV_TIP_FOR_COLLEGE =
    'Pin your favorite colleges and your top 10 will be saved up here for quick reference.';
export const WANT_COLLEGE_CONTACT_YOU = (collegeName) =>
    `Do you want "${collegeName}" to contact you via email or text?`;
export const CONFIRM_TO_CONNECT = 'Confirm to connect with college.';
export const INTERESTED_TO_CONNECT = 'Interested students to contact with colleges';
export const VIRTUAL_TRACK = 'The Virtual Track';
export const VIRTUAL_SUBTITLE = 'You are more than just a resume!';
export const PERSONAL_OPPORTUNITIES = 'Current P&G Opportunities';
export const PERSONAL_SUBTITLE = 'Current P&G Campus Event Sign Ups:';
export const OPPORTUNITIES_PAGE_FOOTER =
    'Remember... with Your29 You are more than just a resume. We are here for you to Find & Be Found.';
export const EVENT_SNACKBAR_TEXT_AUTHORISED = 'Congratulations! Your application has been submitted for ';
export const EVENT_SNACKBAR_TEXT_UNAUTHORISED = 'You are requesting to watch ';
export const EVENT_REGISTRATIONING_MESSAGE = 'You are registering for ';
export const EVENT_REQUESTING_TITLE = 'Confirm Your Request';
export const EVENT_REGISTRATION_TITLE = 'Confirm Your Registration';
export const FINISH_POPUPBOX_MESSAGE =
    'Please confirm this student has successfully completed this opportunity and you approve payment (if applicable). Once you click "Confirm" this application process is finished and complete, and cannot be modified.';
export const WHAT_THEY_ARE_SAYING_TOOLTIP = ` The more recommendations or endorsements you have in your "What They are Saying About Me" section, the stronger your profile becomes, and more likely you are to Be Found in student searches and Be Selected for opportunities.`;
export const WHAT_THEY_SAYING_ININTIAL_INSTUCTION = `Invite your favorite teachers, professors, program leaders, coaches and mentors to write a few quick sentences about you. The more recommendations or endorsements you have in this section, the stronger your profile becomes and the more likely you are to Be Found in searches and Be Selected for opportunities. (Click the pencil icon to begin.)`;
export const ROLE_POSITION_PLACEHOLDER = 'President / Volunteer / Captain / Starter / Intern';
export const ACTIVITY_TITLE_PLACEHOLDER = 'Marching Band / The Art Club / AAU Basketball Team / Babysitting';
export const DATE_SEASON_PLACEHOLDER = 'Summers 2022, 2023 / 2020-2023 / 2021-Present ';
export const ACCOMPLISHMENT_TITLE_PLACEHOLDER = `Dean's List / All-State Soccer / National Honor's Society / Student of the Year`;
export const MAX_CHARACTER_40 = 'Maximum 40 characters allowed';
export const MAX_CHARACTER_30 = 'Maximum 30 characters allowed';
export const MAX_CHARACTER_20 = 'Maximum 20 characters allowed';
export const MAX_CHARACTER_10 = 'Maximum 10 characters allowed';
export const MAX_CHARACTER_200 = 'Maximum 200 characters allowed';

export const PRIVACY = `We care about data privacy and security. By using the Site, you agree to be bound by our Privacy Policy 
posted on the Site, which is incorporated into these Terms of Use. Please be advised the Site is hosted in the United States. If you access 
the Site from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from 
applicable laws in the United States, then through your continued use of the Site, you are transferring your data to the United States, and you agree 
to have your data transferred to and processed in the United States. Further, we do not knowingly accept, request, or solicit information from children or 
knowingly market to children. Therefore, in accordance with the U.S. Children’s Online Privacy Protection Act, if we receive actual knowledge that anyone under 
the age of 13 has provided personal information to us without the requisite and verifiable parental consent, we will delete that information from the Site as quickly as is reasonably practical.`;
export const TERMS_AND_CONDITION = `Messaging Terms & Conditions Your29 SMS Text | Your29 | 6300 Sagewood Dr Ste H480 Park City UT US 84098-7502 General When you opt-in to the service, we will 
send you a message to confirm your signup. By opting into messages, you agree to receive recurring automated marketing and informational text messages from Your29 for Your29 SMS Text. Automated 
messages may be sent using an automatic telephone dialing system to the mobile telephone number you provided when signing up or any other number that you designate. Message frequency varies, and 
additional mobile messages may be sent periodically based on your interaction with Your29 SMS Text. Your29 reserves the right to alter the frequency of messages sent at any time to increase or 
decrease the total number of sent messages. Your29 also reserves the right to change the short code or phone number where messages are sent. Message and data rates may apply. If you have any questions
 about your text plan or data plan, it is best to contact your wireless provider. Your wireless provider is not liable for delayed or undelivered messages.
 Your consent to receive marketing messages is not a condition of purchase. Carriers Carriers are not liable for delayed or undelivered messages. Cancellation You can cancel any time by texting "STOP". 
 After you send the SMS message "STOP", we will send you a message to confirm that you have been unsubscribed and no more messages will be sent. If you would like to receive messages from Your29 again, 
 just sign up as you did the first time and Your29 will start sending messages to you again. Info Text "HELP" at any time and we will respond with instructions on how to unsubscribe. For support regarding 
 our services, email us at kim@your29.com. Transfer of Number You agree that before changing your mobile number or transferring your mobile number to another individual, you will either reply “STOP” from the
  original number or notify us of your old number at kim@your29.com. The duty to inform us based on the above events is a condition of using this service to receive messages. Privacy If you have any questions 
  about your data or our privacy practices, please visit our [Privacy Policy]. Messaging Terms Changes We reserve the right to change or terminate our messaging program at any time. We also reserve the right to
   update these Messaging Terms at any time. Such changes will be effective immediately upon posting. Your continued enrollment following such changes shall constitute your acceptance of such changes.`;

export const WELCOME_TO_FEEDBACK = (name) =>
    `Welcome back ${name}! We would love to hear about your experience so far on Your29 and how we can improve. Thanks so much for your quick feedback and enjoy your day!`;
export const CREATE_GROUP = 'Create Team';
export const SCHOLARSHIP_PAID_MESSAGE =
    'We are excited to post your scholarship on the Your29 platform so you can Find & Be Found by amazing students.There is no fee to post a scholarship or sponsorship on Your29 until you receive applicants and you want to reveal, review and select students.';
export const SPONSORSHIP_PAID_MESSAGE =
    'We are excited to post your sponsorships on the Your29 platform so you can Find & Be Found by amazing students.There is no fee to post a scholarship or sponsorship on Your29 until you receive applicants and you want to reveal, review and select students.';
export const SCHOLARSHIP_STUDENTS_NUMBER_MESSAGE =
    'We are excited to post your opportunity on Your29 and look forward to revealing applicants, simplifying your review, and easing your selection processes. Quickly and efficiently get to your top applicants and enjoy the process with the Your29 platform.';
export const LIMIT_MESSAGE =
    'You have reached your limit of students to accept. To accept more students, please click below and increase your number of students to award for this opportunity.';
export const MESSAGE_FOR_SIGNUP =
    'To apply, quickly sign up in 30 seconds or less and begin finding and applying to opportunities on the Your29 platform.';
export const EMPTY_MYTEAM_LIST_MESSAGE =
    'Add your teams and invite team members to connect and apply for opportunities.';
export const LEADER_DESCRIPTION_PLACEHOLDER =
    'We are Cape Central High School located in Cape Girardeau, Missouri and here to serve, connect, inspire and empower our students to achieve their own greatness.';
export const STOREFRONT_OPPORTUNITY_INSTRUCTION = `Is your high school hosting scholarships, sponsorships or contests for your students? Is your high school hosting an event or inner school election or department competition? Create your type of opportunity here for your students and begin receiving applications and helping your students be found
 for opportunities.`;
export const STOREFRONT_YOUR29_INSTRUCTION = `Write 1-2 sentences about how your high school will use the Your29 platform to help your students find and apply for scholarships, sponsorships, campaigns and events.`;
export const STOREFRONT_MORE_ABOUT_US_INSTRUCTON = `Write 1-2 sentences about your mission, your foundation and/or your background to join Your29: The Student Network to benefit your students.`;
export const SPONSOR_OPPORTUNITY_QUES_1 =
    'Are you a current High School Senior in the counties of Bollinger, Cape, Perry, Scott, and Stoddard graduating this spring 2024? (If no, you do not qualify.)';
export const SPONSOR_OPPORTUNITY_QUES_2 =
    'Have you attached both a pdf document with the required Written Submission and your Letter of Admission or Acceptance into college to this application? (If no, please attach to be considered.)';
export const SPONSOR_OPPORTUNITY_QUES_3 =
    'Do you have at least one recommendation or endorsement on Your29 profile and have you attached either to your profile or this application a picture of your high school transcript? (If no, please do so to be considered.)';
export const STOREFRONT_OPPORTUNITY_QUES_1 =
    'Have you attached both a pdf document with the required Written Submission and your Letter of Admission or Acceptance into college to this application? (If no, please attach to be considered.)';
export const STOREFRONT_OPPORTUNITY_QUES_2 =
    'Have you been an active member of Student Council for at least 2 years? (If no, you do not qualify.)';
export const STOREFRONT_OPPORTUNITY_QUES_3 =
    'Are you graduating with a minimum gpa of 3.0 or higher and have attached your transcript to this application? (If no, please attach to be considered.)';
export const REQUEST_APPROVAL_MESSAGE = `Click CONFIRM below to submit your request to claim your high school homepage. An approval email will be sent from the Your29 Admin Team within 24 hours.`;
export const MORE_ABOUT_ME_EDIT_INSTRUCTION_STOREFRONT = `Write 1-2 sentences about your high school mission to serve, support, inspire and empower your students to achieve their own greatness.`;
export const MORE_ABOUT_ME_PLACEHOLDER_STOREFRONT = ` Insert your high school mission or motto about serving your students.`;
export const YOUR29_EDIT_PLACEHOLDER_STOREFRONT = `We are on a mission to make our ABC High School scholarships, sponsorships, contests and events available and accessible to all of our students utilizing a streamlined student friendly application process here on Your29.Here on Your29, our students can create one profile, update it accordingly throughout their high school and college career, and apply for a multitude of scholarships, sponsorships and events both in high school and beyond.`;
export const YOUR29_EDIT_INSTRUCTION_STOREFRONT = `Wite 1-2 sentences about why you and your high school have joined the Your29 platform.`;
export const EMPTY_PLACEHOLDER_STOREFRONT_OPP =
    'Post your high school scholarships, sponsorships, contests and events here so your students can seamlessly search, review and apply.';
export const YOUR29_USER_SUBSCRIPTION_POINT = [
    <Typography className="text-13 flex flex-row items-baseline gap-1">
        <span className="text-17 font-bold">✔ </span>Post Jobs & Internships
    </Typography>,
    <Typography className="text-13 flex flex-row items-baseline gap-1">
        <span className="text-17 font-bold">✔ </span>Post Scholarships & Sponsorships
    </Typography>,
    <Typography className="text-13 flex flex-row items-baseline gap-1">
        <span className="text-17 font-bold">✔ </span>Search & Match Students
    </Typography>,
    <Typography className="text-13 flex flex-row items-baseline gap-2">
        <span className="text-17 font-bold">x </span>Review & Select Applicants
    </Typography>,
    <Typography className="text-13 flex flex-row items-baseline gap-2">
        <span className="text-17 font-bold">x </span>
        <span>Connect & Message Students</span>
    </Typography>,
    <Typography className="text-13 flex flex-row items-baseline gap-2">
        <span className="text-17 font-bold">x </span>
        <span>Hire Students</span>
    </Typography>,
];
export const YOUR29_PLAN_SUBSCRIPTION_POINT = [
    <Typography className="text-13">
        <span className="text-17 font-bold">✔ </span>Post Jobs & Internships
    </Typography>,
    <Typography className="text-13 flex flex-row items-baseline gap-1">
        <span className="text-17 font-bold">✔ </span>Post Scholarships & Sponsorships
    </Typography>,
    <Typography className="text-13 flex flex-row items-baseline gap-1">
        <span className="text-17 font-bold">✔ </span>Search & Match Students
    </Typography>,
    <Typography className="text-13 flex flex-row items-baseline gap-1">
        <span className="text-17 font-bold">✔ </span>Review & Select Applicants
    </Typography>,
    <Typography className="text-13 flex flex-row items-baseline gap-1">
        <span className="text-17 font-bold">✔ </span>
        <span>Connect & Message Students</span>
    </Typography>,
    <Typography className="text-13 flex flex-row items-baseline gap-1">
        <span className="text-17 font-bold">✔ </span>
        <span>Hire Students</span>
    </Typography>,
];
export const PLAN_VAL = 'Your29 Plan';
export const ALREADY_SUBSCRIPTION_REQUESTED = 'Your request has been submitted and is currently pending approval.';
export const MESSAGE_ACTION_FOR_UNSUBSCRIBED_USER =
    'Sorry, a subscription is required for this action. Please select Subscribe below to begin your subscription or request a 30 day trial on Your29.';
export const SUBSCRIPTION_CONFIRMATION_MESSAGE =
    'Your subscription request has been sent! The Your29 Admin team will review your request and be in contact with you within the next 24 hours. Thank you and welcome to Your29!';
export const MESSAGE_ACTION_FOR_ALREADYSUBSCRIBED_USER =
    'Sorry, a subscription is required for this action. Your request has been submitted. Please wait for approval.';
