/* eslint-disable import/no-anonymous-default-export */

// https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
export const generateGuid = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (c ^ (window.crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );
};

export const generateRange = (len = 0) => Array.from(Array(len).keys());

// https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
export const purgeEmptiness = (obj = {}) => {
    return Object.keys(obj)
        .filter((k) => obj[k] != null && obj[k] !== '')
        .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
};

export default { generateGuid, generateRange, purgeEmptiness };
