import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/Auth';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { deleteFeedback, getAllFeedbacks } from '../../../services/app/api';
import BlockingLoader from '../../UI/BlockingLoader';
import MuiModal from '../../UI/MuiModal';
import Pagination from '../../UI/Pagination';
const FeedbacksList = (props) => {
    const { handleEditFeedback, handleOpenResponse } = props;
    const [feedbacks, setFeedbacks] = useState([]);
    const [pagination, setPagination] = useState({ page: 1 });
    const [loading, setLoading] = useState(false);
    const snackbar = useSnackbar();
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const { getRole } = useAuth();

    const fetchFeedbacks = () => {
        setLoading(true);
        let params = {
            page: pagination?.page,
            pageSize: 10,
        };
        getAllFeedbacks({ params })
            .then((res) => {
                setPagination(res.data.paginate);
                setFeedbacks(res?.data?.data);
            })
            .catch(() => {
                snackbar.error('Failed to fetch feedback forms');
            })
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        fetchFeedbacks();
    }, [pagination?.page]);

    const handleDeleteFeedback = () => {
        deleteFeedback({ feedback_id: openDeleteConfirmation })
            .then((res) => {
                fetchFeedbacks();
                snackbar.success('Feedback form deleted');
            })
            .catch(() => {
                snackbar.error('Failed to delete feedback form');
            })
            .finally(() => {
                setLoading(false);
                setOpenDeleteConfirmation(false);
            });
    };

    return (
        <div className="flex flex-col gap-6 mt-10">
            <MuiModal
                handleClose={() => setOpenDeleteConfirmation(false)}
                handleConfirm={handleDeleteFeedback}
                open={!!openDeleteConfirmation}
                title={'Delete Feedback'}
                message={'Are you sure want to delete this feedback?'}
            />
            <BlockingLoader loading={loading} />
            <p className="text-primary font-geometric text-3xl">All Feedbacks</p>
            {!!feedbacks?.length ? (
                feedbacks?.map((feedback) => (
                    <Paper key={feedback?.id} className="w-full rounded-lg">
                        <div className="group flex w-full justify-between items-center bg-primary p-4 rounded-t-lg">
                            <p className="text-white font-geometric text-2xl">{feedback?.name}</p>
                            <div className="flex items-center">
                                <p
                                    className={`group-hover:hidden p-2 py-[3px] rounded-2xl text-xs font-geometric border-2 border-white ${
                                        feedback?.active ? 'bg-green-200 text-green-700' : 'bg-red-200 text-red-700'
                                    } `}>
                                    {feedback?.active ? 'Active' : 'Inactive'}
                                </p>
                                <div className="hidden group-hover:block">
                                    {getRole() === 'SuperAdmin' && (
                                        <IconButton
                                            onClick={() => setOpenDeleteConfirmation(feedback?.id)}
                                            sx={{ background: '#ffffff', '&:hover': { background: '#ffffff' } }}
                                            size="small">
                                            <DeleteIcon className="text-red-500" fontSize="small" />
                                        </IconButton>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="p-4">
                            <div>
                                <p className="text-secondary font-geometric text-xl">Questions</p>
                                <ol className="list-decimal ml-10">
                                    {!!feedback?.questions &&
                                        feedback?.questions?.map((que, index) => (
                                            <li
                                                className="font-geometric text-gray-800 text-base first-letter:capitalize "
                                                key={que?.id}>
                                                {que?.question}
                                            </li>
                                        ))}
                                </ol>
                            </div>
                            <div className="flex justify-end gap-4">
                                <Button size="small" variant="text" onClick={() => handleOpenResponse(feedback?.id)}>
                                    View response
                                </Button>
                                {getRole() === 'SuperAdmin' && (
                                    <Button
                                        onClick={() => handleEditFeedback(feedback)}
                                        variant={'contained'}
                                        size="small">
                                        Edit
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Paper>
                ))
            ) : (
                <p className="text-primary font-geometric text-3xl text-center">No Feedback available</p>
            )}

            <Pagination
                page={pagination.page}
                count={pagination.totalPages}
                onChange={(e, page) => setPagination({ ...pagination, page })}
                hide={!feedbacks?.length}
                className="mt-8 mx-auto"
            />
        </div>
    );
};

export default FeedbacksList;
