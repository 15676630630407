import { createTheme } from '@mui/material/styles';

// Capture MUI's default theme object
const muiTheme = createTheme();

const fontFamily = '"Open Sans", sans-serif';

const theme = createTheme({
    palette: {
        // Blue Opal
        primary: {
            main: '#103B58',
        },
        // Hot Coral
        secondary: {
            main: '#F35B54',
        },
        // Swim Cap
        bluish: {
            main: '#38A4D0',
        },
        // Acid Lime
        limish: {
            main: '#BBDF32',
        },
        // Knock Out Pink
        pinkish: {
            main: '#FF3EA5',
        },
    },
    typography: {
        fontFamily,
        body1: {
            ...muiTheme.typography.body2,
            fontFamily,
        },
    },
    shape: {
        borderRadius: 2,
    },
    // components: {
    //     MuiInputBase: {
    //         styleOverrides: {
    //             input: {
    //                 border: '2px solid #103B58',
    //                 borderRadius: '4px',
    //                 position: 'inside'
    //             },
    //         },
    //     },
    // },
});

export default theme;
