/* eslint-disable import/no-anonymous-default-export */

import Autocomplete from '@mui/material/Autocomplete';
import Label from './Label';

export default (props) => {
    const {
        classes = {},
        sx = {},
        label,
        placeholder = '',
        helperMessage = '',
        error = false,
        errorMessage = '',
        disablePortal = true,
        disableClearable = true,
        noHelperText = false,
        ...rest
    } = props;

    return (
        <div className={['flex flex-col', classes.root || ''].join(' ')}>
            <Label value={label} />
            <Autocomplete
                className={classes.autocomplete}
                disablePortal={disablePortal}
                disableClearable={disableClearable}
                sx={sx}
                {...rest}>
                {props.children}
            </Autocomplete>
            {noHelperText ? '' : error ? (
                <Label flavor="error" value={errorMessage || `${label || placeholder} seems to be incorrect.`} />
            ) : (
                <Label flavor="helper" value={helperMessage} />
            )}
        </div>
    );
};
